import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder } from '@ngneat/reactive-forms';
import { finalize } from 'rxjs/operators';
import { PageUserZean, UserRole, UserService, UserZean } from 'src/app/apis/user.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { alertSuccess, alertWarning } from 'src/app/utils/functions/alert';

@Component({
  selector: 'app-sage-user-form-offcanvas',
  templateUrl: './sage-user-form-offcanvas.component.html',
  styleUrls: ['./sage-user-form-offcanvas.component.scss']
})
export class SageUserFormOffcanvasComponent extends AppCore implements OnInit, OnChanges {
  @Input() Data: PageUserZean;

  form = this.createForm();
  saving = false;

  userRoleItems: UserRole[] = [];
  constructor(
    private ngFb: FormBuilder,
    private svUser: UserService,
  ) {
    super();

    this.getRoles();
  }

  currentAgentChanged(): void {
    if (!this.currentAgent) {
      return;
    }

    this.form.patchValue({
      ask4betAgentId: this.currentAgent.id,
    });
  }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.form = this.createForm();
    this.form.patchValue({
      ask4betAgentId: this.currentAgent?.id,
    });

    this.setFormZeanRole();
    if (this.Data) {
      this.form.removeControl('username');
      this.form.removeControl('password');
      this.form.removeControl('ask4betAgentId');
      const roles = JSON.parse(this.Data.role || "{}") as any;
      this.form.patchValue({
        name: this.Data.name,
        profit_sharing: this.Data.profit_sharing,
        team_aff: this.Data.team_aff,
        image: '',
        roleIds: roles?.roleId,
      });
      this.setFormZeanRole();
    }
  }

  createForm() {
    return this.ngFb.group<any>({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
      ask4betAgentId: [null],
      name: ['', [Validators.required]],
      profit_sharing: [0, [Validators.required, Validators.min(0)]],
      team_aff: [''],
      image: [''],
      roleIds: [null],
    });
  }

  onSubmit() {
    if (this.saving) {
      return;
    }

    if (this.form.invalid) {
      alertWarning({ message: `กรุณาใส่ข้อมูลให้ครบ!` })
      return;
    }

    const model = {
      ...this.form.getRawValue(),
    };

    this.saving = true;
    if (!this.Data) {
      this.svUser.postAdminAgent(model).pipe(
        finalize(() => this.saving = false)
      ).subscribe(res => {
        alertSuccess({ message: `สร้างเซียนสำเร็จ. ชื่อผู้ใช้: ${model.username} รหัสผ่าน: ${model.password}` });
        this.clickBtnClose('btn-close-sage-user');
      });
      return;
    }

    this.svUser.putAdminAgent(this.Data.id, model).pipe(
      finalize(() => this.saving = false)
    ).subscribe(res => {
      alertSuccess({ message: `แก้ไขเซียนสำเร็จ` });
      this.clickBtnClose('btn-close-sage-user');
    });
  }

  getRoles() {
    this.svUser.getRoleSelect().subscribe(res => {
      this.userRoleItems = res;

      this.setFormZeanRole();
    });
  }

  setFormZeanRole() {
    if (!this.userRoleItems.length) {
      return;
    }

    const findZeanRole = this.userRoleItems.find(role => role.roleName.toLowerCase() === 'zean');
    this.form.patchValue({
      roleIds: [findZeanRole.roleId],
    });
  }

}
