import { SignalrBankService } from './../services/signalr-bank.service';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import { FADE_ROUTER } from '../animations/fade-router.animation';
import { Agent, AgentService, AgentType, allowAgents } from '../apis/agent.service';
import { LoginService } from '../apis/login.service';
import { allowRoles, rolesIcons, UserProfile, UserRole, UserRoleNames, UserService } from '../apis/user.service';
import { LocalName, LocalStorageService } from '../services/local-storage.service';
import { SignalRService } from '../services/signal-r.service';
import { AppCore } from '../utils/classes/app-core';
import { AppPath } from '../utils/constants/paths';
import { alertConfirm, alertConfirmDanger, alertDanger, alertInfo, alertSuccess, alertText, alertWarning } from '../utils/functions/alert';
import { sidebarMenu, SidebarItem, SidebarMenuItem } from './sidebar-menu';
import { SoundSettingService } from '../services/sound-setting.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageItem, LanguageService } from '../services/language.service';
import { UserRolesType } from '../utils/constants/user-roles';
import { CurrencyService } from '../services/currency.service';
import { filter } from 'rxjs/operators';
import { ChatroomService } from '../services/chatroom.service';
import { AppService, MemberCreditFormData } from '../app.service';

@Component({
  selector: 'app-secure',
  templateUrl: './secure.component.html',
  styleUrls: ['./secure.component.scss'],
  animations: [FADE_ROUTER],
})
export class SecureComponent extends AppCore implements OnInit, OnDestroy, AfterViewInit {

  sidebarMenu: SidebarItem[] = [];
  roleItems: UserRole[] = [];
  agentTypeItems: AgentType[] = [];
  sidebarOpen = false;

  minute = 1000 * 60;
  itvAuthen: any;

  showChat = false;
  darkTheme = document.getElementById('host-web').classList.contains('dark');
  Badges = {
    DepositAll: 0,
    DepositAuto: 0,
    DepositRequest: 0,
    WithdrawAll: 0,
    WithdrawRequest: 0,
    WithdrawPending: 0,
    WithdrawFailed: 0,
    WithdrawChecking: 0,
  };
  env = environment;

  agentId: number;

  userProile: UserProfile;

  LogOnline = false;
  TransferOnline = false;

  currentSoundSetting: any = {};
  currentLanguage: LanguageItem;

  spRoles: UserRolesType[] = ['Admin Accounting', 'Admin Support', 'Member Password'];
  newNotiTotal = 0;

  memberCreditFormData: MemberCreditFormData = {
    is_add: false,
    member_id: undefined,
  };
  memberClearTurn = undefined;
  constructor(
    private ngRouter: Router,
    private svLogin: LoginService,
    private svUser: UserService,
    private svAgent: AgentService,
    private svLocalStorage: LocalStorageService,
    public svSignalR: SignalRService,
    private svSignalrBank: SignalrBankService,
    private DetectRef: ChangeDetectorRef,
    private svSoundSetting: SoundSettingService,
    private ngxTranslate: TranslateService,
    public svLanguage: LanguageService,
    public svCurrency: CurrencyService,
    private svChatRoom: ChatroomService,
    public svApp: AppService,
  ) {
    super();
    this.sidebarMenu = JSON.parse(JSON.stringify(sidebarMenu));

    this.Subscriptions.add(
      this.svUser.roles.subscribe(roles => {
        this.roleItems = roles;
        this.updateSidebarMenu();
      })
    );
    this.Subscriptions.add(
      this.svUser.updateRoles$.subscribe(() => {
        this.getRoles();
      })
    );
    this.Subscriptions.add(
      this.svAgent.agentTypes$.subscribe(types => {
        this.agentTypeItems = types;
        this.updateSidebarMenu();
      })
    );
    this.Subscriptions.add(
      this.svAgent.updateAgentTypes$.subscribe(() => {
        this.getAgentTypes();
      })
    );
    this.Subscriptions.add(
      this.svUser.updateUserProfile$.subscribe(() => {
        this.getProfile();
      })
    );
    this.Subscriptions.add(
      this.svApp.updateAgentId$.subscribe(() => {
        this.svApp.setAgentId$(this.agentId);
      })
    );
    this.Subscriptions.add(
      this.svSignalR.depositCount$.subscribe(res => {
        this.Badges.DepositAuto = res.outstanding;
        this.Badges.DepositRequest = res.request;
        this.Badges.DepositAll = res.request + res.outstanding;
      })
    );
    this.Subscriptions.add(
      this.svSignalR.withdrawCount$.subscribe(res => {
        this.Badges.WithdrawChecking = res.checking;
        this.Badges.WithdrawRequest = res.request;
        this.Badges.WithdrawPending = res.pending;
        this.Badges.WithdrawFailed = res.failed;
        this.Badges.WithdrawAll = res.checking + res.request;
      })
    );

    this.Subscriptions.add(
      this.svSignalR.logWorker.subscribe((e) => {
        this.LogOnline = true;
        this.DetectRef.detectChanges();
        setTimeout(() => {
          this.LogOnline = false;
          this.DetectRef.detectChanges();
        }, 100);
      })
    );
    this.Subscriptions.add(
      this.svSignalrBank.StateTransfer.subscribe((e) => {
        this.TransferOnline = true;
        this.DetectRef.detectChanges();
        // this.DetectRef.detach();
        setTimeout(() => {
          this.TransferOnline = false;
          this.DetectRef.detectChanges();
          // this.DetectRef.detach();
        }, 100);
      })
    );
    this.Subscriptions.add(
      this.svSoundSetting.settingUpdated$.subscribe(_ => {
        this.currentSoundSetting = this.svSoundSetting.getCurrentSetting();
      })
    );
    this.currentLanguage = this.svLanguage.getLang();
    this.Subscriptions.add(
      this.ngxTranslate.onLangChange.subscribe(() => {
        this.currentLanguage = this.svLanguage.getLang();
      })
    );
    this.currentSoundSetting = this.svSoundSetting.getCurrentSetting();

    this.getAuthentication();
    this.getProfile();
    this.sidebarOpen = this.svLocalStorage.get(LocalName.Sidebar);

    if (this.isAdmin) {
      this.agentId = this.svLocalStorage.get(LocalName.agentId);
      this.svApp.setAgentId$(this.agentId);
      this.currentAgent = this.svLocalStorage.get(LocalName.currentAgent);
      this.svApp.setCurrentAgent$(this.currentAgent);
    }

    // Connect Signalr
    this.svChatRoom.connectChat().then((e) => {
      this.svChatRoom.reconnecting();
    });
    this.svSignalR.connectNotify();
    this.svSignalrBank.init();

    this.ngRouter.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((e: any) => {
      if (e.url === '/chat' || e.url === '') {
        this.showChat = false;
      } else {
        this.showChat = true;
      }
    });

    const memberCreditFormOffcanvas$ = this.svApp.memberCreditFormOffcanvas$.subscribe(val => {
      this.memberCreditFormData = val;

      const btnEl = document.querySelector('#btn-open-member-credit-form') as HTMLButtonElement;
      btnEl?.click();
    });
    this.Subscriptions.add(memberCreditFormOffcanvas$);

    const memberClearTurn$ = this.svApp.memberClearTurn$.subscribe(val => {
      this.memberClearTurn = val;

      const btnEl = document.querySelector('#btn-open-clear-turnover-member') as HTMLButtonElement;
      btnEl?.click();
    });
    this.Subscriptions.add(memberClearTurn$);
  }

  async ngOnInit() {
    await this.getRoles();
    await this.getAgentTypes();
    this.svCurrency.setupCurrencyEx(this.agentId);
    setTimeout(() => {
      this.setSidebarCollapseToggle(this.ngRouter.url);
    }, 0);
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
    clearTimeout(this.itvAuthen);
  }
  ngAfterViewInit(): void {
    // fix router in same chilren event not called
    setTimeout(() => {
      document.querySelector('#memberCreditFormOffcanvas')?.addEventListener('hide.bs.offcanvas', () => {
        this.svApp.memberCreditClose$.next();
      });
    }, 0);
  }

  onChangeTheme() {
    const element = document.getElementById('host-web');
    element.classList.toggle('dark');
    this.darkTheme = element.classList.contains('dark');
    if (this.darkTheme) {
      localStorage.setItem('theme', 'dark');
    } else {
      localStorage.setItem('theme', 'light');
    }
  }

  getAuthentication() {
    this.svLogin.authentication().subscribe(res => {
      this.svAuth.setToken(res.Token);
    });

    if (this.itvAuthen) {
      clearTimeout(this.itvAuthen);
    }
    this.itvAuthen = setTimeout(() => {
      this.getAuthentication();
    }, 5 * this.minute);
  }

  getRoles() {
    return new Promise(resolve => {
      this.svUser.getRoleSelect().subscribe(res => {
        this.svUser.setRoles(res);
        resolve(true);
      });
    });
  }

  getAgentTypes() {
    return new Promise(resolve => {
      this.svAgent.getType().subscribe(res => {
        this.svAgent.setType(res);
        resolve(true);
      });
    });
  }

  setSidebarCollapseToggle(url: string) {
    this.sidebarMenu.forEach((item, index) => {
      if (item.type !== 'menu') { return; }
      const currentActive = item.menuList.find(menu => {
        return url.includes(menu.link.join('/'));
      });
      if (!currentActive) { return; }
      item.expanded = true;
    });
  }

  onLogout() {
    this.svSignalR.disconnectNotify();
    this.svChatRoom.disconnectChat();
    this.svAuth.logout();
    this.ngRouter.navigate(['', AppPath.login]);
  }

  updateSidebarMenu() {
    this.sidebarMenu = JSON.parse(JSON.stringify(sidebarMenu));

    const isZean = this.currentUser.Role.some(ur => ur.toLowerCase() === 'zean');
    if (isZean) {
      this.sidebarMenu = this.sidebarMenu
        .filter(menu => menu.allowRoles?.some(al => al.toLowerCase() === 'zean'))
        .map(menu => {
          if (menu.menuList?.length) {
            menu.menuList = menu.menuList.filter(sub_menu => sub_menu.allowRoles?.some(al => al.toLowerCase() === 'zean') || true)
          }
          return menu;
        });
      return;
    }

    if (!this.Config.games.klottoEnabled) {
      this.sidebarMenu = this.sidebarMenu.filter(menu => {
        if (menu.useConfigGameKlotto) {
          return false;
        }
        return true;
      });
    }
    if (!this.Config.users.sageEnabled) {
      this.sidebarMenu = this.sidebarMenu.filter(menu => {
        if (menu.useConfigUserSage) {
          return false;
        }
        return true;
      });
    }
    if (this.Config.core === 'roman') {
      const romanMenuHideNames = ['MANAGE_SMS', 'Tournament', 'CONNECT_LINE'];
      this.sidebarMenu = this.sidebarMenu.filter(menu => {
        if (romanMenuHideNames.some(hideName => hideName === menu.name)) {
          return false;
        }
        return true;
      });
    }

    if (this.roleItems?.length) {
      this.sidebarMenu.push({ type: 'header', name: 'SYSTEM_USERS' });
    }
    this.roleItems?.forEach(role => {
      if (role.roleName === 'admin' || role.roleName === 'operator') {
        this.sidebarMenu.push({
          type: 'router',
          name: UserRoleNames[role.roleName],
          iconClass: rolesIcons[role.roleName],
          link: ['', AppPath.user, role.roleName],
          allowRoles: allowRoles[role.roleName] || null,
          allowAgents: allowAgents[role.roleName] || null,
        });
      }
    });
    if (this.isAdmin || this.isOperator) {
      this.sidebarMenu.push({
        type: 'router',
        name: 'ASSISTANT',
        iconClass: ' fa-user-tie fas fix-icon',
        link: ['', AppPath.user, 'operator-help'],
        allowRoles: null,
        allowAgents: null,
      });
    }

    this.sidebarMenu.forEach(menu => {
      // if (this.Config.AgentSystem || this.isSuperAdmin || this.isAdmin) {
      if (menu.name === 'AGENT') {
        this.agentTypeItems?.forEach(type => {
          menu.menuList.push({
            link: ['', AppPath.manageAgent, type.typeName],
            name: AgentType[type.typeName],
            allowRoles: allowRoles[type.typeName] || null,
            allowAgents: allowAgents[type.typeName] || null,
          });
        });
      }
    });

    // filter Permissions
    this.filterSidebarAllowed();
  }

  filterSidebarAllowed() {
    // const isZean = this.currentUser.Role?.some(ur => ur.toLowerCase() === 'zean');
    this.sidebarMenu = this.sidebarMenu.map(menu => {
      if (menu.menuList) {
        menu.menuList = menu.menuList?.map(subMenu => {
          return subMenu;
        }).filter(sm => this.getAccess(sm));
      }
      return menu;
    }).filter(m => this.getAccess(m));
  }
  OnToggleSideBar() {
    this.sidebarOpen = !this.sidebarOpen;
    this.svLocalStorage.set(LocalName.Sidebar, this.sidebarOpen);
  }
  getAccess(menu: SidebarMenuItem) {
    const isSpRoles = this.currentUser.Role?.some(ur => this.spRoles.some(spR => spR.toLowerCase() == ur?.toLowerCase()));
    if (isSpRoles) {
      return menu.spRoles?.some(spR => this.currentUser.Role?.some(ur => ur?.toLowerCase() == spR.toLowerCase())) || false;
    }

    let accessRole = true;
    let accessAgent = true;
    if (menu.allowRoles) {
      // tslint:disable-next-line:triple-equals
      accessRole = menu.allowRoles?.some(r => this.currentUser.Role?.some(ur => ur?.toLowerCase() == r?.toLowerCase()));
      if (accessRole && this.currentUser.AgentType && menu.allowAgents) {
        // tslint:disable-next-line:triple-equals
        accessAgent = menu.allowAgents?.some(r => this.currentUser.AgentType?.toLowerCase() == r?.toLowerCase());
        return accessRole && accessAgent;
      }
      return accessRole;
    }
    return true;
  }

  getProfile() {
    this.svUser.getProfile().subscribe(res => {
      this.userProile = res;
      this.svUser.setUserProfile(res);

      if (!this.isAdmin) {
        this.svApp.setAgentId$(res.agent.id);
        this.svApp.setCurrentAgent$(res.agent);
      }
    });
  }

  selectAgentChange(event: Agent) {
    this.agentId = event?.id;
    this.svLocalStorage.set(LocalName.agentId, this.agentId);
    this.svLocalStorage.set(LocalName.currentAgent, event);

    this.svApp.updateAgentId$.next();
    this.svApp.setCurrentAgent$(event);
  }

  alertSuccess() { alertSuccess({ message: alertText.saveSuccess }); }
  alertWarning() { alertWarning({ message: alertText.saveSuccess }); }
  alertInfo() { alertInfo({ message: alertText.saveSuccess }); }
  alertDanger() { alertDanger({ message: alertText.saveSuccess }); }
  alertConfirm() { alertConfirm({ html: alertText.saveSuccess }); }
  alertConfirmDanger() { alertConfirmDanger({ html: alertText.saveSuccess }); }
}
