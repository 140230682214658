import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AMBOptions, AMBSettingGame, SettingGameMembersService } from 'src/app/apis/setting-game-members.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { randomTxt } from 'src/app/utils/functions/random-txt';

@Component({
  selector: 'app-setting-lotto',
  templateUrl: './setting-lotto.component.html',
  styleUrls: ['../setting-games.scss'],
})
export class SettingLottoComponent extends AppCore implements OnInit, OnChanges {
  @Input() lottoType: string;
  @Input() setting: AMBSettingGame;
  @Input() readOnly = false;

  maxOptions: AMBOptions[] = [];
  hourOptions: AMBOptions[] = [];
  minuteOptions: AMBOptions[] = [];

  min = 0;
  max = 0;
  takingOptions: AMBOptions[] = [];
  inputId = `lotto-${randomTxt(5)}`;
  constructor(
    private svSettingGameMember: SettingGameMembersService,
  ) {
    super();

    this.maxOptions = this.svSettingGameMember.genOptions(1, 50, 0);
    this.hourOptions = this.svSettingGameMember.genOptions(1, 23, 0, false);
    this.minuteOptions = this.svSettingGameMember.genOptions(1, 59, 0, false);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.setting) {
      const shareLotto = this.setting.group_init_member.maxSetting.shareSetting.lotto;
      if (this.lottoType === 'หวยไทย') {
        this.min = shareLotto.minAmbLotto;
        this.max = shareLotto.maxAmbLotto;
      } else if (this.lottoType === 'หวยปิงปอง') {
        this.min = shareLotto.minAmbLottoPP;
        this.max = shareLotto.maxAmbLottoPP;
      } else if (this.lottoType === 'หวยลาว') {
        this.min = shareLotto.minAmbLottoLaos;
        this.max = shareLotto.maxAmbLottoLaos;
      }
      this.takingOptions = this.svSettingGameMember.genOptions(0.5, this.min, this.max);
    }
  }

}
