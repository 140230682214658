import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertPriceType'
})
export class ConvertPriceTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case 'ID':
        return 'ID';
      case 'HK':
        return 'HK';
      case 'MY':
        return 'MY';
      case 'EU':
        return 'EU';
    }
    return value;
  }

}
