import { WebConfig, IWebConfig } from './../../app-web';
import { Component, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { RouterOutlet } from '@angular/router';
import { FormArray, FormGroup } from '@ngneat/reactive-forms';
import { Subscription } from 'rxjs';
import { AppInjector } from 'src/app/app-injector';
import { AppService } from 'src/app/app.service';
import { AuthenticationService, TokenUser } from 'src/app/services/authentication.service';
import { environment } from 'src/environments/environment';
import { AgentTypesType } from '../constants/agent-types';
import { AppPath, BankPath, CreditPath, CUPath, DashboardPath, ManageMemberPath, MarketingPath, MemberOutstanding, OperatorPath, ProfilePath, ReportsPath, SettingPaths, SmsPaths, SupportAdminPaths, TransactionPath, UserPath } from '../constants/paths';
import * as moment from 'moment';
import { UserRoles, UserRolesType } from '../constants/user-roles';
import { Agent } from 'src/app/apis/agent.service';

@Component({
  template: ''
})
export abstract class AppCore implements OnDestroy {
  FilterDay: 'yesterday' | 'week' | 'month';
  AppPath = AppPath;
  DashboardPath = DashboardPath;
  ManageMemberPath = ManageMemberPath;
  MemberOutstanding = MemberOutstanding;
  TransactionPath = TransactionPath;
  CreditPath = CreditPath;
  BankPath = BankPath;
  OperatorPath = OperatorPath;
  CUPath = CUPath;
  UserPath = UserPath;
  ProfilePath = ProfilePath;
  SettingPaths = SettingPaths;
  marketingPath = MarketingPath;
  reportPaths = ReportsPath;
  supportAdminPaths = SupportAdminPaths;
  smsPaths = SmsPaths;

  apiHost = environment.origin;
  imgHost = environment.image;

  svAuth = AppInjector.get(AuthenticationService);
  svApp = AppInjector.get(AppService);
  currentAgentId: number;
  currentAgent: Agent;

  currentUser: TokenUser = this.svAuth.getUser;
  isSuperAdmin = (['Superadmin'] as UserRolesType[]).some(role => this.currentUser?.Role?.some(ur => ur === role));
  isAdmin = (['Superadmin', 'Admin'] as UserRolesType[]).some(role => this.currentUser?.Role?.some(ur => ur === role));
  isShareholder = (['Shareholder'] as AgentTypesType[]).some(at => at === this.currentUser?.AgentType);
  isSeniorAgent = (['Senior'] as AgentTypesType[]).some(at => at === this.currentUser?.AgentType);
  isMasterAgent = (['Master Agent'] as AgentTypesType[]).some(at => at === this.currentUser?.AgentType);
  isAgent = (['Agent'] as AgentTypesType[]).some(at => at === this.currentUser?.AgentType);
  isOperator = (['Operator'] as UserRolesType[]).some(usr => this.currentUser?.Role?.some(r => r === usr));

  isShowMasterAgent = (['Master Agent', 'Senior', 'Shareholder'] as AgentTypesType[]).some(at => at === this.currentUser?.AgentType);
  isShowSenior = (['Senior', 'Shareholder'] as AgentTypesType[]).some(at => at === this.currentUser?.AgentType);
  isShowShareHolder = (['Shareholder'] as AgentTypesType[]).some(at => at === this.currentUser?.AgentType);

  isMemberViewOnly = (['Member Management (View only)'] as UserRolesType[]).some(role => this.currentUser?.Role?.some(ur => ur === role));
  isMemberPassword = (['Member Password'] as UserRolesType[]).some(role => this.currentUser?.Role?.some(ur => ur === role));
  isMemberManagement = (['Member Management'] as UserRolesType[]).some(role => this.currentUser?.Role?.some(ur => ur === role));
  priorityManageMember = 0;

  Subscriptions: Subscription = new Subscription;

  Config: IWebConfig;
  env = environment;
  constructor() {
    this.onSetupForWeb();

    if (this.isMemberManagement) {
      this.priorityManageMember = 0;
    } else if (this.isMemberPassword) {
      this.priorityManageMember = 1;
    } else if (this.isMemberViewOnly) {
      this.priorityManageMember = 2;
    }

    this.Subscriptions.add(
      this.svApp.getAgentId$.subscribe(res => {
        this.currentAgentId = res;
        this.agentIdChange();
      }),
    );
    this.Subscriptions.add(
      this.svApp.getCurrentAgent$.subscribe(res => {
        this.currentAgent = res;
        this.currentAgentChanged();
      }),
    );

    if ((['Member Management'] as UserRolesType[]).some(role => this.currentUser?.Role?.some(ur => ur === role))) {
      this.isMemberViewOnly = false;
    }
  }

  agentIdChange(): void {
  }

  currentAgentChanged(): void {
  }

  ngOnDestroy() {
    this.Subscriptions.unsubscribe();
  }

  getFormArray(group: FormGroup | AbstractControl, name: string) {
    return group.get(name) as FormArray;
  }

  getRouteMemberProfile(memberId: number, route = '') {
    if (route) {
      return ['', AppPath.manageMember, ManageMemberPath.profile, memberId, route];
    } else {
      return ['', AppPath.manageMember, ManageMemberPath.profile, memberId];
    }
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  clickBtnClose(id: string) {
    const btnCloseEl = document.getElementById(id) as HTMLButtonElement;
    btnCloseEl?.click();
  }
  onSetupForWeb() {
    const hostname = location.hostname;
    if (hostname !== 'localhost') {
      const find = WebConfig.filter(c => c.UrlWeb.includes(hostname));
      this.Config = find.length > 0 ? find[0] : WebConfig[0];
    } else {
      this.Config = WebConfig.filter(c => c.UrlWeb.includes('localhost'))[-1] || WebConfig[0];
      this.Config.Production = false;
    }
  }
  selectDate(type: IFilterDay = 'today'): IFromToDate {
    switch (type) {
      case 'yesterday': {
        return {
          from: moment().subtract(1, 'day').startOf('date').format('YYYY-MM-DDTHH:mm'),
          to: moment().subtract(1, 'day').endOf('date').format('YYYY-MM-DDTHH:mm')
        };
      }
      case 'week': {
        return {
          from: moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DDTHH:mm'),
          to: moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DDTHH:mm')
        };
      }
      case '2week': {
        return {
          from: moment().subtract(2, 'week').startOf('week').add(1, 'days').format('YYYY-MM-DDTHH:mm'),
          to: moment().subtract(2, 'week').endOf('week').add(1, 'days').format('YYYY-MM-DDTHH:mm')
        };
      }
      case 'month': {
        return {
          from: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DDTHH:mm'),
          to: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DDTHH:mm')
        };
      }
      default: {
        return {
          from: moment().startOf('date').format('YYYY-MM-DDT00:00'),
          to: moment().endOf('date').format('YYYY-MM-DDT23:59')
        };
      }
    }
  }
  selectDate11h00m(type: IFilterDay = 'today'): IFromToDate {
    const today = moment().unix();
    const todayH11M00 = moment(moment().format('YYYY-MM-DDT11:00')).unix();
    switch (type) {
      case 'today': {
        if (today > todayH11M00) {
          return {
            from: moment().format('YYYY-MM-DDT11:00'),
            to: moment().add(1, 'day').format('YYYY-MM-DDT11:00')
          };
        } else {
          return {
            from: moment().subtract(1, 'day').format('YYYY-MM-DDT11:00'),
            to: moment().format('YYYY-MM-DDT11:00')
          };
        }
      }
      case 'yesterday': {
        if (today > todayH11M00) {
          return {
            from: moment().subtract(1, 'day').format('YYYY-MM-DDT11:00'),
            to: moment().format('YYYY-MM-DDT11:00')
          };
        } else {
          return {
            from: moment().subtract(2, 'day').format('YYYY-MM-DDT11:00'),
            to: moment().subtract(1, 'day').format('YYYY-MM-DDT11:00')
          };
        }
      }
      case 'week': {
        return {
          from: moment().subtract(1, 'week').startOf('week').add(1, 'days').format('YYYY-MM-DDT11:00'),
          to: moment().subtract(1, 'week').endOf('week').add(2, 'days').format('YYYY-MM-DDT11:00')
        };
      }
      case 'thisweek': {
        return {
          from: moment().subtract(1, 'week').startOf('week').add(1, 'days').format('YYYY-MM-DDT11:00'),
          to: moment().subtract(1, 'week').endOf('week').add(2, 'days').format('YYYY-MM-DDT11:00')
        };
      }
      case '2week': {
        return {
          from: moment().subtract(2, 'week').startOf('week').add(1, 'days').format('YYYY-MM-DDT11:00'),
          to: moment().subtract(2, 'week').endOf('week').add(1, 'days').format('YYYY-MM-DDT11:00')
        };
      }
      case 'month': {
        return {
          from: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DDT11:00'),
          to: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DDT11:00')
        };
      }
      default: {
        return {
          from: moment().subtract(1, 'day').format('YYYY-MM-DDT11:00'),
          to: moment().format('YYYY-MM-DDT11:00')
        };
      }
    }
  }
}

export type IFilterDay = 'yesterday' | 'week' | 'thisweek' | '2week' | 'month' | 'thismonth' | 'today';
export interface IFromToDate {
  from: string;
  to: string;
}
