import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@ngneat/reactive-forms';
import { Subscription } from 'rxjs';
import { AgentForm } from 'src/app/utils/interfaces/create-agent-form.interface';

@Component({
  selector: 'app-setting-amb-casino',
  templateUrl: './setting-amb-casino.component.html',
  styleUrls: ['../../setting-agent.scss'],
})
export class SettingAmbCasinoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() form: FormGroup<AgentForm>;

  casinoItems = [
    {
      name: 'pt', limit: 0, enable: false, plays: [
        { name: 'Limit A Min = 5, Max = 2000', disabled: false, icon: true },
        { name: 'Limit B Min = 100, Max = 4000', disabled: false, icon: true },
        { name: 'Limit C Min = 1000, Max = 40000', disabled: false, icon: true },
        { name: 'Limit D Min = 3000, Max = 120000', disabled: false, icon: true },
        { name: 'Limit E Min = 5000, Max = 200000', disabled: false, icon: true },
      ]
    },
    {
      name: 'sa', limit: 0, enable: false, plays: [
        { name: 'Limit A Min = 50, Max = 5000', disabled: false, icon: true },
        { name: 'Limit B Min = 100, Max = 10000', disabled: false, icon: true },
        { name: 'Limit C Min = 200, Max = 50000', disabled: false, icon: true },
        { name: 'Limit D Min = 1000, Max = 100000', disabled: false, icon: true },
        { name: 'Limit E Min = 10000, Max = 200000', disabled: false, icon: true },
      ]
    },
    {
      name: 'sexy', limit: 0, enable: false, plays: [
        { name: 'Limit A Min = 10 , Max = 2000', disabled: false, icon: true },
        { name: 'Limit B Min = 50 , Max = 10000', disabled: false, icon: true },
        { name: 'Limit C Min = 200 , Max = 50000', disabled: false, icon: true },
        { name: 'Limit D Min = 500 , Max = 100000', disabled: false, icon: true },
        { name: 'Limit E Min = 500 , Max = 200000', disabled: false, icon: true },
      ]
    },
    {
      name: 'dg', limit: 0, enable: false, plays: [
        { name: 'Limit A Min = 50 , Max = 5000', disabled: false, icon: true },
        { name: 'Limit B Min = 100 , Max = 10000', disabled: false, icon: true },
        { name: 'Limit C Min = 100 , Max = 50000', disabled: false, icon: true },
        { name: 'Limit D Min = 100 , Max = 100000', disabled: false, icon: true },
        { name: 'Limit E Min = 100 , Max = 250000', disabled: false, icon: true },
      ]
    },
    {
      name: 'ag', limit: 0, enable: false, plays: [
        { name: 'Limit A Min = 40 , Max = 5000', disabled: false, icon: true },
        { name: 'Limit B Min = 40 , Max = 25000', disabled: false, icon: true },
        { name: 'Limit C Min = 1000 , Max = 100000', disabled: false, icon: true },
        { name: 'Limit D Min = 1500 , Max = 150000', disabled: false, icon: true },
        { name: 'Limit E Min = 2500 , Max = 250000', disabled: false, icon: true },
      ]
    },
    {
      name: 'ab', limit: 0, enable: false, plays: [
        // { name: 'Limit A Min = 40 , Max = 5000', disabled: false, icon: true },
        // { name: 'Limit B Min = 40 , Max = 25000', disabled: false, icon: true },
        // { name: 'Limit C Min = 1000 , Max = 100000', disabled: false, icon: true },
      ]
    },
    {
      name: 'bg', limit: 0, enable: false, plays: [
        { name: 'Limit A Min = 20 , Max = 5000', disabled: false, icon: true },
        { name: 'Limit B Min = 100 , Max = 20000', disabled: false, icon: true },
        { name: 'Limit C Min = 100, Max = 200000', disabled: false, icon: true },
      ]
    },
    {
      name: 'gd', limit: 0, enable: false, plays: [
        { name: 'Limit A Min = 100 , Max = 4000', disabled: false, icon: true },
        { name: 'Limit B Min = 500 , Max = 20000', disabled: false, icon: true },
        { name: 'Limit C Min = 2500 , Max = 100000', disabled: false, icon: true },
      ]
    },
    {
      name: 'pm', limit: 0, enable: false, plays: [
        { name: 'Limit A Min = 2 , Max = 100000', disabled: false, icon: true },
      ]
    },
    {
      name: 'bt', limit: 0, enable: false, plays: [
        { name: 'Limit A Min = 50 , Max = 200,000', disabled: false, icon: true },
      ]
    },
    { name: 'eb', limit: 0, enable: false },
    { name: 'cr', limit: 0, enable: false },
    { name: 'gc', limit: 0, enable: false },
  ];

  Subscription = new Subscription();
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.form) {
      const limit_setting_amb_form$ = this.form.get('limit_setting_amb_form').valueChanges.subscribe(() => {
        this.casinoItems.forEach(game => {
          game.enable = this.form.getRawValue().limit_setting_amb_form[`casino_${game.name}_enable`] || false;
          game.limit = this.form.getRawValue().limit_setting_amb_form[`casino_${game.name}_limit`] || 0;
        });
      });
      this.Subscription.add(limit_setting_amb_form$);
    }
  }

  ngOnDestroy(): void {
    this.Subscription.unsubscribe();
  }

}
