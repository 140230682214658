<div class="offcanvas offcanvas-end" id="sageUserFormOffcanvas">
  <div class="offcanvas-header">
    <h4 class="mb-0" id="offcanvasRightLabel">{{'CREATE_SAGE'|translate}}</h4>
    <button id="btn-close-sage-user" type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
  </div>
  <div class="offcanvas-body">

    <form [formGroup]="form" (submit)="onSubmit()">
      <div class="mb-3" *ngIf="!Data">
        <label class="form-label" for="username">{{'USERNAME'|translate}} <span class="text-danger">*</span>
          <small>({{'USE_FOR_LOGIN'|translate}} [A_Z][a-z][0-9])</small> </label>
        <input formControlName="username" type="text" class="form-control" id="username"
          [placeholder]="'PLEASE_INSERT_USERNAME' | translate">
      </div>
      <div class="mb-3" *ngIf="!Data">
        <label class="form-label" for="password">{{'PASSWORD'|translate}} <span class="text-danger">*</span>
          <small>({{'USE_FOR_LOGIN'|translate}} [A_Z][a-z][0-9])</small></label>
        <input formControlName="password" type="text" class="form-control" id="password" [placeholder]="'PLEASE_INSERT_PASSWORD'|translate ">
      </div>
      <div class="mb-3">
        <label class="form-label" for="name">{{'FULLNAME'|translate}} <span class="text-danger">*</span></label>
        <input formControlName="name" type="text" class="form-control" id="ชื่อ" [placeholder]="'PLEASE_INSERT_FULLNAME'|translate">
      </div>
      <div class="mb-3">
        <label class="form-label" for="profit_sharing">{{'PROFIT_SHARING'|translate}} ({{'CALCULATED_AS'|translate}} %)</label>
        <input formControlName="profit_sharing" type="number" min="0" step="1" class="form-control" id="profit_sharing"
          [placeholder]="'PROFIT_SHARING'|translate">
      </div>
      <div class="mb-3">
        <label class="form-label" for="team_aff">{{'TEAM'|translate}} <i class="small opacity-50">({{'EG'|translate}} team_aff, team_zean)</i></label>
        <input formControlName="team_aff" type="text" class="form-control" id="team_aff" [placeholder]="'TEAM'|translate">
      </div>
      <div class="mb-3">
        <button *ngIf="!Data" type="submit" class="btn btn-primary btn-submit" [disabled]="saving">
          <i *ngIf="saving" class="fas fa-spinner fa-spin"></i> {{'CREATE'|translate}}
        </button>
        <button *ngIf="Data" type="submit" class="btn btn-primary btn-submit" [disabled]="saving">
          <i *ngIf="saving" class="fas fa-spinner fa-spin"></i> {{'EDIT'|translate}}
        </button>
      </div>
      <div class="mb-3">
        <div class="">
        "{{'SAGE'|translate}}" {{'CAN_BE_ACCESSED_AS_FOLLOWS'|translate}}
        </div>
        <ul>
          <li>{{'LINK_SAGE'|translate}}</li>
          <li>
            <div class="VARIOUS_REPORTS">{{''|translate}}</div>
            <ul>
              <li>{{'SAGE_REPORT'|translate}}</li>
              <li>{{'SAGE_LINK_REPORT'|translate}}</li>
            </ul>
          </li>
        </ul>
        <!-- <p>"{{Data?.titleName}}" สามารถเข้าถึงการใช้งาน ดังนี้</p>
        <ul>
          <ng-container *ngFor="let data of sidebar">
            <li *ngIf="data.role.includes(Data.roleName)">{{data.title}}</li>
            <ul *ngFor="let data2 of data.sublink">
              <li *ngIf="data2.role.includes(Data.roleName)">{{data2.title}}</li>
            </ul>
          </ng-container>
        </ul> -->
      </div>
    </form>
  </div>
</div>
