import { AppCore } from 'src/app/utils/classes/app-core';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Member, MemberService } from 'src/app/apis/member.service';
import { Transaction, TransactionService } from 'src/app/apis/transaction.service';
import { formPage } from 'src/app/utils/functions/form-page';
import { PageEvent } from 'src/app/utils/interfaces/page-event';
import { Pagination } from 'src/app/utils/interfaces/pagination';

@Component({
  selector: 'app-member-transaction-offcanvas',
  templateUrl: './member-transaction-offcanvas.component.html',
  styleUrls: ['./member-transaction-offcanvas.component.scss']
})
export class MemberTransactionOffcanvasComponent extends AppCore implements OnInit, OnChanges {
  form = formPage({ member_id: '', type: '' });
  currentPage = 1;
  transactionItems: Pagination<Transaction[]>;
  loading = true;
  member: Member;

  @Input() memberId: number;
  constructor(
    private svTransaction: TransactionService,
    private svMember: MemberService,
  ) {
    super();
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.memberId) {
      this.form.patchValue({ member_id: this.memberId });
      this.getTransaction();
      this.getMemberById();
    }
  }

  getTransaction() {
    this.loading = true;
    this.svTransaction.get(this.form.value).pipe(
      finalize(() => this.loading = false)
    ).subscribe(res => {
      this.transactionItems = res;
    });
  }

  getMemberById() {
    this.svMember.getMemberById(this.memberId).subscribe(res => {
      this.member = res;
    });
  }

  pageChange(event: PageEvent) {
    this.form.patchValue(event);
    this.getTransaction();
  }
}
