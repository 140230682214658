<div class="row mb-2">
  <div class="col-12 col-md-6 mb-2">
    <label for="">Share Setting
      (Min {{setting.group_init_member.maxSetting.shareSetting.other.minM2 | number: '1.0-0'}}% - Max
      {{setting.group_init_member.maxSetting.shareSetting.other.maxM2 | number: '1.0-0'}}%)
    </label>
    <div class="border-bottom my-2"></div>
    <table class="table table-borderless">
      <tbody>
        <tr>
          <td class="text-end">{{'STATUS'|translate}} :</td>
          <td>
            <div class="form-check form-check-inline">
              <input id="m2-status-check-on-{{inputId}}" type="radio" class="form-check-input" name="status-{{inputId}}"
                [value]="true" [(ngModel)]="setting.group_init_member.updateLineStatus.otherM2Enable"
                [disabled]="readOnly">
              <label for="m2-status-check-on-{{inputId}}" class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
            </div>
            <div class="form-check form-check-inline">
              <input id="m2-status-check-off-{{inputId}}" type="radio" class="form-check-input"
                name="status-{{inputId}}" [value]="false"
                [(ngModel)]="setting.group_init_member.updateLineStatus.otherM2Enable" [disabled]="readOnly">
              <label for="m2-status-check-off-{{inputId}}" class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
            </div>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="row mb-2">
  <div class="col-12 col-md-6 mb-2">
    <div class="mb-3">
      <label for="">{{'SET_LIMIT'|translate}} - HDP</label>
      <div class="border-bottom my-2"></div>
      <table class="table table-borderless">
        <tbody>
          <tr>
            <td class="text-end">{{'MAX_TURN'|translate}} : </td>
            <td>
              <input type="text" class="form-control" [disabled]="readOnly"
                [(ngModel)]="setting.member_info.limitSetting.other.m2.maxPerBetHDP" min="0">
              <div class="">Max = {{setting.group_init_member.maxSetting.limitSetting.other.m2.maxPerBetHDP | number}}
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td class="text-end">{{'MIN_TURN'|translate}} : </td>
            <td>
              <input type="text" class="form-control" [disabled]="readOnly"
                [(ngModel)]="setting.member_info.limitSetting.other.m2.minPerBetHDP" min="0">
              <div class="">Min = {{setting.group_init_member.maxSetting.limitSetting.other.m2.minPerBetHDP | number}}
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td class="text-end">Max match/game : </td>
            <td>
              <input type="text" class="form-control" [disabled]="readOnly"
                [(ngModel)]="setting.member_info.limitSetting.other.m2.maxBetPerMatchHDP" min="0">
              <div class="">Max =
                {{setting.group_init_member.maxSetting.limitSetting.other.m2.maxBetPerMatchHDP | number}}</div>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="col-12 col-md-6 mb-2">
    <div class="mb-3">
      <label for=""> {{'SET_LIMIT'|translate}} - Parlay </label>
      <div class="border-bottom my-2"></div>
      <table class="table table-borderless">
        <tbody>
          <tr>
            <td class="text-end">{{'MAX_TURN'|translate}} : </td>
            <td>
              <input type="text" class="form-control" [(ngModel)]="setting.member_info.limitSetting.other.m2.maxPerBet"
                min="0" [disabled]="readOnly">
              <div class="">Max =
                {{setting.group_init_member.maxSetting.limitSetting.other.m2.maxPerBet | number}}
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td class="text-end">{{'MIN_TURN'|translate}} : </td>
            <td>
              <input type="text" class="form-control" [(ngModel)]="setting.member_info.limitSetting.other.m2.minPerBet"
                min="0" [disabled]="readOnly">
              <div class="">Max =
                {{setting.group_init_member.maxSetting.limitSetting.other.m2.minPerBet | number}}
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td class="text-end">{{'MAX_PLAY_DAY'|translate}} : </td>
            <td>
              <input type="text" class="form-control" [disabled]="readOnly"
                [(ngModel)]="setting.member_info.limitSetting.other.m2.maxBetPerDay" min="0">
              <div class="">Max =
                {{setting.group_init_member.maxSetting.limitSetting.other.m2.maxBetPerDay | number}}
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td class="text-end">{{'MAX_PAY_BILL'|translate}} : </td>
            <td>
              <input type="text" class="form-control" [disabled]="readOnly"
                [(ngModel)]="setting.member_info.limitSetting.other.m2.maxPayPerBill" min="0">
              <div class="">Max =
                {{setting.group_init_member.maxSetting.limitSetting.other.m2.maxPayPerBill | number}}
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td class="text-end">{{'MAX_NUMBER_PAIRS_BILL'|translate}} : </td>
            <td>
              <select class="form-select" [disabled]="readOnly"
                [(ngModel)]="setting.member_info.limitSetting.other.m2.maxMatchPerBet">
                <option *ngFor="let o of optionSelect" [value]="o.value">{{o.label}}</option>
              </select>
            </td>
            <td></td>
          </tr>
          <tr>
            <td class="text-end">{{'MINIMUM_NUMBER_OF_PAIRS_BILL'|translate}} : </td>
            <td>
              <select class="form-select" [(ngModel)]="setting.member_info.limitSetting.other.m2.minMatchPerBet"
                disabled>
                <option *ngFor="let o of optionSelect" [value]="o.value">{{o.label}}</option>
              </select>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
