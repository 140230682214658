<div class="offcanvas offcanvas-end" tabindex="-1" id="memberWinLoseManualOffcanvas">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title">Member Win Lose Manual</h5>
    <button type="button" id="btn-close-win-lose-manual" class="btn-close text-reset"
      data-bs-dismiss="offcanvas"></button>
  </div>
  <div class="offcanvas-body">
    <form [formGroup]="form" (submit)="onSubmit()">
      <div class="mb-3">
        <label for="" class="form-label">Game Name</label>
        <input type="text" class="form-control" formControlName="game">
      </div>
      <div class="mb-3">
        <label for="" class="form-label">Amount</label>
        <input type="number" class="form-control" formControlName="amount">
      </div>
      <div class="mb-3">
        <label for="" class="form-label">Valid Amount</label>
        <input type="number" class="form-control" formControlName="validAmount">
      </div>
      <div class="mb-3">
        <label for="" class="form-label">Win Lose Turn Amount</label>
        <input type="number" class="form-control" formControlName="wlTurnAmount">
      </div>
      <div class="mb-3">
        <label for="" class="form-label">Win Lose Amount</label>
        <input type="number" class="form-control" formControlName="wlAmount">
      </div>
      <div class="mb-3">
        <label for="" class="form-label">Outstanding</label>
        <input type="number" class="form-control" formControlName="outstanding">
      </div>
      <div class="mb-3">
        <label for="" class="form-label">Note</label>
        <textarea rows="3" class="form-control" formControlName="note"></textarea>
      </div>
      <div class="mb-3 text-end">
        <button type="submit" class="btn btn-primary btn-submit"> บันทึกข้อมูล </button>
      </div>
    </form>
  </div>
</div>
