import { ElementRef, forwardRef, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Component } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ControlValueAccessor } from '@ngneat/reactive-forms';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { Member, MemberService } from 'src/app/apis/member.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { formPage } from 'src/app/utils/functions/form-page';

@Component({
  selector: 'app-select-member-form',
  templateUrl: './select-member-form.component.html',
  styles: [`
    :host {
      display: block;
    }
  `],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectMemberFormComponent),
      multi: true
    },
  ]
})
export class SelectMemberFormComponent extends AppCore implements ControlValueAccessor {
  @ViewChild('ngSelectMember') ngSelectComponent: NgSelectComponent;

  member$: Observable<Member[]>;
  memberInput$ = new Subject<string>();
  loading = false;

  memberId: number;
  selectMember: Member;

  memberItems: Member[];

  form = formPage({ name: 'id', ask4betAgentId: '' });
  @Input() showCredit = false;
  @Input() showPoint = false;
  @Input() showDetail = true;
  @Output() change = new EventEmitter<number>();
  @Output() changeCreditMember = new EventEmitter<number>();
  constructor(
    private svMember: MemberService,
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
  ) {
    super();
  }

  agentIdChange() {
    this.form.patchValue({ ask4betAgentId: this.currentAgentId, filter: `${this.memberId || 0}` });
    this.svMember.getMembers(this.form.value).subscribe(res => {
      this.memberItems = res.items;
      if (this.memberId) {
        this.selectMember = this.memberItems?.find(item => item.id === this.memberId);
        if (this.selectMember) {
          this.changeCreditMember.emit(this.selectMember.wallet_total);
        }
      }

      this.member$ = concat(
        of(res.items || []), // Default items
        this.memberInput$.pipe(
          debounceTime(300),
          distinctUntilChanged(),
          tap(() => this.loading = true),
          switchMap(term => {
            this.form.patchValue({ name: 'username', filter: term || '' });
            return this.svMember.getMembers(this.form.value).pipe(
              map(item => {
                this.memberItems = item.items;
                return this.memberItems;
              }),
              catchError(() => of([])),
              tap(() => this.loading = false),
            );
          }),
        ),
      );
    });
  }

  async writeValue(value: any) {
    this.memberId = value;
    this._renderer.setProperty(this._elementRef.nativeElement, 'value', value);
    this.selectMember = this.memberItems?.find(item => item.id === this.memberId);
    if (!this.selectMember) {
      this.ngSelectComponent?.handleClearClick();
      this.agentIdChange();
    }
  }
  onChange?: (value: any) => void;
  onTouched?: () => void;
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  async memberChange() {
    this.onChange(this.memberId);
    this.change.emit(this.memberId);
    this.selectMember = this.memberItems?.find(item => item.id === this.memberId);
    if (this.selectMember) {
      this.changeCreditMember.emit(this.selectMember.wallet_total);
    }
  }

  trackByFn(item: Member) {
    return item.id;
  }
}
