import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { finalize } from 'rxjs/operators';
import { Agent, AgentService } from 'src/app/apis/agent.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { alertSuccess, alertText, alertWarning } from 'src/app/utils/functions/alert';

@Component({
  selector: 'app-agent-change-password-offcanvas',
  templateUrl: './agent-change-password-offcanvas.component.html',
  styleUrls: ['./agent-change-password-offcanvas.component.scss']
})
export class AgentChangePasswordOffcanvasComponent extends AppCore implements OnInit, OnChanges, OnDestroy {
  form = this.createForm;
  saving = false;

  @Input() agentId: number;
  constructor(
    private ngFb: FormBuilder,
    private svAgent: AgentService,
  ) {
    super();
  }

  ngOnInit(): void {
  }
  ngOnChanges() {
  }

  get createForm(): FormGroup<any> {
    return this.ngFb.group({
      password: ['', [Validators.required, Validators.minLength(4)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(4)]],
    });
  }

  onSubmit() {
    this.form.markAllAsDirty();
    this.form.markAllAsTouched();

    if (
      this.form.get('password').value?.length < 4 ||
      this.form.get('confirmPassword').value?.length < 4
    ) {
      alertWarning({ message: `รหัสผ่านต้องความยาวอย่างน้อย 4 ตัว!` });
      return;
    }

    if (this.form.invalid) {
      alertWarning({ message: alertText.formInvalid });
      return;
    }

    if (this.form.value.password !== this.form.value.confirmPassword) {
      alertWarning({ message: `รหัสผ่านไม่ตรงกัน!` });
      return;
    }

    this.saving = true;
    this.svAgent.putAgentDownlinePassword(this.agentId, this.form.value).pipe(
      finalize(() => this.saving = false)
    ).subscribe(res => {
      alertSuccess({ message: `เปลี่ยนรหัสผ่านสำเร็จ` });
      this.clickBtnClose('btn-close-agent-password');
      this.form.reset();
    });
  }
}
