import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { RevertCreditRequest, RevertCreditService } from 'src/app/apis/revert-credit.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { formPage } from 'src/app/utils/functions/form-page';
import { PageModel } from 'src/app/utils/interfaces/page-model';
import { Pagination } from 'src/app/utils/interfaces/pagination';

@Component({
  selector: 'app-revert-credit-round-detail-offcanvas',
  templateUrl: './revert-credit-round-detail-offcanvas.component.html',
  styleUrls: ['./revert-credit-round-detail-offcanvas.component.scss']
})
export class RevertCreditRoundDetailOffcanvasComponent extends AppCore implements OnInit, OnChanges {
  @Input() roundId: number;

  form = formPage();
  revertCreditRequest: Pagination<RevertCreditRequest[]>;
  loading = false;

  constructor(
    private svRevertCredit: RevertCreditService,
  ) {
    super();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.roundId) {
      this.getData();
    }
  }

  getData() {
    this.loading = true;
    this.svRevertCredit.getRequest(this.roundId, this.currentAgentId, this.form.value).pipe(
      finalize(() => this.loading = false)
    ).subscribe(res => {
      this.revertCreditRequest = res;
    });
  }

  trackByFn(index: number, item: RevertCreditRequest) {
    return item.Id;
  }

  pageChange(event: PageModel) {
    this.form.patchValue(event);
    this.getData();
  }
}
