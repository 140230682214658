import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AMBOptions, AMBSettingGame, SettingGameMembersService } from 'src/app/apis/setting-game-members.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { randomTxt } from 'src/app/utils/functions/random-txt';

@Component({
  selector: 'app-setting-poker',
  templateUrl: './setting-poker.component.html',
  styleUrls: ['../setting-games.scss'],
})
export class SettingPokerComponent extends AppCore implements OnInit, OnChanges {
  @Input() setting: AMBSettingGame;
  @Input() readOnly = false;

  min = 0;
  max = 0;

  takingOption: AMBOptions[] = [];
  inputId = `pk-${randomTxt(5)}`;
  constructor(
    private svSettingGameMember: SettingGameMembersService,
  ) {
    super();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.setting) {
      this.min = this.setting.group_init_member.maxSetting.shareSetting.poker.minOg;
      this.max = this.setting.group_init_member.maxSetting.shareSetting.poker.maxOg;
      this.takingOption = this.svSettingGameMember.genOptions(0.5, this.max, this.min);
    }
  }
}
