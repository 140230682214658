import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  get(key: LocalName | string): any {
    let data = localStorage.getItem(key);
    try {
      data = JSON.parse(data);
    } catch (e) {
      data = '';
    }
    return data;
  }

  set(key: LocalName | string, data: any) {
    const stringData = JSON.stringify(data);
    localStorage.setItem(key, stringData);
  }

  delete(key: LocalName) {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }
}

export enum LocalName {
  Token = 'token',
  User = 'user',
  Remember = 'remember',
  Sidebar = 'sidebar',
  ApiHost = 'api-host',
  IpAddress = 'ip-address',
  agentId = 'agent-id',
  notifySounds = 'notify-sounds',
  Language = 'lang',
  saveBill = 'save-bill',
  pageAgentOrderBy = 'page-agent-order-by',
  checkLocalOutstanding = 'check-local-outstanding',
  currentAgent = 'current-agent',
  currency = 'currency',
  tokenLobbyVB = 'lobby-vb',
}
