import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime, finalize } from 'rxjs/operators';
import { HttpApiService } from 'src/app/services/http-api.service';
import { SignalRService } from 'src/app/services/signal-r.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { AppPath } from 'src/app/utils/constants';
import { Pagination } from 'src/app/utils/interfaces/pagination';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent extends AppCore implements OnInit, OnDestroy {
  @Input() newItemTotal = 0;
  @Output() newItemTotalChange = new EventEmitter<number>();

  notificationItems: Pagination<Notification[]>;
  loading = false;

  constructor(
    private http: HttpApiService,
    private svSignalR: SignalRService,
    private ngRouter: Router,
  ) {
    super();

    if (!this.isAdmin) {
      const updated$ = this.svSignalR.statementUpdated$.pipe(debounceTime(300)).subscribe(_ => {
        this.getData();
      });
      this.Subscriptions.add(updated$);

      this.getData();
    }
  }

  ngOnInit() {
  }

  getData() {
    this.loading = true;
    this.getNotification().pipe(
      finalize(() => this.loading = false)
    ).subscribe(res => {
      this.notificationItems = res;

      this.newItemTotal = this.notificationItems.items.reduce((pre, val) => pre + (val.read ? 0 : 1), 0);
      this.newItemTotalChange.emit(this.newItemTotal);
    });
  }

  getNotification() {
    return this.http.get<Pagination<Notification[]>>(`api/ask4bet/notification`);
  }
  getNotificationById(id: number) {
    return this.http.get(`api/ask4bet/notification/${id}`);
  }
  putNotificationRead(id: number, read = true) {
    return this.http.put(`api/ask4bet/notification/${id}/read/${read}`, {});
  }
  putNotificationReadAll() {
    return this.http.put(`api/ask4bet/notification/read/all`, {});
  }
  deleteNotification(id: number) {
    return this.http.delete(`api/ask4bet/notification/${id}`);
  }
  deleteNotificationAll() {
    return this.http.delete(`api/ask4bet/notification/all`);
  }

  onReadMessage(item: Notification, read: boolean) {
    this.putNotificationRead(item.id, read).subscribe(res => {
      this.getData();
    });
  }
  onReadAll() {
    this.putNotificationReadAll().subscribe(res => {
      this.getData();
    });
  }
  onDeleteMessage(item: Notification) {
    this.deleteNotification(item.id).subscribe(res => {
      this.getData();
    });
  }
  onDeleteAll() {
    this.deleteNotificationAll().subscribe(res => {
      this.getData();
    });
  }
  onSelectMessage(item: Notification) {
    this.onReadMessage(item, true);

    if (item.type === 'statement_updated') {
      this.ngRouter.navigate(['', AppPath.bank]);
    } else if (item.type === 'request_deposit') {
      this.ngRouter.navigate(['', AppPath.workDeposit, 'request']);
    } else if (item.type === 'request_withdraw') {
      this.ngRouter.navigate(['', AppPath.workWithdraw, 'all']);
    } else if (item.type === 'withdraw_result_fail') {
      this.ngRouter.navigate(['', AppPath.autoTranfer, item.id, 'error']);
    } else if (item.type === 'withdraw_result_success') {
      this.ngRouter.navigate(['', AppPath.autoTranfer, item.id, 'history']);
    }
    this.clickBtnClose('noti-btn-close');
  }

  trackByFn(index: number, item: Notification) {
    return item.id;
  }
}

export interface Notification {
  id: number;
  agent_id: number;
  user_id: number;
  label: string;
  message: string;
  type: 'statement_updated' | 'request_withdraw' | 'request_deposit' | 'withdraw_result' | 'withdraw_result_success' | 'withdraw_result_fail';
  read: boolean;
  status: string;
  created_at: string;
}
