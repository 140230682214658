import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { PageEvent } from 'src/app/utils/interfaces/page-event';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() page = 1;
  @Input() limit = 10;
  @Input() total = 1;
  @Input() options = [10, 20, 30, 40, 50, 100, 200, 300, 400, 500];
  @Input() maxSize = 5;
  @Output() pageChange = new EventEmitter<number>();
  @Output() valueChange = new EventEmitter<PageEvent>();

  pages: number[] = [];
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.setPaginate();
  }

  get totalPages() {
    return Math.max(Math.ceil((this.total || 1) / (this.limit || 10)), 1);
  }

  setPaginate() {
    let startPage = Math.max(this.page - Math.floor(this.maxSize / 2), 1);
    let endPage = startPage + this.maxSize - 1;

    if (endPage > this.totalPages) {
      endPage = this.totalPages;
      startPage = (endPage - this.maxSize + 1) >= 1 ? (endPage - this.maxSize + 1) : 1;
    }

    this.pages = Array.from(new Array(this.maxSize > endPage ? endPage : this.maxSize), (_, index) => startPage + index);

    if ((this.page || 1) > this.totalPages) {
      setTimeout(() => {
        this.page = 1;
        this.pageChange.emit(1);
        this.valueChange.emit({ page: this.page, limit: this.limit });
      }, 100);
    }
  }

  onPageChange(event: number) {
    this.page = event;
    this.pageChange.emit(this.page);
    this.valueChange.emit({ page: this.page, limit: this.limit });
  }

  goFirst() {
    if (this.page === 1) { return; }
    this.page = 1;
    this.onPageChange(this.page);
  }

  goPrev() {
    if (this.page === 1) { return; }
    this.page--;
    this.onPageChange(this.page);
  }

  goNext() {
    if (this.page === this.pages[this.pages.length - 1]) { return; }
    this.page++;
    this.onPageChange(this.page);
  }

  goLast() {
    if (this.page === this.totalPages) { return; }
    this.page = this.totalPages;
    this.onPageChange(this.page);
  }

  onChange() {
    this.valueChange.emit({ page: 1, limit: this.limit });
  }
}
