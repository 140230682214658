<div class="offcanvas offcanvas-end" tabindex="-1" id="alertModal" aria-labelledby="offcanvasRightLabel">
  <div class="offcanvas-header">
    <h4 class="offcanvas-title" id="offcanvasRightLabel">เเจ้งเตือนระบบ</h4>
    <div class="dropdown ms-auto" *ngIf="notificationItems?.items?.length">
      <a class="d-inline-block p-2" data-bs-toggle="dropdown">
        <i class="fas fa-cog"></i>
      </a>
      <ul class="dropdown-menu">
        <li class="">
          <a class="dropdown-item" (click)="onReadAll()">
            <i class="fas fa-check-double"></i> ทำให้อ่านแล้วทั้งหมด
          </a>
        </li>
        <li>
          <a class="dropdown-item" (click)="onDeleteAll()">
            <i class="fas fa-times"></i> ลบทั้งหมด
          </a>
        </li>
      </ul>
      <br>
    </div>
    <button type="button" id="noti-btn-close" class="btn-close text-reset" data-bs-dismiss="offcanvas"
      aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <table class="table-borderless w-100">
      <tbody>
        <tr *ngFor="let item of notificationItems?.items; let i = index; trackBy: trackByFn" [tabindex]="i">
          <td class="">
            <a class="d-block p-2" (click)="onSelectMessage(item)">
              <div class="d-flex align-items-center">
                <div class="pe-2">
                  <i *ngIf="item.type === 'statement_updated'" class="fa-fw fa-2x fas fa-exchange-alt"></i>
                  <i *ngIf="item.type === 'request_withdraw' || item.type === 'request_deposit'"
                    class="fa-fw fa-2x fas fa-bell"></i>
                  <i *ngIf="item.type === 'withdraw_result'" class="fa-fw fa-2x fas fa-angle-double-right"></i>
                  <i *ngIf="item.type === 'withdraw_result_success'" class="fa-fw fa-2x fas fa-angle-double-right"></i>
                  <i *ngIf="item.type === 'withdraw_result_fail'" class="fa-fw fa-2x fas fa-angle-double-right"></i>
                </div>
                <div class="">
                  <strong class="fs-5">{{ item.label }}</strong>
                  <div class="">{{ item.message }}</div>
                </div>
              </div>
            </a>
          </td>
          <td class="p-2" width="25px">
            <div class="dropdown">
              <a data-bs-toggle="dropdown">
                <i class="fas fa-ellipsis-h"></i>
              </a>
              <ul class="dropdown-menu ">
                <li *ngIf="!item.read">
                  <a href="javascript:;" class="dropdown-item" (click)="onReadMessage(item, true)">
                    <i class="fas fa-check fa-fw"></i> ทำให้อ่านแล้ว
                  </a>
                </li>
                <li *ngIf="item.read">
                  <a href="javascript:;" class="dropdown-item" (click)="onReadMessage(item, false)">
                    <i class="fas fa-circle fa-fw"></i> ทำให้ยังไม่อ่าน
                  </a>
                </li>
                <li>
                  <a href="javascript:;" class="dropdown-item" (click)="onDeleteMessage(item)">
                    <i class="fas fa-times fa-fw"></i> ลบ
                  </a>
                </li>
              </ul>
            </div>
          </td>
          <td class="p-2">
            <i class="fas fa-circle text-primary" *ngIf="!item.read"></i>
            <!-- <i class="far fa-circle text-primary" *ngIf="item.read"></i> -->
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-center">
      <div class="mb-3" *ngIf="notificationItems && !notificationItems?.items.length"> ไม่มีข้อมูล </div>
      <div class="mb-3" *ngIf="loading"> <span class="spinner-border"></span> </div>
    </div>
  </div>
</div>
