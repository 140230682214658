import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input, OnChanges } from '@angular/core';
import { Chart, ChartDataset, ChartOptions } from 'chart.js';
import { hexToRgb } from 'src/app/utils/functions/hex-to-rgb';
import { DataLineChart } from '../../chart.interface';
import { data } from '../../data.mock';
import { optionLineChart } from '../../options';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() MasterData: DataLineChart.Data[];
  @Input() height = '200px';
  @ViewChild('myChart') canvas: ElementRef<HTMLCanvasElement>;
  ctx: CanvasRenderingContext2D;
  myChart: Chart;
  OptionChart: ChartOptions = Object.assign({}, optionLineChart);
  ConventDataSets: ChartDataset[] = [];
  ConventLabels: string[] = [];

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  ngAfterViewInit() {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.setupData();
    this.setupChart();
  }

  setupData() {
    this.MasterData?.forEach(async label => {
      const gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);
      gradientStroke.addColorStop(1, `rgba(${hexToRgb(label.color)},0.2)`);
      gradientStroke.addColorStop(0.4, `rgba(${hexToRgb(label.color)},0.0)`);
      gradientStroke.addColorStop(0, `rgba(${hexToRgb(label.color)},0)`); // red colors
      const temp = {
        label: label.name,
        borderColor: `rgba(${hexToRgb(label.color)},1)`,
        backgroundColor: gradientStroke,
        fill: true,
        borderWidth: 1,
        cubicInterpolationMode: 'monotone',
        data: []
      } as ChartDataset<any>;
      this.ConventLabels = [];
      label.series.forEach(data2 => {
        temp.data.push(data2.value);
        this.ConventLabels.push(data2.name);
      });
      this.ConventDataSets.push(Object.assign({}, temp));
    });
  }
  setupChart() {
    if (this.MasterData?.length > 1) {
      this.OptionChart.plugins.legend.display = true;
    }
    Chart.defaults.font.family = 'Prompt';
    this.myChart = new Chart(this.ctx, {
      type: 'line',
      data: {
        labels: this.ConventLabels,
        datasets: this.ConventDataSets
      },
      options: this.OptionChart
    });
  }
}
