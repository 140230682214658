<div class="offcanvas offcanvas-end" tabindex="-1" id="agentPasswordOffcanvas">
  <div class="offcanvas-header">
    <h4 class="offcanvas-title">{{'CHANGE_PASSWORD'|translate}}</h4>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    <button type="button" id="btn-close-agent-password" class="d-none" data-bs-dismiss="offcanvas"
      aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <form [formGroup]="form" (submit)="onSubmit()">
      <div class="mb-3">
        <label for="" class="form-label">{{'PASSWORD'|translate}}</label>
        <input type="password" class="form-control" formControlName="password">
      </div>
      <div class="mb-3">
        <label for="" class="form-label">{{'CONFIRM_PASSWORD'|translate}}</label>
        <input type="password" class="form-control" formControlName="confirmPassword">
      </div>
      <div class="text-end mb-3">
        <button type="submit" class="btn btn-primary btn-submit" [disabled]="saving">
          {{'SAVE_PASSWORD'|translate}}
        </button>
      </div>
    </form>
  </div>
</div>
