<div class="offcanvas offcanvas-end" [id]="id" style="width: 500px;">
  <div class="offcanvas-header align-items-center">
    <h4 id="offcanvasRightLabel">{{'CLEAR_TURNOVER'|translate}}</h4>
    <button type="button" id="clear-turn-btn-close" class="btn-close text-reset" data-bs-dismiss="offcanvas"
      aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <div class="text-center mb-3" *ngIf="loading">
      <span class="spinner-border"></span>
    </div>

    <ng-container *ngIf="!loading && withdrawTurnover">
      <ng-container *ngFor="let promotion of withdrawTurnover?.promotion_used; trackBy: trackPromotion">
        <div class="card bg-success mb-3">
          <div class="card-body py-3">
            <h5 class="card-title mb-0 text-white">{{promotion.promotion_name}}</h5>
          </div>
        </div>
        <ul class="list-group mb-3">
          <li class="list-group-item">
            <span *ngIf="promotion.can_withdraw">
              <i class="fas fa-check-circle text-success me-1"></i>
              {{'TURNOVER_COMPLETED'|translate}}
            </span>
            <span *ngIf="!promotion.can_withdraw">
              <i class="fas fa-times-circle text-danger me-1"></i>
              {{'TURNOVER_OUTSTANDING'|translate}} {{promotion.promotion_turnover | number: '0.2-2'}}
              {{'CREDIT'|translate}}
            </span>
          </li>
          <li class="list-group-item">
            <span *ngIf="promotion.winlose_complete">
              <i class="fas fa-check-circle text-success me-1"></i>
              {{'WINLOSE_COMPLETED'|translate}}
            </span>
            <span *ngIf="!promotion.winlose_complete">
              <i class="fas fa-times-circle text-danger me-1"></i>
              {{'TURNOVER_OUTSTANDING'}} {{promotion.winlose_amount | number: '0.2-2'}} {{'CREDIT'|translate}}
            </span>
          </li>
          <li class="list-group-item">
            <i *ngIf="promotion.credit_can_not_withdraw <= 0" class="fas fa-check-circle text-success me-1"></i>
            <i *ngIf="promotion.credit_can_not_withdraw > 0" class="fas fa-times-circle text-danger me-1"></i>
            {{'NON_WITHDRAW_CREDIT'|translate}} {{promotion.credit_can_not_withdraw | number: '0.2-2'}}
            {{'CREDIT'|translate}}
          </li>
        </ul>

        <div class="text-center mb-3">
          <button type="button" class="btn btn-danger" (click)="clearPromotion(promotion)">
            <i class="fas fa-broom"></i> {{'CLEAR_PROMOTION'|translate}}
          </button>
        </div>

        <ul class="">
          <li *ngFor="let game of promotion.game" class="text-uppercase"> {{game}} </li>
        </ul>
        <hr>

      </ng-container>
      <!-- <div class="text-center mb-3" *ngIf="!withdrawTurnover.promotion_used.length">
        {{'NO_DATA'|translate}}
      </div> -->

      <h5 class="mb-3">
        {{'NON_WITHDRAW_CREDIT'|translate}}
        <span class="text-danger">{{noWithdrawCredit | number: '0.2-2'}}</span> {{'CREDIT'|translate}}
      </h5>
      <h5 class="mb-3">
        {{'TOTAL_TURNOVER_OUTSTANDING'|translate}}
        <span class="text-warning">{{withdrawTurnover.should_play | number: '0.2-2'}}</span> {{'CREDIT'|translate}}
      </h5>
      <hr>
      <div class="text-end mb-3">
        <button type="button" class="btn btn-primary btn-submit" (click)="onSubmitClearTurnover()" [disabled]="saving">
          {{'CLEAR_TURNOVER_ALL'|translate}}
        </button>
      </div>
    </ng-container>
  </div>
</div>
