<div class="full-page-fixed bg-primary">
  <div class="d-flex align-items-center w-100 h-100">
    <div class="w-100 text-center">
      <!-- <img src="assets/karnival-v3-dark.svg" width="230px" alt=""> -->
      <img class="brand-logo" alt="brand-logo" width="230px" src="{{Config.LogoDark}}">
      <img class="brand-logo-w" alt="brand-logo" width="230px" src="{{Config.Logo}}">
      <br>
      <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</div>
