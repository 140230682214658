export * from './setting-card/setting-card.component';
export * from './setting-casino/setting-casino.component';
export * from './setting-klotto/setting-klotto.component';
export * from './setting-lotto/setting-lotto.component';
export * from './setting-m2-sport/setting-m2-sport.component';
export * from './setting-partner/setting-partner.component';
export * from './setting-poker/setting-poker.component';
export * from './setting-slot/setting-slot.component';
export * from './setting-sport/setting-sport.component';
export * from './setting-sport2/setting-sport2.component';
