<div class="mb-2">
  <div class="form-check form-check-inline">
    <input id="filetype-file" type="radio" class="form-check-input" [(ngModel)]="fileType" value="file">
    <label for="filetype-file" class="form-check-label">File</label>
  </div>
  <div class="form-check form-check-inline">
    <input id="filetype-url" type="radio" class="form-check-input" [(ngModel)]="fileType" value="url">
    <label for="filetype-url" class="form-check-label">URL</label>
  </div>
</div>

<div class="text-center mb-3">
  <label class="d-inline-block">
    <ng-container *ngIf="srcImage">
      <img *ngIf="host === ''" [src]="srcImage" alt="" [ngStyle]="{width: width, height: height}">
      <img *ngIf="host === 'host'" [src]="imgHost + srcImage" alt="" [ngStyle]="{width: width, height: height}">
    </ng-container>
    <ng-container *ngIf="!srcImage">
      <img src="/assets/placeholder.jpg" alt="" [ngStyle]="{width: width, height: height}">
    </ng-container>
  </label>
</div>
<div class="text-center" *ngIf="fileType === 'file'">
  <label for="input-file" class="btn btn-outline-primary">
    <i class="fas fa-file-upload"></i> {{'CHOOSE_PICTURE'|translate}}
  </label>
  <input id="input-file" type="file" class="d-none" accept=".jpg,.png,.jpeg" (change)="fileChange($event)">
</div>
<div class="mb-2" *ngIf="fileType === 'url'">
  <input type="text" class="form-control" [(ngModel)]="srcImage" (change)="host = ''">
</div>
