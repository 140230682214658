import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AMBOptions, KlottoLotto, KlottoSettingPlay, SettingGameMembersService } from 'src/app/apis/setting-game-members.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { BankImgPipe } from '../../pipes/bank-img.pipe';

@Component({
  selector: 'app-setting-klotto',
  templateUrl: './setting-klotto.component.html',
  styleUrls: ['../setting-games.scss'],
})
export class SettingKlottoComponent extends AppCore implements OnInit, OnChanges {
  @Input() klottoSetting: KlottoLotto;
  @Output() klottoSettingChange = new EventEmitter<KlottoLotto>();

  lottoPlayActive = 1;
  playSettings: KlottoSettingPlay;
  takingOption: AMBOptions[] = [];

  flagcdn = (code) => (`https://flagcdn.com/w20/${code}.png`)
  flags = {
    "THA": this.flagcdn('th'),
    "THA-GSB": this.pipeImg.transform('gsb', 'bank_code'),
    "THA-BAAC": this.pipeImg.transform('baac', 'bank_code'),
    "VNM": this.flagcdn('vn'),
    "VNM-VIP": this.flagcdn('vn'),
    "LAO": this.flagcdn('la'),
    "MYS": this.flagcdn('my'),
    "VNM-SPL": this.flagcdn('vn'),
    "YEEKEE": "https://dev-klotto.karnival.systems/assets/favicon.png",
    "SGP": this.flagcdn('sg'),
    "DEU": this.flagcdn('de'),
    "KOR": this.flagcdn('kr'),
    "JPN": this.flagcdn('jp'),
    "HKG": this.flagcdn('hk'),
    "CHN": this.flagcdn('cn'),
    "TWN": this.flagcdn('tw'),
    "EGY": this.flagcdn('eg'),
    "UK": this.flagcdn('gb'),
    "RUS": this.flagcdn('ru'),
    "IND": this.flagcdn('in'),
    "US": this.flagcdn('us'),
    "THA-STOCK": this.flagcdn('th'),
  };

  constructor(
    private svSettingGameMember: SettingGameMembersService,
    private pipeImg: BankImgPipe,
  ) {
    super();
    this.takingOption = this.svSettingGameMember.genOptions(0.5, 100);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.klottoSetting) {
      // const codes = this.klottoSetting.Lottos.map(play => play.LottoTypeCode);
      this.playSettings = this.klottoSetting.Lottos[0];
    }
  }

  selectChange(item: KlottoSettingPlay) {
    this.klottoSetting.Lottos.forEach((sett) => {
      if (this.playSettings.LottoTypeKey === sett.LottoTypeKey) {
        sett = this.playSettings;
      }
    });
    this.klottoSettingChange.emit(this.klottoSetting);

    this.lottoPlayActive = item.LottoTypeKey;
    this.playSettings = item;
  }
}
