import { Environment } from 'src/app/utils/interfaces/environment';

export const environment: Environment = {
  production: true,
  bankCenter: 'https://bank-api.karnival.systems/',
  origin: 'https://api.roman168.bet/',
  image: 'https://api.roman168.bet/images/',
  name: 'production',
  klotto_bo: 'https://agent.klotto.co/',
};
