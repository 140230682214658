<ng-select [items]="allAgentItems$ | async" bindLabel="agentName" [clearable]="true"
  [placeholder]="'CHOOSE_AGENT' | translate" [(ngModel)]="currentAgent" [loading]="loading" [trackByFn]="trackAgentByFn"
  [typeahead]="allAgentInput$" (change)="selectAgentChange()" [typeToSearchText]="'TYPE_SEARCH_AGENT' | translate"
  appendTo="body" style="position: relative;">
  <ng-template ng-option-tmp let-item="item">
    <div class="">
      <div class=""> <strong>{{'USERNAME' | translate}} :</strong> {{item.agentName || '-'}}</div>
      <div class=""> <strong>{{'NAME' | translate}} :</strong> {{item.partnerName || '-'}}</div>
      <div class=""> <strong>{{'TYPE' | translate}} :</strong> {{item.typeDetail || '-'}}</div>
      <div class=""> <strong>{{'CREDIT' | translate}} :</strong> {{item.credit_remaining || 0 | number: '0.2-2'}}</div>
    </div>
  </ng-template>
</ng-select>
