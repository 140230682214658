<div class="row" *ngIf="MasterData">
  <div class="col-md-6">
    <div class="mb-1" *ngFor="let item of MasterData;index as i">
      <div class="animate__animated animate__fadeInDown" [ngStyle]="{animationDelay:'0.'+i+'s'}">
        <div class="d-flex justify-content-between">
          <div class="fs-4 mb-1">
            <div>
              {{item.name}}
            </div>
            <div style="height: 5px; width: 100px;" [ngStyle]="{'backgroundColor':item.color}"></div>
          </div>
          <div class="fs-3 mb-2">
            <app-number-counter [numberCount]="item.value" [NoSeparator]="true"></app-number-counter> {{'PERSON'|translate}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="row justify-content-center">
      <div class="col-md-5">
        <app-pie-chart *ngIf="MasterData" [MasterData]="MasterData"></app-pie-chart>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="!MasterData">
  <div class="col-md-6">
    <div *ngFor="let item of [].constructor(3)">
      <div>
        <ngx-skeleton-loader [theme]="{
                      width: '100%',
                      height: '15px'
                    }">
        </ngx-skeleton-loader>
      </div>
      <ngx-skeleton-loader [theme]="{
                    width: '80%',
                    height: '5px'
                  }">
      </ngx-skeleton-loader>
    </div>
  </div>
  <div class="col-md-6">
    <div class="d-flex justify-content-center">
      <div style="width: 150px;">
        <div class="wrapper">
          <ngx-skeleton-loader [theme]="{
                  width: '150px',
                  height: '150px'
                }" appearance="circle"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</div>
