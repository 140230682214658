<form [formGroup]="form">
  <ng-container class="" formGroupName="limit_setting_amb_form">
    <div class="" [class.block]="!form?.getRawValue().limit_setting_amb_form.sportsBook_percent_max">
      <div class="mb-2">
        <label for="" class="form-label me-3 mb-2"> {{'STATUS'|translate}} : </label>
        <div class="form-check form-check-inline">
          <input id="sport-status-on" type="radio" class="form-check-input" [value]="true"
            formControlName="sportsBook_enable">
          <label for="sport-status-off" class="form-check-label text-success">{{'ACTIVE'|translate}}</label>
        </div>
        <div class="form-check form-check-inline">
          <input id="sport-status-on" type="radio" class="form-check-input" [value]="false"
            formControlName="sportsBook_enable">
          <label for="sport-status-off" class="form-check-label text-danger">{{'DISABLED'|translate}}</label>
        </div>
      </div>

      <label for="" class="form-label mb-2"> {{'SET_UP_PARTNERSHIP'|translate}} : </label>
      <div class="row">
        <div class="col-12 col-lg-4 mb-3">
          <div class="input-group">
            <div class="input-group-text">
              SPORT
              <i class="ms-1 bi bi-exclamation-circle text-warning" *ngIf="form?.getRawValue().limit_setting_amb_form.sportsBook_percent_own >
                form?.getRawValue().limit_setting_amb_form.sportsBook_percent_max"></i>
            </div>
            <input type="number" class="form-control text-end" formControlName="sportsBook_percent_own" [min]="0"
              [max]="form?.getRawValue().limit_setting_amb_form.sportsBook_percent_max" step="0.5">
            <div class="input-group-text">%</div>
          </div>
        </div>
        <div class="col-12 col-lg-4 mb-3">
          <div class=""> {{'BOARD'|translate}} =
            {{form?.getRawValue().limit_setting_amb_form.sportsBook_percent_board}}% </div>
          <div class=""> MAX = {{form?.getRawValue().limit_setting_amb_form.sportsBook_percent_max}}% </div>
          <div class=""> {{'BALANCE'|translate}} ({{'YOUR_PARTNER'|translate}}) =
            {{ form?.getRawValue().limit_setting_amb_form.sportsBook_percent_max -
            form?.getRawValue().limit_setting_amb_form.sportsBook_percent_own }}%
          </div>
        </div>
      </div>

      <!-- <div class="row">
      <div class="col-12 col-lg-6 mb-3">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="text-start" style="background-color: var(--bs-primary); color: var(--bs-body-bg);">
                Share Setting
              </th>
              <th style="background-color: var(--bs-primary); color: var(--bs-body-bg);">
                Percent
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-start">Sport today</td>
              <td>85%</td>
            </tr>
            <tr>
              <td class="text-start">Sport live</td>
              <td>85%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div> -->

      <div class="row">
        <!-- <div class="col-12 col-lg-6 mb-3">
      <div class="">
        <label for="" class="form-label">Sport (Min 85% - Max 85%)</label>
        <hr class="mt-0 mb-2">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="text-end">
                Positing Taking :
              </td>
              <td class="text-start">
                <span class="position-taking-label">AGENT</span>
                <select class="form-select form-inline">
                  <option value="" selected>85%</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="">
        <label for="" class="form-label">Sport live (Min 85% - Max 85%)</label>
        <hr class="mt-0 mb-2">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="text-end">
                Positing Taking :
              </td>
              <td class="text-start">
                <span class="position-taking-label">AGENT</span>
                <select class="form-inline form-select">
                  <option value="" selected>85%</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="">
        <label for="" class="form-label">Commission Setting</label>
        <hr class="mt-0 mb-2">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="text-end">Type :</td>
              <td class="text-start">
                <select class="form-select form-inline">
                  <option value="" selected>
                    A (HDP/OU/OE)
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="text-end">HDP/OU/OE 0.3:</td>
              <td class="text-start">
                <select class="form-select form-inline">
                  <option value="" selected>
                    0.3 %
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="text-end">1X2/Double Chance :</td>
              <td class="text-start">
                <select class="form-select form-inline">
                  <option value="" selected>
                    0.4 %
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="text-end">Others 1 :</td>
              <td class="text-start">
                <select class="form-select form-inline">
                  <option value="" selected>
                    1 %
                  </option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div> -->
        <div class="col-12 col-lg-6 mb-3">
          <label for="" class="form-label">{{'SET_LIMIT'|translate}}</label>
          <hr class="mt-0 mb-2">
          <table class="table table-borderless">
            <thead>
              <tr>
                <th></th>
                <th>{{'MAXIMUM_PER_LIST'|translate}}</th>
                <th>{{'MAXIMUM_PER_GAMES'|translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-end">
                  {{'HANDICAP_OVER_UNDER_EVEN'|translate}}
                </td>
                <td class="text-start">
                  <div *ngIf="!formValue.limit_setting_amb_form.sportsBook_hdpOuOe_maxPerBet_uplineLimit">
                    <input type="number" class="form-control" formControlName="sportsBook_hdpOuOe_maxPerBet">
                  </div>
                  <div *ngIf="formValue.limit_setting_amb_form.sportsBook_hdpOuOe_maxPerBet_uplineLimit">
                    <input type="number" class="form-control" formControlName="sportsBook_hdpOuOe_maxPerBet"
                      [max]="formValue.limit_setting_amb_form.sportsBook_hdpOuOe_maxPerBet_uplineLimit">
                    <div class="small opacity-75"> Max =
                      {{formValue.limit_setting_amb_form.sportsBook_hdpOuOe_maxPerBet_uplineLimit|number:'1.2-2'}}
                    </div>
                  </div>
                </td>
                <td class="text-start">
                  <div *ngIf="!formValue.limit_setting_amb_form.sportsBook_oneTwoDoubleChance_maxPerBet_uplineLimit">
                    <input type="number" class="form-control" formControlName="sportsBook_hdpOuOe_maxPerMatch">
                  </div>
                  <div *ngIf="formValue.limit_setting_amb_form.sportsBook_hdpOuOe_maxPerMatch_uplineLimit">
                    <input type="number" class="form-control" formControlName="sportsBook_hdpOuOe_maxPerMatch"
                      [max]="formValue.limit_setting_amb_form.sportsBook_hdpOuOe_maxPerMatch_uplineLimit">
                    <div class="small opacity-75"> Max =
                      {{formValue.limit_setting_amb_form.sportsBook_hdpOuOe_maxPerMatch_uplineLimit|number:'1.2-2'}}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="text-end">
                  1X2/{{'TWO_CHANCE'|translate}}
                </td>
                <td class="text-start">
                  <div *ngIf="!formValue.limit_setting_amb_form.sportsBook_oneTwoDoubleChance_maxPerBet_uplineLimit">
                    <input type="number" class="form-control" formControlName="sportsBook_oneTwoDoubleChance_maxPerBet">
                  </div>
                  <div *ngIf="formValue.limit_setting_amb_form.sportsBook_oneTwoDoubleChance_maxPerBet_uplineLimit">
                    <input type="number" class="form-control" formControlName="sportsBook_oneTwoDoubleChance_maxPerBet"
                      [max]="formValue.limit_setting_amb_form.sportsBook_oneTwoDoubleChance_maxPerBet_uplineLimit">
                    <div class="small opacity-75"> Max =
                      {{formValue.limit_setting_amb_form.sportsBook_oneTwoDoubleChance_maxPerBet_uplineLimit|number:'1.2-2'}}
                    </div>
                  </div>
                </td>
                <td></td>
              </tr>
              <tr>
                <td class="text-end">
                  {{'OTHER'|translate}}
                </td>
                <td class="text-start">
                  <div *ngIf="!formValue.limit_setting_amb_form.sportsBook_others_maxPerBet_uplineLimit">
                    <input type="number" class="form-control" formControlName="sportsBook_others_maxPerBet">
                  </div>
                  <div *ngIf="formValue.limit_setting_amb_form.sportsBook_others_maxPerBet_uplineLimit">
                    <input type="number" class="form-control" formControlName="sportsBook_others_maxPerBet"
                      [max]="formValue.limit_setting_amb_form.sportsBook_others_maxPerBet_uplineLimit">
                    <div class="small opacity-75"> Max =
                      {{formValue.limit_setting_amb_form.sportsBook_others_maxPerBet_uplineLimit|number:'1.2-2'}}
                    </div>
                  </div>
                </td>
                <td></td>
              </tr>
              <tr>
                <td class="text-end">
                  {{'PREDICT_THE_WINNER'|translate}}
                </td>
                <td class="text-start">
                  <div *ngIf="!formValue.limit_setting_amb_form.sportsBook_outright_maxPerMatch_uplineLimit">
                    <input type="number" class="form-control" formControlName="sportsBook_outright_maxPerMatch">
                  </div>
                  <div *ngIf="formValue.limit_setting_amb_form.sportsBook_outright_maxPerMatch_uplineLimit">
                    <input type="number" class="form-control" formControlName="sportsBook_outright_maxPerMatch"
                      [max]="formValue.limit_setting_amb_form.sportsBook_outright_maxPerMatch_uplineLimit">
                    <div class="small opacity-75"> Max =
                      {{formValue.limit_setting_amb_form.sportsBook_outright_maxPerMatch_uplineLimit|number:'1.2-2'}}
                    </div>
                  </div>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ng-container>
</form>
