import { AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CountUp, CountUpOptions } from 'countup.js';

@Component({
  selector: 'app-number-counter',
  templateUrl: './number-counter.component.html',
  styleUrls: ['./number-counter.component.scss']
})
export class NumberCounterComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('targetId') targetId: ElementRef<HTMLSpanElement>;
  @Input() numberCount!: number;
  @Input() NoSeparator!: boolean;
  @Input() duration = 2;
  options!: CountUpOptions;
  main!: CountUp;
  randomId = Math.random();
  constructor() {
  }

  ngOnInit() {
    if (this.NoSeparator) {
      this.options = {
        decimalPlaces: 0,
        duration: this.duration,
        separator: '',
        decimal: '',
      };
    } else {
      this.options = {
        decimalPlaces: 2,
        duration: this.duration,
      };
    }
  }
  ngOnChanges() {
    if (typeof this.numberCount === 'number' && this.main) {
      this.main.update(this.numberCount);
    }
  }

  ngAfterViewInit() {
    this.main = new CountUp(this.targetId.nativeElement, 0, this.options);
    if (!this.main.error) {
      this.ngOnChanges();
      this.main.start();
    } else {
      console.error(this.main.error);
    }
  }
}
