import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { FbGroupConfig } from '@ngneat/reactive-forms/lib/formBuilder';
import { AppInjector } from 'src/app/app-injector';
import { PageModel } from '../interfaces/page-model';

export function formPage<T = PageModel>(controlsConfig: FbGroupConfig<PageModel> = {}): FormGroup<T> {
  const ngFb = AppInjector.get(FormBuilder);
  return ngFb.group<any>({
    page: [1],
    limit: [20],
    name: [''],
    filter: [''],
    ...controlsConfig,
  });
}
