<div class="theme-dark">
  <app-loading-screen *ngIf="showloadingScreen" @MyFade></app-loading-screen>
  <router-outlet *ngIf="isReady"></router-outlet>
  <div *ngIf="viewImage" class="show-image animate__animated animate__fadeIn animate__faster" (click)="closeImage()">
    <img [src]="viewImage" alt="" style="object-fit: contain; max-width: 100%; max-height: 100%;"
      onerror="this.src = '/assets/placeholder.jpg'">
  </div>
</div>

<ngx-loading-bar color="var(--bs-primary)" height="3px" [includeSpinner]="false" container="body"></ngx-loading-bar>

<button type="button" class="d-none" id="btn-open-choose-lang" data-bs-toggle="modal"
  data-bs-target="#chooseLanguageModal"> {{'CHOOSE_LANGUAGE'|translate}} </button>
<app-choose-language-modal></app-choose-language-modal>

<button type="button" class="d-none" id="btn-open-choose-currency" data-bs-toggle="modal"
  data-bs-target="#chooseCurrencyModal"> {{'CHOOSE_CURRENTCY'|translate}} </button>
<app-choose-currency-modal></app-choose-currency-modal>

