import { createLinearGradient } from 'src/app/utils/functions/canvas-linear-gradient';
import { Chart } from 'chart.js';
import { AdvancedPieChart } from 'src/app/shared/components/charts/chart.interface';
import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent implements OnInit {
  @ViewChild('Doughnut') canvas: ElementRef<HTMLCanvasElement>;
  @Input() MasterData: AdvancedPieChart.Data;
  ctx: CanvasRenderingContext2D;
  myChart: Chart;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.setupChart();
  }
  ngOnChanges() {
  }
  get setupData() {
    return {
      labels: this.Labels,
      datasets: [
        {
          borderWidth: 0,
          data: this.data,
          fill: true,
          backgroundColor: this.backgroundColor,
        }
      ]
    };
  }
  get data() {
    const labels = [];
    this.MasterData.series.forEach(series => {
      labels.push(series?.value);
    });
    return labels;
  }
  get Labels() {
    const labels = [];
    this.MasterData.series.forEach(series => {
      labels.push(series?.name);
    });
    return labels;
  }
  get backgroundColor() {
    const bg = [];
    this.MasterData.series.forEach(series => {
      bg.push(createLinearGradient(series.color, this.ctx));
    });
    return bg;
  }
  setupChart() {
    const config: any = {
      type: 'doughnut',
      data: this.setupData,
      options: {
        cutout: '80%',
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: 'top',
          },
          title: {
            display: false
          }
        }
      },
    };
    Chart.defaults.font.family = `'Prompt'`;
    this.myChart = new Chart(this.ctx, config);

  }
}
