export * from './setting-amb/setting-amb-casino/setting-amb-casino.component';
export * from './setting-amb/setting-amb-esport/setting-amb-esport.component';
export * from './setting-amb/setting-amb-keno/setting-amb-keno.component';
export * from './setting-amb/setting-amb-lotto/setting-amb-lotto.component';
export * from './setting-amb/setting-amb-m2/setting-amb-m2.component';
export * from './setting-amb/setting-amb-multi/setting-amb-multi.component';
export * from './setting-amb/setting-amb-parlay/setting-amb-parlay.component';
export * from './setting-amb/setting-amb-poker/setting-amb-poker.component';
export * from './setting-amb/setting-amb-slot/setting-amb-slot.component';
export * from './setting-amb/setting-amb-sport/setting-amb-sport.component';
export * from './setting-amb/setting-amb-step/setting-amb-step.component';
export * from './setting-amb/setting-amb-trading/setting-amb-trading.component';

export * from './setting-klotto/setting-klotto-play/setting-klotto-play.component';
