import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { filter, map, skipWhile } from 'rxjs/operators';
import { HttpApiService } from '../services/http-api.service';
import { UserRoles, UserRolesType } from '../utils/constants/user-roles';
import { PageModel } from '../utils/interfaces/page-model';
import { Pagination } from '../utils/interfaces/pagination';
import { Agent } from './agent.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private roles$ = new BehaviorSubject<UserRole[]>(null);
  roles = this.roles$.pipe(map(roles => roles?.map(role => {
    if (role.roleName === 'admin_accounting') {
      role.roleSp = true;
    } else if (role.roleName === 'admin_support') {
      role.roleSp = true;
    } else if (role.roleName === 'member_password') {
      role.roleSp = true;
    } else {
      role.roleSp = false;
    }
    return { ...role, roleTH: UserRoleNames[role.roleName] };
  }) || []));
  updateRoles$ = new Subject<any>();

  private user$ = new BehaviorSubject<User>(null);
  private userProfile$ = new BehaviorSubject<UserProfile>(null);
  updateUserProfile$ = new Subject();

  sageId$ = new BehaviorSubject<number | null>(null);
  constructor(
    private http: HttpApiService,
  ) { }

  getUser() {
    return this.user$.pipe(skipWhile(val => !val));
  }
  setUser(data: User) {
    this.user$.next(data);
  }

  getUserProfile() {
    return this.userProfile$.pipe(skipWhile(val => !val));
  }
  setUserProfile(data: UserProfile) {
    this.userProfile$.next(data);
  }

  getRoleSelect() {
    return this.http.get<UserRole[]>(`api/ask4bet/admin/get/role/select`);
  }

  getAdminById(userId: number) {
    return this.http.get<User>(`api/ask4bet/admin/${userId}`);
  }

  getAdmin(params: PageModel) {
    return this.http.get<Pagination<User[]>>(`api/ask4bet/admin`, { params });
  }

  // API System Start //
  postAdminSystem(model: UserModel) {
    return this.http.post(`api/ask4bet/admin/create/system`, model);
  }

  putAdminSystem(userId: number, model: UserModel) {
    return this.http.put(`api/ask4bet/admin/edit/system/${userId}`, model);
  }

  // deleteAdminSystem(userId: number) {
  //   return this.http.delete(`api/ask4bet/admin/delete/system/${userId}`);
  // }
  // API System End //

  deleteUser(id: number) {
    return this.http.delete(`api/ask4bet/admin/delete/${id}`);
  }

  // API Agent Start //
  postAdminAgent(model: any) {
    return this.http.post(`api/ask4bet/admin/create/agent`, model);
  }

  putAdminAgent(userId: number, model: any) {
    return this.http.put(`api/ask4bet/admin/edit/agent/${userId}`, model);
  }

  // deleteAdminAgent(userId: number) {
  //   return this.http.delete(`api/ask4bet/admin/delete/agent/${userId}`);
  // }

  getConnectIpAddress() {
    return this.http.get(`api/core/connection_info`);
  }
  // API Agent End //

  setRoles(roles: UserRole[]) {
    this.roles$.next(roles);
  }

  updateRoles() {
    this.updateRoles$.next();
  }

  getProfile() {
    return this.http.get<UserProfile>(`api/ask4bet/admin/get/profile`);
  }

  getLogin(params: PageModel) {
    return this.http.get<Pagination<UserLogLogin[]>>(`api/ask4bet/admin/get/profile/log_login`, { params });
  }

  //#region Sage

  /**
   *
   * @param params ?agent_id={agent_id}
   * @returns
   */
  getSageUsers(params: any) {
    return this.http.get<UserZean[]>(`api/v1/affiliate/get/user`, { params });
  }

  /**
   *
   * @param params Page & ?from={from}&to={to}&timefrom={timefrom}&timeto={timeto}
   * @returns
   */
  getSageUsersByDatetime(params: any) {
    return this.http.get<PageUserZean[]>(`api/v1/affiliate/get/user/page`, { params }).pipe(
      map(res => res.map(r => {
        r.winlose = r.deposit - r.withdraw - r.wallet;
        r.profit_sharing_total = 0;
        if (r.profit_sharing > 0) {
          r.profit_sharing_total = r.winlose * (r.profit_sharing / 100);
        }
        return r;
      }))
    );
  }

  /**
   *
   * @param params ?agent_id={agent_id}&user_id={user_id}
   */
  getSageLinksByUserIdPagination(params: any) {
    return this.http.get<PageUserZean>(`api/v1/affiliate/get/user/link/page`, { params }).pipe(
      map(r => {
        r.winlose = r.deposit - r.withdraw - r.wallet;
        r.profit_sharing_total = 0;
        if (r.profit_sharing > 0) {
          r.profit_sharing_total = r.winlose * (r.profit_sharing / 100);
        }
        return r;
      })
    );
  }

  /**
   *
   * @param params ?user_id={user_id}
   * @returns
   */
  getSageLinksByUserIdList(params: any) {
    return this.http.get<SageLink[]>(`api/v1/affiliate/link`, { params });
  }

  /**
   *
   * @param params
   * @returns ?user_id={user_id}&link_id={link_id}&from={from}&to={to}&timefrom={timefrom}&timeto={timeto}
   */
  getReportSageLinkById(params: any) {
    return this.http.get<ReportSageLink[]>(`api/v1/affiliate/get/user/link/member/sub_link`, { params }).pipe(
      map(res => {
        return res.map(r => {
          r.winlose = (r.deposit - r.withdraw) - r.wallet;
          r.profit_sharing_total = 0;
          if (r.profit_sharing > 0) {
            r.profit_sharing_total = r.winlose * (r.profit_sharing / 100);
          }
          return r;
        });
      })
    );
  }

  /**
   *
   * @param params ?user_id={user_id}&from={from}&to={to}&timefrom={timefrom}&timeto={timeto}
   * @returns
   */
  getReportSageLinksSummary(params: any) {
    return this.http.get<ReportSageLinkSummary[]>(`api/v1/affiliate/get/user/link/summary`, { params }).pipe(
      map(res => {
        return res.map(r => {
          r.winlose = (r.deposit - r.withdraw) - r.wallet;
          r.profit_sharing_total = 0;
          if (r.profit_sharing > 0) {
            r.profit_sharing_total = r.winlose * (r.profit_sharing / 100);
          }
          return r;
        });
      })
    );
  }

  /**
   *
   * @param params ?user_id={user_id}&sortby={sortby}
   * @returns
   */
  getSageLinks(params: any) {
    return this.http.get<Pagination<SageLink[]>>(`api/v1/affiliate/get/link/pagination`, { params });
  }

  /**
   *
   * @param params ?user_id={user_id}
   * @returns
   */
  postSageLink(params: any) {
    return this.http.post(`api/v1/affiliate/create/link`, {}, { params });
  }

  /**
   *
   * @param params ?user_id={user_id}&id={id}
   * @returns
   */
  putSageLink(model: any, params: any) {
    return this.http.put(`api/v1/affiliate/edit/link`, model, { params });
  }

  /**
   *
   * @param params ?user_id={user_id}&id={id}
   * @returns
   */
  deleteSageLink(params: any) {
    return this.http.delete(`api/v1/affiliate/remove/link`, { params });
  }

  /**
   *
   * @param params ?user_id={user_id}
   * @returns
   */
  getSageLinkHeader(params: any) {
    return this.http.get<SageLinkHeader>(`api/v1/affiliate/get/link/header`, { params });
  }

  /**
   *
   * @param params ?user_id={user_id}&from={from}&to={to}&timefrom={timefrom}&timeto={timeto}
   * @returns
   */
  getSageLinkMembers(params: any) {
    return this.http.get<PageUserZean[]>(`api/v1/affiliate/get/user/link/member`, { params }).pipe(
      map(res => res.map(r => {
        r.winlose = r.deposit - r.withdraw - r.wallet;
        r.profit_sharing_total = 0;
        if (r.profit_sharing > 0) {
          r.profit_sharing_total = r.winlose * (r.profit_sharing / 100);
        }
        return r;
      }))
    );
  }

  //#endregion
}

export interface AdminSystemModel {
  username: string;
  password: string;
  name: string;
  roleId: number;
}

export interface AdminAgentModel {
  username: string;
  password: string;
  name: string;
  ask4betAgentId: number;
  role: number[];
}

export interface User {
  id: number;
  username: string;
  name: string;
  image?: string;
  status: string;
  ask4betAgentId: number;
  agentName: string;
  partnerName: string;
  update_by: number;
  roles: UserRole[];
  roleIds: string;
}

export interface UserRole {
  roleId: number;
  roleName: UserRoleType;
  roleDetail: UserRoles;
  roleTH?: string;
  roleSp?: boolean;
}

export interface UserModel {
  username: string;
  password?: string;
  name: string;
  image: string;
  roleId?: string; // System
  ask4betAgentId?: any; // Agent
  roleIds?: number[]; // Agent
  profit_sharing?: number;
  team_aff?: string;

  typeAgentId?: string;
}

export const UserRoleNames = {
  admin: 'ADMIN',
  operator: 'OPERATOR',
  'operator-help': 'ผู้ช่วยดำเนินการ',
  member_management: 'ผู้จัดการสมาชิก',
  member_management_view: 'ผู้จัดการสมาชิก (ดูเท่านั้น)',
  report: 'หุ้นส่วน (only report)',
  deposit: 'ผู้ดำเนินการ (ฝาก)',
  withdraw: 'ผู้ดำเนินการ (ถอน)',
  promotion: 'ผู้จัดโปร',
  game_management: 'ผู้จัดเกมส์',
  deposit_approver: 'ผู้ดำเนินการ (ยืนยันการฝาก)',
  member_password: 'Member Password',
  admin_accounting: 'Admin Accounting',
  admin_support: 'Admin Support',
  zean: 'เซียน',
};

export type UserRoleType = keyof typeof UserRoleNames;

interface AllowRoles {
  [key: string]: UserRolesType[];
}

export const allowRoles: AllowRoles = {
  admin: ['Superadmin', 'Admin'],
  operator: ['Superadmin', 'Admin'],
  member_management: ['Superadmin', 'Admin', 'Operator'],
  member_management_view: ['Superadmin', 'Admin', 'Operator'],
  report: ['Superadmin', 'Admin', 'Operator'],
  deposit: ['Superadmin', 'Admin', 'Operator'],
  withdraw: ['Superadmin', 'Admin', 'Operator'],
  promotion: ['Superadmin', 'Admin', 'Operator'],
  shareholder: ['Superadmin', 'Admin', 'Operator'],
  senior: ['Superadmin', 'Admin', 'Operator'],
  master_agent: ['Superadmin', 'Admin', 'Operator'],
  agent: ['Superadmin', 'Admin', 'Operator'],
};

export const rolesIcons = {
  admin: 'fas fa-user-cog',
  operator: 'fas fa-user-shield',
  member_management: 'fas fa-users-cog',
  member_management_view: 'fas fa-street-view',
  report: 'fas fa-user-tie',
  deposit: 'fas fa-user-plus',
  withdraw: 'fas fa-user-minus',
  promotion: 'fas fa-user-tag',
  member_password: 'fas fa-user-lock',
};


export interface UserLogLogin {
  id: number;
  user_id: number;
  username: string;
  name: string;
  login_time: string;
}

export interface UserProfile {
  user: UserProfileDetail;
  agent?: Agent;
}

export interface UserProfileDetail {
  id: number;
  username: string;
  name: string;
  status: string;
  ask4betAgentId: number;
  update_by: number;
  roles: UserRole[];
  roleIds: string;
  image: string;
}


export interface IPAddress {
  ip_address: string;
  cf_connecting_ip: string;
  forwarded_for: string;
}

export interface UserZean {
  id: number;
  username: string;
  name: string;
  image: string;
  status: string;
  ask4betAgentId: number;
  team_aff: string;
  profit_sharing: number;
  agentName: string;
  partnerName: string;
  update_by: number;
  roles: string;
  roleIds: string;
}

export interface PageUserZean {
  id: number;
  ask4betAgentId: number;
  agent_username: string;
  username: string;
  name: string;
  status: string;
  role: string;
  update_by: number;
  profit_sharing: number;
  team_aff: string;
  deposit: number;
  withdraw: number;
  wallet: number;

  winlose?: number;
  profit_sharing_total?: number;
  deleting?: boolean;
}

export interface SageLink {
  Id: number;
  user_id: number;
  link_name: string;
  code_aff: string;
  amount_regis: number;
  amount_click: number;
  create_dt: string;
  status: string;
}

export interface SageLinkHeader {
  total_regis: number;
  total_clink: number;
  total_link: number;
  web: string;
}

export interface ReportSageLink {
  id: number;
  phone: string;
  line_id: string;
  ask4betAgentId: number;
  game_username: string;
  first_name: string;
  last_name: string;
  status: string;
  register_date: string;
  user_affiliate_id: number;
  link_affiliate_id: number;
  profit_sharing: number;
  team_aff: string;
  link_name: string;
  code_aff: string;
  deposit: number;
  withdraw: number;
  wallet: number;

  winlose?: number;
  profit_sharing_total?: number;
  deleting?: boolean;
}

export interface ReportSageLinkSummary {
  id: number;
  phone?: any;
  line_id?: any;
  ask4betAgentId?: any;
  game_username?: any;
  first_name?: any;
  last_name?: any;
  status: string;
  register_date: string;
  user_affiliate_id?: any;
  link_affiliate_id?: any;
  profit_sharing: number;
  team_aff?: any;
  link_name: string;
  code_aff: string;
  deposit: number;
  withdraw: number;
  wallet?: any;

  winlose?: number;
  profit_sharing_total?: number;
}
