import { Routes } from '@angular/router';
import { AppPath } from '../utils/constants/paths';

export const secureRoutes: Routes = [
  {
    path: AppPath.dashboard,
    loadChildren: () => import('../pages').then(C => C.DashboardModule),
    data: { title: 'DASHBOARD' },
  },
  {
    path: AppPath.manageAgent,
    loadChildren: () => import('../pages').then(C => C.ManageAgentModule),
    data: { title: 'MANAGE_AGENT' },
  },
  {
    path: AppPath.manageMember,
    loadChildren: () => import('../pages').then(C => C.ManageMemberModule),
    data: { title: 'MANAGE_MENBER' },
  },
  {
    path: AppPath.scammer,
    loadChildren: () => import('../pages').then(C => C.ScammerModule),
    data: { title: 'MANAGE_MENBER' },
  },
  {
    path: AppPath.credit,
    loadChildren: () => import('../pages').then(C => C.CreditModule),
    data: { title: 'CREDIT' },
  },
  {
    path: AppPath.bank,
    loadChildren: () => import('../pages').then(C => C.BankModule),
    data: { title: 'BANK' },
  },
  {
    path: AppPath.user,
    loadChildren: () => import('../pages').then(C => C.UserModule),
    data: { title: 'USER' },
  },
  {
    path: AppPath.tournament,
    loadChildren: () => import('../pages').then(C => C.TournamentModule),
    data: { title: 'TOURNAMENT' }
  },
  {
    path: AppPath.games,
    loadChildren: () => import('../pages').then(C => C.GamesModule),
    data: { title: 'GAMES' }
  },
  {
    path: AppPath.help,
    loadChildren: () => import('../pages').then(C => C.HelpModule),
    data: { title: 'HELP' },
  },
  {
    path: AppPath.chat,
    loadChildren: () => import('../pages').then(C => C.ChatModule),
    data: { title: 'CHAT' },
  },
  {
    path: AppPath.promotion,
    loadChildren: () => import('../pages').then(C => C.PromotionModule),
    data: { title: 'PROMOTION' },
  },
  {
    path: AppPath.line,
    loadChildren: () => import('../pages').then(C => C.LineModule),
    data: { title: 'LINE' },
  },
  {
    path: AppPath.autoTranfer,
    loadChildren: () => import('../pages').then(C => C.AutoTranferModule),
    data: { title: 'AUTO_TRANFER' },
  },
  {
    path: AppPath.marketing,
    loadChildren: () => import('../pages').then(C => C.MarketingModule),
    data: { title: 'MARKETING' },
  },
  {
    path: AppPath.workDeposit,
    loadChildren: () => import('../pages').then(C => C.WorkDepositModule),
    data: { title: 'WORK_DEPOSIT' },
  },
  {
    path: AppPath.workWithdraw,
    loadChildren: () => import('../pages').then(C => C.WorkWithdrawModule),
    data: { title: 'WORK_WITHDRAW' },
  },
  {
    path: AppPath.settings,
    loadChildren: () => import('../pages').then(C => C.SettingsModule),
    data: { title: 'WORK_DEPOSIT' },
  },
  {
    path: AppPath.point,
    loadChildren: () => import('../pages').then(C => C.PointModule),
    data: { title: 'POINT' },
  },
  {
    path: AppPath.pointStore,
    loadChildren: () => import('../pages').then(C => C.BuyPointModule),
    data: { title: 'POINT_STORE' },
  },
  {
    path: AppPath.redeemReward,
    loadChildren: () => import('../pages').then(C => C.RewardRedeemModule),
    data: { title: 'REDEEM_REWARD' },
  },
  {
    path: AppPath.rewardWheel,
    loadChildren: () => import('../pages').then(C => C.RewardWheelModule),
    data: { title: 'REWARD_WHEEL' },
  },
  {
    path: AppPath.bankSupport,
    loadChildren: () => import('../pages').then(C => C.BankSupportModule),
    data: { title: 'BANK_SUPPORT' },
  },
  {
    path: AppPath.soundSetting,
    loadChildren: () => import('../pages').then(C => C.SoundSettingModule),
    data: { title: 'SOUND_SRTTING' },
  },
  {
    path: AppPath.broadcatSMS,
    loadChildren: () => import('../pages').then(C => C.BroadcatSmsModule),
    data: { title: 'BROADCAT_SMS' },
  },
  {
    path: AppPath.news,
    loadChildren: () => import('../pages').then(C => C.NewsModule),
    data: { title: 'NEWS' },
  },
  {
    path: AppPath.transaction,
    loadChildren: () => import('../pages').then(C => C.TransactionModule),
    data: { title: 'TRANSACTION' },
  },
  {
    path: AppPath.profile,
    loadChildren: () => import('../pages').then(C => C.ProfileModule),
    data: { title: 'PROFILE' },
  },
  {
    path: AppPath.article,
    loadChildren: () => import('../pages').then(C => C.ArticleModule),
    data: { title: 'ARTICLE' },
  },
  {
    path: AppPath.metaTag,
    loadChildren: () => import('../pages').then(C => C.MetaTagModule),
    data: { title: 'META_TAG' },
  },
  {
    path: AppPath.reports,
    loadChildren: () => import('../pages').then(C => C.ReportsModule),
    data: { title: 'REPORTS' },
  },
  {
    path: AppPath.Jobs,
    loadChildren: () => import('../pages').then(C => C.BackgroundJobsModule),
    data: { title: 'BACKGROUP_JOBS' },
  },
  {
    path: AppPath.alertTelegram,
    loadChildren: () => import('../pages').then(C => C.AlertTelegramModule),
    data: { title: 'Telegram Alert' },
  },
  {
    path: AppPath.alertLine,
    loadChildren: () => import('../pages').then(C => C.AlertLineModule),
    data: { title: 'Line Alert' },
  },
  {
    path: AppPath.logs,
    loadChildren: () => import('../pages').then(C => C.LogsModule),
    data: { title: 'Logs' },
  },
  {
    path: AppPath.invoices,
    loadChildren: () => import('../pages').then(C => C.BillsModule),
    data: { title: 'Bill' },
  },
  {
    path: AppPath.manageSMS,
    loadChildren: () => import('../pages').then(C => C.ManageSmsModule),
    data: { title: 'SMS' },
  },
  {
    path: AppPath.broadcast,
    loadChildren: () => import('../pages').then(C => C.BroadcastModule),
    data: { title: 'BROADCAST' },
  },
  {
    path: AppPath.helpUser,
    loadChildren: () => import('../pages').then(C => C.HelpUserModule),
    data: { title: 'HELP USER' },
  },
  {
    path: AppPath.announce,
    loadChildren: () => import('../pages').then(C => C.AnnounceModule),
    data: { title: 'ANNOUNCE' },
  },
  {
    path: AppPath.supportAdmin,
    loadChildren: () => import('../pages').then(C => C.SupportAdminModule),
    data: { title: 'SUPPORT_ADMIN' },
  },
  {
    path: AppPath.settingAgentFE,
    loadChildren: () => import('../pages').then(C => C.SettingAgentFeModule),
    data: { title: 'SUPPORT_ADMIN' },
  },
  {
    path: AppPath.memberOutstanding,
    loadChildren: () => import('../pages').then(C => C.MemberOutstandingModule),
    data: { title: 'MEMBER_OUTSTANDING' },
  },
  {
    path: AppPath.klotto,
    loadChildren: () => import('../pages').then(C => C.KlottoModule),
    data: { title: 'KLOTTO' },
  },
  {
    path: AppPath.manageSage,
    loadChildren: () => import('../pages').then(C => C.SagesModule),
    data: { title: 'MANAGE_SAGES' },
  },
];
