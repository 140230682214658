import { Component, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { Subscription } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';
import { LoyaltyService, WheelGame, WheelGameModel, WheelGameRewardModel } from 'src/app/apis/loyalty.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { alertConfirmDanger, alertSuccess, alertText, alertWarning } from 'src/app/utils/functions/alert';
import { noWhitespaceValidator } from 'src/app/utils/functions/no-whitespace';

@Component({
  selector: 'app-reward-wheel-form-offcanvas',
  templateUrl: './reward-wheel-form-offcanvas.component.html',
  styleUrls: ['./reward-wheel-form-offcanvas.component.scss']
})
export class RewardWheelFormOffcanvasComponent extends AppCore implements OnInit, OnChanges, OnDestroy {
  form = this.createForm();
  saving = false;

  @Input() item: WheelGame;
  constructor(
    private ngFb: FormBuilder,
    private svLoyalty: LoyaltyService,
  ) {
    super();
  }

  ngOnInit(): void {
    const rewardWheelEl = document.getElementById('rewardWheelOffcanvas');
    rewardWheelEl.addEventListener('show.bs.offcanvas', () => {
      this.form = this.createForm();
      const rewardChanges$ = this.form.get(`rewards`).valueChanges.pipe(debounceTime(300)).subscribe((rewardGroup: WheelGameRewardModel[]) => {
        const totalInWheel = rewardGroup.reduce((val, nxt) => val + (+nxt.reward_in_wheel), 0);
        const totalPercent = rewardGroup.reduce((val, nxt) => val + nxt.reward_percent, 0);
        const totalAmount = rewardGroup.reduce((val, nxt) => val + nxt.reward_amount, 0);
        this.form.patchValue({ totalInWheel, totalPercent, totalAmount });
      });
      this.Subscriptions.add(rewardChanges$);
    });
  }
  ngOnChanges() {
    if (this.item) {
      this.getFormArray(this.form, 'rewards').clear();
      for (const _ of this.item.rewards) {
        const rewardGroup = this.createFormReward();
        this.getFormArray(this.form, 'rewards').push(rewardGroup);
      }
      this.form.patchValue(this.item);
    }
  }

  createForm(): FormGroup<WheelGameModel> {
    return this.ngFb.group({
      name: ['', [noWhitespaceValidator]],
      point: [0, [Validators.required]],
      rewards: this.ngFb.array([this.createFormReward()]),
      is_enabled: [true],

      totalInWheel: [0],
      totalPercent: [0, [Validators.required, Validators.min(100), Validators.max(100)]],
      totalAmount: [0],
    });
  }

  createFormReward(): FormGroup<WheelGameRewardModel> {
    return this.ngFb.group({
      reward_amount: [0],
      reward_in_wheel: ['1'],
      reward_percent: [0],
      reward_type: [''],
    });
  }

  addFormRewardToForm() {
    const formReward = this.createFormReward();
    this.getFormArray(this.form, 'rewards').push(formReward);
  }

  removeFormRewardAt(index: number) {
    this.getFormArray(this.form, 'rewards').removeAt(index);
  }

  async onSubmit() {
    this.form.markAllAsDirty();
    this.form.markAllAsTouched();

    if (!this.form.get('totalPercent').valid) {
      alertWarning({ message: `ความน่าจะเป็นรวม ต้องเท่ากับ <b>100%</b>` });
      return;
    }
    if (!this.form.valid) {
      alertWarning({ message: alertText.formInvalid });
      return;
    }

    this.saving = true;
    try {
      if (!this.item) {
        await this.createWheelGame();
      } else {
        await this.updateWheelGame();
      }
      alertSuccess({ message: alertText.saveSuccess });
      this.clickBtnClose('btn-close');
    } catch (e) { }
  }

  createWheelGame() {
    return this.svLoyalty.postWheelGame(this.currentAgent.id, this.form.value).pipe(finalize(() => this.saving = false)).toPromise();
  }

  updateWheelGame() {
    return this.svLoyalty.putWheelGame(this.item.id, this.form.value).pipe(finalize(() => this.saving = false)).toPromise();
  }

  async onDelete() {
    const swal = await alertConfirmDanger({
      html: `ต้องการลบวงล้อ <b>${this.item.name}</b>?`
    });
    if (!swal.isConfirmed) { return; }

    this.saving = true;
    this.svLoyalty.deleteWheelGame(this.item.id).pipe(finalize(() => this.saving = false)).subscribe(res => {
      alertSuccess({ message: alertText.deleteSuccess });
      this.clickBtnClose('btn-close');
    });
  }
}
