import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Sound } from 'src/assets/sounds/sound.interface';
import { LocalName, LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SoundSettingService {
  defaultSetting: CurrentSoundSetting = {
    depositName: { name: 'Consequence', path: 'assets/sounds/consequence.mp3' },
    withdrawName: { name: 'Dont Think So', path: 'assets/sounds/dont-think-so.mp3' },
    transferSuccessName: { name: 'Inflicted', path: 'assets/sounds/inflicted.mp3' },
    transferFailedName: { name: 'Moonless', path: 'assets/sounds/moonless.mp3' },
  };
  settingUpdated$ = new Subject<any>();

  constructor(
    private svLocalStorage: LocalStorageService,
  ) { }

  getAllSound() {
    return new Promise<Sound[]>((resolve, _) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState == 4 && xhr.status == 200) {
          const response = JSON.parse(xhr.responseText);
          resolve(response.data)
        }
      };
      xhr.open('GET', 'assets/sounds/sounds.json', true);
      xhr.send();
    });
  }

  getCurrentSetting(): CurrentSoundSetting {
    const settings: CurrentSoundSetting = this.svLocalStorage.get(LocalName.notifySounds) || {};
    return Object.assign({}, this.defaultSetting, settings);
  }

  saveCurrentSetting(settings: CurrentSoundSetting) {
    this.svLocalStorage.set(LocalName.notifySounds, settings);
    this.settingUpdated$.next();
  }
}

export interface CurrentSoundSetting {
  depositName?: Sound;
  withdrawName?: Sound;
  transferSuccessName?: Sound;
  transferFailedName?: Sound;
}
