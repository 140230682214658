<header class="topbar-header" [ngClass]="{'active':sidebarOpen}">
  <div class="topbar shadow-sm">
    <nav class="navbar navbar-expand">
      <div class="topbar-menu">
        <ul class="navbar-nav align-items-center">
          <li class="nav-item">
            <a href="javascript:;" (click)="OnToggleSideBar()" pRipple class="nav-link">
              <i class="fas fa-bars"></i>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['',AppPath.apiDoc]">
              <i class="bi bi-journal-code"></i> APIs
            </a>
          </li>
          <!-- <li class="nav-item d-desktop">
            <a href="javascript:;" pRipple class="nav-link">
              <i class="fas fa-bars"></i>
            </a>
          </li> -->
        </ul>
      </div>
      <div class="topbar-menu ms-auto">
        <ul class="navbar-nav align-items-center">
          <!-- <ng-container *ngIf="env.name === 'develop'">
            <li class="">
              <a class="btn btn-outline-danger btn-sm mx-1" (click)="svSignalR.testStatementUpdated()">State Update</a>
            </li>
            <li class="">
              <a class="btn btn-outline-danger btn-sm mx-1" (click)="svSignalR.testDepositRequest()">D. Req</a>
            </li>
            <li class="">
              <a class="btn btn-outline-danger btn-sm mx-1" (click)="svSignalR.testWithdrawRequest()">W. Req</a>
            </li>
            <li class="">
              <a class="btn btn-outline-danger btn-sm mx-1" (click)="svSignalR.testWithdrawResult()">W. Res</a>
            </li>
            <li class="">
              <a class="btn btn-outline-danger btn-sm mx-1" (click)="svSignalR.testWithdrawCount()">W. Count</a>
            </li>
            <li class="">
              <a class="btn btn-outline-danger btn-sm mx-1" (click)="svSignalR.testDepositCount()">D. Count</a>
            </li>
          </ng-container> -->
          <!-- <li class="nav-item">
            <a routerLink="/help" pRipple class="nav-link">
              <i class="far fa-question-circle"></i>
            </a>
          </li> -->
          <li *ngIf="userProile?.agent" class="text-center">
            <a pRipple class="nav-link credit-bal">
              {{'CREDIT' | translate}} <span class="text-primary">{{userProile.agent.credit_remaining | number:
                '0.2-2'}} THB</span>
            </a>
          </li>

          <li class="d-none d-lg-block nav-item" [appTooltip]="'CHANGE_LANGUAGE' | translate" placement="bottom">
            <a pRipple class="nav-link" (click)="svLanguage.openChooseLanguage()">
              <img [src]="svLanguage.getLang().image" width="14px" height="14px" alt="">
            </a>
          </li>

          <li class="d-none d-lg-block nav-item" placement="bottom">
            <a pRipple class="nav-link">
              <small class="top-currency">Main</small>
              THB
            </a>
          </li>
          <li class="d-none d-lg-block nav-item" [appTooltip]="'CHANGE_CURRENCY' | translate" placement="bottom">
            <a pRipple class="nav-link" (click)="svCurrency.openChooseCurrency()">
              <small class="top-currency">Minor</small>
              {{svCurrency.getCurrency().value}}
            </a>
          </li>

          <li class="d-none d-lg-block nav-item">
            <a (click)="onChangeTheme()" pRipple class="nav-link">
              <i *ngIf="darkTheme" class="bi bi-cloud-moon-fill"></i>
              <i *ngIf="!darkTheme" class="bi bi-sun"></i>
            </a>
          </li>
          <!-- <li class="d-none d-lg-block nav-item">
            <a class="nav-link" [appTooltip]="'Realtime Notification Status' | translate" placement="bottom">
              <i class="fas fa-circle" [class]="svSignalR.notificationConnected ? 'text-success': 'text-danger'"></i>
            </a>
          </li> -->
          <li class="d-none d-lg-block nav-item">
            <a href="javascript:;" pRipple class="nav-link" style="overflow: inherit;" data-bs-toggle="offcanvas"
              data-bs-target="#alertModal" aria-controls="offcanvasRight">
              <i class="bi bi-bell"></i>
              <span *ngIf="newNotiTotal > 0"
                class="position-absolute top-0 start-100 translate-middle p-2 bg-danger rounded-circle fa-fade fa-sm">
                <span class="visually-hidden">New alerts</span>
              </span>
            </a>
          </li>
        </ul>
      </div>
      <div class="user-dropdown btn-group ms-3">
        <a href="javascript:;" pRipple class="d-flex" data-bs-toggle="dropdown">
          <img *ngIf="userProile?.user?.image" [src]="imgHost + userProile?.user?.image" alt="">
          <img *ngIf="!userProile?.user?.image" src="/assets/user.png" alt="">
          <div class="user-info ps-2 d-none d-lg-block">
            <p class="mb-0">{{currentUser?.Username}}</p>
            <p class="mb-0 text-muted"
              style="width: 120px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
              <small>{{currentUser.Role}}</small>
            </p>
          </div>
        </a>
        <ul class="dropdown-menu dropdown-menu-end shadow">
          <li>
            <a [routerLink]="['', AppPath.profile, ProfilePath.detail]" pRipple class="dropdown-item">
              <i class="bi bi-person-fill me-2"></i> {{'PROFILE' | translate}}
            </a>
          </li>
          <li>
            <a pRipple class="dropdown-item" data-bs-toggle="offcanvas" data-bs-target="#AllLink"
              aria-controls="offcanvasRight">
              <i class="bi bi-folder-symlink me-2"></i> {{'VARIOUS_LINKS' | translate}}
            </a>
          </li>
          <li>
            <a [routerLink]="['', AppPath.profile, ProfilePath.login]" pRipple class="dropdown-item">
              <i class="bi bi-box-arrow-in-right me-2"></i> {{'LOGIN_HISTORY' | translate}}
            </a>
          </li>
          <li>
            <a [routerLink]="['', AppPath.profile, ProfilePath.changePassword]" Ripple class="dropdown-item">
              <i class="bi bi-box-arrow-in-right me-2"></i> {{'CHANGE_PASSWORD' | translate}}
            </a>
          </li>
          <li class="dropdown-divider"></li>
          <li class="d-block d-lg-none">
            <a class="dropdown-item" pRipple (click)="svLanguage.openChooseLanguage()">
              <i class="fas fa-globe"></i> {{'CHOOSE_LANGUAGE' | translate}}
            </a>
          </li>
          <li class="d-block d-lg-none">
            <a class="dropdown-item" pRipple (click)="onChangeTheme()">
              <i *ngIf="darkTheme" class="bi bi-cloud-moon-fill"></i>
              <i *ngIf="!darkTheme" class="bi bi-sun"></i> {{'THEME' | translate}}
            </a>
          </li>
          <li class="d-block d-lg-none">
            <a class="dropdown-item" pRipple data-bs-toggle="offcanvas" data-bs-target="#alertModal">
              <i class="bi bi-bell"></i> {{'NOTIFACATION' | translate}}
            </a>
          </li>
          <li class="d-block d-lg-none dropdown-divider"></li>
          <li>
            <a href="javascript:;" pRipple class="dropdown-item" (click)="onLogout()">
              <i class="me-2 fas fa-power-off"></i> {{'LOG_OUT' | translate}}</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>

<div class="overlay-toggle-sidebar" (click)="OnToggleSideBar()" [ngClass]="{'overlay-open':sidebarOpen}"></div>

<div class="sidebar-layout bg-body shadow-sm" [ngClass]="{'sidebar-open':sidebarOpen}">
  <a class="sidebar-header text-center" [routerLink]="['']">
    <img class="brand-logo img-shadow" alt="brand-logo" height="55px" src="{{Config.LogoDark}}">
    <img class="brand-logo-w img-shadow" alt="brand-logo" height="55px" src="{{Config.Logo}}">
  </a>
  <div class="nav-item px-3 pt-2" *ngIf="isAdmin">
    <!-- <app-select-agent-form [(ngModel)]="agentId" (agentChanged)="selectAgentChange($event)">
    </app-select-agent-form> -->

    <app-admin-select-agent-form [(ngModel)]="currentAgent" (agentChange)="selectAgentChange($event)">
    </app-admin-select-agent-form>
    <hr>
  </div>
  <div class="sidebar-content" [perfectScrollbar]={}>
    <div class="pt-2 pb-5 px-3" id="accordion-sidebar">
      <ul class="list-unstyled sidebar-menu-group">
        <ng-container *ngFor="let item of sidebarMenu; let i = index">
          <li *ngIf="item.type === 'router' && (item.autosystem === Config.AutoSystem || !item.autosystem)"
            class="menu-router">
            <a class="link-dark" href="javascript:;" [routerLink]="item.link" routerLinkActive="active" pRipple>
              <i [class]="'fix-icon '+item.iconClass"> {{item.iconName}} </i>
              <span>
                {{item.name | translate}}

                <span *ngIf="item.badgesName && Badges[item.badgesName]" class="badge bg-danger rounded-pill">
                  {{Badges[item.badgesName] }}
                </span>

                <ng-container *ngIf="item.badgesName === 'WithdrawAuto'">
                  <span class="badge bg-info rounded-pill me-1" *ngIf="Badges.WithdrawPending !== 0">
                    {{Badges.WithdrawPending}}
                  </span>
                  <span class="badge bg-danger rounded-pill" *ngIf="Badges.WithdrawFailed !== 0">
                    {{Badges.WithdrawFailed}}
                  </span>
                </ng-container>

                <span *ngIf="item.realTimeWork">
                  <ng-container *ngIf="item.realTimeWork === 'LogOnline'">
                    <i *ngIf="LogOnline" class="bi bi-arrow-left-right "></i>
                  </ng-container>
                  <ng-container *ngIf="item.realTimeWork === 'TransferOnline'">
                    <i *ngIf="TransferOnline" class="bi bi-arrow-left-right "></i>
                  </ng-container>

                </span>
              </span>
            </a>
          </li>
          <li *ngIf="item.type === 'header' && (item.autosystem === Config.AutoSystem || !item.autosystem)"
            class="menu-header"> <span>{{item.name | translate}}</span> </li>

          <li *ngIf="item.type === 'menu' && (item.autosystem === Config.AutoSystem || !item.autosystem)"
            class="menu-list">
            <a class="link-dark menu-list-label" href="#collapse-menu-{{i}}" data-bs-toggle="collapse"
              [attr.aria-expanded]="item.expanded ? true: false" pRipple>
              <i [class]="'fix-icon '+item.iconClass"> {{item.iconName}} </i>
              <span> {{item.name | translate}}
                <span *ngIf="item.badgesName && Badges[item.badgesName]" class="badge bg-danger rounded-pill">
                  {{Badges[item.badgesName] }}
                </span>
              </span>
            </a>
            <ul id="collapse-menu-{{i}}" class="list-unstyled sidebar-menu-collapse collapse"
              data-bs-parent="#accordion-sidebar" [class.show]="item.expanded">
              <ng-container *ngFor="let menu of item.menuList; let j = index">
                <li class="" *ngIf="menu.autosystem === Config.AutoSystem || !menu.autosystem">
                  <a class="link-dark" href="javascript:;" [routerLink]="menu.link" routerLinkActive="active" pRipple>
                    <i class="far fa-circle fa-xs" style="width: 12px;"></i> <span class="me-2"> {{menu.name |
                      translate}} </span>
                    <span *ngIf="menu.badgesName && Badges[menu.badgesName]" class="badge bg-danger rounded-pill">
                      {{+Badges[menu.badgesName] }}
                    </span>
                  </a>
                </li>
              </ng-container>
            </ul>
          </li>
        </ng-container>
      </ul>
      <hr>
      <div class="bg-black-op text-center">
        <p>{{'THANK_YOU_USING_SERVICE' | translate}}</p>
        <small class="text-uppercase"> {{Config?.createBy?.name || 'KARNIVAL™ exclusive'}}</small>
      </div>
      <div style="height: 150px;"></div>
    </div>
  </div>
</div>

<div class="page-wrapper" [ngClass]="{'active':sidebarOpen}">
  <div class="page-content">
    <div class="row mb-2">
      <div class="col-12">
        <div class="box-message ">
          <div class="nav-message float-start">
            <i class="bi bi-envelope"></i> {{'MESSAGE'|translate}}
          </div>
          <div class="p-1">
            <marquee behavior="scroll" direction="left" onmouseover="this.stop()" scrolldelay="120"
              onmouseout="this.start()" class="w-100"> Hi!! Welcome to {{Config.YourBand.en}} 🤟🤟🤟
            </marquee>
          </div>
        </div>
      </div>
    </div>
    <router-outlet></router-outlet>
    <div class="text-end pt-4 pb-4 opacity-50">
      Copyright © 2021. All Right Reserved. ({{Config?.createBy?.name || 'KARNIVAL™'}} {{svApp.version}})
    </div>
  </div>
</div>

<app-alert-modal [(newItemTotal)]="newNotiTotal"></app-alert-modal>

<div class="offcanvas offcanvas-end" tabindex="-1" id="AllLink" aria-labelledby="offcanvasRightLabel">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasRightLabel">
      {{'LINKS_FOR_USE' | translate}}
    </h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <div class="highlight">
      <div class="mb-3">
        {{'LINK_TO_PLAY' | translate}}
        <code data-lang="html">
           {{' https://{host}/login?a={agent_code}'}}
          </code>
      </div>
      <div class="mb-3">
        {{'LINK_TO_APPLY_FROM_MAIN_PAGE' | translate}}
        <code data-lang="html">
       {{'https://{host}/register?a={agent_code}'}}
        </code>
      </div>
      <div class="mb-3">
        {{'LINK_TO_APPLY_FROM_LINE' | translate}}
        <code data-lang="html">
        {{'https://{host}/register?referer=LINE&agun={agent_code}'}}
        </code>
      </div>
      <div class="mb-3">
        {{'LOGIN_LINK_FOR_REDIRECT_TOKEN' | translate}}
        <code data-lang="html">
        {{'https://{host}/redirect/token?t={token}'}}
        </code>
      </div>
      <div class="mb-3">
        {{'LOGIN_LINK_FOR_REDIRECT_LOGIN' | translate}}
        <code data-lang="html">
        {{'https://{host}/redirect/pass-login?username={user}&password={pass}'}}
        </code>
      </div>
    </div>
  </div>
</div>

<div class="d-none">
  <audio *ngFor="let item of (currentSoundSetting | keyvalue)" [id]="$any(item.value).name">
    <source [src]="$any(item.value).path">
  </audio>
</div>
<ng-container>
  <app-chat-panel *ngIf="showChat"></app-chat-panel>
</ng-container>

<button type="button" class="btn d-none" data-bs-target="#memberCreditFormOffcanvas" data-bs-toggle="offcanvas"
  id="btn-open-member-credit-form">
  open memberCreditFormOffcanvas
</button>
<app-credit-form-offcanvas [id]="'memberCreditFormOffcanvas'" [isAdd]="memberCreditFormData.is_add"
  [memberId]="memberCreditFormData.member_id">
</app-credit-form-offcanvas>

<button type="button" class="btn d-none" data-bs-target="#mainClearTurnoverMemberOffCanvas" data-bs-toggle="offcanvas"
  id="btn-open-clear-turnover-member">
  open memberCreditFormOffcanvas
</button>
<app-clear-turn-over-member-offcanvas [id]="'mainClearTurnoverMemberOffCanvas'" [memberId]="memberClearTurn">
</app-clear-turn-over-member-offcanvas>
