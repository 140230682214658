<div class="offcanvas offcanvas-end" id="requestConfirmOffcanvas">
  <div class="offcanvas-header">
    <h4 class="mb-0" id="offcanvasRightLabel">{{'CHOOSE_TRANSFER_FORMAT'|translate}}</h4>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
  </div>
  <div class="offcanvas-body">
    <form [formGroup]="form" (submit)="onSubmit()">
      <label for="" class="form-label">{{'CHOOSE_TRANSFER_FORMAT'|translate}}</label>
      <div class="row">
        <div class="col-6 mb-3">
          <div class="form-check form-check-inline">
            <input id="transfer-auto" type="radio" class="form-check-input" name="transfer" [(ngModel)]="transferType"
              [ngModelOptions]="{standalone: true}" value="auto">
            <label for="transfer-auto" class="form-check-label">{{'AUTO_TRANSFER_SYSTEM'|translate}}</label>
          </div>
        </div>
        <div class="col-6 mb-3">
          <div class="form-check form-check-inline">
            <input id="transfer-manual" type="radio" class="form-check-input" name="transfer" [(ngModel)]="transferType"
              [ngModelOptions]="{standalone: true}" value="manual">
            <label for="transfer-manual" class="form-check-label">{{'MANUAL_TRANSFER'|translate}}</label>
          </div>
        </div>
      </div>

      <div class="" *ngIf="transferType === 'auto'">
        <label for="" class="form-label">{{'CONDITIONS_USING_AUTO_TRANSFER'|translate}}</label>
        <div class="alert alert-danger">
          <ul class="m-0">
            <li>
              {{'PLEASE_CHECK_EVERY_TRANSFER_WHETHER_THE_TRANSFER'|translate}} <strong>{{'SUCCESSFUL_OR_UNSUCCESSFUL'|translate}}</strong>
              {{'SOMETIMES_IN_THE_BANK'|translate}}
              <strong>{{'THERE_MIGHT_BE_ERROR'|translate}}</strong>
            </li>
            <li>{{'IF_THERE_IS_A_DUPLICATE_TRANSFER_WE_WILL_NOW_BE_RESPONSIBLE'|translate}} <strong>{{'SUCH_ERROR'|translate}}</strong></li>
          </ul>
        </div>

        <label for="" class="form-label">{{'PLEASE_SELECT_THE_ACCOUNT_USED_TO_TRANSFER_MONEY'|translate}}</label>
        <app-select-account-bank-form formControlName="account_bank_agent" AccountBankType="withdraw">
        </app-select-account-bank-form>
      </div>

      <div class="text-end">
        <button type="submit" class="btn btn-outline-primary" [appLoading]="saving" pRipple>
          {{'SAVE_DATE'|translate}}
        </button>
      </div>
    </form>
  </div>
</div>
