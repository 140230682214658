<div class="offcanvas offcanvas-end" tabindex="-1" id="resetPassowrdOffcanvas">
  <div class="offcanvas-header">
    <h4 class="offcanvas-title" id="offcanvasRightLabel">{{'RESET_PASSWORD'|translate}}</h4>
    <button id="reset-pass-close" type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
  </div>
  <div class="offcanvas-body">
    <form [formGroup]="form" (submit)="onSubmit()">
      <div class="mb-3">
        <label for="" class="form-label">{{'NEW_PASSWORD'|translate}}</label>
        <input type="text" class="form-control" formControlName="new_password">
      </div>
      <div class="mb-3">
        <label for="" class="form-label">{{'CONFIRM_PASSWORD'|translate}}</label>
        <input type="text" class="form-control" formControlName="confirm_password">
      </div>
      <div class="mb-3 text-end">
        <button type="submit" class="btn btn-primary btn-submit" [appLoading]="saving">
          {{'SAVE_DATA'|translate}}
        </button>
      </div>
    </form>
  </div>
</div>
