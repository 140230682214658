import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@ngneat/reactive-forms';
import { AgentForm } from 'src/app/utils/interfaces/create-agent-form.interface';

@Component({
  selector: 'app-setting-amb-esport',
  templateUrl: './setting-amb-esport.component.html',
  styleUrls: ['../../setting-agent.scss'],
})
export class SettingAmbEsportComponent implements OnInit {
  @Input() form: FormGroup<AgentForm>;

  constructor() { }

  ngOnInit(): void {
  }

}
