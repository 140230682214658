<div class="modal fade" id="chooseLanguageModal" tabindex="-1">
  <div class="modal-dialog modal-sm modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header border-bottom-0 pb-0  rounded-0">
        <h4 class="modal-title" id="exampleModalLabel">{{'CHOOSE_LANGUAGE' | translate}}</h4>
        <button type="button" class="btn-close d-none" data-bs-dismiss="modal" id="close-lang"></button>
      </div>
      <div class="modal-body rounded-0">
        <div class="d-flex justify-content-center">
          <ul class="list-unstyled language-group">
            <li class="language-item" *ngFor="let lang of languageItems">
              <button type="button" class="btn d-flex align-items-center w-100 mb-2"
                [class]="lang.value === currentLang?.value ? 'btn-primary': 'btn-outline-primary'"
                (click)="[svLanguage.setLang(lang.value), close()]">
                <img [src]="lang.image" alt="" width="25px" height="25px" class="me-2">
                {{lang.name}}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
