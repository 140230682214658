import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AMBOptions, AMBSettingGame, SettingGameMembersService } from 'src/app/apis/setting-game-members.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { randomTxt } from 'src/app/utils/functions/random-txt';

@Component({
  selector: 'app-setting-casino',
  templateUrl: './setting-casino.component.html',
  styleUrls: ['../setting-games.scss'],
})
export class SettingCasinoComponent extends AppCore implements OnInit, OnChanges {
  @Input() setting: AMBSettingGame;
  @Input() readOnly = false;

  takingOption: AMBOptions[] = [];
  commOption: AMBOptions[] = [];

  ptTakingOptions: AMBOptions[] = [];
  saTakingOptions: AMBOptions[] = [];
  sexyTakingOptions: AMBOptions[] = [];
  dgTakingOptions: AMBOptions[] = [];
  agTakingOptions: AMBOptions[] = [];
  abTakingOptions: AMBOptions[] = [];
  bgTakingOptions: AMBOptions[] = [];
  gdTakingOptions: AMBOptions[] = [];
  pmTakingOptions: AMBOptions[] = [];
  btTakingOptions: AMBOptions[] = [];
  ebTakingOptions: AMBOptions[] = [];

  inputId = `casino-${randomTxt(5)}`;
  constructor(
    private svSettingGameMember: SettingGameMembersService,
  ) {
    super();

    this.takingOption = this.svSettingGameMember.genOptions(0.5, 100);
    this.commOption = this.svSettingGameMember.genOptions(0.05, 1);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.setting) {
      let shareCasino = this.setting.group_init_member.maxSetting.shareSetting.casino;
      this.ptTakingOptions = this.svSettingGameMember.genOptions(0.5, shareCasino.maxPt, shareCasino.minPt);
      this.saTakingOptions = this.svSettingGameMember.genOptions(0.5, shareCasino.maxSa, shareCasino.minSa);
      this.sexyTakingOptions = this.svSettingGameMember.genOptions(0.5, shareCasino.maxSexy, shareCasino.minSexy);
      this.dgTakingOptions = this.svSettingGameMember.genOptions(0.5, shareCasino.maxDg, shareCasino.minDg);
      this.agTakingOptions = this.svSettingGameMember.genOptions(0.5, shareCasino.maxAG, shareCasino.minAG);
      this.abTakingOptions = this.svSettingGameMember.genOptions(0.5, shareCasino.maxAb, shareCasino.minAb);
      this.bgTakingOptions = this.svSettingGameMember.genOptions(0.5, shareCasino.maxBg, shareCasino.minBg);
      this.gdTakingOptions = this.svSettingGameMember.genOptions(0.5, shareCasino.maxGd, shareCasino.minGd);
      this.pmTakingOptions = this.svSettingGameMember.genOptions(0.5, shareCasino.maxPm, shareCasino.minPm);
      this.btTakingOptions = this.svSettingGameMember.genOptions(0.5, shareCasino.maxBt, shareCasino.minBt);
      this.ebTakingOptions = this.svSettingGameMember.genOptions(0.5, shareCasino.maxEb, shareCasino.minEb);
    }
  }

}
