import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { HttpApiService } from '../services/http-api.service';
import { PageModel } from '../utils/interfaces/page-model';
import { Pagination } from '../utils/interfaces/pagination';

@Injectable({
  providedIn: 'root',
})
export class GamesService {
  constructor(
    private http: HttpApiService,
    private ngFb: FormBuilder,
  ) { }

  getTypeGames() {
    return this.http.get<{ id: string; }[]>(`api/ask4bet/game/type`);
  }

  getGames(params) {
    return this.http.get<Pagination<Game[]>>(`api/ask4bet/game`, { params });
  }

  getGame(id) {
    return this.http.get<Game>(`api/ask4bet/game/${id}`);
  }

  getAllGame(type = '') {
    return this.http.get<Game[]>(`api/ask4bet/game/list?type=${type}`);
  }

  create(value) {
    return this.http.post(`api/ask4bet/game`, value);
  }

  edit(value, id) {
    return this.http.put(`api/ask4bet/game/${id}`, value);
  }

  delete(id) {
    return this.http.delete(`api/ask4bet/game/${id}`);
  }

  /**
   * Get Game Demo Pagination
   * @param params PageModel, ?status={status}
   * @returns Observeable
   */
  getDemoPagination(params: PageModel = {}) {
    return this.http.get<Pagination<GameDemo[]>>(`api/ask4bet/game_demo`, { params });
  }

  postDemo(model: GameDemoModel) {
    return this.http.post(`api/ask4bet/game_demo`, model);
  }

  putDemo(id: number, model: GameDemoModel) {
    return this.http.put(`api/ask4bet/game_demo/${id}`, model);
  }

  deleteDemo(id: number) {
    return this.http.delete(`api/ask4bet/game_demo/${id}`);
  }

  get createFormGameDemo(): FormGroup<GameDemoModel> {
    return this.ngFb.group({
      game_id: [null],
      game_demo_image: [''],
      game_demo_name: [''],
      game_demo_url: [''],
      status: ['active'],
    });
  }

  getAgentGames(agent_id: number) {
    return this.http.get(`api/ask4bet/agent_game?agent_id=${agent_id}`);
  }

  putReorder(agent_id: number, model: GameOrder[]) {
    return this.http.put(`api/ask4bet/agent_game/order?agent_id=${agent_id}`, model);
  }
}

export interface Game {
  id: number;
  game_code: string;
  game_name: string;
  game_image: string;
  game_image_preview: string;
  game_provider: string;
  game_url_type: string;
  game_url: string;
  game_type: GameTypes;
  status: string;
  create_dt: string;
  update_dt: string;
  update_by: number;
  game_order?: number;
  [key: string]: any;
}

export interface GameOrder {
  game_id: number;
  game_order: number;
}

export enum GameType {
  casino = 'คาสิโน',
  keno = 'Keno',
  lotto = 'หวย',
  poker = 'โป๊กเกอร์',
  slot = 'สล็อต',
  sport = 'กีฬา',
  trading = 'Trading',
}

export type GameTypes = keyof typeof GameType;

export interface GameDemoModel {
  game_id: number;
  game_demo_name: string;
  game_demo_url: string;
  game_demo_image: string;
  status: GameStatusTypes;
}

export type GameStatusTypes = 'active' | 'deative';

export interface GameDemo {
  id: number;
  game_id: number;
  game_provider: string;
  game_code: string;
  game_type: string;
  game_name: string;
  game_image: string;
  game_image_preview: string;
  game_demo_name: string;
  game_demo_url: string;
  game_demo_image: string;
  status: string;
  created_at: string;
  updated_at: string;
  updated_by: number;
}
