import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { HttpApiService } from '../services/http-api.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private http: HttpApiService,
    private ngFb: FormBuilder,
  ) { }

  //#region Setting Member
  getMember(agent_id: number) {
    return this.http.get<SettingMember>(`api/ask4bet/member/setting?agent_id=${agent_id}`);
  }
  putMember(agent_id: number, model: SettingMember) {
    return this.http.put(`api/ask4bet/member/setting/edit?agent_id=${agent_id}`, model);
  }
  get createFormMember(): FormGroup<SettingMember> {
    return this.ngFb.group({
      member_phone_number_duplicate: [false],
      member_bank_account_duplicate: [false],
      member_ipaddress_duplicate: [false],
      member_name_duplicate: [false],
      clear_turn_over: [false],
      clear_turn_over_min: [0, [Validators.required, Validators.min(0)]],
      turn_over: [0, [Validators.required, Validators.min(0)]],
      check_outstand: [false],
      use_sms: [false],
      member_change_password: [false],
    });
  }
  //#endregion

  //#region Setting Deposit
  getDeposit(agent_id: number) {
    if(!agent_id){
      return;
    }
    return this.http.get<SettingDeposit>(`api/ask4bet/deposit/setting?agent_id=${agent_id}`);
  }
  putDeposit(agent_id: number, model: SettingDeposit) {
    return this.http.put(`api/ask4bet/deposit/setting/edit?agent_id=${agent_id}`, model);
  }
  get createFormDeposit(): FormGroup<SettingDeposit> {
    return this.ngFb.group({
      deposit_on: [false],
      deposit_auto_on: [false],
      deposit_min: [0, [Validators.required, Validators.min(0)]],
      deposit_decimal: [false],
      deposit_was_play: [false],
      USD_to_THB: [null],
      EUR_to_THB: [null],
      LAK_to_THB: [null],
      MMK_to_THB: [null],
      KHR_to_THB: [null],
    });
  }
  //#endregion

  //#region Setting Withdraw
  getWithdraw(agent_id: number) {
    return this.http.get<SettingWithdraw>(`api/ask4bet/withdraw/setting?agent_id=${agent_id}`);
  }
  putWithdraw(agent_id: number, model: SettingWithdraw) {
    return this.http.put(`api/ask4bet/withdraw/setting/edit?agent_id=${agent_id}`, model);
  }
  get createFormWithdraw(): FormGroup<SettingWithdraw> {
    return this.ngFb.group({
      withdraw_on: [false],
      withdraw_auto_on: [false],
      withdraw_auto_min: [0, [Validators.required, Validators.min(0)]],
      withdraw_auto_max: [0, [Validators.required, Validators.min(0)]],
      withdraw_min: [0, [Validators.required, Validators.min(0)]],
      withdraw_decimal: [false],
      withdraw_all: [false],
      THB_to_USD: [null],
      THB_to_EUR: [null],
      THB_to_LAK: [null],
      THB_to_MMK: [null],
      THB_to_KHR: [null],
    });
  }
  //#endregion

  //#region Setting Loyalty
  getLoyalty(agent_id: number) {
    return this.http.get<SettingLoyalty>(`api/ask4bet/admin/config/setting?agent=${agent_id}`);
  }
  putLoyalty(agent_id: number, model: SettingLoyalty) {
    return this.http.put(`api/ask4bet/admin/config/setting/edit?agent=${agent_id}`, model);
  }
  get createFormLoyalty(): FormGroup<SettingLoyalty> {
    return this.ngFb.group({
      loyalty: this.ngFb.group({
        loyalty_system_on: [false],
      }),
      recommend: this.ngFb.group({
        recommend_system_on: [false],
        recommend_start_date: [''],
        recommend_withdraw: [false],
        recommend_withdraw_min: [0],
        recommend_type: [''],
      }),
      tournament: this.ngFb.group({
        tournament_system_on: [false],
      }),
      leaderboard: this.ngFb.group({
        leaderboard_system_on: [false],
      }),
      broadcat: this.ngFb.group({
        broadcat_sms_system_on: [false],
      }),
      ranking: this.ngFb.group({
        ranking_system_on: [false],
      }),
      coupon: this.ngFb.group({
        coupon_create_on: [false],
      }),
      vocher: this.ngFb.group({
        gift_voucher_on: [false],
      }),
      revert: this.ngFb.group({
        revert_credit_on: [false],
        revert_credit_auto: [false],
      }),
      agent_separate: this.ngFb.group({
        agent_separate: [false],
      })
    });
  }
  //#endregion

  //#region Setting All
  getConfigType() {
    return this.http.get<string[]>(`api/ask4bet/config/type`);
  }
  getSystem() {
    return this.http.get<SettingSystem[]>(`api/ask4bet/config`);
  }
  postSystem(model: SettingSystemModel) {
    return this.http.post(`api/ask4bet/config`, model);
  }
  putSystem(id: number, model: SettingSystemModel) {
    return this.http.put(`api/ask4bet/config/${id}`, model);
  }
  deleteSystem(id: number) {
    return this.http.delete(`api/ask4bet/config/${id}`);
  }
  get createFormSystem(): FormGroup<SettingSystemModel> {
    return this.ngFb.group({
      config_type: [null, [Validators.required, Validators.maxLength(100)]],
      url: ['', [Validators.required, Validators.maxLength(256)]],
      name: ['', [Validators.required, Validators.maxLength(100)]],
      type: [null, [Validators.required, Validators.maxLength(20)]],
      value: ['', [Validators.required, Validators.maxLength(1024)]],
    });
  }
  //#endregion

  getJobWLYesterday() {
    return this.http.get(`api/ask4bet/config/Job_Winlose_yesterday`);
  }
  putJobWLYesterday(model: JobWLYesterdayModel) {
    return this.http.put(`api/ask4bet/config/Job_Winlose_yesterday`, model);
  }
  createFormJobWLYesterday(): FormGroup<JobWLYesterdayModel> {
    return this.ngFb.group({
      Open_Job: [false],
      Job_running: [false],
      Start_date_get_winloseyesterday: [''],
      End_date_get_winloseyesterday: [''],
    });
  }
}

export interface SettingMember {
  member_phone_number_duplicate: boolean;
  member_bank_account_duplicate: boolean;
  member_ipaddress_duplicate: boolean;
  member_name_duplicate: boolean;
  clear_turn_over: boolean;
  clear_turn_over_min: number;
  turn_over: number;
  check_outstand: boolean;
  use_sms: boolean;
}

export interface SettingDeposit {
  deposit_on: boolean;
  deposit_auto_on: boolean;
  deposit_min: number;
  deposit_decimal: boolean;
  deposit_was_play: boolean;
  USD_to_THB: number | null;
  EUR_to_THB: number | null;
  LAK_to_THB: number | null;
  MMK_to_THB: number | null;
  KHR_to_THB: number | null;
}

export interface SettingWithdraw {
  withdraw_on: boolean;
  withdraw_auto_min: number;
  withdraw_auto_max: number;
  withdraw_auto_on: boolean;
  withdraw_min: number;
  withdraw_decimal: boolean;
  withdraw_all: boolean;
  THB_to_USD: number | null;
  THB_to_EUR: number | null;
  THB_to_LAK: number | null;
  THB_to_MMK: number | null;
  THB_to_KHR: number | null;
}

export interface SettingLoyalty {
  loyalty?: SettingLoyaltyLoyalty;
  recommend?: SettingLoyaltyRecommend;
  tournament?: SettingLoyaltyTournament;
  leaderboard?: SettingLoyaltyLeaderboard;
  broadcat?: SettingLoyaltyBroadcat;
  ranking?: SettingLoyaltyRanking;
  coupon?: SettingLoyaltyCoupon;
  vocher?: SettingLoyaltyVocher;
  revert?: SettingLoyaltyRevert;
}

export interface SettingLoyaltyLoyalty {
  loyalty_system_on?: boolean;
}

export interface SettingLoyaltyRecommend {
  recommend_system_on?: boolean;
  recommend_start_date?: string;
  recommend_withdraw?: boolean;
  recommend_withdraw_min?: number;
  recommend_type?: 'game' | 'deposit' | 'withdraw';
}

export interface SettingLoyaltyTournament {
  tournament_system_on?: boolean;
}

export interface SettingLoyaltyLeaderboard {
  leaderboard_system_on?: boolean;
}

export interface SettingLoyaltyBroadcat {
  broadcat_sms_system_on?: boolean;
}

export interface SettingLoyaltyRanking {
  ranking_system_on?: boolean;
}

export interface SettingLoyaltyCoupon {
  coupon_create_on?: boolean;
}

export interface SettingLoyaltyVocher {
  gift_voucher_on?: boolean;
}

export interface SettingLoyaltyRevert {
  revert_credit_on?: boolean;
  revert_credit_auto?: boolean;
}

export interface SettingSystemModel {
  config_type: string;
  name: string;
  type: string;
  value: string;
  url: string;
}

export interface SettingSystem {
  id: number;
  config_type: string;
  name: string;
  type: string;
  value: string;
  url: string;
  for_superadmin: boolean;
}

export enum SystemTypes {
  boolean = 'boolean',
  text = 'text',
  decimal = 'decimal',
  int = 'int',
};

export interface JobWLYesterdayModel {
  Job_running: boolean;
  Open_Job: boolean;
  Start_date_get_winloseyesterday: string;
  End_date_get_winloseyesterday: string;
}
