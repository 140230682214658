<div class="row">
  <div class="col-12 col-md-6 mb-3">
    <div class="card" [class.disabled]="!setting.group_init_member.maxSetting.shareSetting.casino.maxPt">
      <div class="card-header text-center">
        Pretty Gaming
        (Min {{setting.group_init_member.maxSetting.shareSetting.casino.minPt | number: '1.0-0'}}% - Max
        {{setting.group_init_member.maxSetting.shareSetting.casino.maxPt | number: '1.0-0'}}%)
      </div>
      <div class="card-body">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="text-end">{{'STATUS'|translate}} : </td>
              <td>
                <div class="form-check form-check-inline">
                  <input [id]="'on-pt'+inputId" type="radio" class="form-check-input" name="status-pt-{{inputId}}"
                    [value]="true" [disabled]="readOnly"
                    [(ngModel)]="setting.member_info.limitSetting.casino.pt.isEnable">
                  <label [for]="'on-pt'+inputId" class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input [id]="'off-pt'+inputId" type="radio" class="form-check-input" name="status-pt-{{inputId}}"
                    [value]="false" [disabled]="readOnly"
                    [(ngModel)]="setting.member_info.limitSetting.casino.pt.isEnable">
                  <label [for]="'off-pt'+inputId" class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="text-end">{{'LIMITED_PLAY'|translate}} :</td>
              <td>
                <div class="form-check">
                  <input id="limit-pt-1" type="radio" class="form-check-input" name="pt-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.pt.limit" [value]="1"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.pt.limit < 1 || readOnly">
                  <label for="limit-pt-1" class="form-check-label">
                    Limit A Min = 5 , Max = 2000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.pt.limit < 1 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-pt-2" type="radio" class="form-check-input" name="pt-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.pt.limit" [value]="2"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.pt.limit < 2 || readOnly">
                  <label for="limit-pt-2" class="form-check-label">
                    Limit B Min = 100 , Max = 4000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.pt.limit < 2 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-pt-3" type="radio" class="form-check-input" name="pt-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.pt.limit" [value]="3"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.pt.limit < 3 || readOnly">
                  <label for="limit-pt-3" class="form-check-label">
                    Limit C Min = 1000 , Max = 40000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.pt.limit < 3 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-pt-4" type="radio" class="form-check-input" name="pt-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.pt.limit" [value]="4"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.pt.limit < 4 || readOnly">
                  <label for="limit-pt-4" class="form-check-label">
                    Limit D Min = 3000 , Max = 120000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.pt.limit < 4 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-pt-5" type="radio" class="form-check-input" name="pt-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.pt.limit" [value]="5"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.pt.limit < 5 || readOnly">
                  <label for="limit-pt-5" class="form-check-label">
                    Limit E Min = 5000 , Max = 200000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.pt.limit < 5 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <div class="text-danger">
          * {{'THE_COMMISSION_APPLIES_TO_NORMAL_BACCARAT_TYPE_PLAY_ONLY'|translate}}
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 mb-3">
    <div class="card" [class.disabled]="!setting.group_init_member.maxSetting.shareSetting.casino.maxSa">
      <div class="card-header text-center">
        SA Gaming
        (Min {{setting.group_init_member.maxSetting.shareSetting.casino.minSa | number: '1.0-0'}}% - Max
        {{setting.group_init_member.maxSetting.shareSetting.casino.maxSa | number: '1.0-0'}}%)
      </div>
      <div class="card-body">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="text-end">{{'STATUS'|translate}} : </td>
              <td>
                <div class="form-check form-check-inline">
                  <input [id]="'on-sa'+inputId" type="radio" class="form-check-input" name="status-sa-{{inputId}}"
                    [value]="true" [disabled]="readOnly"
                    [(ngModel)]="setting.member_info.limitSetting.casino.sa.isEnable">
                  <label [for]="'on-sa'+inputId" class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input [id]="'off-sa'+inputId" type="radio" class="form-check-input" name="status-sa-{{inputId}}"
                    [value]="false" [disabled]="readOnly"
                    [(ngModel)]="setting.member_info.limitSetting.casino.sa.isEnable">
                  <label [for]="'off-sa'+inputId" class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="text-end">{{'LIMITED_PLAY'|translate}} :</td>
              <td>
                <div class="form-check">
                  <input id="limit-sa-1" type="radio" class="form-check-input" name="sa-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.sa.limit" [value]="1"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.sa.limit < 1 || readOnly">
                  <label for="limit-sa-1" class="form-check-label">
                    Limit A Min = 50 , Max = 5000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.sa.limit < 1 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-sa-2" type="radio" class="form-check-input" name="sa-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.sa.limit" [value]="2"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.sa.limit < 2 || readOnly">
                  <label for="limit-sa-2" class="form-check-label">
                    Limit B Min = 100 , Max = 10000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.sa.limit < 2 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-sa-3" type="radio" class="form-check-input" name="sa-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.sa.limit" [value]="3"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.sa.limit < 3 || readOnly">
                  <label for="limit-sa-3" class="form-check-label">
                    Limit C Min = 200 , Max = 50000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.sa.limit < 3 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-sa-4" type="radio" class="form-check-input" name="sa-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.sa.limit" [value]="4"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.sa.limit < 4 || readOnly">
                  <label for="limit-sa-4" class="form-check-label">
                    Limit D Min = 1000 , Max = 100000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.sa.limit < 4 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-sa-5" type="radio" class="form-check-input" name="sa-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.sa.limit" [value]="5"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.sa.limit < 5 || readOnly">
                  <label for="limit-sa-5" class="form-check-label">
                    Limit E Min = 10000 , Max = 200000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.sa.limit < 5 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 mb-3">
    <div class="card" [class.disabled]="!setting.group_init_member.maxSetting.shareSetting.casino.maxSexy">
      <div class="card-header text-center">
        Sexy Baccarat
        (Min {{setting.group_init_member.maxSetting.shareSetting.casino.minSexy | number: '1.0-0'}}% - Max
        {{setting.group_init_member.maxSetting.shareSetting.casino.maxSexy | number: '1.0-0'}}%)
      </div>
      <div class="card-body">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="text-end">{{'STATUS'|translate}} : </td>
              <td>
                <div class="form-check form-check-inline">
                  <input [id]="'on-sex+inputIdy'" type="radio" class="form-check-input" name="status-sexy-{{inputId}}"
                    [value]="true" [disabled]="readOnly"
                    [(ngModel)]="setting.member_info.limitSetting.casino.sexy.isEnable">
                  <label [for]="'on-sex+inputIdy'" class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input [id]="'off-sex+inputIdy'" type="radio" class="form-check-input" name="status-sexy-{{inputId}}"
                    [value]="false" [disabled]="readOnly"
                    [(ngModel)]="setting.member_info.limitSetting.casino.sexy.isEnable">
                  <label [for]="'off-sex+inputIdy'" class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="text-end">{{'LIMITED_PLAY'|translate}} :</td>
              <td>
                <div class="form-check">
                  <input id="limit-sexy-1" type="radio" class="form-check-input" name="sexy-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.sexy.limit" [value]="1"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.sexy.limit < 1 || readOnly">
                  <label for="limit-sexy-1" class="form-check-label">
                    Limit A Min = 10 , Max = 2000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.sexy.limit < 1 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-sexy-2" type="radio" class="form-check-input" name="sexy-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.sexy.limit" [value]="2"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.sexy.limit < 2 || readOnly">
                  <label for="limit-sexy-2" class="form-check-label">
                    Limit B Min = 50 , Max = 10000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.sexy.limit < 2 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-sexy-3" type="radio" class="form-check-input" name="sexy-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.sexy.limit" [value]="3"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.sexy.limit < 3 || readOnly">
                  <label for="limit-sexy-3" class="form-check-label">
                    Limit C Min = 200 , Max = 50000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.sexy.limit < 3 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-sexy-4" type="radio" class="form-check-input" name="sexy-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.sexy.limit" [value]="4"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.sexy.limit < 4 || readOnly">
                  <label for="limit-sexy-4" class="form-check-label">
                    Limit D Min = 500 , Max = 100000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.sexy.limit < 4 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-sexy-5" type="radio" class="form-check-input" name="sexy-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.sexy.limit" [value]="5"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.sexy.limit < 5 || readOnly">
                  <label for="limit-sexy-5" class="form-check-label">
                    Limit E Min = 500 , Max = 200000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.sexy.limit < 5 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 mb-3">
    <div class="card" [class.disabled]="!setting.group_init_member.maxSetting.shareSetting.casino.maxDg">
      <div class="card-header text-center">
        Dream Gaming
        (Min {{setting.group_init_member.maxSetting.shareSetting.casino.minDg | number: '1.0-0'}}% - Max
        {{setting.group_init_member.maxSetting.shareSetting.casino.maxDg | number: '1.0-0'}}%)
      </div>
      <div class="card-body">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="text-end">{{'STATUS'|translate}} : </td>
              <td>
                <div class="form-check form-check-inline">
                  <input [id]="'on-dg'+inputId" type="radio" class="form-check-input" name="status-dg-{{inputId}}"
                    [value]="true" [disabled]="readOnly"
                    [(ngModel)]="setting.member_info.limitSetting.casino.dg.isEnable">
                  <label [for]="'on-dg'+inputId" class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input [id]="'off-dg'+inputId" type="radio" class="form-check-input" name="status-dg-{{inputId}}"
                    [value]="false" [disabled]="readOnly"
                    [(ngModel)]="setting.member_info.limitSetting.casino.dg.isEnable">
                  <label [for]="'off-dg'+inputId" class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="text-end">{{'LIMITED_PLAY'|translate}} :</td>
              <td>
                <div class="form-check">
                  <input id="limit-dg-1" type="radio" class="form-check-input" name="dg-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.dg.limit" [value]="1"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.dg.limit < 1 || readOnly">
                  <label for="limit-dg-1" class="form-check-label">
                    Limit A Min = 50 , Max = 5000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.dg.limit < 1 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-dg-2" type="radio" class="form-check-input" name="dg-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.dg.limit" [value]="2"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.dg.limit < 2 || readOnly">
                  <label for="limit-dg-2" class="form-check-label">
                    Limit B Min = 100 , Max = 10000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.dg.limit < 2 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-dg-3" type="radio" class="form-check-input" name="dg-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.dg.limit" [value]="3"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.dg.limit < 3 || readOnly">
                  <label for="limit-dg-3" class="form-check-label">
                    Limit C Min = 100 , Max = 50000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.dg.limit < 3 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-dg-4" type="radio" class="form-check-input" name="dg-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.dg.limit" [value]="4"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.dg.limit < 4 || readOnly">
                  <label for="limit-dg-4" class="form-check-label">
                    Limit D Min = 100 , Max = 100000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.dg.limit < 4 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-dg-5" type="radio" class="form-check-input" name="dg-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.dg.limit" [value]="5"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.dg.limit < 5 || readOnly">
                  <label for="limit-dg-5" class="form-check-label">
                    Limit E Min = 100 , Max = 250000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.dg.limit < 5 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 mb-3">
    <div class="card" [class.disabled]="!setting.group_init_member.maxSetting.shareSetting.casino.maxAG">
      <div class="card-header text-center">
        AG Gaming
        (Min {{setting.group_init_member.maxSetting.shareSetting.casino.minAG | number: '1.0-0'}}% - Max
        {{setting.group_init_member.maxSetting.shareSetting.casino.maxAG | number: '1.0-0'}}%)
      </div>
      <div class="card-body">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="text-end">{{'STATUS'|translate}} : </td>
              <td>
                <div class="form-check form-check-inline">
                  <input [id]="'on-ag'+inputId" type="radio" class="form-check-input" name="status-ag-{{inputId}}"
                    [value]="true" [disabled]="readOnly"
                    [(ngModel)]="setting.member_info.limitSetting.casino.ag.isEnable">
                  <label [for]="'on-ag'+inputId" class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input [id]="'off-ag'+inputId" type="radio" class="form-check-input" name="status-ag-{{inputId}}"
                    [value]="false" [disabled]="readOnly"
                    [(ngModel)]="setting.member_info.limitSetting.casino.ag.isEnable">
                  <label [for]="'off-ag'+inputId" class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="text-end">{{'LIMITED_PLAY'|translate}} :</td>
              <td>
                <div class="form-check">
                  <input id="limit-ag-1" type="radio" class="form-check-input" name="ag-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.ag.limit" [value]="1"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.ag.limit < 1 || readOnly">
                  <label for="limit-ag-1" class="form-check-label">
                    Limit A Min = 40 , Max = 5000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.ag.limit < 1 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-ag-2" type="radio" class="form-check-input" name="ag-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.ag.limit" [value]="2"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.ag.limit < 2 || readOnly">
                  <label for="limit-ag-2" class="form-check-label">
                    Limit B Min = 40 , Max = 25000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.ag.limit < 2 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-ag-3" type="radio" class="form-check-input" name="ag-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.ag.limit" [value]="3"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.ag.limit < 3 || readOnly">
                  <label for="limit-ag-3" class="form-check-label">
                    Limit C Min = 1000 , Max = 100000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.ag.limit < 3 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-ag-4" type="radio" class="form-check-input" name="ag-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.ag.limit" [value]="4"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.ag.limit < 4 || readOnly">
                  <label for="limit-ag-4" class="form-check-label">
                    Limit D Min = 1500 , Max = 150000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.ag.limit < 4 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-ag-5" type="radio" class="form-check-input" name="ag-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.ag.limit" [value]="5"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.ag.limit < 5 || readOnly">
                  <label for="limit-ag-5" class="form-check-label">
                    Limit E Min = 2500 , Max = 250000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.ag.limit < 5 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 mb-3">
    <div class="card disabled" [class.disabled]="!setting.group_init_member.maxSetting.shareSetting.casino.maxAb">
      <div class="card-header text-center">
        AllBET
        (Min {{setting.group_init_member.maxSetting.shareSetting.casino.minAb | number: '1.0-0'}}% - Max
        {{setting.group_init_member.maxSetting.shareSetting.casino.maxAb | number: '1.0-0'}}%)
      </div>
      <div class="card-body">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="text-end">{{'STATUS'|translate}} : </td>
              <td>
                <div class="form-check form-check-inline">
                  <input [id]="'on-ab'+inputId" type="radio" class="form-check-input" name="status-ab-{{inputId}}"
                    [value]="true" [disabled]="readOnly"
                    [(ngModel)]="setting.member_info.limitSetting.casino.ab.isEnable">
                  <label [for]="'on-ab'+inputId" class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input [id]="'off-ab'+inputId" type="radio" class="form-check-input" name="status-ab-{{inputId}}"
                    [value]="false" [disabled]="readOnly"
                    [(ngModel)]="setting.member_info.limitSetting.casino.ab.isEnable">
                  <label [for]="'off-ab'+inputId" class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="text-end">{{'LIMITED_PLAY'|translate}} :</td>
              <td>
                <div class="form-check">
                  <input id="limit-ab-1" type="radio" class="form-check-input" name="ab-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.ab.limit" [value]="1"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.ab.limit < 1 || readOnly">
                  <label for="limit-ab-1" class="form-check-label">
                    Limit A Min = 40 , Max = 5000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.ab.limit < 1 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-ab-2" type="radio" class="form-check-input" name="ab-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.ab.limit" [value]="2"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.ab.limit < 2 || readOnly">
                  <label for="limit-ab-2" class="form-check-label">
                    Limit B Min = 40 , Max = 25000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.ab.limit < 2 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-ab-3" type="radio" class="form-check-input" name="ab-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.ab.limit" [value]="3"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.ab.limit < 3 || readOnly">
                  <label for="limit-ab-3" class="form-check-label">
                    Limit C Min = 1000 , Max = 100000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.ab.limit < 3 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 mb-3">
    <div class="card" [class.disabled]="!setting.group_init_member.maxSetting.shareSetting.casino.maxBg">
      <div class="card-header text-center">
        Big Gaming
        (Min {{setting.group_init_member.maxSetting.shareSetting.casino.minBg | number: '1.0-0'}}% - Max
        {{setting.group_init_member.maxSetting.shareSetting.casino.maxBg | number: '1.0-0'}}%)
      </div>
      <div class="card-body">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="text-end">{{'STATUS'|translate}} : </td>
              <td>
                <div class="form-check form-check-inline">
                  <input [id]="'on-bg'+inputId" type="radio" class="form-check-input" name="status-bg-{{inputId}}"
                    [value]="true" [disabled]="readOnly"
                    [(ngModel)]="setting.member_info.limitSetting.casino.bg.isEnable">
                  <label [for]="'on-bg'+inputId" class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input [id]="'off-bg'+inputId" type="radio" class="form-check-input" name="status-bg-{{inputId}}"
                    [value]="false" [disabled]="readOnly"
                    [(ngModel)]="setting.member_info.limitSetting.casino.bg.isEnable">
                  <label [for]="'off-bg'+inputId" class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="text-end">{{'LIMITED_PLAY'|translate}} :</td>
              <td>
                <div class="form-check">
                  <input id="limit-bg-1" type="radio" class="form-check-input" name="bg-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.bg.limit" [value]="1"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.bg.limit < 1 || readOnly">
                  <label for="limit-bg-1" class="form-check-label">
                    Limit A Min = 20 , Max = 5000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.bg.limit < 1 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-bg-2" type="radio" class="form-check-input" name="bg-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.bg.limit" [value]="2"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.bg.limit < 2 || readOnly">
                  <label for="limit-bg-2" class="form-check-label">
                    Limit B Min = 100 , Max = 20000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.bg.limit < 2 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-bg-3" type="radio" class="form-check-input" name="bg-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.bg.limit" [value]="3"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.bg.limit < 3 || readOnly">
                  <label for="limit-bg-3" class="form-check-label">
                    Limit C Min = 100, Max = 200000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.bg.limit < 3 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 mb-3">
    <div class="card" [class.disabled]="!setting.group_init_member.maxSetting.shareSetting.casino.maxGd">
      <div class="card-header text-center">
        Green Dragon
        (Min {{setting.group_init_member.maxSetting.shareSetting.casino.minGd | number: '1.0-0'}}% - Max
        {{setting.group_init_member.maxSetting.shareSetting.casino.maxGd | number: '1.0-0'}}%)
      </div>
      <div class="card-body">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="text-end">{{'STATUS'|translate}} : </td>
              <td>
                <div class="form-check form-check-inline">
                  <input [id]="'on-gd'+inputId" type="radio" class="form-check-input" name="status-dg-{{inputId}}"
                    [value]="true" [disabled]="readOnly"
                    [(ngModel)]="setting.member_info.limitSetting.casino.gd.isEnable">
                  <label [for]="'on-gd'+inputId" class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input [id]="'off-gd'+inputId" type="radio" class="form-check-input" name="status-dg-{{inputId}}"
                    [value]="false" [disabled]="readOnly"
                    [(ngModel)]="setting.member_info.limitSetting.casino.gd.isEnable">
                  <label [for]="'off-gd'+inputId" class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="text-end">{{'LIMITED_PLAY'|translate}} :</td>
              <td>
                <div class="form-check">
                  <input id="limit-gd-1" type="radio" class="form-check-input" name="gd-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.gd.limit" [value]="1"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.gd.limit < 1 || readOnly">
                  <label for="limit-gd-1" class="form-check-label">
                    Limit A Min = 100 , Max = 4000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.gd.limit < 1 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-gd-2" type="radio" class="form-check-input" name="gd-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.gd.limit" [value]="2"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.gd.limit < 2 || readOnly">
                  <label for="limit-gd-2" class="form-check-label">
                    Limit B Min = 500 , Max = 20000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.gd.limit < 2 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-gd-3" type="radio" class="form-check-input" name="gd-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.gd.limit" [value]="3"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.gd.limit < 3 || readOnly">
                  <label for="limit-gd-3" class="form-check-label">
                    Limit C Min = 2500 , Max = 100000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.gd.limit < 3 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 mb-3">
    <div class="card" [class.disabled]="!setting.group_init_member.maxSetting.shareSetting.casino.maxPm">
      <div class="card-header text-center">
        Pragmatic Play
        (Min {{setting.group_init_member.maxSetting.shareSetting.casino.minPm | number: '1.0-0'}}% - Max
        {{setting.group_init_member.maxSetting.shareSetting.casino.maxPm | number: '1.0-0'}}%)
      </div>
      <div class="card-body">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="text-end">{{'STATUS'|translate}} : </td>
              <td>
                <div class="form-check form-check-inline">
                  <input [id]="'on-pm'+inputId" type="radio" class="form-check-input" name="status-pm-{{inputId}}"
                    [value]="true" [disabled]="readOnly"
                    [(ngModel)]="setting.member_info.limitSetting.casino.pm.isEnable">
                  <label [for]="'on-pm'+inputId" class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input [id]="'off-pm'+inputId" type="radio" class="form-check-input" name="status-pm-{{inputId}}"
                    [value]="false" [disabled]="readOnly"
                    [(ngModel)]="setting.member_info.limitSetting.casino.pm.isEnable">
                  <label [for]="'off-pm'+inputId" class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="text-end">{{'LIMITED_PLAY'|translate}} :</td>
              <td>
                <div class="form-check">
                  <input id="limit-pm-1" type="radio" class="form-check-input" name="pm-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.pm.limit" [value]="1"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.pm.limit < 1 || readOnly">
                  <label for="limit-pm-1" class="form-check-label">
                    Limit A Min = 2 , Max = 100000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.pm.limit < 1 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 mb-3">
    <div class="card" [class.disabled]="!setting.group_init_member.maxSetting.shareSetting.casino.maxBt">
      <div class="card-header text-center">
        BetGame.Tv
        (Min {{setting.group_init_member.maxSetting.shareSetting.casino.minBt | number: '1.0-0'}}% - Max
        {{setting.group_init_member.maxSetting.shareSetting.casino.maxBt | number: '1.0-0'}}%)
      </div>
      <div class="card-body">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="text-end">{{'STATUS'|translate}} : </td>
              <td>
                <div class="form-check form-check-inline">
                  <input [id]="'on-bt'+inputId" type="radio" class="form-check-input" name="status-bt-{{inputId}}"
                    [value]="true" [disabled]="readOnly"
                    [(ngModel)]="setting.member_info.limitSetting.casino.bt.isEnable">
                  <label [for]="'on-bt'+inputId" class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input [id]="'off-bt'+inputId" type="radio" class="form-check-input" name="status-bt-{{inputId}}"
                    [value]="false" [disabled]="readOnly"
                    [(ngModel)]="setting.member_info.limitSetting.casino.bt.isEnable">
                  <label [for]="'off-bt'+inputId" class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="text-end">{{'LIMITED_PLAY'|translate}} :</td>
              <td>
                <div class="form-check">
                  <input id="limit-bt-1" type="radio" class="form-check-input" name="bt-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.bt.limit" [value]="1"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.bt.limit < 1 || readOnly">
                  <label for="limit-pm-1" class="form-check-label">
                    Limit A Min = 50 , Max = 200,000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.bt.limit < 1 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 mb-3">
    <div class="card" [class.disabled]="!setting.group_init_member.maxSetting.shareSetting.casino.maxEb">
      <div class="card-header text-center">
        EBET
        (Min {{setting.group_init_member.maxSetting.shareSetting.casino.minEb | number: '1.0-0'}}% - Max
        {{setting.group_init_member.maxSetting.shareSetting.casino.maxEb | number: '1.0-0'}}%)
      </div>
      <div class="card-body">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="text-end">{{'STATUS'|translate}} : </td>
              <td>
                <div class="form-check form-check-inline">
                  <input [id]="'on-eb'+inputId" type="radio" class="form-check-input" name="status-eb-{{inputId}}"
                    [value]="true" [disabled]="readOnly"
                    [(ngModel)]="setting.member_info.limitSetting.casino.eb.isEnable">
                  <label [for]="'on-eb'+inputId" class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input [id]="'off-eb'+inputId" type="radio" class="form-check-input" name="status-eb-{{inputId}}"
                    [value]="false" [disabled]="readOnly"
                    [(ngModel)]="setting.member_info.limitSetting.casino.eb.isEnable">
                  <label [for]="'off-eb'+inputId" class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="text-end">{{'LIMITED_PLAY'|translate}} :</td>
              <td>
                <div class="form-check">
                  <input id="limit-eb-1" type="radio" class="form-check-input" name="eb-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.eb.limit" [value]="1"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.eb.limit < 1 || readOnly">
                  <label for="limit-eb-1" class="form-check-label">
                    Limit A Min = 5 , Max = 5000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.eb.limit < 1 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-eb-2" type="radio" class="form-check-input" name="eb-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.eb.limit" [value]="2"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.eb.limit < 2 || readOnly">
                  <label for="limit-eb-2" class="form-check-label">
                    Limit B Min = 10 , Max = 10000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.eb.limit < 2 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-eb-3" type="radio" class="form-check-input" name="eb-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.eb.limit" [value]="3"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.eb.limit < 3 || readOnly">
                  <label for="limit-eb-3" class="form-check-label">
                    Limit C Min = 50 , Max = 25000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.eb.limit < 3 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-eb-4" type="radio" class="form-check-input" name="eb-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.eb.limit" [value]="4"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.eb.limit < 4 || readOnly">
                  <label for="limit-eb-4" class="form-check-label">
                    Limit D Min = 100 , Max = 50000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.eb.limit < 4 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-eb-5" type="radio" class="form-check-input" name="eb-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.eb.limit" [value]="5"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.eb.limit < 5 || readOnly">
                  <label for="limit-eb-5" class="form-check-label">
                    Limit E Min = 1000 , Max = 100000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.eb.limit < 5 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 mb-3">
    <div class="card disabled">
      <div class="card-header text-center">
        Xtream
        (Min {{0}}% - Max {{0}}%)
      </div>
      <div class="card-body">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="text-end">{{'STATUS'|translate}} : </td>
              <td>
                <div class="form-check form-check-inline">
                  <input [id]="'on-gc'+inputId" type="radio" class="form-check-input" name="status-gc-{{inputId}}"
                    [value]="true" [disabled]="readOnly">
                  <label [for]="'on-gc'+inputId" class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input [id]="'off-gc'+inputId" type="radio" class="form-check-input" name="status-gc-{{inputId}}"
                    [value]="false" [disabled]="readOnly">
                  <label [for]="'off-gc'+inputId" class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="text-end">{{'LIMITED_PLAY'|translate}} :</td>
              <td>
                <div class="form-check">
                  <input id="limit-gc-1" type="radio" class="form-check-input" name="gc-{{inputId}}" [value]="1"
                    [disabled]="readOnly" checked>
                  <label for="limit-gc-1" class="form-check-label">
                    Limit A Min = 20 , Max = 1000 <i class="fas text-success fa-check"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-gc-2" type="radio" class="form-check-input" name="gc-{{inputId}}" [value]="1"
                    [disabled]="readOnly">
                  <label for="limit-gc-2" class="form-check-label">
                    Limit B Min = 50 , Max = 10000 <i class="fas text-danger fa-times"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-gc-3" type="radio" class="form-check-input" name="gc-{{inputId}}" [value]="1"
                    [disabled]="readOnly">
                  <label for="limit-gc-3" class="form-check-label">
                    Limit C Min = 100 , Max = 30000 <i class="fas text-danger fa-times"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-gc-4" type="radio" class="form-check-input" name="gc-{{inputId}}" [value]="1"
                    [disabled]="readOnly">
                  <label for="limit-gc-4" class="form-check-label">
                    Limit D Min = 200 , Max = 100000 <i class="fas text-danger fa-times"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-gc-5" type="radio" class="form-check-input" name="gc-{{inputId}}" [value]="1"
                    [disabled]="readOnly">
                  <label for="limit-gc-5" class="form-check-label">
                    Limit E Min = 500 , Max = 200000 <i class="fas text-danger fa-times"></i>
                  </label>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 mb-3">
    <!-- [class.disabled]="!setting.group_init_member.maxSetting.shareSetting.casino.maxMc" -->
    <div class="card disabled" disabled>
      <div class="card-header text-center">
        Micro Casino
        (Min {{setting.group_init_member.maxSetting.shareSetting.casino.minMc}}%
        - Max {{setting.group_init_member.maxSetting.shareSetting.casino.maxMc}}%)
      </div>
      <div class="card-body">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="text-end">{{'STATUS'|translate}} : </td>
              <td>
                <div class="form-check form-check-inline">
                  <input [id]="'on-mc'+inputId" type="radio" class="form-check-input" name="status-mc-{{inputId}}"
                    [value]="true" [disabled]="readOnly">
                  <label [for]="'on-mc'+inputId" class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input [id]="'off-mc'+inputId" type="radio" class="form-check-input" name="status-mc-{{inputId}}"
                    [value]="false" [disabled]="readOnly" checked>
                  <label [for]="'off-mc'+inputId" class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="text-end">{{'LIMITED_PLAY'|translate}}จำกัดการเล่น :</td>
              <td>
                <div class="form-check">
                  <input id="limit-mc-1" type="radio" class="form-check-input" name="mc-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.mc.limit" [value]="1"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.mc?.limit < 1 || readOnly">
                  <label for="limit-mc-1" class="form-check-label">
                    Limit A Min = 5 , Max = 5000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.mc?.limit < 1 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-mc-2" type="radio" class="form-check-input" name="mc-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.mc.limit" [value]="2"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.mc?.limit < 2 || readOnly">
                  <label for="limit-mc-2" class="form-check-label">
                    Limit B Min = 10 , Max = 10000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.mc?.limit < 2 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-mc-3" type="radio" class="form-check-input" name="mc-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.mc.limit" [value]="3"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.mc?.limit < 3 || readOnly">
                  <label for="limit-mc-3" class="form-check-label">
                    Limit C Min = 50 , Max = 25000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.mc?.limit < 3 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-mc-4" type="radio" class="form-check-input" name="mc-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.mc.limit" [value]="4"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.mc?.limit < 4 || readOnly">
                  <label for="limit-mc-4" class="form-check-label">
                    Limit D Min = 100 , Max = 50000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.mc?.limit < 4 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-mc-5" type="radio" class="form-check-input" name="mc-{{inputId}}"
                    [(ngModel)]="setting.member_info.limitSetting.casino.mc.limit" [value]="5"
                    [disabled]="setting.group_init_member.maxSetting.limitSetting.casino.mc?.limit < 5 || readOnly">
                  <label for="limit-mc-5" class="form-check-label">
                    Limit E Min = 1000 , Max = 100000
                    <i class="fas"
                      [class]="setting.group_init_member.maxSetting.limitSetting.casino.mc?.limit < 5 ? 'text-danger fa-times': 'text-success fa-check'"></i>
                  </label>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 mb-3">
    <div class="card disabled">
      <div class="card-header text-center">
        Cherry Gaming
        (Min {{0}}% - Max {{0}}%)
      </div>
      <div class="card-body">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="text-end">{{'STATUS'|translate}} : </td>
              <td>
                <div class="form-check form-check-inline">
                  <input [id]="'on-av'+inputId" type="radio" class="form-check-input" name="status-av-{{inputId}}"
                    [value]="true" [disabled]="readOnly">
                  <label [for]="'on-av'+inputId" class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input [id]="'off-av'+inputId" type="radio" class="form-check-input" name="status-av-{{inputId}}"
                    [value]="false" [disabled]="readOnly">
                  <label [for]="'off-av'+inputId" class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="text-end">{{'LIMITED_PLAY'|translate}} :</td>
              <td>
                <div class="form-check">
                  <input id="limit-av-1" type="radio" class="form-check-input" name="av-{{inputId}}" [value]="1"
                    [disabled]="readOnly">
                  <label for="limit-av-1" class="form-check-label">
                    Limit A Min = 5 , Max = 5000 <i class="fas text-danger fa-times"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-av-2" type="radio" class="form-check-input" name="av-{{inputId}}" [value]="1"
                    [disabled]="readOnly">
                  <label for="limit-av-2" class="form-check-label">
                    Limit B Min = 10 , Max = 10000 <i class="fas text-danger fa-times"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-av-3" type="radio" class="form-check-input" name="av-{{inputId}}" [value]="1"
                    [disabled]="readOnly">
                  <label for="limit-av-3" class="form-check-label">
                    Limit C Min = 50 , Max = 25000 <i class="fas text-danger fa-times"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-av-4" type="radio" class="form-check-input" name="av-{{inputId}}" [value]="1"
                    [disabled]="readOnly">
                  <label for="limit-av-4" class="form-check-label">
                    Limit D Min = 100 , Max = 50000 <i class="fas text-danger fa-times"></i>
                  </label>
                </div>
                <div class="form-check">
                  <input id="limit-av-5" type="radio" class="form-check-input" name="av-{{inputId}}" [value]="1"
                    [disabled]="readOnly">
                  <label for="limit-av-5" class="form-check-label">
                    Limit E Min = 1000 , Max = 100000 <i class="fas text-danger fa-times"></i>
                  </label>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</div>
