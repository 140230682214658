import { Component, OnInit } from '@angular/core';
import { UserService, UserZean } from 'src/app/apis/user.service';
import { TokenUser } from 'src/app/services/authentication.service';
import { LocalName, LocalStorageService } from 'src/app/services/local-storage.service';
import { AppCore } from 'src/app/utils/classes/app-core';

@Component({
  selector: 'app-sage-content',
  templateUrl: './sage-content.component.html',
  styleUrls: ['./sage-content.component.scss']
})
export class SageContentComponent extends AppCore implements OnInit {
  user: TokenUser;

  isRealZean = false;
  sageId: any = '';

  sageItems: UserZean[] = [];
  constructor(
    private svUser: UserService,
  ) {
    super();

    this.user = this.svAuth.getUser;
    if (this.user.Role.some(r => r.toLowerCase() === 'zean')) {
      this.isRealZean = true;
      this.sageId = this.user.Id;
    } else {
      this.isRealZean = false;
    }
    this.svUser.sageId$.next(this.sageId);
  }

  ngOnInit(): void {
  }

  currentAgentChanged() {
    if (this.isRealZean) {
      return;
    }
    if (!this.currentAgent?.id) {
      return;
    }

    this.getUserSages();
  }

  getUserSages() {
    this.svUser.getSageUsers({ agent_id: this.currentAgent.id }).subscribe(res => {
      this.sageItems = res;

      if (!this.sageId && this.sageItems?.length) {
        this.sageId = this.sageItems[0].id;
        this.svUser.sageId$.next(this.sageId);
      }
    });
  }

  selectSageChange() {
    this.svUser.sageId$.next(this.sageId);
  }

}
