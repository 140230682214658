import { KeyValue } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AMBGameTypes, AMBSettingGame, SettingGameMembersService, AMBOptions } from 'src/app/apis/setting-game-members.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { randomTxt } from 'src/app/utils/functions/random-txt';

@Component({
  selector: 'app-setting-sport2',
  templateUrl: './setting-sport2.component.html',
  styleUrls: ['../setting-games.scss'],
})
export class SettingSport2Component extends AppCore implements OnInit, OnChanges {
  @Input() setting: AMBSettingGame;
  @Input() gameType: AMBGameTypes;
  @Input() readOnly = false;

  shareParlayOptions: AMBOptions[] = [];
  shareStepOptions: AMBOptions[] = [];

  maxBillOptions: AMBOptions[] = [];
  minBillOptions: AMBOptions[] = [];

  parlayCommissionOptions: AMBOptions[] = [];
  stepCommissionOptions = {
    com2: [] as AMBOptions[],
    com3: [] as AMBOptions[],
    com4: [] as AMBOptions[],
    com5: [] as AMBOptions[],
    com6: [] as AMBOptions[],
    com7: [] as AMBOptions[],
    com8: [] as AMBOptions[],
    com9: [] as AMBOptions[],
    com10: [] as AMBOptions[],
    com11: [] as AMBOptions[],
    com12: [] as AMBOptions[],
  };

  inputId = `sport2-${randomTxt(5)}`;
  constructor(
    public svSettingGameMembers: SettingGameMembersService,
  ) {
    super();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.setting) {
      const parlay = this.setting.group_init_member.maxSetting.shareSetting.step;
      this.shareParlayOptions = this.svSettingGameMembers.genOptions(0.5, parlay.maxParlay, parlay.minParlay);
      this.shareStepOptions = this.svSettingGameMembers.genOptions(0.5, parlay.maxStep, parlay.minStep);

      this.maxBillOptions = this.svSettingGameMembers.genOptions(1, 12, 2, false);
      this.minBillOptions = this.svSettingGameMembers.genOptions(1, 12, 2, false);
      this.parlayCommissionOptions = this.svSettingGameMembers.genOptions(1, 5);

      this.stepCommissionOptions.com2 = this.svSettingGameMembers.genOptions(1, 5);
      this.stepCommissionOptions.com3 = this.svSettingGameMembers.genOptions(1, 10);
      this.stepCommissionOptions.com4 = this.svSettingGameMembers.genOptions(1, 13);
      this.stepCommissionOptions.com5 = this.svSettingGameMembers.genOptions(1, 15);
      this.stepCommissionOptions.com6 = this.svSettingGameMembers.genOptions(1, 15);
      this.stepCommissionOptions.com7 = this.svSettingGameMembers.genOptions(1, 15);
      this.stepCommissionOptions.com8 = this.svSettingGameMembers.genOptions(1, 17);
      this.stepCommissionOptions.com9 = this.svSettingGameMembers.genOptions(1, 17);
      this.stepCommissionOptions.com10 = this.svSettingGameMembers.genOptions(1, 17);
      this.stepCommissionOptions.com11 = this.svSettingGameMembers.genOptions(1, 20);
      this.stepCommissionOptions.com12 = this.svSettingGameMembers.genOptions(1, 20);
    }
  }

  // Preserve original property order
  originalOrder(a: any, b: any): number {
    return 0;
  }

}
