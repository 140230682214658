<form [formGroup]="form">
  <div class="text-center">
    <!-- {{form.value|json}} -->
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" id="File-{{inputId}}" value="File" formControlName="file_type">
      <label class="form-check-label" for="File-{{inputId}}">File</label>
    </div>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" id="URL-{{inputId}}" value="URL" formControlName="file_type">
      <label class="form-check-label" for="URL-{{inputId}}">URL</label>
    </div>
  </div>
</form>

<div class="text-center p-3">
  <div class="p-2">
    <label for="file-img-{{inputId}}">
      <img *ngIf="!image" class="img-promotion mb-2 border" width="80%" [src]="(image || placeholder) | imgHost">
      <img *ngIf="!!image" class="img-promotion mb-2 border bg-img-transparent" width="80%" [src]="image || placeholder | imgHost">
    </label>
  </div>

  <ng-container *ngIf="form.value.file_type === 'File'">
    <label for="file-img-{{inputId}}" class="btn btn-outline-primary">
      <i class="bi bi-upload"></i> {{'CHOOSE_PICTURE'|translate}}
    </label>
    <!-- #inputImageFile -->
    <input type="file" class="d-none" id="file-img-{{inputId}}" accept=".jpg,.png,.jpeg"
      (change)="inputFileChange($event)">
  </ng-container>
  <ng-container *ngIf="form.value.file_type === 'URL'">
    <div class="mb-3 text-start">
      <label for="Image" class="form-label">URL Image</label>
      <input type="text" class="form-control" id="Image" [(ngModel)]="image" (keyup)="inputURLChange()">
    </div>
  </ng-container>

</div>