<ng-select [items]="member$ | async" bindValue="id" bindLabel="username" [clearable]="false"
  [placeholder]="'CHOOSE_MEMBER'|translate" [(ngModel)]="memberId" [loading]="loading" [trackByFn]="trackByFn"
  [typeahead]="memberInput$" (change)="memberChange()" [typeToSearchText]="'TYPE_TO_SEARCH_USERNAME'|translate"
  #ngSelectMember>
  <ng-template ng-option-tmp let-item="item">
    <div class="">
      <div class=""> <strong>{{'USERNAME'|translate}} :</strong> {{item.username || '-'}}</div>
      <div class=""> <strong>{{'FULLNAME'|translate}} :</strong> {{item.fullname || '-'}}</div>
      <div class=""> <strong>{{'PHONE_NUMBER'|translate}} :</strong> {{item.phone || '-'}}</div>
      <div class="" *ngIf="showCredit">
        <strong>{{'CREDIT'|translate}} :</strong> {{(item?.wallet_total || 0) | number: '0.2-2'}}
      </div>
      <div class="" *ngIf="showPoint">
        <strong>{{'POINT'|translate}} :</strong> {{(item?.loyalty?.point_current || 0) | number: '0.2-2'}}
      </div>
    </div>
  </ng-template>
</ng-select>
<div class="mt-2" *ngIf="showDetail">
  <div class="bg-secondary p-2">
    <label for="" class="form-label strong">{{'MEMBER_DATA'|translate}}</label>
    <div class="">
      <div class=""> <strong>{{'USERNAME'|translate}} :</strong> {{selectMember?.username || '-'}}</div>
      <div class=""> <strong>{{'FULLNAME'|translate}} :</strong> {{selectMember?.fullname || '-'}}</div>
      <div class=""> <strong>{{'PHONE_NUMBER'|translate}} :</strong> {{selectMember?.phone || '-'}}</div>
      <div class="" *ngIf="showCredit">
        <strong>{{'CREDIT'|translate}} :</strong> {{(selectMember?.wallet_total || 0) | number: '0.2-2'}}
      </div>
      <div class="" *ngIf="showPoint">
        <strong>{{'POINT'|translate}} :</strong> {{(selectMember?.loyalty?.point_current || 0) | number: '0.2-2'}}
      </div>
    </div>
  </div>
</div>
