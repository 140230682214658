<div class="offcanvas offcanvas-end" id="promotionOffcanvas" style="width: 80em;">
  <div class="offcanvas-header">
    <h4 *ngIf="!isView && !promotion" class="mb-0" id="offcanvasRightLabel">
      {{'CREATE'|translate}}{{namePage|translate}}
    </h4>
    <h4 *ngIf="!isView && promotion" class="mb-0" id="offcanvasRightLabel">
      {{'EDIT'|translate}}{{namePage|translate}}
    </h4>
    <h4 *ngIf="isView" class="mb-0" id="offcanvasRightLabel">
      {{'DETAIL'|translate}}{{namePage|translate}}
    </h4>
    <button type="button" id="promotion-close" class="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
  </div>
  <div class="offcanvas-body">
    <form [formGroup]="form" (submit)="onSubmit()">
      <div class="">
        <div class="row">
          <div class="col-12 col-lg-4">
            <div class="">
              <label for="" class="form-label">{{'IMAGE'|translate}}</label>
              <div class="text-center p-3">
                <label for="file-img">
                  <ng-container *ngIf="!form.get('imgLocal').value">
                    <img *ngIf="!form.get('image').value" class="img-promotion mb-3" src="/assets/placeholder.jpg"
                      alt="">
                    <img *ngIf="form.get('image').value" class="img-promotion mb-3"
                      [src]="imgHost + form.get('image').value" alt="">
                  </ng-container>
                  <ng-container *ngIf="form.get('imgLocal').value">
                    <img *ngIf="form.get('image').value" class="img-promotion mb-3" [src]="form.get('image').value"
                      alt="">
                  </ng-container>
                </label>
                <label *ngIf="!isView" for="file-img" class="btn btn-outline-primary"> <i class="bi bi-upload"></i>
                  {{'CHOOSE_PICTURE'|translate}} </label>
                <input *ngIf="!isView" type="file" class="d-none" id="file-img" (change)="inputFileChange($event)">
              </div>
            </div>
            <div class="mb-3">
              <label for="" class="form-label">{{'NAME'|translate}}</label>
              <input type="text" class="form-control" formControlName="name">
            </div>
            <div class="mb-3" *ngIf="isCoupon">
              <label for="" class="form-label">{{'CODE'|translate}}</label>
              <input type="text" class="form-control" formControlName="code_pro">
            </div>
            <div class="mb-3">
              <label for="" class="form-label">{{'DETAIL'|translate}}</label>
              <textarea rows="3" class="form-control" formControlName="detail"></textarea>
            </div>
            <div class="mb-3">
              <label for="" class="form-label">{{'EXPIRATION_DATE'|translate}}</label>
              <input type="date" class="form-control" formControlName="end_dt">
            </div>
          </div>

          <div class="col-12 col-lg-8">
            <div class="row">
              <div class="col-12 col-lg-6 mb-2">
                <div class="form-check form-check-inline">
                  <input type="checkbox" class="form-check-input" id="pro1" formControlName="stack_other_pro">
                  <label for="pro1"
                    class="form-check-label">{{'CAN_BE_USED_IN_CONJUNCTION_WITH_OTHER_PROMOTIONS'|translate}}</label>
                </div>
              </div>
              <div class="col-12 col-lg-6 mb-2">
                <div class="form-check form-check-inline">
                  <input type="checkbox" class="form-check-input" id="pro2" formControlName="again">
                  <label for="pro2"
                    class="form-check-label">{{'THIS_PROMOTION_CAN_BE_UESD_MULTIPLE_TIMES'|translate}}</label>
                </div>
              </div>
              <div class="col-12 col-lg-6 mb-2">
                <div class="form-check form-check-inline">
                  <input type="checkbox" class="form-check-input" id="pro3"
                    formControlName="reduce_credit_when_withdrawing">
                  <label for="pro3" class="form-check-label">{{'REMOVE_BONUS_UPON_WITHDRAWAL'|translate}}</label>
                  <div class="small opacity-75" *ngIf="!form.get('reduce_credit_when_withdrawing').value">
                    {{'MEMBERS_WILL_BE_ABLE_TO_WITHDRAW_BONUS_CREDITS'|translate}} </div>
                  <div class="small opacity-75" *ngIf="form.get('reduce_credit_when_withdrawing').value">
                    {{'MEMBERS_WILL_NOT_BE_ABLE_TO_WITHDRAW_BONUS_CREDITS'|translate}} </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 mb-2">
                <div class="form-check form-check-inline">
                  <input type="checkbox" class="form-check-input" id="pro4" formControlName="show_from_member">
                  <label for="pro4" class="form-check-label">{{'SHOW_THIS_PROMOTION_ON_PLAY_PAGE'|translate}}</label>
                </div>
              </div>
              <div class="col-12 col-lg-6 mb-2">
                <div class="form-check form-check-inline">
                  <input type="checkbox" class="form-check-input" id="receive_now" formControlName="receive_now">
                  <label for="receive_now" class="form-check-label">{{'GET_CREDIT_INSTANTLY'|translate}}</label>
                </div>
              </div>
            </div>
            <hr>

            <div class="row">
              <div class="col-12 col-lg-6">
                <div class="mb-3" *ngIf="!form.get('receive_now').value">
                  <label for="" class="me-3">{{'UNIT'|translate}}</label>
                  <div class="form-check form-check-inline">
                    <input type="radio" class="form-check-input" id="percent" formControlName="type" value="percent">
                    <label for="percent" class="form-check-label"> {{'PERCENT'|translate}} </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input type="radio" class="form-check-input" id="credit" formControlName="type" value="credit">
                    <label for="credit" class="form-check-label"> {{'CREDIT'|translate}} </label>
                  </div>
                </div>
                <div class="mb-3">
                  <label for="" class="form-label">{{'BONUS_AMOUNT'|translate}}
                    <span *ngIf="form.get('type').value === 'credit'">({{'CREDIT'|translate}})</span>
                    <span *ngIf="form.get('type').value !== 'credit'">({{'PERCENT'|translate}})</span>
                    <!-- ({{form.get('type').value === 'credit' ? 'เครดิต': 'เปอร์เซนต์'}}) -->
                  </label>
                  <input type="number" class="form-control" formControlName="amount">
                </div>
                <div class="mb-3">
                  <label for="" class="form-label">{{'MAXIMUM_BONUS_AMOUNT_PER_PERSON_IN_1_DAY'|translate}}</label>
                  <input type="number" class="form-control" formControlName="max_bonus_amount_per_date">
                  <small
                    class="text-secondary">{{'0_MEANS_NO_MAXIMUM_BONUS_AMOUNT_PER_PERSON_IN_1_DAY'|translate}}</small>
                </div>
                <div class="mb-3">
                  <label for="max_use_per_date"
                    class="form-label">{{'LIMIT_MAXIMUM_NUMBER_OF_TIMES_USED_PER_PERSON_IN_1_DAY'|translate}}</label>
                  <input id="max_use_per_date" type="number" class="form-control" formControlName="max_use_per_date">
                  <small
                    class="text-secondary">{{'0_MEANS_THERE_IS_NO_LIMIT_TO_MAXIMUM_NUMBER_OF_TIMES_USED_PER_PERSON_IN_1_DAY'|translate}}</small>
                </div>
                <div class="mb-3">
                  <label for=""
                    class="form-label">{{'LIMITED_USE_OF_ALL_PROMOTIONS'|translate}}({{'TIMES'|translate}})</label>
                  <input type="number" class="form-control" formControlName="limit_amount_use_pro">
                  <small class="text-secondary">{{'0_MEANS_UNLIMITED_USE_OF_THIS_PROMOTION'|translate}}</small>
                </div>

              </div>
              <div class="col-12 col-lg-6">
                <div class="mb-3" *ngIf="form.get('type').value === 'percent'">
                  <label for=""
                    class="form-label">{{'MAXIMUM_RECEIVED_BONUS'|translate}}({{'CREDIT'|translate}})</label>
                  <input type="number" class="form-control" formControlName="max_bonus_amount">
                  <small class="text-secondary">{{'0_MEANS_UNLIMITED_MAXIMUM_BONUS_AMOUNT'|translate}}</small>
                </div>
                <div class="mb-3" *ngIf="!form.get('receive_now').value">
                  <label for="" class="form-label">{{'MINIMUM_DEPOSIT'|translate}}({{'CREDIT'|translate}})</label>
                  <input type="number" class="form-control" formControlName="min_deposit_amount">
                  <small class="text-secondary">{{'0_MEANS_NO_MINIMUM_DEPOSIT_LIMIT'|translate}}</small>
                </div>
                <div class="mb-3" *ngIf="!form.get('receive_now').value">
                  <label for="" class="form-label">{{'MAXIMUM_DEPOSIT'|translate}}({{'CREDIT'|translate}})</label>
                  <input type="number" class="form-control" formControlName="max_deposit_amount">
                  <small class="text-secondary">{{'0_MEANS_NO_MAXIMUM_DEPOSIT_LIMIT'|translate}}</small>
                </div>
                <div class="mb-3">
                  <label for=""
                    class="form-label">{{'AMOUNT_THAT_CAN_BE_WITHDRAWN'|translate}}({{'CREDIT'|translate}})</label>
                  <input type="number" class="form-control" formControlName="amount_can_be_withdraw">
                  <small
                    class="text-secondary">{{'0_MEANS_THERE_IS_NO_LIMIT_TO_AMOUNT_THAT_CAN_BE_WITHDRAWN'|translate}}</small>
                </div>
                <div class="mb-3">
                  <label for="" class="form-label">{{'AMOUNT_TO_WIN'|translate}}({{'CREDIT'|translate}})</label>
                  <input type="number" class="form-control" formControlName="winlose_amount">
                  <small
                    class="text-secondary">{{'0_MEANS_THERE_IS_NO_LIMIT_TO_AMOUNT_OF_WINNING_THAT_MUST_BE_MADE'|translate}}</small>
                </div>
                <div class="mb-3">
                  <label for="" class="form-label">{{'GET_TO_KNOW_THIS_WEB'|translate}}</label>
                  <select class="form-select" formControlName="get_to_know_id">
                    <option value="">{{'DO_NOT_HAVE'|translate}}</option>
                    <option *ngFor="let item of toKnowItems" [value]="item.id">{{item.know_by}}</option>
                  </select>
                </div>
              </div>
            </div>

            <hr>
            <div class="mb-3">
              <label for="" class="form-label"> {{'TURN_OVER_AMOUNT'|translate}} </label>
              <input type="number" class="form-control" formControlName="turn_over">
              <small class="text-secondary">{{'0_MEANS_NO_TURN_OVER_AMOUNT'|translate}}</small>
            </div>
            <div class="mb-3">
              <label for="" class="form-label">
                {{'CHOOSE_TYPE_THAT_YOU_WANT_TO_HAVE_A_TURN_OVER'|translate}}
                ({{'IT_WILL_ONLY_EFFECTIVE_WHEN_TURN_OVER_AMOUNT_IS_GREATER_THAN_0'|translate}})
              </label>
              <div class="row">
                <div class="col-6 col-lg-3 mb-2">
                  <div class="form-check form-check-inline">
                    <input type="checkbox" class="form-check-input" id="game-all" formControlName="turn_All"
                      (change)="checkAllChange()">
                    <label for="game-all" class="form-check-label">{{'ALL'|translate}}</label>
                  </div>
                </div>
                <div class="col-6 col-lg-3 mb-2">
                  <div class="form-check form-check-inline">
                    <input type="checkbox" class="form-check-input" id="turn_over_sport"
                      formControlName="turn_over_sport" (change)="checkGameChange()">
                    <label for="turn_over_sport" class="form-check-label">{{'SPORT'|translate}}</label>
                  </div>
                </div>
                <div class="col-6 col-lg-3 mb-2">
                  <div class="form-check form-check-inline">
                    <input type="checkbox" class="form-check-input" id="turn_over_casino"
                      formControlName="turn_over_casino" (change)="checkGameChange()">
                    <label for="turn_over_casino" class="form-check-label">{{'SS_CASINO'|translate}}</label>
                  </div>
                </div>
                <div class="col-6 col-lg-3 mb-2">
                  <div class="form-check form-check-inline">
                    <input type="checkbox" class="form-check-input" id="turn_over_poker"
                      formControlName="turn_over_poker" (change)="checkGameChange()">
                    <label for="turn_over_poker" class="form-check-label">{{'POKER'|translate}}</label>
                  </div>
                </div>
                <div class="col-6 col-lg-3 mb-2">
                  <div class="form-check form-check-inline">
                    <input type="checkbox" class="form-check-input" id="turn_over_slot" formControlName="turn_over_slot"
                      (change)="checkGameChange()">
                    <label for="turn_over_slot" class="form-check-label">{{'SS_GAME'|translate}}</label>
                  </div>
                </div>
                <div class="col-6 col-lg-3 mb-2">
                  <div class="form-check form-check-inline">
                    <input type="checkbox" class="form-check-input" id="turn_over_keno" formControlName="turn_over_keno"
                      (change)="checkGameChange()">
                    <label for="turn_over_keno" class="form-check-label">Keno</label>
                  </div>
                </div>
                <div class="col-6 col-lg-3 mb-2">
                  <div class="form-check form-check-inline">
                    <input type="checkbox" class="form-check-input" id="turn_over_lotto"
                      formControlName="turn_over_lotto" (change)="checkGameChange()">
                    <label for="turn_over_lotto" class="form-check-label">{{'SS_LOTTO'|translate}}</label>
                  </div>
                </div>
                <div class="col-6 col-lg-3 mb-2">
                  <div class="form-check form-check-inline">
                    <input type="checkbox" class="form-check-input" id="turn_over_trading"
                      formControlName="turn_over_trading" (change)="checkGameChange()">
                    <label for="turn_over_trading" class="form-check-label">Trading</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="" *ngIf="!isView">
        <hr>
        <div class="text-end">
          <button *ngIf="promotion" type="submit" class="btn btn-primary btn-submit" [appLoading]="loading">
            {{'EDIT'|translate}}{{namePage|translate}}
          </button>
          <button *ngIf="!promotion" type="submit" class="btn btn-primary btn-submit" [appLoading]="loading">
            {{'CREATE'|translate}}{{namePage|translate}}
          </button>
        </div>
      </div>
    </form>

  </div>
</div>
