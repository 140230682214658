import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { Modal } from 'bootstrap';
import { MemberCreditService, Withdraw } from 'src/app/apis/member-credit.service';
import { alertText, alertWarning } from 'src/app/utils/functions/alert';

@Component({
  selector: 'app-withdraw-change-status-modal',
  templateUrl: './withdraw-change-status-modal.component.html',
  styleUrls: ['./withdraw-change-status-modal.component.scss']
})
export class WithdrawChangeStatusModalComponent implements OnInit, OnChanges {
  form = this.createForm();

  @Input() modal: Modal;
  @Input() status: string;
  @Input() item: Withdraw;
  constructor(
    private svMemberCredit: MemberCreditService,
    private ngFb: FormBuilder,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.item && this.status) {
      this.form = this.createForm();
      this.form.patchValue({ amount: this.item.amount });
    }
  }

  createForm(): FormGroup<any> {
    return this.ngFb.group({
      amount: [0, [Validators.required, Validators.min(0)]],
    });
  }

  closeModal() {
    this.modal.hide();
  }

  onSubmit() {
    this.form.markAllAsDirty();
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      alertWarning({ message: alertText.formInvalid });
      return;
    }
  }
}
