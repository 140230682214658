<div class="offcanvas offcanvas-end" tabindex="-1" id="rewardWheelOffcanvas" style="width: 40em;">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" *ngIf="!item"> <i class="bi bi-plus-square"></i> {{'CREATE_REWARD_WHEEL'|translate}} </h5>
    <h5 class="offcanvas-title" *ngIf="item"> <i class="bi bi-pencil-square"></i> {{'EDIT_REWARD_WHEEL'|translate}} </h5>
    <button id="btn-close" type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
  </div>
  <div class="offcanvas-body">
    <form [formGroup]="form" (submit)="onSubmit()">
      <div class="form-check form-switch mb-3">
        <input id="form_is_enabled" type="checkbox" class="form-check-input" formControlName="is_enabled">
        <label for="form_is_enabled" class="form-check-label">
          <span *ngIf="form.value.is_enabled">{{'ACTIVE'|translate}}</span>
          <span *ngIf="!form.value.is_enabled">{{'DISABLED'|translate}}</span>
        </label>
      </div>
      <div class="mb-3">
        <label for="" class="form-label">{{'REWARD_WHEEL_NAME'|translate}}</label>
        <input type="text" class="form-control" formControlName="name">
      </div>
      <div class="mb-3">
        <label for="" class="form-label">{{'POINT'|translate}}</label>
        <input type="number" class="form-control" formControlName="point">
      </div>
      <div class="mb-3">
        <label for="" class="form-label">{{'REWARD_WHEEL'|translate}}</label>
        <table class="table table-hover">
          <thead>
            <tr>
              <th>{{'TYPE_REWARD'|translate}}</th>
              <th>{{'REWARD_AMOUNT'|translate}}</th>
              <th>% {{'PROBABILITY'|translate}}</th>
              <th>{{'AMOUNT_OF_PIECES_IN_WHEEL'|translate}}</th>
              <th>
                <i class="bi bi-gear-fill"></i>
              </th>
            </tr>
          </thead>
          <tbody formArrayName="rewards">
            <ng-container *ngFor="let rewardGroup of getFormArray(form, 'rewards').controls;index as i">
              <tr [formGroupName]="i">
                <td>
                  <select class="form-select" formControlName="reward_type">
                    <option value="">{{'SELECT_REWARD_TYPE'|translate}}</option>
                    <option value="credit">💰 {{'CREDIT'|translate}}</option>
                    <option value="point">💎 {{'DIAMOND'|translate}}</option>
                    <option value="free-spin">🎊 FREE SPIN</option>
                    <option value="black-hole">☢️ {{'BLACK_HOLE'|translate}}</option>
                  </select>
                </td>
                <td>
                  <ng-container *ngIf="rewardGroup.value.reward_type !== 'black-hole'">
                    <input type="number" formControlName="reward_amount" class="form-control" id="reward-{{i}}"
                      placeholder="0">
                  </ng-container>
                  <ng-container *ngIf="rewardGroup.value.reward_type === 'black-hole'">
                    {{'UNDEFINED'|translate}}
                  </ng-container>
                </td>
                <td>
                  <input type="number" formControlName="reward_percent" max="100" step="0.1" class="form-control"
                    id="persent-{{i}}" placeholder="0.0">
                </td>
                <td>
                  <select class="form-select" formControlName="reward_in_wheel">
                    <option value="0">{{'SELECT_AMOUNT_PIECES'|translate}}</option>
                    <option *ngFor="let data of [].constructor(10);index as iiw" [value]="iiw+1">{{ iiw +1}} {{'PIECE'|translate}}
                    </option>
                  </select>
                </td>
                <td>
                  <a class="link-danger" [appTooltip]="'DELETE'|translate" (click)="removeFormRewardAt(i)">
                    <i class="bi bi-trash"></i>
                  </a>
                </td>
              </tr>
            </ng-container>
            <tr>
              <td class="bg-primary">
                {{'SUM'|translate}}
              </td>
              <td>
                {{form.value.totalAmount || 0}} {{'REWARD'|translate}}
              </td>
              <td>
                <ng-container *ngIf="form.value.totalPercent < 100">
                  {{form.value.totalPercent}}% <i class="bi bi-exclamation-triangle text-warning"></i>
                </ng-container>
                <ng-container *ngIf="form.value.totalPercent === 100">
                  {{form.value.totalPercent}}% <i class="bi bi-check2-circle text-success"></i>
                </ng-container>
                <ng-container *ngIf="form.value.totalPercent > 100">
                  {{form.value.totalPercent}}% <i class="bi bi-exclamation-triangle text-danger"></i>
                </ng-container>
              </td>
              <td>
                {{form.value.totalInWheel || 0}} {{'PIECE'|translate}}
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="5">
                <button type="button" class="btn btn-outline-primary" (click)="addFormRewardToForm()">
                  <i class="bi bi-plus-circle-dotted"></i> {{'ADD_REWARD_WHEEL'|translate}}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="mb-3">
        <div class="alert bg-primary p-1 mb-2">
          <p class="m-0 "><i class="bi bi-info-circle"></i> {{'THINGS_TO_KNOW_ABOUT_EACH_TYPE_OF_AWARD'|translate}}</p>
          <ul class="m-0 fs-6">
            <li><span class="text-decoration-underline">💰 {{'CREDIT'|translate}}</span> : {{'CUSTOMERS_WHO_GET_SPIN_GET_FREE_CREDITS'|translate}}</li>
            <li><span class="text-decoration-underline">💎 {{'DIAMOND'|translate}}</span> : {{'CUSTOMERS_WHO_CAN_SPIN'|translate}}
              {{'WILL_RECEIVE_SUCH_DIAMONDS_THAT_WILL_BE_COMBINED_WITH_EXISTING_DIAMONDS'|translate}}</li>
            <li><span class="text-decoration-underline">🎊 FREE SPIN</span> : {{'CUSTOMERS_WHO_GET_SPIN_GET_1_FREE_SPIN'|translate}}</li>
            <li><span class="text-decoration-underline">☢️ {{'BLACK_HOLE'|translate}}</span> : {{'CUSTOMERS_WHO_GET_SPIN_WILL_NOT_RECEIVE_ANY_REWARDS'|translate}} </li>
          </ul>
        </div>
      </div>
      <div class="text-end mb-3">
        <div class="text-danger">% {{'TOTAL_PROBABILITY_MUST_BE_EQUAL_TO_100_ONLY'|translate}}</div>
      </div>
      <hr>
      <div class="row">
        <div class="col-auto mb-3" *ngIf="item">
          <button type="button" class="btn btn-outline-danger" (click)="onDelete()" [appLoading]="saving">
            <i class="bi bi-trash"></i> {{'DELETE_REWARD_WHEEL'|translate}}
          </button>
        </div>
        <div class="col-auto ms-auto mb-3">
          <button type="submit" class="btn btn-primary btn-submit" [appLoading]="saving"> {{'SAVE_DATA'|translate}} </button>
        </div>
      </div>
    </form>
  </div>
</div>
