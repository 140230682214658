<div class="row">
  <div class="col-12 col-md-6">
    <div class="card" [ngClass]="{'disabled': max === 0}">
      <div class="card-header text-center">
        {{nameGames[ambGame]}}
        (Min {{min | number: '1.0-0'}}% - Max {{max | number: '1.0-0'}}%)
      </div>
      <div class="card-body">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="text-end">สถานะ :</td>
              <td>
                <ng-container *ngIf="ambGame === 'KENO'">
                  <div class="form-check form-check-inline">
                    <input id="status-check-on-{{inputId}}" type="radio" class="form-check-input" [value]="true"
                      name="tg-{{inputId}}" [(ngModel)]="setting.group_init_member.updateLineStatus.otherTgEnable"
                      [disabled]="readOnly">
                    <label for="status-check-on-{{inputId}}" class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input id="status-check-off-{{inputId}}" type="radio" class="form-check-input" [value]="false"
                      name="tg-{{inputId}}" [(ngModel)]="setting.group_init_member.updateLineStatus.otherTgEnable"
                      [disabled]="readOnly">
                    <label for="status-check-off-{{inputId}}" class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
                  </div>
                </ng-container>
                <ng-container *ngIf="ambGame === 'TRADE'">
                  <div class="form-check form-check-inline">
                    <input id="status-check-on-{{inputId}}" type="radio" class="form-check-input" [value]="true"
                      name="hg-{{inputId}}" [(ngModel)]="setting.group_init_member.updateLineStatus.otherHgEnable"
                      [disabled]="readOnly">
                    <label for="status-check-on-{{inputId}}" class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input id="status-check-off-{{inputId}}" type="radio" class="form-check-input" [value]="false"
                      name="hg-{{inputId}}" [(ngModel)]="setting.group_init_member.updateLineStatus.otherHgEnable"
                      [disabled]="readOnly">
                    <label for="status-check-off-{{inputId}}" class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
                  </div>
                </ng-container>
                <ng-container *ngIf="ambGame === 'E-SPORT'">
                  <div class="form-check form-check-inline">
                    <input id="status-check-on-{{inputId}}" type="radio" class="form-check-input" [value]="true"
                      name="es-{{inputId}}" [(ngModel)]="setting.group_init_member.updateLineStatus.otherEsEnable"
                      [disabled]="readOnly">
                    <label for="status-check-on-{{inputId}}" class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input id="status-check-off-{{inputId}}" type="radio" class="form-check-input" [value]="false"
                      name="es-{{inputId}}" [(ngModel)]="setting.group_init_member.updateLineStatus.otherEsEnable"
                      [disabled]="readOnly">
                    <label for="status-check-off-{{inputId}}" class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
                  </div>
                </ng-container>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
