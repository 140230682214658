import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { ControlValueAccessor } from '@ngneat/reactive-forms';
import { fileToBase64, validateFiles } from 'src/app/utils/functions/base64';

@Component({
  selector: 'app-input-img-file',
  templateUrl: './input-img-file.component.html',
  styleUrls: ['./input-img-file.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputImgFileComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: InputImgFileComponent
    }
  ]
})
export class InputImgFileComponent implements OnInit, OnChanges, ControlValueAccessor, AfterViewInit {
  @Input('placeholder') placeholder: string;

  image = '';

  touched = false;

  disabled = false;

  loadingFileTypeChange = false;

  Url = '';

  inputId = 0;
  form = new FormGroup({
    file_type: new FormControl('File'),
  });
  constructor(
    private cdRef: ChangeDetectorRef,
  ) {
    this.inputId = Math.round(Math.random() * 1000);
  }

  onChange = (image) => { };

  onTouched = () => { };

  writeValue(value: string): void {
    this.image = value;
  }
  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }
  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  ngOnInit() {
  }
  ngAfterViewInit() {
    // this.InputImgFile.nativeElement.onchange = (res) => {
    //   this.inputFileChange(res);
    // };
  }

  ngOnChanges() {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const image = control.value;
    if (image <= 0) {
      return {
        mustBePositive: {
          image
        }
      };
    }
  }

  inputURLChange() {
    this.image = this.image;
    this.onChange(this.image);
  }

  async inputFileChange(event: Event) {
    const inputEl = event.target as HTMLInputElement;
    const files = inputEl.files;

    const fileValid = validateFiles(files);
    if (!fileValid) { return; }

    const base64 = await fileToBase64(files[0]);
    this.image = base64;

    this.onChange(base64);
    this.cdRef.detectChanges();
  }
}
