<ng-container *ngIf="onOverlay">
  <div class="btn-floating-chat shadow-sm" (click)="openChat = !openChat" *ngIf="onOverlay">
    <div class="d-flex">
      <div class="inside w-75 text-center border-right">
        <i class="fa-regular fa-comment-dots"></i> Chat
      </div>
      <div class="inside scale- w-25 text-center">
        <i class="fa-solid fa-up-right-from-square"></i>
      </div>
    </div>
    <div class="bagde-chat" *ngIf="badge">
      <span class="badge rounded-pill bg-danger">
        {{badge || 0}}
      </span>
    </div>
  </div>
  <div class="panel-chat shadow-sm" *ngIf="openChat">
    <div class="recon-load" *ngIf="svChatRoom.LoadingConnecting">
      <div class="d-flex align-items-center justify-content-center w-100 h-100">
        <div class="text-center">
          <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          Please wait Reconnecting Chat...
        </div>
      </div>
    </div>
    <div class="panel-chat-header shadow-sm">
      <div class="d-flex justify-content-between">
        <span class="fs-5"> <i class="fa-regular fa-comment-dots"></i> Chat</span>
        <div>
          <button class="btn btn-outline-info btn-sm me-2" (click)="svChatRoom.disConnection()">
            <i class="bi bi-arrow-clockwise"></i> Reconnect
          </button>
          <a class="me-2" href="/chat" target="_blank">
            <i class="fa-regular fa-share-from-square"></i>
          </a>
          <a (click)="openChat = !openChat"><i class="fa-solid fa-xmark"></i></a>
        </div>
      </div>
    </div>
    <div class="panel-chat-body">
      <div class="d-flex h-100">
        <div class="border-end" style="width: 25%;height: 100%;">
          <app-chat-list></app-chat-list>
        </div>
        <div style="width: 75%;height: 100%;">
          <app-chat-room></app-chat-room>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div class="panel-chat shadow-sm on-page" *ngIf="!onOverlay">
  <div class="recon-load" *ngIf="svChatRoom.LoadingConnecting">
    <div class="d-flex align-items-center justify-content-center w-100 h-100">
      <div class="text-center">
        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        Please wait Reconnecting Chat...
      </div>
    </div>
  </div>
  <div class="panel-chat-header shadow-sm">
    <span class="fs-5"> <i class="fa-regular fa-comment-dots"></i> Chat
      <button class="btn btn-outline-info btn-sm" (click)="svChatRoom.disConnection()">
        <i class="bi bi-arrow-clockwise"></i> Reconnect
      </button>
    </span>
  </div>
  <div class="panel-chat-body">
    <div class="d-flex h-100">
      <div class="border-end" style="width: 25%;height: 100%;">
        <app-chat-list></app-chat-list>
      </div>
      <div style="width: 75%;height: 100%;">
        <app-chat-room></app-chat-room>
      </div>
    </div>
  </div>
</div>