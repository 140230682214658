import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { alertDanger } from './utils/functions/alert';
import { cleanObject } from './utils/functions/clean-object';
import { SignalRService } from './services/signal-r.service';
import { AuthenticationService } from './services/authentication.service';
import { Router } from '@angular/router';
import { AppPath } from './utils/constants';
import { AppInjector } from './app-injector';
import { ChatroomService } from './services/chatroom.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

  constructor(
    private ngRouter: Router,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      body: cleanObject(request.body),
    });
    return next.handle(request).pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    if (error?.status === 403) {
      const svChatRoom = AppInjector.get(ChatroomService);
      const svSignalR = AppInjector.get(SignalRService);
      const svAuth = AppInjector.get(AuthenticationService);
      const ngRouter = AppInjector.get(Router);
      svChatRoom.connectChat();
      svSignalR?.disconnectNotify();
      svAuth.logout();
      ngRouter.navigate(['', AppPath.login]);
    }
    if (error.url.includes('noalert')) {
      return throwError(error);
    }

    alertDanger({ message: error?.error?.Message || error?.error });
    return throwError(error);
  }
}

export const AppInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
];
