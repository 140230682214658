<div class="offcanvas offcanvas-end" tabindex="-1" id="billStatusOffcanvas">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title">Invoice Status</h5>
    <button type="button" id="close-bill-status" class="btn-close text-reset" data-bs-dismiss="offcanvas"
      aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <form [formGroup]="form" (submit)="onSubmit()">
      <div class="mb-3">
        <label for="" class="form-label"> วันที่จ่ายเงิน </label>
        <input type="date" class="form-control" formControlName="payment_date">
      </div>
      <div class="mb-3">
        <label for="" class="form-label">รูปหลักฐาน</label>
        <app-input-file-form formControlName="image" width="300px" height="300px"></app-input-file-form>
      </div>
      <div class="mb-3 text-end">
        <button type="submit" class="btn btn-primary btn-submit" [appLoading]="saving"> ยืนยันการจ่ายเงิน </button>
      </div>
    </form>
  </div>
</div>
