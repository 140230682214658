import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { KlottoBill } from 'src/app/pages/klotto/pages';
import { AppCore } from 'src/app/utils/classes/app-core';
import { BankImgPipe } from '../../pipes/bank-img.pipe';

@Component({
  selector: 'app-view-bill-klotto-offcanvas',
  templateUrl: './view-bill-klotto-offcanvas.component.html',
  styleUrls: ['./view-bill-klotto-offcanvas.component.scss']
})
export class ViewBillKlottoOffcanvasComponent extends AppCore implements OnInit, OnChanges {
  @Input() klottoBill: KlottoBill;

  flagcdn = (code) => (`https://flagcdn.com/w20/${code}.png`)
  flags = {
    "THA": this.flagcdn('th'),
    "THA-GSB": this.pipeBankImg.transform('gsb', 'bank_code'),
    "THA-BAAC": this.pipeBankImg.transform('baac', 'bank_code'),
    "VNM": this.flagcdn('vn'),
    "VNM-VIP": this.flagcdn('vn'),
    "LAO": this.flagcdn('la'),
    "MYS": this.flagcdn('my'),
    "VNM-SPL": this.flagcdn('vn'),
    "YEEKEE": "https://dev-klotto.karnival.systems/assets/favicon.png",
    "SGP": this.flagcdn('sg'),
    "DEU": this.flagcdn('de'),
    "KOR": this.flagcdn('kr'),
    "JPN": this.flagcdn('jp'),
    "HKG": this.flagcdn('hk'),
    "CHN": this.flagcdn('cn'),
    "TWN": this.flagcdn('tw'),
    "EGY": this.flagcdn('eg'),
    "UK": this.flagcdn('gb'),
    "RUS": this.flagcdn('ru'),
    "IND": this.flagcdn('in'),
    "US": this.flagcdn('us'),
    "THA-STOCK": this.flagcdn('th'),
  };

  constructor(
    private pipeBankImg: BankImgPipe
  ) {
    super();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  clickBtnClose(): void {
    super.clickBtnClose('btn-close-ss-offcanvas')
  }
}
