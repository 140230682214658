<div class="offcanvas offcanvas-end" tabindex="-1" id="broadcastFormOffcanvas">
  <div class="offcanvas-header">
    <h4 class="offcanvas-title" *ngIf="!updateItem">{{'CREATE'|translate}} Broadcast</h4>
    <h4 class="offcanvas-title" *ngIf="updateItem">{{'EDIT'|translate}} Broadcast</h4>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
      id="btn-close-broadcast-form"></button>
  </div>
  <div class="offcanvas-body">
    <form [formGroup]="form" (submit)="onSubmit()">
      <div class="mb-3">
        <label for="" class="form-label">{{'CHOOSE_AGENT'|translate}}</label>
        <app-select-agent-form formControlName="agent_id" [showLabel]="false"></app-select-agent-form>
      </div>
      <div class="mb-3">
        <label for="" class="form-label">{{'IMAGE'|translate}}</label>
        <app-input-file-form formControlName="image" width="150px" height="150px"></app-input-file-form>
      </div>
      <div class="mb-3">
        <label for="" class="form-label">{{'TITLE'|translate}}</label>
        <input type="text" class="form-control" formControlName="title">
      </div>
      <div class="mb-3">
        <label for="" class="form-label">{{'MESSAGE'|translate}}</label>
        <input type="text" class="form-control" formControlName="message">
      </div>
      <hr>
      <div class="text-end">
        <button type="submit" class="btn btn-primary btn-submit" [appLoading]="saving">{{'SAVE_DATA'|translate}}</button>
      </div>
    </form>
  </div>
</div>
