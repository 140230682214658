export const WebConfig: IWebConfig[] = [
  {
    // 0
    UrlWeb: ['agent.karnival.systems', 'plusgold-bo.karnival.systems'],
    signUpUrl: 'https://www.roman168.bet/sign-up',
    Production: false,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'KARNIVAL™',
      th: ''
    },
    Logo: 'assets/karnival-v3.png',
    LogoDark: 'assets/karnival-v3-dark.png',
    Favicon: 'assets/karnival-v3-favo.svg',
    prefix: 't',
    core: 'plusgold',
    games: {
      klottoEnabled: true,
    },
    users: {
      sageEnabled: true,
    },
  },
  {
    // 1
    UrlWeb: ['ag.karnival.systems'],
    Production: true,
    AutoSystem: false,
    AgentSystem: true,
    YourBand: {
      en: 'KARNIVAL™',
      th: ''
    },
    Logo: 'assets/karnival-v3.png',
    LogoDark: 'assets/karnival-v3-dark.png',
    Favicon: 'assets/karnival-v3-favo.svg',
    prefix: '',
    core: 'plusgold',
    games: {
      klottoEnabled: true,
    },
    users: {
      sageEnabled: false,
    },
  },
  {
    // 2
    UrlWeb: ['agent-slot333.karnival.systems', 'agent-slot333.karnival.cloud'],
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'SLOT333',
      th: 'สล็อต333'
    },
    Logo: 'https://cdn.karnival.systems/Images/logo/full/slot333.png',
    LogoDark: 'https://cdn.karnival.systems/Images/logo/full/slot333.png',
    Favicon: 'https://cdn.karnival.systems/Images/logo/fav/slot333-fav.png',
    prefix: '33so',
    core: 'plusgold',
    games: {
      klottoEnabled: true,
    },
    users: {
      sageEnabled: false,
    },
  },
  {
    // 3
    UrlWeb: ['agent-vblive.karnival.systems'],
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'Viewbet.LIVE',
      th: 'วิวเบ็ท.ไลฟ์'
    },
    Logo: 'https://cdn.karnival.systems/Images/logo/full/vb-live.png',
    LogoDark: 'https://cdn.karnival.systems/Images/logo/full/vb-live.png',
    Favicon: 'https://cdn.karnival.systems/Images/logo/fav/vb-live-fav.png',
    prefix: '11ppg',
    core: 'plusgold',
    games: {
      klottoEnabled: true,
    },
    users: {
      sageEnabled: false,
    },
  },
  {
    // 4
    UrlWeb: ['agent-infinite66.karnival.systems'],
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'infinite66.com',
      th: ''
    },
    Logo: 'https://cdn.karnival.systems/Images/logo/full/inf70-2.png',
    LogoDark: 'https://cdn.karnival.systems/Images/logo/full/inf70-2.png',
    Favicon: 'https://cdn.karnival.systems/Images/logo/fav/inf70-fav.png',
    prefix: '66ifn',
    core: 'plusgold',
    games: {
      klottoEnabled: true,
    },
    users: {
      sageEnabled: false,
    },
  },
  {
    // 5
    UrlWeb: ['agent.ceo787.com'],
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'ceo787.com',
      th: ''
    },
    Logo: 'https://cdn.karnival.systems/Images/logo/full/ceo787.png',
    LogoDark: 'https://cdn.karnival.systems/Images/logo/full/ceo787.png',
    Favicon: 'https://cdn.karnival.systems/Images/logo/fav/ceo787-fav.png',
    prefix: 'ceo',
    core: 'plusgold',
    games: {
      klottoEnabled: true,
    },
    users: {
      sageEnabled: false,
    },
  },
  {
    // 6
    UrlWeb: ['agent.betroad.vip'],
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'betroad.vip',
      th: ''
    },
    Logo: 'https://cdn.karnival.systems/Images/logo/full/betroadvip.png',
    LogoDark: 'https://cdn.karnival.systems/Images/logo/full/betroadvip.png',
    Favicon: 'https://cdn.karnival.systems/Images/logo/fav/betroadvip-fav.png',
    prefix: '',
    core: 'plusgold',
    games: {
      klottoEnabled: true,
    },
    users: {
      sageEnabled: false,
    },
  },
  {
    // 7
    UrlWeb: ['agent.god78.com'],
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'god78.com',
      th: ''
    },
    Logo: 'https://cdn.karnival.systems/Images/logo/full/god78.png',
    LogoDark: 'https://cdn.karnival.systems/Images/logo/full/god78.png',
    Favicon: 'https://cdn.karnival.systems/Images/logo/fav/god78-fav.png',
    prefix: 'ceog',
    core: 'plusgold',
    games: {
      klottoEnabled: true,
    },
    users: {
      sageEnabled: false,
    },
  },
  {
    // 8
    UrlWeb: ['agent-kingroyal888.karnival.systems'],
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'kingroyal888.com',
      th: ''
    },
    Logo: 'https://cdn.karnival.systems/Images/logo/full/KingRoyal888.png',
    LogoDark: 'https://cdn.karnival.systems/Images/logo/full/KingRoyal888.png',
    Favicon: 'https://cdn.karnival.systems/Images/logo/fav/KingRoyal888-fav.png',
    prefix: 'tkingy',
    core: 'plusgold',
    games: {
      klottoEnabled: true,
    },
    users: {
      sageEnabled: false,
    },
  },
  {
    // 9
    UrlWeb: ['agent-akk999.karnival.systems'],
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'Akk999.com',
      th: ''
    },
    Logo: 'https://cdn.karnival.systems/Images/logo/full/Akk999.png',
    LogoDark: 'https://cdn.karnival.systems/Images/logo/full/Akk999.png',
    Favicon: 'https://cdn.karnival.systems/Images/logo/fav/Akk999-fav.png',
    prefix: 'ceoa',
    core: 'plusgold',
    games: {
      klottoEnabled: true,
    },
    users: {
      sageEnabled: false,
    },
  },
  {
    // 10
    UrlWeb: ['agent-betwing888.karnival.systems', 'agent.betwing888.com'],
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'betwing888.com',
      th: ''
    },
    Logo: 'https://cdn.karnival.systems/Images/logo/full/betwing888.png',
    LogoDark: 'https://cdn.karnival.systems/Images/logo/full/betwing888.png',
    Favicon: 'https://cdn.karnival.systems/Images/logo/fav/betwing888-fav.png',
    prefix: 'twing',
    core: 'plusgold',
    games: {
      klottoEnabled: true,
    },
    users: {
      sageEnabled: false,
    },
  },
  {
    // 11
    UrlWeb: ['agent.giant878.com'],
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'giant878.com',
      th: ''
    },
    Logo: 'https://cdn.karnival.systems/Images/logo/full/giant878.png',
    LogoDark: 'https://cdn.karnival.systems/Images/logo/full/giant878.png',
    Favicon: 'https://cdn.karnival.systems/Images/logo/fav/giant878-fav.png',
    prefix: 'ceon',
    core: 'plusgold',
    games: {
      klottoEnabled: true,
    },
    users: {
      sageEnabled: false,
    },
  },
  {
    // 12
    UrlWeb: ['agent.giant878.com'],
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'giant878.com',
      th: ''
    },
    Logo: 'https://cdn.karnival.systems/Images/logo/full/giant878.png',
    LogoDark: 'https://cdn.karnival.systems/Images/logo/full/giant878.png',
    Favicon: 'https://cdn.karnival.systems/Images/logo/fav/giant878-fav.png',
    prefix: 'ceon',
    core: 'plusgold',
    games: {
      klottoEnabled: true,
    },
    users: {
      sageEnabled: false,
    },
  },
  {
    // 13
    UrlWeb: ['agent.ufo482.com'],
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'ufo482.com',
      th: ''
    },
    Logo: 'https://cdn.karnival.systems/Images/logo/full/ufo482.png',
    LogoDark: 'https://cdn.karnival.systems/Images/logo/full/ufo482.png',
    Favicon: 'https://cdn.karnival.systems/Images/logo/fav/ufo482-fav.png',
    prefix: 'ceou',
    core: 'plusgold',
    games: {
      klottoEnabled: true,
    },
    users: {
      sageEnabled: false,
    },
  },
  {
    // 14
    UrlWeb: ['agent.alfabet168.com', 'agent-alfabet168.karnival.cloud'],
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'alfabet168.com',
      th: ''
    },
    Logo: 'https://cdn.karnival.systems/Images/logo/full/alfabet168.png',
    LogoDark: 'https://cdn.karnival.systems/Images/logo/full/alfabet168.png',
    Favicon: 'https://cdn.karnival.systems/Images/logo/fav/alfabet168-fav.png',
    prefix: '11vryc',
    core: 'plusgold',
    games: {
      klottoEnabled: true,
    },
    users: {
      sageEnabled: false,
    },
  },
  {
    // 15
    UrlWeb: ['agent-skingbet.karnival.systems'],
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'skingbet.com',
      th: ''
    },
    Logo: 'https://cdn.karnival.systems/Images/logo/full/skingbet.png',
    LogoDark: 'https://cdn.karnival.systems/Images/logo/full/skingbet.png',
    Favicon: 'https://cdn.karnival.systems/Images/logo/fav/skingbet-fav.png',
    prefix: '',
    core: 'plusgold',
    games: {
      klottoEnabled: true,
    },
    users: {
      sageEnabled: false,
    },
  },
  {
    // 16
    UrlWeb: ['agent-akk777.karnival.systems', 'agent.akk777.bet', 'agent.ak777.bet'],
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'ak777.bet',
      th: ''
    },
    Logo: 'https://cdn.karnival.systems/Images/logo/full/akk777.png',
    LogoDark: 'https://cdn.karnival.systems/Images/logo/full/akk777.png',
    Favicon: 'https://cdn.karnival.systems/Images/logo/fav/akk777-fav.png',
    prefix: 'skk',
    core: 'plusgold',
    games: {
      klottoEnabled: true,
    },
    users: {
      sageEnabled: false,
    },
  },
  {
    // 17
    UrlWeb: ['agent.alpha69.co'],
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'alpha69.co',
      th: ''
    },
    Logo: 'https://cdn.karnival.systems/Images/logo/full/alpha69.png',
    LogoDark: 'https://cdn.karnival.systems/Images/logo/full/alpha69.png',
    Favicon: 'https://cdn.karnival.systems/Images/logo/fav/alpha69-fav.png',
    prefix: '',
    core: 'plusgold',
    games: {
      klottoEnabled: true,
    },
    users: {
      sageEnabled: false,
    },
  },
  /**
   * ROMAN
   */
  {
    // 18
    UrlWeb: ['agent.roman168.bet'],
    signUpUrl: 'https://www.roman168.bet/sign-up',
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'ROMAN',
      th: ''
    },
    Logo: 'assets/logo/roman.png',
    LogoDark: 'assets/logo/roman.png',
    Favicon: 'assets/logo/roman-fav.png',
    prefix: 'rm',
    createBy: {
      logo: 'assets/logo/roman.png',
      name: 'ROMAN168'
    },
    core: 'roman',
    games: {
      klottoEnabled: false,
    },
    users: {
      sageEnabled: true,
    },
  },
  {
    // 19
    UrlWeb: ['ag.roman168.bet'],
    signUpUrl: 'https://www.roman168.bet/sign-up',
    Production: true,
    AutoSystem: false,
    AgentSystem: true,
    YourBand: {
      en: 'ROMAN',
      th: ''
    },
    Logo: 'assets/logo/roman.png',
    LogoDark: 'assets/logo/roman.png',
    Favicon: 'assets/logo/roman-fav.png',
    prefix: 'rm',
    createBy: {
      logo: 'assets/logo/roman.png',
      name: 'ROMAN168'
    },
    core: 'roman',
    games: {
      klottoEnabled: false,
    },
    users: {
      sageEnabled: true,
    },
  },
  {
    // 20
    UrlWeb: ['agent-auto.bln789.com'],
    signUpUrl: 'https://www.bln789.com/sign-in',
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'BLN789',
      th: ''
    },
    Logo: 'assets/logo/bln789.png',
    LogoDark: 'assets/logo/bln789.png',
    Favicon: 'assets/logo/bln789-fav.png',
    prefix: 'rmab',
    createBy: {
      logo: 'assets/logo/roman.png',
      name: 'ROMAN168'
    },
    core: 'roman',
    games: {
      klottoEnabled: false,
    },
    users: {
      sageEnabled: true,
    },
  },
  {
    // 21
    UrlWeb: ['agent-auto.roman69.com'],
    signUpUrl: 'https://www.roman69.com/sign-up',
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'ROMAN69',
      th: ''
    },
    Logo: 'assets/logo/roman69.png',
    LogoDark: 'assets/logo/roman69.png',
    Favicon: 'assets/logo/roman69-fav.png',
    prefix: 'rm69',
    createBy: {
      logo: 'assets/logo/roman.png',
      name: 'ROMAN168'
    },
    core: 'roman',
    games: {
      klottoEnabled: false,
    },
    users: {
      sageEnabled: true,
    },
  },
  {
    // 22
    UrlWeb: ['agent-auto.168io.com'],
    signUpUrl: 'https://www.168io.com/sign-up',
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: '168IO',
      th: ''
    },
    Logo: 'assets/logo/168io.png',
    LogoDark: 'assets/logo/168io.png',
    Favicon: 'assets/logo/168io-fav.png',
    prefix: 'rmai',
    createBy: {
      logo: 'assets/logo/roman.png',
      name: 'ROMAN168'
    },
    core: 'roman',
    games: {
      klottoEnabled: false,
    },
    users: {
      sageEnabled: true,
    },
  },
  {
    // 23
    UrlWeb: ['agent-auto.flushroyals.com'],
    signUpUrl: 'https://www.flushroyals.com/sign-up',
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'FLUSHROYALS',
      th: ''
    },
    Logo: 'assets/logo/FlushRoyals.png',
    LogoDark: 'assets/logo/FlushRoyals.png',
    Favicon: 'assets/logo/FlushRoyals-fav.png',
    prefix: 'rmaf',
    createBy: {
      logo: 'assets/logo/roman.png',
      name: 'ROMAN168'
    },
    core: 'roman',
    games: {
      klottoEnabled: false,
    },
    users: {
      sageEnabled: true,
    },
  },
  {
    // 24
    UrlWeb: ['agent-auto.168cosmo.com', '168cosmo-agent-auto.roman168.bet'],
    signUpUrl: 'https://168cosmo-member.roman168.bet/sign-up',
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: '168Cosmo',
      th: ''
    },
    Logo: 'assets/logo/168Cosmo.jpg',
    LogoDark: 'assets/logo/168Cosmo.jpg',
    Favicon: 'assets/logo/168Cosmo-fav.jpg',
    prefix: 'rmac',
    createBy: {
      logo: 'assets/logo/roman.png',
      name: 'ROMAN168'
    },
    core: 'roman',
    games: {
      klottoEnabled: false,
    },
    users: {
      sageEnabled: true,
    },
  },
  {
    // 25
    UrlWeb: ['agent.feeds88.com', 'agent-feeds88.karnival.systems', 'agent-feeds88.karnival.cloud'],
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'feeds88.com',
      th: ''
    },
    Logo: 'assets/logo/feeds88.png',
    LogoDark: 'assets/logo/feeds88.png',
    Favicon: 'assets/logo/feeds88-fav.png',
    prefix: 'feed',
    core: 'plusgold',
    games: {
      klottoEnabled: true,
    },
    users: {
      sageEnabled: false,
    },
  },
  {
    // 26
    UrlWeb: ['system.goodgame66.co', 'agent-goodgame66.karnival.systems', 'agent-goodgame66.karnival.cloud'],
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'goodgame66.com',
      th: ''
    },
    Logo: 'assets/logo/goodgame66-white.png',
    LogoDark: 'assets/logo/goodgame66.png',
    Favicon: 'assets/logo/goodgame66-fav.png',
    prefix: 'gg66',
    core: 'plusgold',
    games: {
      klottoEnabled: true,
    },
    users: {
      sageEnabled: true,
    },
  },
  {
    // 27
    UrlWeb: ['agent-auto.goodshot777.com', 'goodshot777-agent-auto.roman168.bet'],
    signUpUrl: 'https://goodshot777-member.roman168.bet/sign-up',
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'goodshot777',
      th: ''
    },
    Logo: 'assets/logo/goodshot777.png',
    LogoDark: 'assets/logo/goodshot777.png',
    Favicon: 'assets/logo/goodshot777-fav.png',
    prefix: 'rmae',
    createBy: {
      logo: 'assets/logo/roman.png',
      name: 'ROMAN168'
    },
    core: 'roman',
    games: {
      klottoEnabled: false,
    },
    users: {
      sageEnabled: true,
    },
  },
  {
    // 28
    UrlWeb: ['agent-auto.kingronan.com', 'kingronan-agent-auto.roman168.bet'],
    signUpUrl: 'https://kingronan-member.roman168.bet/sign-up',
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'kingronan',
      th: ''
    },
    Logo: 'assets/logo/kingronan.png',
    LogoDark: 'assets/logo/kingronan.png',
    Favicon: 'assets/logo/kingronan-fav.png',
    prefix: 'rmad',
    createBy: {
      logo: 'assets/logo/roman.png',
      name: 'ROMAN168'
    },
    core: 'roman',
    games: {
      klottoEnabled: false,
    },
    users: {
      sageEnabled: true,
    },
  },
  {
    // 29
    UrlWeb: ['agent-auto.mixim88.com', 'mixim88-agent-auto.roman168.bet'],
    signUpUrl: 'https://mixim88-member.roman168.bet/sign-up',
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'mixim88',
      th: ''
    },
    Logo: 'assets/logo/mixim88.png',
    LogoDark: 'assets/logo/mixim88.png',
    Favicon: 'assets/logo/mixim88-fav.png',
    prefix: 'rmag',
    createBy: {
      logo: 'assets/logo/roman.png',
      name: 'ROMAN168'
    },
    core: 'roman',
    games: {
      klottoEnabled: false,
    },
    users: {
      sageEnabled: true,
    },
  },
  {
    // 30
    UrlWeb: ['agent-auto.funkub.com', 'funkub-agent-auto.roman168.bet'],
    signUpUrl: 'https://funkub-member.roman168.bet/sign-up',
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'funkub',
      th: ''
    },
    Logo: 'assets/logo/funkub.png',
    LogoDark: 'assets/logo/funkub.png',
    Favicon: 'assets/logo/funkub-fav.png',
    prefix: 'rmah',
    createBy: {
      logo: 'assets/logo/roman.png',
      name: 'ROMAN168'
    },
    core: 'roman',
    games: {
      klottoEnabled: false,
    },
    users: {
      sageEnabled: true,
    },
  },
  {
    // 31
    UrlWeb: ['agent-auto.shibuya69.com', 'shibuya69-agent-auto.roman168.bet'],
    signUpUrl: 'https://shibuya69-member.roman168.bet/sign-up',
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'shibuya69',
      th: ''
    },
    Logo: 'assets/logo/shibuya69.png',
    LogoDark: 'assets/logo/shibuya69.png',
    Favicon: 'assets/logo/shibuya69-fav.png',
    prefix: 'rmba',
    createBy: {
      logo: 'assets/logo/roman.png',
      name: 'ROMAN168'
    },
    core: 'roman',
    games: {
      klottoEnabled: false,
    },
    users: {
      sageEnabled: true,
    },
  },
  {
    // 32
    UrlWeb: ['agent-auto.rishhunter.com', 'rishhunter-agent-auto.roman168.bet'],
    signUpUrl: 'https://rishhunter-member.roman168.bet/sign-up',
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'rishhunter',
      th: ''
    },
    Logo: 'assets/logo/rishhunter.png',
    LogoDark: 'assets/logo/rishhunter.png',
    Favicon: 'assets/logo/rishhunter-fav.png',
    prefix: 'rmaj',
    createBy: {
      logo: 'assets/logo/roman.png',
      name: 'ROMAN168'
    },
    core: 'roman',
    games: {
      klottoEnabled: false,
    },
    users: {
      sageEnabled: true,
    },
  },
  {
    // 32
    UrlWeb: ['agent-auto.asia88th.com', 'asia88th-agent-auto.roman168.bet'],
    signUpUrl: 'https://asia88th-member.roman168.bet/sign-up',
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'asia88th',
      th: ''
    },
    Logo: 'assets/logo/asia88th.png',
    LogoDark: 'assets/logo/asia88th.png',
    Favicon: 'assets/logo/asia88th-fav.png',
    prefix: 'rmc',
    createBy: {
      logo: 'assets/logo/roman.png',
      name: 'ROMAN168'
    },
    core: 'roman',
    games: {
      klottoEnabled: false,
    },
    users: {
      sageEnabled: true,
    },
  },
  {
    // 33
    UrlWeb: ['agent-yesbet.karnival.systems'],
    Production: true,
    AutoSystem: true,
    AgentSystem: true,
    YourBand: {
      en: 'Viewbet.LIVE',
      th: 'วิวเบ็ท.ไลฟ์'
    },
    Logo: 'https://file.karnival.systems/assets/logo-band/yesbet.png',
    LogoDark: 'https://file.karnival.systems/assets/logo-band/yesbet.png',
    Favicon: 'https://file.karnival.systems/assets/favicon-icon/yesbet/apple-touch-icon.png',
    prefix: '88h',
    core: 'plusgold',
    games: {
      klottoEnabled: true,
    },
    users: {
      sageEnabled: false,
    },
  },
  {
    // 34
    UrlWeb: ['bh-auto.karnival.cloud'],
    signUpUrl: 'https://bh.karnival.cloud/register/',
    Production: true,
    AutoSystem: true,
    AgentSystem: false,
    YourBand: {
      en: 'BETHUB',
      th: ''
    },
    Logo: 'https://cdn.karnival.systems/Images/logo/full/bethub-vip.png',
    LogoDark: 'https://cdn.karnival.systems/Images/logo/full/bethub-vip.png',
    Favicon: 'https://cdn.karnival.systems/Images/logo/fav/bethub-vip-fav.png',
    prefix: '',
    core: 'bethub',
    games: {
      klottoEnabled: false,
    },
    users: {
      sageEnabled: true,
    },
  },
  {
    // 35
    UrlWeb: ['muntifun-auto.karnival.cloud', 'montifun-auto.karnival.systems'],
    signUpUrl: 'https://montifun-member.karnival.cloud/register/',
    Production: true,
    AutoSystem: true,
    AgentSystem: false,
    YourBand: {
      en: 'MONTIFUN',
      th: 'MONTIFUN'
    },
    Logo: 'https://cdn.karnival.systems/Images/logo/full/montifun.png',
    LogoDark: 'https://cdn.karnival.systems/Images/logo/full/montifun.png',
    Favicon: 'https://cdn.karnival.systems/Images/logo/fav/montifun-fav.png',
    prefix: '',
    core: 'bethub',
    games: {
      klottoEnabled: false,
    },
    users: {
      sageEnabled: true,
    },
  },
  {
    // 35
    UrlWeb: ['sbo24hr-auto.karnival.cloud', 'sbo24hr-auto.karnival.systems'],
    signUpUrl: 'https://sbo24hr-member.karnival.cloud/register/',
    Production: true,
    AutoSystem: true,
    AgentSystem: false,
    YourBand: {
      en: 'sbo24hr',
      th: 'sbo24hr'
    },
    Logo: 'https://cdn.karnival.systems/Images/logo/full/sbo24hr.png',
    LogoDark: 'https://cdn.karnival.systems/Images/logo/full/sbo24hr.png',
    Favicon: 'https://cdn.karnival.systems/Images/logo/fav/sbo24hr-fav.png',
    prefix: '',
    core: 'plusgold',
    games: {
      klottoEnabled: true,
    },
    users: {
      sageEnabled: true,
    },
  },
];

export interface IWebConfig {
  UrlWeb: string[];
  Production: boolean;
  AutoSystem: boolean;
  AgentSystem: boolean;
  YourBand: {
    th: string;
    en: string;
  };
  Logo: string;
  LogoDark: string;
  Favicon: string;
  prefix: string;
  createBy?: {
    logo: string;
    name: string;
  };
  core: 'plusgold' | 'roman' | 'bethub';
  games: {
    klottoEnabled: boolean;
  };
  users: {
    sageEnabled: boolean;
  };
  signUpUrl?: string;
}
