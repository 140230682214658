import { DecimalPipe } from '@angular/common';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import iziToast, { IziToastSettings } from 'izitoast';
import { BehaviorSubject, Subject } from 'rxjs';
import { Sound } from 'src/assets/sounds/sound.interface';
import { environment } from 'src/environments/environment';
import { BankImgPipe } from '../shared/pipes/bank-img.pipe';
import { AppPath } from '../utils/constants';
import { AuthenticationService } from './authentication.service';
import { HttpApiService } from './http-api.service';
import { SoundSettingService } from './sound-setting.service';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private apiHost = environment.origin;
  statementUpdated$ = new Subject();
  depositCount$ = new BehaviorSubject<NotifyCountDeposit>({
    outstanding: 0,
    request: 0,
  });
  withdrawCount$ = new BehaviorSubject<NotifyCountWithdraw>({
    checking: 0,
    failed: 0,
    pending: 0,
    request: 0,
  });
  logWorker = new Subject<ILogWork>();

  Connection: SignalR.Hub.Connection;
  connectioNotifyHub: SignalR.Hub.Proxy;
  connectioLogHub: SignalR.Hub.Proxy;

  notificationConnected = false;
  constructor(
    private svAuth: AuthenticationService,
    private ppNumber: DecimalPipe,
    private ngRouter: Router,
    private ppBankImg: BankImgPipe,
    private http: HttpApiService,
    private ngZone: NgZone,
    private svSoundSetting: SoundSettingService,
  ) { }

  connectNotify() {
    this.Connection = $.hubConnection(this.apiHost + `signalr`, { useDefaultPath: false });
    this.Connection.qs = { token: this.svAuth.getToken };
    this.connectioNotifyHub = this.Connection.createHubProxy(`notificationHub`);
    this.connectioLogHub = this.Connection.createHubProxy(`accountBankAgentHub`);

    this.connectioNotifyHub.on('statementUpdated', (val) => this.statementUpdated(val));
    this.connectioNotifyHub.on('requestDeposit', (val) => this.requestDeposit(val));
    this.connectioNotifyHub.on('requestWithdraw', (val) => this.requestWithdraw(val));
    this.connectioNotifyHub.on('withdrawResult', (val) => this.withdrawResult(val));

    this.connectioNotifyHub.on('countDeposit', (val) => this.countDeposit(val));
    this.connectioNotifyHub.on('countWithdraw', (val) => this.countWithdraw(val));

    this.Connection.start()
      .done(() => {
        this.notificationConnected = true;
        console.log(`Noti&Log Now connected ${this.Connection.id}`); // , connection ID= ${connection.id}
        if (this.svAuth.getUser?.Ask4betAgent) {
          this.connectioNotifyHub.invoke('getTransferCount').done(val => {
            this.getTransferCount(val);
          });
        }
      })
      .fail(() => {
        console.log('Noti&Log Could not connect!!!');
      });
  }

  disconnectNotify() {
    this.notificationConnected = false;
    this.Connection?.stop();
  }

  // tslint:disable-next-line:member-ordering
  private defaultSettings: IziToastSettings = {
    class: 'pointer notify-signalr',
    position: 'bottomRight',
    timeout: 5000,
    theme: '',
    titleSize: '1.2rem',
    title: ``, // Title
    message: ``, // Message
    icon: 'fas fa-bell', // ICON
    iconColor: '#fff',
    titleColor: '#fff',
    messageColor: '#fff',
    backgroundColor: 'rgba(var(--bs-danger-rgb))', // Background
    layout: 2,
    close: false,
    closeOnClick: true,
    onClosing: () => { }, // Event OnClose
  };

  stopAudio() {
    const currentSetting = this.svSoundSetting.getCurrentSetting();
    for (const [key, value] of Object.entries(currentSetting)) {
      const auEl = document.getElementById(value.name) as HTMLAudioElement;
      if (!auEl) {
        return;
      }
      auEl.pause();
      auEl.currentTime = 0;
      auEl.volume = 1;
    }
  }
  playAudio(item: Sound) {
    this.stopAudio();
    const auEl = document.getElementById(item.name) as HTMLAudioElement;
    if (!auEl) {
      return;
    }
    auEl.volume = 1;
    auEl.play();
  }

  private requestDeposit(val: NotifyRequestDeposit) {
    console.log(`🧪 ~ requestDeposit`, val);
    this.statementUpdated$.next();

    const currentSetting = this.svSoundSetting.getCurrentSetting();
    this.playAudio(currentSetting.depositName);

    this.ngZone.run(() => {
      iziToast.show({
        ...this.defaultSettings,
        title: `มีคำขอการฝากใหม่`,
        message: `
      <div class="d-flex align-items-center mb-2">
        <img class="me-2" src="${this.ppBankImg.transform(val.bank?.bank_code, 'bank_code')}" width="30px" height="30px">
        <div class="pe-3">
          <div class="opacity-75 border-bottom mb-1 pb-1"> ${val.bank?.account_name} </div>
          <div> ${val.bank?.account_no} </div>
        </div>
      </div>
      <div class=""> จำนวน ${this.ppNumber.transform(val.amount, '0.2-2')}฿ </div>
      `,
        backgroundColor: 'rgba(var(--bs-primary-rgb), 0.9)',
        onClosing: (_, __, closeby) => {
          if (closeby === 'toast') {
            this.ngRouter.navigate(['', AppPath.workDeposit, 'request']);
          }
        },
      });
    });
  }
  private requestWithdraw(val: NotifyRequestWithdraw) {
    console.log(`🧪 ~ requestWithdraw`, val);
    this.statementUpdated$.next();

    const currentSetting = this.svSoundSetting.getCurrentSetting();
    this.playAudio(currentSetting.withdrawName);

    this.ngZone.run(() => {
      iziToast.show({
        ...this.defaultSettings,
        title: `มีคำขอการถอนใหม่`,
        message: `ชื่อผู้ใข้งานสมาชิก ${val.username} <br> จำนวน ${this.ppNumber.transform(val.amount, '0.2-2')}฿`,
        backgroundColor: 'rgba(var(--bs-primary-rgb), 0.9)',
        onClosing: (_, __, closeby) => {
          if (closeby === 'toast') {
            this.ngRouter.navigate(['', AppPath.workWithdraw, 'all']);
          }
        },
      });
    });
  }
  private statementUpdated(val: NotifyStatementUpdated) {
    console.log(`🧪 ~ statementUpdated`, val);
    this.statementUpdated$.next();

    const currentSetting = this.svSoundSetting.getCurrentSetting();
    this.playAudio(currentSetting.depositName);

    this.ngZone.run(() => {
      iziToast.show({
        ...this.defaultSettings,
        icon: 'fas fa-exchange-alt',
        title: `มีการโอนเงินเข้า`,
        message: `
      <div class="d-flex align-items-center mb-2">
        <img class="me-2" src="${this.ppBankImg.transform(val.bank?.bank_code, 'bank_code')}" width="35px" height="35px">
        <div class="pe-3">
          <div class="opacity-75 border-bottom mb-1 pb-1"> ${val.bank?.account_name} </div>
          <div> ${val.bank?.account_no} </div>
        </div>
      </div>
      <div> มีการโอนเงินเข้าทั้งหมด ${val.added} รายการ </div>
      `,
        backgroundColor: 'rgba(var(--bs-success-rgb), 0.9)',
        onClosing: (_, __, closeby) => {
          if (closeby === 'toast') {
            this.ngRouter.navigate(['', AppPath.bank]);
          }
        },
      });
    });
  }
  private withdrawResult(val: NotifyWithdrawResult) {
    console.log(`🧪 ~ withdrawResult`, val);
    this.statementUpdated$.next();

    const currentSetting = this.svSoundSetting.getCurrentSetting();
    const sound = val.transfer_status === 'success' ?
      currentSetting.transferSuccessName :
      currentSetting.transferFailedName;
    this.playAudio(sound);

    this.ngZone.run(() => {
      iziToast.show({
        ...this.defaultSettings,
        icon: 'fas fa-angle-double-right',
        title: `การโอนเงินอัตโนมัติ ${val.from.account_no} <b>[${val.transfer_status === 'success' ? 'สำเร็จ' : 'ไม่สำเร็จ'}]</b>`,
        message: `
          <div class="d-flex align-items-center mb-2">
            <img src="${this.ppBankImg.transform(val.from.bank_code, 'bank_code')}" width="30px" height="30px">
            <i class="fas fa-angle-double-right mx-2"></i>
            <img src="${this.ppBankImg.transform(val.to.bank_code, 'bank_code')}" width="30px" height="30px">
          </div>
          <div class="mb-2"> ทำรายการ${val.transfer_status === 'success' ? 'สำเร็จ' : 'ไม่สำเร็จ'} ${val.to.bank_code} ${val.to.account_no} </div>
          <div> จำนวนเงิน ${this.ppNumber.transform(val.amount, '0.2-2')}฿</div>
        `,
        backgroundColor: 'rgba(var(--bs-secondary-rgb), 0.9)',
        onClosing: (_, __, closeby) => {
          if (closeby === 'toast') {
            if (val.transfer_status === 'success') {
              this.ngRouter.navigate(['', AppPath.autoTranfer, val.from.account_id, 'history']);
            } else if (val.transfer_status === 'failed') {
              this.ngRouter.navigate(['', AppPath.autoTranfer, val.from.account_id, 'error']);
            }
          }
        },
      });
    });
  }
  private countDeposit(val: NotifyCountDeposit) {
    this.depositCount$.next(val);
  }
  private countWithdraw(val: NotifyCountWithdraw) {
    this.withdrawCount$.next(val);
  }
  private getTransferCount(val: NotifyGetTransferCount) {
    this.depositCount$.next(val.deposit);
    this.withdrawCount$.next(val.withdraw);
  }

  //#region Test Functions
  public testStatementUpdated() {
    const model: NotifyStatementUpdated = {
      bank: {
        bank_code: 'KBANK',
        bank_image: '',
        account_no: '1546-481-113154',
        account_name: 'sample string 4'
      },
      added: 1
    };
    this.http.post(`api/test/statement_updated`, model).subscribe(() => { });
  }
  public testDepositRequest() {
    const model: NotifyRequestDeposit = {
      amount: 500,
      datetime: '',
      bank: {
        bank_code: 'KBANK',
        bank_image: 'KBANK',
        account_no: '1546-481-113154',
        account_name: 'sample string 4'
      }
    };
    this.http.post(`api/test/deposit_request`, model).subscribe(() => { });
  }
  public testWithdrawRequest() {
    const model: NotifyRequestWithdraw = {
      username: 'sample string 1',
      amount: 100,
    };
    this.http.post(`api/test/withdraw_request`, model).subscribe(() => { });
  }
  public testWithdrawResult() {
    const model: NotifyWithdrawResult = {
      from: {
        account_id: 1,
        bank_code: 'SCB',
        bank_image: '',
        account_no: '12312-484-054',
        account_name: 'sample string 4'
      },
      to: {
        account_id: 2,
        bank_code: 'BBL',
        bank_image: '',
        account_no: '156546-1544-521',
        account_name: 'sample string 4'
      },
      amount: 100.0,
      datetime: '',
      status: '',
      transfer_status: 'success'
    };
    this.http.post(`api/test/withdraw_result`, model).subscribe(() => { });
  }
  public testWithdrawCount() {
    const model: NotifyCountWithdraw = {
      checking: Math.round(Math.random() * 10),
      failed: Math.round(Math.random() * 10),
      pending: Math.round(Math.random() * 10),
      request: Math.round(Math.random() * 10),
    };
    this.http.post(`api/test/withdraw_count`, model).subscribe(() => { });
  }
  public testDepositCount() {
    const model: NotifyCountDeposit = {
      outstanding: Math.round(Math.random() * 10),
      request: Math.round(Math.random() * 10),
    };
    this.http.post(`api/test/deposit_count`, model).subscribe(() => { });
  }
  //#endregion
}

export interface NotifyRequestDeposit {
  bank?: NotifyBank;
  amount?: number;
  datetime?: string;
}
export interface NotifyRequestWithdraw {
  amount?: number;
  username?: string;
}
export interface NotifyStatementUpdated {
  bank?: NotifyBank;
  added?: number;
}
export interface NotifyWithdrawResult {
  amount?: number;
  datetime?: string;
  status?: string;
  from?: NotifyBank;
  to?: NotifyBank;
  transfer_status?: 'success' | 'failed';
}

export interface NotifyBank {
  account_id?: number;
  bank_code?: string;
  bank_image?: string;
  account_no?: string;
  account_name?: string;
}

export interface NotifyCountDeposit {
  outstanding?: number;
  request?: number;
}

export interface NotifyCountWithdraw {
  checking?: number;
  failed?: number;
  pending?: number;
  request?: number;
}

export interface NotifyGetTransferCount {
  deposit?: NotifyCountDeposit;
  withdraw?: NotifyCountWithdraw;
}


export interface ILogWorkBank {
  bank_code: string;
  bank_name: string;
  bank_image: string;
  accout_number: string;
  account_bank_name: string;
  balance: number;
}

export interface ILogWork {
  account_bank_agent_id: number;
  agent: ILogWorkBank;
  description: string;
  action: string;
  status: string;
  create_time: string;
}
