import { animate, style, transition, trigger } from '@angular/animations';

/**
 * Alias 'void => *' is ':enter'
 * Alias '* => void' is ':leave'
 */
export const FADE_ROUTER = trigger(
  'fadeRouter', [
  // route 'enter and leave (<=>)' transition
  transition('*<=>*', [
    // css styles at start of transition
    style({ opacity: 0 }),

    // animation and styles at end of transition
    animate(300, style({ opacity: 1 }))
  ]),
]);
