<div class="row mb-3">
  <div class="col-12 mb-3 text-center" *ngIf="!accountBankItems.length">
    <span class="opacity-75">{{'NO_WITHDRAWAL_BANK_ACCOUNT_LIST'|translate}}</span>
  </div>
  <div class="col-12 mb-2" *ngFor="let item of accountBankItems;index as i; trackBy: trackAccBankId">
    <label class="card pointer" for="bank{{item.Id}}">
      <div class="card-body p-3">
        <div class="d-flex align-items-center">
          <div class="pe-2">
            <input type="radio" class="form-check-input" id="bank{{item.Id}}" [(ngModel)]="bankId" [value]="item.Id"
              style="pointer-events: none;" (change)="onSelectBank(item)">
          </div>
          <div class="flex-grow-1 d-flex align-items-center h-100 pointer">
            <img [src]="item.bank_code | bankImg: 'bank_code'" alt="bank-logo" [appTooltip]="item.bank_name"
              width="30px" height="30px">
            <div class="flex-grow-1 px-2">
              <div class="opacity-75 border-bottom">{{item.accout_number}}</div>
              <div class="">{{item.name}}</div>
            </div>
            <div class="border-start text-center px-2 h-100">
              <div class="opacity-75 small text-success">{{'BALANCE'|translate}}</div>
              <div class="">{{(item.balance || 0) | number: '0.2-2'}}</div>
            </div>
          </div>
        </div>
      </div>
    </label>
  </div>
</div>
