import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@ngneat/reactive-forms';
import { Subscription } from 'rxjs';
import { AgentForm } from 'src/app/utils/interfaces/create-agent-form.interface';

@Component({
  selector: 'app-setting-amb-sport',
  templateUrl: './setting-amb-sport.component.html',
  styleUrls: ['../../setting-agent.scss'],
})
export class SettingAmbSportComponent implements OnInit, OnChanges, OnDestroy {
  @Input() form: FormGroup<AgentForm>

  formValue: AgentForm;
  Subscription = new Subscription();
  constructor() { }

  ngOnInit(): void {
    if (this.form) {
      const limit_amb_form$ = this.form.get('limit_setting_amb_form').valueChanges.subscribe(res => {
        this.formValue = this.form.getRawValue();
      });
      this.Subscription.add(limit_amb_form$);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy(): void {
    this.Subscription.unsubscribe();
  }

}
