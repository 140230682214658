import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { Tooltip } from 'bootstrap';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective implements OnInit, OnChanges, OnDestroy {
  @Input() appTooltip = '';
  @Input() title = '';
  @Input() placement: 'auto' | 'top' | 'bottom' | 'left' | 'right' = 'top';

  tooltip: Tooltip | undefined;
  constructor(
    private ngEl: ElementRef<HTMLElement>,
    private ngRenderer: Renderer2,
  ) { }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.tooltip) {
      this.tooltip?.dispose();
      this.tooltip = undefined;
    }
    this.setupTooltip();
  }
  ngOnDestroy(): void {
    this.tooltip?.dispose();
  }

  setupTooltip() {
    this.ngRenderer.setAttribute(this.ngEl?.nativeElement, 'data-bs-toggle', 'tooltip');
    this.ngRenderer.setAttribute(this.ngEl?.nativeElement, 'title', this.title || this.appTooltip);
    this.tooltip = new Tooltip(this.ngEl.nativeElement, {
      trigger: 'hover',
      placement: this.placement || 'top',
    });
  }
}
