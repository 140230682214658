import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpApiService } from '../services/http-api.service';
import { PageModel } from '../utils/interfaces/page-model';
import { Pagination } from '../utils/interfaces/pagination';
import { IAgent, IAgentDetail } from './interfaces/agent.interface';

@Injectable({
  providedIn: 'root',
})
export class AgentService {
  agentTypes$ = new BehaviorSubject<AgentType[]>([]);
  updateAgentTypes$ = new Subject<any>();
  agentTypes: AgentType[] = [];

  constructor(
    private http: HttpApiService,
    private ngFb: FormBuilder,
  ) { }

  getType() {
    return this.http.post<AgentType[]>(`api/ask4bet/agent/get/type`, {});
  }

  setType(data: AgentType[]) {
    this.agentTypes$.next(data);
  }

  getSharedholder(params: PageModel) {
    return this.http.get<Pagination<Agent[]>>(
      `api/ask4bet/agent/get/shareholder`,
      { params }
    );
  }

  /**
   * get Agent Downline
   * @param params PageModel, ?typeAgentId={typeAgentId}order_by=prefix&sort=asc
   * @returns Obserable
   */
  getAgentDownline(params: PageModel) {
    return this.http.get<Pagination<Agent[]>>(`api/ask4bet/agent/get/downline`, { params });
  }

  getAgentById(ask4betAgentId: number) {
    return this.http.get<Agent>(`api/ask4bet/agent/get/by_ask4betAgentId?ask4betAgentId=${ask4betAgentId}`);
  }

  postSharedholder(model: AgentModel) {
    return this.http.post(`api/ask4bet/agent/create/shareholder`, model);
  }

  postDownline(model: AgentModel) {
    return this.http.post(`api/ask4bet/agent/create/downline`, model);
  }

  getAll(params: PageModel = {}) {
    return this.http.get<Pagination<Agent[]>>(`api/ask4bet/agent/get/all`, { params });
  }

  getSelf() {
    return this.http.get<Agent>(`api/ask4bet/agent/get/self`);
  }

  // ---------------------------------------------------------------
  createAgent(form: any) { // IAgent.IFormCreate
    return this.http.post(`api/ask4bet/agent/create`, form);
  }
  updateAgent(form: any, agentAsk4betId: number) { // IAgent.IFormCreate
    return this.http.put(`api/ask4bet/agent/edit?agentAsk4betId=${agentAsk4betId}`, form);
  }
  getShareSettingAgentById(ask4betAgentId: number) {
    return this.http.get<IAgentDetail.Detail>(`api/ask4bet/agent/get/shareSettingAsk4betAgent?ask4betAgentId=${ask4betAgentId}`);
  }
  getShareSettingAMB(form: IAgent.AskmebetSetting) {
    return this.http.post(`api/ask4bet/agent/get/shareSettingAMB`, form);
  }
  getShareSettingKlotto(form: any) { // IAgent.ShareSettingKlotto
    return this.http.post(`api/ask4bet/agent/get/shareSettingKlotto`, form);
  }
  /**
   * Get Limit Setting Template
   * @param params api/ask4bet/limit_setting/templete?agent_id={agent_id}&member_id={member_id}
   * @returns Obs
   */
  getLimitSettingTemplate(params: any) {
    return this.http.get(`api/ask4bet/limit_setting/templete`, { params });
  }
  getModelConfigAdmin() {
    return this.http.get(`api/ask4bet/agent_config/get/model_config_admin`);
  }
  getModelConfig() {
    return this.http.get(`api/ask4bet/agent_config/get/model_config`);
  }
  getByProviderName(providerName: string) {
    return this.http.get<IAgent.IProvider[]>(`api/ask4bet/provider_game/get_by_provider_name?provider_name=${providerName}`);
  }
  createGroupGame(): FormGroup<IAgent.ShareSetting> {
    return this.ngFb.group({
      game_id: [null],
      game_name: [''],
      percent_own: [0],
      percent_max: [0],
      percent_board: [0],
    });
  }
  putAgentDownlinePassword(id: number, model: any) {
    return this.http.post(`api/ask4bet/admin/${id}/reset_password`, model);
  }

  postModifyCredit(agentId: number, model: ModifyCreditModel) {
    return this.http.post(`api/ask4bet/agent/modify_credit?agent_id=${agentId}`, model);
  }

  putAgentStatusActive(agentAsk4betId: number) {
    return this.http.put(`api/ask4bet/agent/status/active?agentAsk4betId=${agentAsk4betId}`, {});
  }
  putAgentStatusInactive(agentAsk4betId: number) {
    return this.http.put(`api/ask4bet/agent/status/inactive?agentAsk4betId=${agentAsk4betId}`, {});
  }
  deleteAgent(agentAsk4betId: number) {
    return this.http.delete(`api/ask4bet/agent/status/remove?agentAsk4betId=${agentAsk4betId}`);
  }

  refreshAMB(agentId: number) {
    return this.http.get(`api/ask4bet/agent/refresh/amb?agent_id=${agentId}`);
  }
  refreshKlotto(agentId: number) {
    return this.http.get(`api/ask4bet/agent/refresh/klotto?agent_id=${agentId}`);
  }
  generateKey(agentId: number) {
    return this.http.get(`api/ask4bet/agent/${agentId}/issue_secret`);
  }
  getMaintanace(agentId: number) {
    return this.http.get(`api/ask4bet/game/maintenance_time?agent_id=${agentId}`);
  }

  getAgentShareSettings(agentId: number) {
    return this.http.get(`api/ask4bet/agent/share_setting?agent_id=${agentId}`);
  }
}

export interface AgentType {
  typeId: number;
  typeName: AgentTypeKey;
  typeDetail: string;
}

export const AgentType = {
  shareholder: 'SHAREHOLDER',
  senior: 'SENIOR_AGENT',
  master_agent: 'MASTER_AGENT',
  agent: 'AGENT',
};

export const AgentTypeUpline = {
  shareholder: '',
  senior: 'shareholder',
  master_agent: 'senior',
  agent: 'master_agent',
};
export interface ImaintenanceTime {
  Id: number;
  agent_id: number;
  agentName: string;
  game: string;
  active: boolean;
  status: string;
  update_date: Date;
}
export type AgentTypeKey = keyof typeof AgentType;

export interface AgentModel {
  agentName: string;
  partnerName: string;
  password: string;
  typeAgent?: number; // use create downline
  credit_init: number; // use create downline
  agentAsk4BetConfig?: AgentAsk4BetConfigModel;
  agentAsk4betConfigByAdmin?: AgentConfigByAdminModel; // use create shareholder
  askmebetSetting?: AskmebetSettingModel; // use create shareholder
}

export interface AskmebetSettingModel {
  agent_name: string;
  password: string;
  key: string;
  hash: string;
  client: string;
  end_point: string;
  url_for_bypass: string;
}

export interface AgentAsk4BetConfigModel {
  deposit_on: boolean;
  deposit_min: number;
  deposit_decimal: boolean;
  withdraw_on: boolean;
  withdraw_min: number;
  withdraw_decimal: boolean;
  member_phone_number_duplicate: boolean;
  member_bank_account_duplicate: boolean;
  member_ipaddress_duplicate: boolean;
  turn_over: number;
}

export interface AgentConfigByAdminModel {
  loyalty_system_on: boolean;
  recommend_system_on: boolean;
  tournament_system_on: boolean;
  leaderboard_system_on: boolean;
}

export interface Agent {
  id: number;
  agentAMBId: number;
  AMB?: any;
  agentKlottoId: number;
  Klotto?: any;
  agentUfaId?: number;
  ufa?: any;
  prefix: string;
  agent_code: string;
  agentName: string;
  partnerName: string;
  countMember: number;
  MemberPostfix: number;
  typeAgent: number;
  typeName: string;
  typeDetail: string;
  credit_init: number;
  credit_remaining: number;
  credit_used: number;
  line_id?: string;
  phone_no?: string;
  telegram?: string;
  status: string;
  create_by: number;
  create_dt: string;
  create_name: string;
  update_by?: any;
  update_dt?: any;
  update_name?: any;
  upline: string;
  upline_agentAsk4bet: number;
  upline_agentName: string;
  upline_prefix: string;
  image: string;
  website: string;
  secret?: any;
  secret_issue_at?: any;
  secret_issue_by?: any;
  secret_issue_username?: any;
  line_login?: any;
  admin_main: AgentAdminMain;

  ambLoading?: boolean;
  klottoLoading?: boolean;
  [key: string]: any;
}

export interface AgentAdminMain {
  id: number;
  username: string;
  name: string;
}

export interface AgentAMB {
  agent_name: string;
  credit_init: number;
  credit_remaining: number;
  credit_used: number;
  currency: string;
}

export interface AgentKlotto {
  agent_name: string;
  credit_init: number;
  credit_remaining: number;
  credit_used: number;
}

export const allowAgents = {
  shareholder: [],
  senior: ['Shareholder'],
  master_agent: ['Shareholder', 'Senior'],
  agent: ['Shareholder', 'Senior', 'Master Agent'],
};

export interface ModifyCreditModel {
  type: ModifyCreditType;
  amount: number;
}

export type ModifyCreditType = 'add' | 'reduce';

export interface AgentLimitSetting {
  amb: Amb;
  klotto: Klotto;
}

interface Klotto {
  limit_setting: KlottoLimitSetting;
}

interface KlottoLimitSetting {
  Id: number;
  THAI_3_TOP_maxBet: number;
  THAI_3_BOTTOM_maxBet: number;
  THAI_3_FRONT_maxBet: number;
  THAI_3_ROLL_maxBet: number;
  THAI_2_TOP_maxBet: number;
  THAI_2_BOTTOM_maxBet: number;
  THAI_1_TOP_maxBet: number;
  THAI_1_BOTTOM_maxBet: number;
  THAIBANK_3_TOP_maxBet: number;
  THAIBANK_3_BOTTOM_maxBet: number;
  THAIBANK_3_FRONT_maxBet: number;
  THAIBANK_3_ROLL_maxBet: number;
  THAIBANK_2_TOP_maxBet: number;
  THAIBANK_2_BOTTOM_maxBet: number;
  THAIBANK_1_TOP_maxBet: number;
  THAIBANK_1_BOTTOM_maxBet: number;
  VNM_3_TOP_maxBet: number;
  VNM_3_BOTTOM_maxBet: number;
  VNM_3_ROLL_maxBet: number;
  VNM_2_BOTTOM_maxBet: number;
  VNM_2_TOP_maxBet: number;
  VNM_1_TOP_maxBet: number;
  VNM_1_BOTTOM_maxBet: number;
  LAO_3_TOP_maxBet: number;
  LAO_3_ROLL_maxBet: number;
  LAO_2_TOP_maxBet: number;
  LAO_2_BOTTOM_maxBet: number;
  LAO_1_TOP_maxBet: number;
  LAO_1_BOTTOM_maxBet: number;
  MYS_3_TOP_maxBet: number;
  MYS_3_ROLL_maxBet: number;
  MYS_2_TOP_maxBet: number;
  MYS_2_BOTTOM_maxBet: number;
  MYS_1_TOP_maxBet: number;
  MYS_1_BOTTOM_maxBet: number;
  STOCK_3_TOP_maxBet: number;
  STOCK_3_ROLL_maxBet: number;
  STOCK_2_TOP_maxBet: number;
  STOCK_2_BOTTOM_maxBet: number;
  STOCK_1_TOP_maxBet: number;
  STOCK_1_BOTTOM_maxBet: number;
  YEEKEE_3_TOP_maxBet: number;
  YEEKEE_3_ROLL_maxBet: number;
  YEEKEE_2_TOP_maxBet: number;
  YEEKEE_2_BOTTOM_maxBet: number;
  YEEKEE_1_TOP_maxBet: number;
  YEEKEE_1_BOTTOM_maxBet: number;
  update_dt: string;
  create_dt: string;
  member_id?: any;
  agent_id: number;
  update_by: number;
}

interface Amb {
  limit_setting: AMBLimitSetting;
  limit_setting_lotto: AMBLimitSettingLotto;
}

interface AMBLimitSetting {
  Id: number;
  casino_ag_limit: number;
  casino_ag_enable: boolean;
  casino_ab_limit: number;
  casino_ab_enable: boolean;
  casino_bg_limit: number;
  casino_bg_enable: boolean;
  casino_bt_limit: number;
  casino_bt_enable: boolean;
  casino_cr_limit: number;
  casino_cr_enable: boolean;
  casino_dg_limit: number;
  casino_dg_enable: boolean;
  casino_eb_limit: number;
  casino_eb_enable: boolean;
  casino_gc_limit: number;
  casino_gc_enable: boolean;
  casino_gd_limit: number;
  casino_gd_enable: boolean;
  casino_pm_limit: number;
  casino_pm_enable: boolean;
  casino_pt_limit: number;
  casino_pt_enable: boolean;
  casino_sa_limit: number;
  casino_sa_enable: boolean;
  casino_sexy_limit: number;
  casino_sexy_enable: boolean;
  game_enable: boolean;
  multi_amb_enable: boolean;
  poker_og_enable: boolean;
  sportsBook_enable: boolean;
  sportsBook_hdpOuOe_maxPerBet: number;
  sportsBook_hdpOuOe_maxPerMatch: number;
  sportsBook_oneTwoDoubleChance_maxPerBet: number;
  sportsBook_others_maxPerBet: number;
  sportsBook_outright_maxPerMatch: number;
  step_parlay_enable: boolean;
  step_parlay_maxBetPerDay: number;
  step_parlay_maxMatchPerBet: number;
  step_parlay_maxPayPerBill: number;
  step_parlay_maxPerBet: number;
  step_parlay_minMatchPerBet: number;
  step_parlay_minPerBet: number;
  step_enable: boolean;
  step_maxBetPerDay: number;
  step_maxMatchPerBet: number;
  step_maxPayPerBill: number;
  step_maxPerBet: number;
  step_minMatchPerBet: number;
  step_minPerBet: number;
  other_es_enable: boolean;
  other_hg_enable: boolean;
  other_tg_enable: boolean;
  other_m2_enable: boolean;
  other_m2_maxBetPerDay: number;
  other_m2_maxBetPerMatchHDP: number;
  other_m2_maxMatchPerBet: number;
  other_m2_maxPayPerBill: number;
  other_m2_maxPerBet: number;
  other_m2_maxPerBetHDP: number;
  other_m2_minMatchPerBet: number;
  other_m2_minPerBet: number;
  other_m2_minPerBetHDP: number;
  update_dt: string;
  create_dt: string;
  member_id?: any;
  agent_id: number;
  update_by: number;
  update_on_amb: boolean;
}

interface AMBLimitSettingLotto {
  Id: number;
  thai_enable: boolean;
  thai_cut_of_time: string;
  thai_1BOT_max: number;
  thai_1BOT_payout: number;
  thai_1BOT_discount: number;
  thai_1TOP_max: number;
  thai_1TOP_payout: number;
  thai_1TOP_discount: number;
  thai_2BOT_max: number;
  thai_2BOT_payout: number;
  thai_2BOT_discount: number;
  thai_2BOT_OE_max: number;
  thai_2BOT_OE_payout: number;
  thai_2BOT_OE_discount: number;
  thai_2BOT_OU_max: number;
  thai_2BOT_OU_payout: number;
  thai_2BOT_OU_discount: number;
  thai_2TOD_max: number;
  thai_2TOD_payout: number;
  thai_2TOD_discount: number;
  thai_2TOP_max: number;
  thai_2TOP_payout: number;
  thai_2TOP_discount: number;
  thai_2TOP_OE_max: number;
  thai_2TOP_OE_payout: number;
  thai_2TOP_OE_discount: number;
  thai_2TOP_OU_max: number;
  thai_2TOP_OU_payout: number;
  thai_2TOP_OU_discount: number;
  thai_3BOT_max: number;
  thai_3BOT_payout: number;
  thai_3BOT_discount: number;
  thai_3TOD_max: number;
  thai_3TOD_payout: number;
  thai_3TOD_discount: number;
  thai_3TOP_max: number;
  thai_3TOP_payout: number;
  thai_3TOP_discount: number;
  thai_3TOP_OE_max: number;
  thai_3TOP_OE_payout: number;
  thai_3TOP_OE_discount: number;
  thai_3TOP_OU_max: number;
  thai_3TOP_OU_payout: number;
  thai_3TOP_OU_discount: number;
  thai_4TOD_max: number;
  thai_4TOD_payout: number;
  thai_4TOD_discount: number;
  thai_4TOP_max: number;
  thai_4TOP_payout: number;
  thai_4TOP_discount: number;
  thai_5TOP_max: number;
  thai_5TOP_payout: number;
  thai_5TOP_discount: number;
  thai_6TOP_max: number;
  thai_6TOP_payout: number;
  thai_6TOP_discount: number;
  pp_enable: boolean;
  pp_cut_of_time: string;
  pp_1BOT_max: number;
  pp_1BOT_payout: number;
  pp_1BOT_discount: number;
  pp_1TOP_max: number;
  pp_1TOP_payout: number;
  pp_1TOP_discount: number;
  pp_2BOT_max: number;
  pp_2BOT_payout: number;
  pp_2BOT_discount: number;
  pp_2BOT_OE_max: number;
  pp_2BOT_OE_payout: number;
  pp_2BOT_OE_discount: number;
  pp_2BOT_OU_max: number;
  pp_2BOT_OU_payout: number;
  pp_2BOT_OU_discount: number;
  pp_2TOD_max: number;
  pp_2TOD_payout: number;
  pp_2TOD_discount: number;
  pp_2TOP_max: number;
  pp_2TOP_payout: number;
  pp_2TOP_discount: number;
  pp_2TOP_OE_max: number;
  pp_2TOP_OE_payout: number;
  pp_2TOP_OE_discount: number;
  pp_2TOP_OU_max: number;
  pp_2TOP_OU_payout: number;
  pp_2TOP_OU_discount: number;
  pp_3BOT_max: number;
  pp_3BOT_payout: number;
  pp_3BOT_discount: number;
  pp_3TOD_max: number;
  pp_3TOD_payout: number;
  pp_3TOD_discount: number;
  pp_3TOP_max: number;
  pp_3TOP_payout: number;
  pp_3TOP_discount: number;
  pp_3TOP_OE_max: number;
  pp_3TOP_OE_payout: number;
  pp_3TOP_OE_discount: number;
  pp_3TOP_OU_max: number;
  pp_3TOP_OU_payout: number;
  pp_3TOP_OU_discount: number;
  pp_4TOD_max: number;
  pp_4TOD_payout: number;
  pp_4TOD_discount: number;
  pp_4TOP_max: number;
  pp_4TOP_payout: number;
  pp_4TOP_discount: number;
  pp_5TOP_max: number;
  pp_5TOP_payout: number;
  pp_5TOP_discount: number;
  pp_6TOP_max: number;
  pp_6TOP_payout: number;
  pp_6TOP_discount: number;
  lao_enable: boolean;
  lao_cut_of_time: string;
  lao_L_2FB_max: number;
  lao_L_2FB_payout: number;
  lao_L_2FB_discount: number;
  lao_L_3TOD_max: number;
  lao_L_3TOD_payout: number;
  lao_L_3TOD_discount: number;
  lao_L_3TOP_max: number;
  lao_L_3TOP_payout: number;
  lao_L_3TOP_discount: number;
  lao_L_4TOD_max: number;
  lao_L_4TOD_payout: number;
  lao_L_4TOD_discount: number;
  lao_L_4TOP_max: number;
  lao_L_4TOP_payout: number;
  lao_L_4TOP_discount: number;
  lao_T_1BOT_max: number;
  lao_T_1BOT_payout: number;
  lao_T_1BOT_discount: number;
  lao_T_1TOP_max: number;
  lao_T_1TOP_payout: number;
  lao_T_1TOP_discount: number;
  lao_T_2BOT_max: number;
  lao_T_2BOT_payout: number;
  lao_T_2BOT_discount: number;
  lao_T_2TOD_max: number;
  lao_T_2TOD_payout: number;
  lao_T_2TOD_discount: number;
  lao_T_2TOP_max: number;
  lao_T_2TOP_payout: number;
  lao_T_2TOP_discount: number;
  lao_T_3TOD_max: number;
  lao_T_3TOD_payout: number;
  lao_T_3TOD_discount: number;
  lao_T_3TOP_max: number;
  lao_T_3TOP_payout: number;
  lao_T_3TOP_discount: number;
  lao_T_4TOD_max: number;
  lao_T_4TOD_payout: number;
  lao_T_4TOD_discount: number;
  lao_T_4TOP_max: number;
  lao_T_4TOP_payout: number;
  lao_T_4TOP_discount: number;
  update_dt: string;
  create_dt: string;
  member_id?: any;
  agent_id: number;
  update_by: number;
  update_on_amb: boolean;
}

export interface AgentShareSetting {
  id: number;
  agentAsk4betId: number;
  game_id: number;
  percent_own: number;
  percent_parent: number;
  agentName: string;
  partnerName: string;
  provider_id: number;
  game_type: string;
  game_name: string;
  provider_name: string;
}
