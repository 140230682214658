import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { User } from '../apis/user.service';
import { AppPath } from '../utils/constants';
import { LocalName, LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private svLocalStorage: LocalStorageService,
    private ngRouter: Router,
  ) { }

  logout() {
    this.deleteToken();
    this.deleteUser();
    // this.svLocalStorage.clear();
    this.ngRouter.navigate(['', AppPath.login]);
  }

  get getToken() {
    return this.svLocalStorage.get(LocalName.Token);
  }
  setToken(token: string) {
    this.svLocalStorage.set(LocalName.Token, token);
  }
  deleteToken() {
    this.svLocalStorage.delete(LocalName.Token);
  }

  get getUser(): TokenUser {
    return this.svLocalStorage.get(LocalName.User);
  }
  setUser(user: TokenUser) {
    this.svLocalStorage.set(LocalName.User, user);
  }
  deleteUser() {
    this.svLocalStorage.delete(LocalName.User);
  }

  get getRemember() {
    return this.svLocalStorage.get(LocalName.Remember);
  }
  setRemember(username: string) {
    this.svLocalStorage.set(LocalName.Remember, username);
  }
  deleteRemember() {
    this.svLocalStorage.delete(LocalName.Remember);
  }

  get checkAuth() {
    const token = this.svLocalStorage.get(LocalName.Token);
    if (!token) { return false; }
    const decodedToken = jwtDecode<DecodeToken>(token);
    this.setUser(decodedToken.Auth);

    if (Date.now() > decodedToken.exp * 1000) { return false; }
    return true;
  }
}

export interface DecodeToken {
  Auth: TokenUser;
  exp: number;
}

export interface TokenUser {
  AgentType?: string;
  Ask4betAgent?: number;
  Fullname: string;
  Id: number;
  Role: string[];
  Username: string;
}
