import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpApiService } from '../services/http-api.service';
import { noWhitespaceValidator } from '../utils/functions/no-whitespace';
import { PageModel } from '../utils/interfaces/page-model';
import { Pagination } from '../utils/interfaces/pagination';
import { Member } from './member.service';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  billing$ = new BehaviorSubject<any>(null);
  genenateBill$ = new Subject<boolean>();

  constructor(
    private http: HttpApiService,
    private ngFb: FormBuilder,
  ) { }


  /**
   * Get Win Lose Self
   * @param params PageModel | ?from={from}&to={to}&agent_id={agent_id}
   * @returns Observable
   */
  getWinLoseSelf(params: PageModel) {
    return this.http.get<Pagination<ReportWinLoseAgent[]>>(`api/ask4bet/winlose/report/share/agent/self`, { params });
  }

  /**
   * Get Win Lose Self Old
   * @param params PageModel | ?from={from}&to={to}&agent_id={agent_id}
   * @returns Observable
   */
  getWinLoseSelfOld(params: PageModel) {
    return this.http.get<Pagination<ReportWinLoseAgent[]>>(`api/ask4bet/winlose/report/old/winlose/share/agent/self`, { params });
  }

  /**
   * Get Win Lose Self
   * @param params PageModel | ?agent_id={agent_id}&from={from}&to={to}&order_by={order_by}
   * @returns Observable
   */
  getWinLoseSelfStable(params: PageModel) {
    return this.http.get<Pagination<ReportWinLoseAgent[]>>(`api/ask4bet/winlose/winlose_yesterday/share/agent/self`, { params });
  }

  /**
   * Get Reports Win/Lose Member
   * @param params PageModel | ?from={from}&to={to}&username={username}&agent_id={agent_id}
   * @returns Observable
   */
  getWinLoseMember(params: PageModel) {
    return this.http.get<Pagination<ReportWinLoseMember[]>>(
      `api/ask4bet/winlose/report/share/member`,
      { params }
    );
  }

  /**
   * Get Reports Win/Lose Member
   * @param params PageModel | ?from={from}&to={to}&username={username}&agent_id={agent_id}
   * @returns Observable
   */
  getWinLoseMemberOld(params: PageModel) {
    return this.http.get<Pagination<ReportWinLoseMember[]>>(
      `api/ask4bet/winlose/report/old/winlose/share/member`,
      { params }
    );
  }

  /**
   * Get Reports Stable Win/Lose Member
   * @param params PageModel | ?from={from}&to={to}&agent_id={agent_id}
   * @returns Observable
   */
  getWinLoseMemberStable(params: PageModel) {
    return this.http.get<Pagination<ReportWinLoseMember[]>>(
      `api/ask4bet/winlose/winlose_yesterday/share/member`,
      { params }
    );
  }

  /**
   * Get Win Lose Share Game
   * @param params PageModel | ?from={from}&to={to}&agent_id={agent_id}
   * @returns Observable
   */
  getShareGame(params: PageModel) {
    return this.http.get<ReportWinLoseGame[]>(
      `api/ask4bet/winlose/report/share/game`,
      { params }
    );
  }
  /**
   * Get Stable Win Lose Share Game
   * @param params PageModel | ?from={from}&to={to}&agent_id={agent_id}
   * @returns Observable
   */
  getShareGameStable(params: PageModel) {
    return this.http.get<Pagination<ReportWinLoseGame[]>>(
      `api/ask4bet/winlose/winlose_yesterday/share/game`,
      { params }
    );
  }

  /**
   * Get Win Lose Share Agent
   * @param params PageModel | ?from={from}&to={to}&agent_id={agent_id}&order_by={order_by}
   * @returns Observable
   */
  getShareAgent(params: PageModel) {
    return this.http.get<Pagination<ReportWinLoseAgent[]>>(`api/ask4bet/winlose/report/share/agent`, { params });
  }

  /**
   * Get Win Lose Share Agent Old
   * @param params PageModel | ?from={from}&to={to}&agent_id={agent_id}&order_by={order_by}
   * @returns Observable
   */
  getShareAgentOld(params: PageModel) {
    return this.http.get<Pagination<ReportWinLoseAgent[]>>(`api/ask4bet/winlose/report/old/winlose/share/agent`, { params });
  }

  /**
   * Get Stable Win Lose Share Agent
   * @param params PageModel | ?from={from}&to={to}&agent_id={agent_id}
   * @returns Observable
   */
  getShareAgentStable(params: PageModel) {
    return this.http.get<Pagination<ReportWinLoseAgent[]>>(`api/ask4bet/winlose/winlose_yesterday/share/agent`, { params });
  }

  /**
   * Get Win Lose Share Agent
   * @param params PageModel | ?from={from}&to={to}&agent_id={agent_id}
   * @returns Observable
   */
  getReportProfit(params: PageModel) {
    return this.http.get(`api/ask4bet/report/profit`, { params });
  }
  getReportProfitUpline(params: PageModel) {
    return this.http.get(`api/ask4bet/report/profit/pay_lineup`, { params });
  }

  /**
   * Get summary of deposit/withdraw of members
   * @param params page={page}&limit={limit}&from={from}&to={to}&agent_id={agent_id}&name={name}&filter={filter}
   * @returns
   */
  getReportTransferMembers(params: PageModel) {
    return this.http.get<IReportTransferMembers>(
      `api/ask4bet/report/transfer_members`,
      { params }
    );
  }

  getReportTransferAccounts(params: PageModel) {
    return this.http.get(`api/ask4bet/report/transfer_accounts`, { params });
  }

  getReportCreditAgent(params: PageModel) {
    return this.http.get(`api/ask4bet/report/credit_agent`, { params });
  }

  getReportPromotion(params: PageModel) {
    return this.http.get(`api/ask4bet/report/report_promotion`, { params });
  }

  getReportPromotionCredit(params: PageModel) {
    return this.http.get(`api/ask4bet/report/promotion_from_credit`, {
      params,
    });
  }

  getReportMemberCount(params: PageModel) {
    return this.http.get(`api/ask4bet/report/member_count`, { params });
  }

  getReportWebAccess(params: PageModel) {
    return this.http.get<IReportWebAccess[]>(`api/ask4bet/report/report_get_to_know`, { params });
  }

  getReportReturnCredit(params: PageModel) {
    return this.http.get<Pagination<IReportReturnCredit[]>>(`api/ask4bet/report/revert_credit_history`, { params });
  }

  getReportMemberCountDeposit(params: PageModel) {
    return this.http.get<Pagination<Member[]>>(`api/ask4bet/report/member_deposit`, { params });
  }

  getReportFinanceBank(params: PageModel) {
    return this.http.get(`api/ask4bet/report/account_bank`, { params });
  }

  //#region Bill
  /**
   * Get Bill
   * @param params agent_id=${agent_id}
   * @returns Obs
   */
  getBill(params: PageModel) {
    return this.http.get<Pagination<ReportBill[]>>(`api/ask4bet/bill`, { params });
  }

  getBillById(bill_id: number) {
    return this.http.get<ReportBill>(`api/ask4bet/bill/${bill_id}`);
  }

  postBill(agent_id: number, bill_type: string, model: ReportBillModel) {
    return this.http.post(`api/ask4bet/bill?agent_id=${agent_id}&bill_type=${bill_type}`, model);
  }

  /**
   * Create Bill
   * @param params ?from={from}&to={to}&agent_id={agent_id}
   * @returns Obs
   */
  postBillByDate(params: any) {
    return this.http.post(`api/ask4bet/bill/create/winlose`, {}, { params });
  }

  putBill(bill_id: number, model: ReportBillModel) {
    return this.http.put(`api/ask4bet/bill?bill_id=${bill_id}`, model);
  }

  deleteBill(bill_id: number) {
    return this.http.delete(`api/ask4bet/bill?bill_id=${bill_id}`);
  }

  putBillPayment(bill_id: number, model: any) {
    return this.http.put(`api/ask4bet/bill/payment/update?bill_id=${bill_id}`, model);
  }

  putBillCancel(bill_id: number) {
    return this.http.delete(`api/ask4bet/bill/payment/cancel?bill_id=${bill_id}`, {});
  }

  get createFormBill(): FormGroup<ReportBillModel> {
    return this.ngFb.group({
      bill_name: ['', [noWhitespaceValidator]],
      note: [''],
      lists: this.ngFb.array([]),
      payment_date: [''],
      due_date: [''],
      bill_discount: [0],
      bill_discount_type: ['amount'],
      image: [''],

      billTotal: [0],
      billDiscountAmount: [0],
      billGrandTotal: [0],
    });
  }

  get createFormBillList(): FormGroup<ReportBillListModel> {
    return this.ngFb.group({
      list_name: ['', [noWhitespaceValidator]],
      winlose: [0, [Validators.required]],
      amount: [0, [Validators.required]],
      valid_amount: [0, [Validators.required]],
      type_discount: ['amount'],
      discount: [0],
      price: [0, [Validators.required]],
      note: [''],
      provider_game: ['amb'], // amb, klotto, ufa

      listDiscountAmount: [0],
      listTotal: [0],
    });
  }

  get createFromBillDate(): FormGroup {
    return this.ngFb.group({
      from: ['', [Validators.required]],
      to: ['', [Validators.required]],
      agent_id: ['', [Validators.required]],
    });
  }

  postBillImage(id: number, image_bill: string) {
    return this.http.post(`api/ask4bet/bill/image_bill?id=${id}`, { image_bill });
  }

  /**
   * POST create bill non stable
   * @param params ?from={from}&to={to}&agent_id={agent_id}
   * @returns Observable
   */
  createBillNonStable(params: any) {
    return this.http.post(`api/ask4bet/bill/create/winlose/non_stable`, {}, { params });
  }

  /**
   * get Config step
   * @returns obs
   */
  getBillConfigStep() {
    return this.http.get<ConfigStep[]>(`api/ask4bet/bill/config_step`);
  }

  /**
   * post Config step
   * @param model ConfigStep[]
   * @returns obs
   */
  postBillConfigStep(model: ConfigStep[]) {
    return this.http.post(`api/ask4bet/bill/config_step/edit`, model);
  }

  /**
   * Post Bill Step Stable
   * @param params ?from={from}&to={to}&agent_id={agent_id}
   * @returns Obs
   */
  postBillStepStable(params: any) {
    return this.http.post(`api/ask4bet/bill/create/winlose/step/stable`, {}, { params });
  }

  /**
   * Post Bill Step Non Stable
   * @param params ?from={from}&to={to}&agent_id={agent_id}
   * @returns Obs
   */
  postBillStepNonStable(params: any) {
    return this.http.post(`api/ask4bet/bill/create/winlose/step/non_stable`, {}, { params });
  }
  //#endregion

  //#region Commission
  /**
   * Get Report Commission
   * @param params ?from={from}&to={to}
   * @returns Obs
   */
  getCommission(params: any) {
    return this.http.get<Pagination<ReportCommission[]>>(`api/ask4bet/report/commission/system`, { params });
  }

  /**
   * Get Commission Games
   * @param params ?agent_id={agent_id}&from={from}&to={to}
   * @returns Obs
   */
  getCommissionGames(params: any) {
    return this.http.get<ReportCommissionGame[]>(`api/ask4bet/report/commission/system/games`, { params });
  }
  //#endregion

  /**
   * Get First Deposit Member
   * @param params ?agent_id={agent_id}&from={from}&to={to}
   * @returns Observalbe
   */
  getFirstDepositMember(params: any) {
    return this.http.get<Pagination<FistDepositMember[]>>(`api/ask4bet/report/first_deposit`, { params });
  }

  /**
   * Get Report Transaction member list
   * @param params ?from={from}&to={to}&agent_id={agent_id}&name={name}&filter={filter}
   * @returns Observeable
   */
  getTransactionMemberList(params: any) {
    return this.http.get<IReportTransferMember[]>(`api/ask4bet/report/transfer_member/list`, { params });
  }

  deleteWinLose(id: number) {
    return this.http.delete(`api/ask4bet/winlose/delete/winlose?id=${id}`);
  }
}

export interface ReportWinLoseMember {
  member_name: string;
  member_id: number;
  aff_username?: any;
  code_aff?: any;
  fullname: string;
  agent_name: string;
  partner_name: string;
  amount: number;
  valid_amount: number;
  ref_date_new?: any;
  member: WinLose;
  agent: WinLose;
  master_agent: WinLose;
  senior_agent: WinLose;
  shareholder: WinLose;
  company: WinLose;
}

export interface WinLose {
  winlose: number;
}

export interface ReportWinLoseGame {
  game_name: string;
  amount: number;
  valid_amount: number;
  member: WinLose;
  agent: WinLose;
  master_agent: WinLose;
  senior_agent: WinLose;
  shareholder: WinLose;
  company: WinLose;
}

export interface ReportWinLoseAgent {
  agent_id: number;
  agent_name: string;
  partner_name: string;
  amount: number;
  valid_amount: number;
  member: WinLose;
  agent: WinLose;
  master_agent: WinLose;
  senior_agent: WinLose;
  shareholder: WinLose;
  company: WinLose;
}

export interface IReportProfit {
  deposit: number;
  deposit_bonus: number;
  deposit_bonus_percent: number;
  withdraw: number;
  pay_line_up_sum: number;
  list: IReportProfitList[];
  withdraw_bonus: number;
  profit: number;
}
export interface IReportProfitList {
  date: Date;
  deposit: number;
  deposit_bonus: number;
  deposit_bonus_percent: number;
  withdraw: number;
  pay_line_up: number;
  withdraw_bonus: number;
  profit: number;
}
export interface IReportFinanceBank {
  account_number: string;
  account_name: string;
  deposit: number;
  withdraw: number;
  difference: number;
}
export interface IReportPromotion {
  promotion_id: number;
  promotion_name: string;
  total_bonus: number;
  sum_used: number;
}

export interface ItemReportPromotionCredit {
  Id: number;
  member_id: number;
  first_name: string;
  last_name: string;
  fullname: string;
  member_bank: string;
  member_bank_number: string;
  member_bank_code: string;
  phone: string;
  username: string;
  know_by_id?: number;
  know_by: string;
  promotion_id?: number;
  promotion_name: string;
  promotion_detail: string;
  promotion_type: string;
  promotion_code: string;
  max_bonus_amount?: number;
  max_bonus_amount_per_date?: number;
  max_use_per_date?: number;
  receive_now?: boolean;
  min_deposit_amount?: number;
  max_deposit_amount?: number;
  amount_can_be_withdraw?: number;
  again?: boolean;
  image: string;
  type_pro: string;
  agent: number;
  agent_name: string;
  account_bank_agent_id?: number;
  account_agent_username: string;
  account_agent_number: string;
  accout_agent_name: string;
  accout_agent_bank: string;
  account_agent_balance?: any;
  account_agent_bankcode: string;
  credit_before: number;
  amount: number;
  amount_bonus?: number;
  amount_total: number;
  credit_after: number;
  stack_other_pro?: boolean;
  reduce_credit_when_withdrawing?: boolean;
  member_id_recommend?: any;
  withdraw_then: boolean;
  note: string;
  datetime: Date;
  image_statement: string;
  deposit_statement_id?: number;
  statement_account_number: string;
  statement_amount?: number;
  statement_bank: string;
  create_date: Date;
  update_by?: number;
  update_username: string;
  update_name: string;
  update_date: Date;
  is_bonus: boolean;
  is_manual: boolean;
  turn_over: number;
  max_withdraw: number;
  can_withdraw: boolean;
  turn_over_sport: boolean;
  turn_over_casino: boolean;
  turn_over_poker: boolean;
  turn_over_slot: boolean;
  turn_over_keno: boolean;
  turn_over_lotto: boolean;
  turn_over_trading: boolean;
  deposit_type: string;
  status: string;
  check_by?: any;
  check_date?: any;
  ref_amb: string;
  promotion_new_member: boolean;
  get_to_know_id?: number;
  agent_id: number;
  agentName: string;
}

export interface IReportTransferMembers
  extends Pagination<IReportTransferMember[]> {
  count_deposit: number;
  count_withdraw: number;
  count_withdraw_bonus: number;
  total_deposit: number;
  total_withdraw: number;
  total_withdraw_bonus: number;
}

export interface IReportTransferMember {
  agent_id: number;
  agent_name: string;
  member_id: number;
  member_username: string;
  member_phone: string;
  count_deposit: number;
  count_withdraw: number;
  count_withdraw_bonus: number;
  total_deposit: number;
  total_withdraw: number;
  total_withdraw_bonus: number;
}

export interface IReportWebAccess {
  know_name: string;
  count_member: number;
  count_member_with_deposit: number;
  from_date: string;
  to_date: string;
}

export interface IReportReturnCredit {
  Id: number;
  member_id: number;
  username: string;
  first_name: string;
  last_name: string;
  phone: string;
  agent_id: number;
  agentName: string;
  revert_round_id: number;
  percent_revert: number;
  max_credit_revert: number;
  start_revert: string;
  end_revert: string;
  valid_amount: number;
  wl_amount: number;
  revert_credit: number;
  status: string;
  turn_over_slot: boolean;
  turn_over_poker: boolean;
  turn_over_casino: boolean;
  turn_over_sport: boolean;
  turn_over_keno: boolean;
  turn_over_lotto: boolean;
  turn_over_trading: boolean;
  create_date: string;
  update_date: string;
}

export interface ReportBillModel {
  bill_name: string;
  note: string;
  payment_date: string;
  due_date: string;
  bill_discount: number;
  bill_discount_type: DiscountType;
  image: string;
  lists: ReportBillListModel[];

  billTotal?: number;
  billDiscountAmount?: number;
  billGrandTotal?: number;
}

export interface ReportBillListModel {
  list_name: string;
  winlose: number;
  amount: number;
  valid_amount: number;
  price: number;
  discount: number;
  type_discount: DiscountType;
  note: string;
  provider_game: 'amb' | 'klotto' | 'ufa';

  listDiscountAmount?: number;
  listTotal?: number;
}

export interface ReportBill {
  Id: number;
  agent_id: number;
  agentName: string;
  bill_name: string;
  lists: ReportBillList[];
  bill_discount?: number;
  bill_discount_type?: DiscountType;
  bill_no: number;
  bill_type: string;
  total_price: number;
  note?: string;
  status: string;
  image?: string;
  payment_status: string;
  payment_date?: string;
  due_date?: any;
  create_by?: number;
  update_by?: number;
  update_date: string;
  create_date: string;
  image_bill: string;

  billTotal?: number;
  billDiscountAmount?: number;
  billGrandTotal?: number;
}

export interface ReportBillList {
  Id: number;
  bill_id: number;
  list_no?: string;
  list_name: string;
  winlose: number;
  amount: number;
  valid_amount: number;
  discount?: number;
  type_discount?: DiscountType;
  price: number;
  note: string;
  provider?: string;
  create_by?: number;
  update_by?: number;
  update_date: string;
  create_date: string;

  listDiscountAmount?: number;
  listTotal?: number;
}

export type DiscountType = 'amount' | 'percent';

export interface ReportCommission {
  agent_id: number;
  agent_name: string;
  winlose: number;
  agent: number;
  master: number;
  senior: number;
  shareholder: number;
  company: number;
  system: number;
  provider: number;
}

export interface ReportCommissionGame {
  game_id: number;
  game_name: string;
  winlose: number;
  agent: number;
  master: number;
  senior: number;
  shareholder: number;
  shareholder_percent: number;
  company: number;
  system: number;
  system_percent: number;
  provider: number;
  provider_percent: number;
}

export interface FistDepositMember {
  Id: number;
  member_id: number;
  username: string;
  first_name: string;
  last_name: string;
  agent_id: number;
  agent_name: string;
  bank_number: string;
  bank: string;
  bank_code: string;
  bank_name: string;
  bank_image: string;
  register_date: string;
  deposit_id: number;
  credit_before: number;
  amount: number;
  amount_bonus: number;
  amount_total: number;
  credit_after: number;
  promotion_id?: any;
  promotion_name?: any;
  datetime: string;
  status: string;
  know_by_id: number;
  know_by: string;
  update_date: string;
  create_date: string;
  recommender: string;
}

export interface ConfigStep {
  winlose: number;
  percent: number;
}
