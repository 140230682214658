import { Component, EventEmitter, forwardRef, OnDestroy, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '@ngneat/reactive-forms';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { Agent, AgentService } from 'src/app/apis/agent.service';
import { formPage } from 'src/app/utils/functions/form-page';

@Component({
  selector: 'app-admin-select-agent-form',
  templateUrl: './admin-select-agent-form.component.html',
  styleUrls: ['./admin-select-agent-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AdminSelectAgentFormComponent),
      multi: true
    }
  ]
})
export class AdminSelectAgentFormComponent implements ControlValueAccessor, OnDestroy {
  currentAgent: Agent = null;
  form = formPage({ limit: 100 });
  loading = false;

  allAgentItems$: Observable<Agent[]>;
  allAgentInput$ = new Subject<string>();

  setItems$ = new Subject();

  allAgentItem: Agent[] = [];
  @Output() agentChange = new EventEmitter();
  constructor(
    private svAgent: AgentService,
  ) {
    this.setItems$.pipe(
      debounceTime(500)
    ).subscribe(() => {
      this.setItem();
    });
  }

  ngOnDestroy(): void {
  }

  writeValue(value: any): void {
    this.currentAgent = value;
    this.setItems$.next();
  }
  onChange?: (value: any) => void;
  onTouched?: () => void;
  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  trackAgentByFn(item: Agent) {
    return item.id;
  }

  selectAgentChange() {
    this.agentChange.emit(this.currentAgent);
  }

  setItem() {
    let agentName = '';
    if (this.currentAgent) {
      agentName = this.currentAgent.agentName;
    }
    this.form.patchValue({ name: 'agentName', filter: agentName });
    this.svAgent.getAll(this.form.value).subscribe(res => {
      this.allAgentItems$ = concat(
        of(this.sortAgentItems(res?.items || []) as any[]),
        this.allAgentInput$.pipe(
          debounceTime(300),
          distinctUntilChanged(),
          tap(() => this.loading = true),
          switchMap(term => {
            this.form.patchValue({ filter: term });
            return this.svAgent.getAll(this.form.value).pipe(
              map(res2 => this.sortAgentItems(res2?.items || [])),
              catchError(() => of([])),
              tap(() => this.loading = false),
            );
          }),
        )
      );
    });

  }

  sortAgentItems(items: Agent[]) {
    return items.sort((a, b) => {
      const aName = a.agentName.toUpperCase();
      const bName = b.agentName.toUpperCase();
      if (aName < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }
      return 0;
    });
  }
}
