<div class="row animate__animated animate__fadeIn" *ngIf="MasterData">
  <div class="col-md-3 col-6">
    <app-doughnut-chart [MasterData]="MasterData"></app-doughnut-chart>
  </div>
  <div class="col-md-9 col-6 ps-4">
    <div class="total w-100">
      <div class="fs-2">
        <app-number-counter [numberCount]="MasterData?.value" [duration]="6"></app-number-counter>
      </div>
      <div class="fs-4">
        {{MasterData?.name}}
      </div>
    </div>
    <div class="row">
      <div class="col" *ngFor="let item of this.MasterData?.series;index as i">
        <div class="p-2 animate__animated animate__fadeInRight" [ngStyle]="{animationDelay:'0.'+i+'s'}">
          <div class="cborder-start-doughnut" [ngStyle]="{borderColor:item.color}">
            <div class="fs-4">
              <app-number-counter [numberCount]="item.value" [duration]="4"></app-number-counter>
            </div>
            <div class="fs-6">
              {{item.name}}
            </div>
          </div>
          <h4 class="ps-3 m-0">
            <app-number-counter [numberCount]="(item.value / sum) * 100" [duration]="2"></app-number-counter>%
          </h4>
        </div>
      </div>
    </div>
  </div>
</div>

<div class=" w-100" *ngIf="!MasterData">
  <div class="row">
    <div class="col-md-3 col-6">
      <div class="wrapper">
        <div class="item">
          <ngx-skeleton-loader [theme]="{
              width: '135px',
              height: '135px'
            }" appearance="circle"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="col-md-9 col-6 ps-4">
      <div class="total w-100">
        <ngx-skeleton-loader [theme]="{
              width: '150px',
              height: '20px'
            }"></ngx-skeleton-loader>
        <br>
        <ngx-skeleton-loader [theme]="{
              width: '100px',
              height: '15px'
            }"></ngx-skeleton-loader>
      </div>
      <div class="row">
        <div class="col-md-4" *ngFor="let data of [].constructor(3)">
          <ngx-skeleton-loader [theme]="{
              width: '100%',
              height: '20px'
            }"></ngx-skeleton-loader>
          <br>
          <ngx-skeleton-loader [theme]="{
              width: '80%',
              height: '10px'
            }"></ngx-skeleton-loader>
          <br>
          <ngx-skeleton-loader [theme]="{
              width: '20%',
              height: '10px'
            }"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</div>
