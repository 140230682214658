import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import * as dayjs from 'dayjs';
import { debounceTime, finalize } from 'rxjs/operators';
import { CreditModel, MemberCreditService } from 'src/app/apis/member-credit.service';
import { Promotion, PromotionMember, PromotionService } from 'src/app/apis/promotion.service';
import { UserService } from 'src/app/apis/user.service';
import { CurrencyService } from 'src/app/services/currency.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { alertConfirmDanger, alertSuccess, alertText, alertWarning } from 'src/app/utils/functions/alert';
import { fileToBase64, validateFiles } from 'src/app/utils/functions/base64';
import { focusInvalid } from 'src/app/utils/functions/focus-invalid';

@Component({
  selector: 'app-member-credit-form',
  templateUrl: './member-credit-form.component.html',
  styleUrls: ['./member-credit-form.component.scss']
})
export class MemberCreditFormComponent extends AppCore implements OnInit, OnChanges {
  @Input() isAdd: boolean;
  @Input() memberId: number;
  @Output() memberChange = new EventEmitter<number>();
  @Output() saveSuccess = new EventEmitter<boolean>();
  credit = 0;
  form = this.createForm();
  saving = false;

  promotionReqeusting: PromotionMember;
  promotion: Promotion;
  canceling = false;


  constructor(
    private ngFb: FormBuilder,
    private svUser: UserService,
    private svMemberCredit: MemberCreditService,
    private svPromotion: PromotionService,
    public svCurrency: CurrencyService
  ) {
    super();
  }

  agentIdChange(): void {
    this.form.patchValue({ promotion_id: null });
  }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.form = this.createForm();
    const formMemberIdChange$ = this.form.get('member_id').valueChanges.pipe(debounceTime(100)).subscribe(val => {
      this.memberChange.emit(val);
      if (this.isAdd) {
        this.getMemberPromotionRequest();
      }
    });

    this.Subscriptions.add(formMemberIdChange$);

    if (this.memberId) {
      this.form.patchValue({ member_id: this.memberId });
    }

    this.form.get('amountEx').valueChanges.pipe(debounceTime(100)).subscribe(() => {
    })
    this.form.get('amount').valueChanges.pipe(debounceTime(100)).subscribe(() => {
    })
  }
  onChangeTHB() {
    const amount = this.svCurrency.ExchangeANY2THB(this.form.get('amountEx').value);
    this.form.get('amount').setValue(amount);
  }
  onChangeANY() {
    const amountEx = this.svCurrency.ExchangeTHB2ANY(this.form.get('amount').value);
    this.form.get('amountEx').setValue(amountEx);
  }
  createForm(): FormGroup<CreditModel> {
    const nowDate = dayjs().format('YYYY-MM-DDTHH:mm');
    return this.ngFb.group({
      member_id: [null, [Validators.required]],
      amount: [0, [Validators.min(0.01)]],
      note: [''],
      promotion_id: [null],
      image_statement: [''],
      is_manual: [true],
      datetime: [nowDate, [Validators.required]],

      amountEx: [0],
      showAddPromotion: [false],
      showAddSlip: [true],
      showAddNote: [false],
      showAddTime: [true],
    });
  }
  onSetCredit(e: any) {
    this.credit = +(Number(e || 0).toFixed(2));
  }
  async onSubmit() {
    if (!this.form.value.member_id) {
      alertWarning({ message: `เลือกสมาชิก` });
      focusInvalid();
      return;
    }
    if (this.form.value.amount <= 0) {
      alertWarning({ message: `จำนวนเครดิตต้องมากกว่า 0` });
      focusInvalid();
      return;
    }
    if (!this.form.valid) {
      alertWarning({ message: alertText.formInvalid });
      focusInvalid();
      return;
    }

    if (!this.isAdd) {
      const swal = await alertConfirmDanger({
        html: `หากลูกอยู่ในเกมส์ ระบบจะทำการดึงเครดิตใน ขณะที่ลูกค้ากำลังเล่นเกมส์อยู่ <br> ต้องการทำรายการต่อ?`,
      });
      if (!swal.isConfirmed) { return; }
    }

    try {
      this.saving = true;
      if (this.isAdd) {
        await this.addCredit();
      } else {
        await this.reduceCredit();
      }
      alertSuccess({ message: `${this.isAdd ? 'ฝาก' : 'ถอน'}เครดิตสำเร็จ` });
      const member_id = this.form.value.member_id;
      this.form = this.createForm();
      this.form.patchValue({ member_id });
      this.saveSuccess.emit();

      this.svUser.updateUserProfile$.next();
    } catch (e) { }
  }

  addCredit() {
    const member_id = this.form.value.member_id;
    const model = { ...this.form.value };
    delete model.member_id;
    return this.svMemberCredit.postDeposit(member_id, model).pipe(finalize(() => this.saving = false)).toPromise();
  }

  reduceCredit() {
    const member_id = this.form.value.member_id;
    const model = { ...this.form.value };
    delete model.member_id;
    return this.svMemberCredit.postWithdraw(member_id, model).pipe(finalize(() => this.saving = false)).toPromise();
  }

  getMemberPromotionRequest() {
    if (!this.form.value.member_id) {
      return;
    }
    this.svPromotion.getRequesting(this.form.value.member_id, {}).subscribe(res => {
      this.promotionReqeusting = res?.items[0];
      this.promotion = null;

      if (this.promotionReqeusting) {
        this.getPromotion();
      }
    });
  }

  getPromotion() {
    this.svPromotion.get({ agent_id: this.currentAgentId, promotion_id: this.promotionReqeusting.promotion_id }).subscribe(res => {
      this.promotion = res?.items[0];
    });
  }

  async onCancelPromotionRequest() {
    const swal = await alertConfirmDanger({ html: `ต้องการยกเลิกโปรโมชัน <b>${this.promotionReqeusting.promotion_name}</b> ของสมาชิก <b>${this.promotionReqeusting.member_username}</b>?` });
    if (!swal.isConfirmed) { return; }

    this.canceling = true;
    this.svPromotion.putReject(this.promotionReqeusting.member_id).pipe(
      finalize(() => this.canceling = false)
    ).subscribe(res => {
      alertSuccess({ message: alertText.saveSuccess });
      this.getMemberPromotionRequest();
    });
  }

  async onSelectFile(event: Event) {
    const inpFileEl = event.target as HTMLInputElement;
    if (!inpFileEl.files.length) { return; }
    const validFile = await validateFiles(inpFileEl.files);
    if (!validFile) { return; }

    const file = inpFileEl.files[0];
    const base64 = await fileToBase64(file);
    this.form.get(`image_statement`).setValue(base64);
  }

  selectMemberChange() {
    this.memberChange.emit(this.form.value.member_id);
  }
}
