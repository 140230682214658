import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-iframe-print',
  templateUrl: './iframe-print.component.html',
  styleUrls: ['./iframe-print.component.scss']
})
export class IframePrintComponent implements OnInit, OnChanges, OnDestroy {
  @Input() iFrameUrl = '';
  @Input() iFrameHeight: any = 'auto';
  @Input() showLoading = true;
  trustUrl: SafeResourceUrl;
  loading = false;

  minHeight = '1100px';
  constructor(
    private ngSanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.iFrameUrl) {
      this.trustUrl = this.ngSanitizer.bypassSecurityTrustResourceUrl(this.iFrameUrl);
      this.loading = true;
      setTimeout(() => {
        this.iframeLoaded();
      }, 300);
    } else {
      this.trustUrl = null;
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
  }

  iframeLoaded() {
    const iFrameID = document.getElementById('iframe-print') as HTMLIFrameElement;
    if (iFrameID) {
      iFrameID.addEventListener('load', () => {
        iFrameID.height = "";
        if (this.iFrameHeight === 'auto') {
          iFrameID.height = `${iFrameID.contentWindow.document.body.scrollHeight}px`;
          this.minHeight = `1100px`;
        } else {
          iFrameID.style.height = `${this.iFrameHeight}px`;
          this.minHeight = `auto`;
        }
        this.loading = false;
      });
    }
  }

}
