import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@ngneat/reactive-forms';
import { MetaTagService, MetaTagPages, MetaTag } from 'src/app/apis/meta-tag.service';
import { alertSuccess, alertText, alertWarning } from 'src/app/utils/functions/alert';

@Component({
  selector: 'app-meta-tag-offcanvas',
  templateUrl: './meta-tag-offcanvas.component.html',
  styleUrls: ['./meta-tag-offcanvas.component.scss']
})
export class MetaTagOffcanvasComponent implements OnInit, OnChanges {
  agentId: number;
  form = this.svMetaTag.createForm;
  saving = false;

  PageKey = MetaTagPages;
  alertNoPage = false;

  @Input() updateItem: MetaTag;
  @Input() defaultAgentId: number;
  constructor(
    private svMetaTag: MetaTagService,
  ) { }

  ngOnInit(): void {
    const metaOffcanvasEl = document.getElementById(`metatagFormOffcanvas`);
    metaOffcanvasEl.addEventListener(`shown.bs.offcanvas`, () => {
      this.defaultAgentId ? this.agentId = this.defaultAgentId : this.agentId = null;
    });
    metaOffcanvasEl.addEventListener(`hidden.bs.offcanvas`, () => {
      setTimeout(() => {
        this.agentId = null;
        this.form = this.svMetaTag.createForm;
        this.updateItem = null;
      }, 1000);
    });
  }
  ngOnChanges() {
    if (this.updateItem) {
      this.form = this.svMetaTag.createForm;
      this.getFormArray(this.form, 'tags').clear();
      this.updateItem.tags.forEach(tag => {
        const tagGroup = this.svMetaTag.createTagForm;
        tagGroup.get('tag_key').disable();
        this.getFormArray(this.form, 'tags').push(tagGroup);
      });
      this.form.patchValue(this.updateItem);
    }
  }

  onSeletePage(key: string) {
    this.alertNoPage = false;
    this.form.get('tag_page').setValue(key);
  }

  onSubmit() {
    this.alertNoPage = false;
    this.form.markAllAsDirty();
    this.form.markAllAsTouched();

    if (!this.form.value.tag_page) {
      this.alertNoPage = true;
      alertWarning({ message: `กรุณาเลือก Page!!` });
      return;
    }
    if (!this.form.valid) {
      alertWarning({ message: alertText.formInvalid });
      return;
    }

    this.svMetaTag.post(this.agentId, this.form.getRawValue()).subscribe(res => {
      alertSuccess({ message: alertText.saveSuccess });

      const closeEl = document.getElementById('metatag-close');
      closeEl?.click();
    });
  }

  getFormArray(group: AbstractControl | FormGroup, name: string) {
    return group.get(name) as FormArray;
  }
}
