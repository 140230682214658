import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@ngneat/reactive-forms';
import { Member, MemberService } from 'src/app/apis/member.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { formPage } from 'src/app/utils/functions/form-page';
import { Pagination } from 'src/app/utils/interfaces/pagination';
import { finalize } from 'rxjs/operators';
import { ChatroomService } from 'src/app/services/chatroom.service';
import { ChatService, IChatRoom, IChatRoomAll } from 'src/app/apis/chat.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss']
})
export class ChatListComponent extends AppCore implements OnInit {
  tab: 'chat' | 'member' = 'chat';
  filterForm: FormGroup = formPage({ name: 'username', filter: '', status: '', ask4betAgentId: '' });
  memberItems: Pagination<Member[]>;
  ChatRoom: IChatRoomAll[];
  memberLoading = false;
  agentId;
  constructor(
    private svMember: MemberService,
    public svChartRoom: ChatroomService,
    private svChat: ChatService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
    this.svChartRoom.$UpdateChatRoom.subscribe((room: IChatRoomAll) => {
      if (room) {
        this.updateChatRoomLocal(room)
        // this.svChartRoom.SoundComeRoomMessage();
        setTimeout(() => {
          this.changeDetectorRef.detectChanges();
        }, 100);
      }
    })
  }

  ngOnInit() {
  }
  updateChatRoomLocal(room: IChatRoomAll) {
    var foundIndex = this.ChatRoom.findIndex(x => x.roomId == room.roomId);
    if (this.ChatRoom[foundIndex]) {
      this.ChatRoom[foundIndex] = room;
    } else {
      this.ChatRoom.push(room);
    }
    this.ChatRoom = _.sortBy(this.ChatRoom, c => c.room_update_dt).reverse();
  }
  agentIdChange() {
    if (!this.currentAgentId) {
      return;
    }
    this.agentId = this.currentAgentId;
    this.filterForm.patchValue({ ask4betAgentId: this.agentId || '' });
    this.onChangeTab();
  }
  onActiveMember(MemberId) {
    this.svChartRoom.ActiveMemberId.next(MemberId);
  }
  onChangeTab() {
    if (this.tab === 'chat') {
      this.getAllRoomChat();
    } else {
      this.getMembers();
    }
  }

  getMembers() {
    this.memberLoading = true;
    this.svMember.getMembers(this.filterForm.value).pipe(
      finalize(() => this.memberLoading = false)
    ).subscribe((res: any) => {
      this.memberItems = res;
    });
  }

  getAllRoomChat() {
    this.svChat.GetChatRoomAgent(this.agentId)
      .subscribe((e: IChatRoomAll[]) => {
        this.ChatRoom = e;
      });
  }
}
