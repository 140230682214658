import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AMBOptions, AMBSettingGame, SettingGameMembersService } from 'src/app/apis/setting-game-members.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { randomTxt } from 'src/app/utils/functions/random-txt';

@Component({
  selector: 'app-setting-m2-sport',
  templateUrl: './setting-m2-sport.component.html',
  styleUrls: ['../setting-games.scss'],
})
export class SettingM2SportComponent extends AppCore implements OnInit, OnChanges {
  @Input() setting: AMBSettingGame;
  @Input() readOnly = false;

  optionSelect: AMBOptions[] = [];
  takingOptions: AMBOptions[] = [];

  inputId = `m2sport-${randomTxt(5)}`;
  constructor(
    private svSettingGameMember: SettingGameMembersService,
  ) {
    super();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.setting) {
      const max = this.setting.group_init_member.maxSetting.limitSetting.other.m2.maxMatchPerBet;
      const min = this.setting.group_init_member.maxSetting.limitSetting.other.m2.minMatchPerBet;
      this.optionSelect = this.svSettingGameMember.genOptions(1, max, min, false);
      this.takingOptions = this.svSettingGameMember.genOptions(
        1,
        this.setting.group_init_member.maxSetting.shareSetting.other.maxM2,
        this.setting.group_init_member.maxSetting.shareSetting.other.minM2,
      );
    }
  }
}
