import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormBuilder } from '@ngneat/reactive-forms';
import { ChatService, IChatMessage, IChatRoom, IChatRoomAll, ICreateMessage } from 'src/app/apis/chat.service';
import { ChatroomService, IAdminOnline } from 'src/app/services/chatroom.service';
import { MemberService, Member } from 'src/app/apis/member.service';
import { UserProfile, UserService } from 'src/app/apis/user.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { v4 as uuidv4 } from 'uuid';
import { fileToBase64, validateFiles } from 'src/app/utils/functions/base64';
import Compressor from 'compressorjs';
import { alertSuccess } from 'src/app/utils/functions/alert';

@Component({
  selector: 'app-chat-room',
  templateUrl: './chat-room.component.html',
  styleUrls: ['./chat-room.component.scss']
})
export class ChatRoomComponent extends AppCore implements OnInit, OnChanges {
  image: string;
  member: Member;
  memberLoading = false;
  form: FormGroup;
  ChatRoom: IChatRoom;
  ChatMessage: IChatMessage[];
  adminOnline: IAdminOnline[];
  EditChatMessage: IChatMessage;
  reply: Reply;
  admin: UserProfile;
  memberId: number;
  emoji = "😀 😃 😄 😁 😆 😅 😂 🤣 😊 😇 🙂 🙃 😉 😌 😍 🥰 😘 😗 😙 😚 😋 😛 😝 😜 🤪 🤨 🧐 🤓 😎 🤩 🥳 😏 😒 😞 😔 😟 😕 🙁 ☹️ 😣 😖 😫 😩 🥺 😢 😭 😤 😠 😡 🤬 🤯 😳 🥵 🥶 😱 😨 😰 😥 😓 🤗 🤔 🤭 🤫 🤥 😶 😐 😑 😬 🙄 😯 😦 😧 😮 😲 🥱 😴 🤤 😪 😵 🤐 🥴 🤢 🤮 🤧 😷 🤒 🤕 🤑 🤠 😈 👿 👹 👺 🤡 💩 👻 💀 ☠️ 👽 👾 🤖 🎃 😺 😸 😹 😻 😼 😽 🙀 😿 😾"
  constructor(
    private ngBuild: FormBuilder,
    private svChat: ChatService,
    public svChatRoom: ChatroomService,
    private svMember: MemberService,
    private svUser: UserService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
    this.form = this.ngBuild.group({
      message: ['', [Validators.required]]
    });
    this.svChatRoom.ActiveMemberId.pipe().subscribe(e => {
      if (e) {
        if (this.memberId === e) {
          return;
        }
        this.memberId = e;
        this.GetMember(e);
      }
    })
    this.svChatRoom.$ResponseMessageToChatRoom.subscribe((e: IChatMessage) => {
      this.updateChatMessageLocal(e);
    });
    this.svChatRoom.$ResponseDeleteMessageToChatRoom.subscribe((e: number) => {
      this.deleteChatMessageLocal(e);
    });
    this.svChatRoom.$UpdateChatRoom.subscribe((e: IChatRoomAll) => {
      if (e.count_unread_admin === 0) {
        this.updateReadedChatMessageLocal(e.roomId);
      }
    });
    this.svChatRoom.$AdminOnline.subscribe((data: IAdminOnline[]) => {
      this.adminOnline = data;
      this.changeDetectorRef.detectChanges();
    });

    this.svApp.memberCreditClose$.subscribe(() => {
      this.GetMember(this.member.id);
    });
  }

  ngOnInit() {
    this.svUser.getUserProfile().subscribe((e: UserProfile) => {
      this.admin = e;
    });
  }
  ngOnChanges() {
  }
  agentIdChange() {
    if (!this.currentAgentId) {
      return;
    }
    this.svChatRoom.onGetAdminOnline(this.currentAgentId);
  }
  onReadMessage() {
    if (this.ChatRoom) {
      this.svChatRoom.onReadMessage(this.ChatRoom.roomId);
    }
  }
  updateReadedChatMessageLocal(roomId: number) {
    var found = this.ChatMessage.filter(x => x.roomId == roomId && x.adminId);
    found.forEach(c => {
      c.readed = true;
    })
    this.changeDetectorRef.detectChanges();
  }
  updateChatMessageLocal(message: IChatMessage) {
    var foundIndex = this.ChatMessage.findIndex(x => x.uuid == message.uuid);

    if (this.ChatMessage[foundIndex]) {
      this.ChatMessage[foundIndex] = message;
      this.changeDetectorRef.detectChanges();
      return;
    }
    this.ChatMessage.push(message);
    this.changeDetectorRef.detectChanges();
    setTimeout(() => {
      this.scrollBottom();
    }, 10);
  }
  deleteChatMessageLocal(messageId: number) {
    var foundIndex = this.ChatMessage.findIndex(x => x.id == messageId);
    if (this.ChatMessage[foundIndex]) {
      this.ChatMessage.splice(foundIndex, 1);
      this.changeDetectorRef.detectChanges();
      return;
    }
    setTimeout(() => {
      this.scrollBottom();
    }, 10);
  }

  GetRoomChat(memberId: number) {
    this.svChat.GetChatRoomMember(memberId)
      .subscribe((e) => {
        if (e) {
          this.svChatRoom.onHoldChatRoom(e);
          this.ChatRoom = e;
          this.GetChatToday(e.roomId);
        }
      });
  }
  async inputFileChange(event: Event) {
    const inputEl = event.target as HTMLInputElement;
    const files = inputEl.files;

    const fileValid = validateFiles(files);
    if (!fileValid) { return; }

    const compressSuccess = async (result: File | Blob) => {
      if (result instanceof File) {
        const base64 = await fileToBase64(files[0]);
        this.image = base64;
        inputEl.value = '';
      }
      if (result instanceof Blob) {
        const reader = new FileReader();
        reader.readAsDataURL(result);
        reader.onloadend = () => {
          this.image = reader.result as string;
          inputEl.value = '';
        }
      }
    }
    new Compressor(files[0], {
      quality: 0.6,
      success: compressSuccess,
      error(err) {
        console.log(`✨ ~ err:`, err);
      },
    });
  }

  onDeleteMessage(messageId: number) {
    this.svChatRoom.onDeleteMessage({
      messageId: messageId,
      roomName: this.ChatRoom.roomName
    });
  }
  onEditMessage(message: IChatMessage) {
    message.message = this.deurlify(message.message);
    this.EditChatMessage = message;
    this.EditChatMessage.roomName = this.ChatRoom.roomName;
    if (message.replyId) {
      this.reply = {
        replyId: message.replyId,
        replyTxt: message.replyMessage,
        replyType: 'text',
        replyAt: message.replyAt
      }
    }
    this.form.get('message').setValue(message.message);
  }
  onSendImage() {
    if (this.image) {
      var payload: ICreateMessage = {
        uuid: uuidv4(),
        message: this.image,
        roomId: this.ChatRoom.roomId,
        roomName: this.ChatRoom.roomName,
        type: 'image'
      }
      this.form.reset();
      this.image = '';
      this.ChatMessage.push({ ...payload } as IChatMessage);
      this.svChat.sendMessage(payload)
        .subscribe((e: any) => {
          this.updateChatMessageLocal(e);
        })
      setTimeout(() => {
        this.form.reset();
        this.scrollBottom();
      }, 100);
    }
  }
  onOnSubmitMessage() {
    if (this.form.valid && this.form.value.message.trim()) {
      var payload: ICreateMessage = this.EditChatMessage as ICreateMessage;
      payload.message = this.urlify(this.form.value.message);
      payload.replyId = this.reply?.replyId;
      payload.replyType = this.reply?.replyType;
      payload.replyAt = this.reply?.replyAt;
      payload.replyMessage = this.reply?.replyTxt;

      this.EditChatMessage = null;
      this.form.get('message').setValue('');
      this.changeDetectorRef.detectChanges();
      this.svChatRoom.onEditMessage(payload as any);
      this.reply = null;
      setTimeout(() => {
        this.scrollBottom();
      }, 10);
    }
  }


  GetMember(MemberId) {
    this.member = null;
    this.ChatRoom = null;
    this.ChatMessage = [];
    this.memberLoading = true;
    this.svMember.getMemberByIdNotUpdate(MemberId)
      .subscribe((res: any) => {
        this.member = res;
        this.GetRoomChat(MemberId);
        this.memberLoading = false;
      }, err => {
        this.memberLoading = false;
      });
  }
  GetChatToday(roomId: number) {
    this.svChat.GetChatToday(roomId)
      .subscribe((e: IChatMessage[]) => {
        this.ChatMessage = e;
        setTimeout(() => {
          this.scrollBottom();
        }, 10);
      });
  }
  CreateRoomChat() {
    this.svChat.CreateChatRoom(this.member.id)
      .subscribe((e) => {
        this.ChatRoom = e;
        this.svChatRoom.onHoldChatRoom(e);
      });
  }

  onReplyMessage(replay: IChatMessage) {
    this.reply = {
      replyType: replay.type,
      replyId: replay.id,
      replyTxt: replay.message,
      replyAt: replay.create_dt
    }
    this.changeDetectorRef.detectChanges();
  }
  onSendMessage() {
    if (this.form.valid && this.form.value.message?.trim()) {
      var payload: ICreateMessage = {
        uuid: uuidv4(),
        message: this.urlify(this.form.value.message),
        replyId: this.reply?.replyId,
        replyType: this.reply?.replyType,
        replyAt: this.reply?.replyAt,
        replyMessage: this.reply?.replyTxt,
        roomId: this.ChatRoom.roomId,
        type: 'text',
        roomName: this.ChatRoom.roomName,
      }
      this.form.reset();
      this.ChatMessage.push(payload as any);
      this.changeDetectorRef.detectChanges();
      this.svChatRoom.onSendMessage(payload);
      this.reply = null;
      setTimeout(() => {
        this.form.reset();
        this.scrollBottom();
      }, 10);
    }
  }
  onEnter(event: any) {
    if (event.keyCode === 13 && !event.shiftKey && !(this.form.value.message as string)?.trim()) {
      this.form.get('message').setValue('');
      return;
    }
    if (event.keyCode === 13 && !event.shiftKey) {
      if (this.EditChatMessage) {
        this.onOnSubmitMessage();
      } else {
        this.onSendMessage();
      }
    }
  }
  onResetForm(event: any) {
    if (event.keyCode === 13 && !event.shiftKey) {
      this.form.reset();
      return;
    }
  }
  onSubmit() {
    if (this.EditChatMessage) {
      this.onOnSubmitMessage();
    } else {
      this.onSendMessage();
    }
  }

  onRemoveReply() {
    this.reply = null;
  }

  scrollBottom() {
    var objDiv = document.getElementById("chat-body");
    objDiv.scrollTop = objDiv?.scrollHeight;
  }

  ChatMessageTrackBy(index, message: IChatMessage) {
    return message.uuid;
  }
  onEmoji(emoji: string) {
    var text = (this.form.value.message || '') + emoji;
    this.form.get('message').setValue(text);
  }

  openMemberCreditFormOffcanvas(is_add: boolean, member_id: number) {
    this.svApp.memberCreditFormOffcanvas$.next({ is_add, member_id });
  }

  async changeStatusMember(newStatus: string) {
    try {
      if (newStatus === 'confirmed') {
        await this.svMember.confirm(this.member.id).toPromise();
      } else if (newStatus === 'suspend') {
        await this.svMember.suspend(this.member.id).toPromise();
      } else {
        await this.svMember.lock(this.member.id).toPromise();
      }
      alertSuccess({ message: 'เปลี่ยนสถานะสมาชิกสำเร็จ' });
      this.GetMember(this.member.id);
    } catch (e) { }
  }

  openClearTurnoverMemberOffcanvas() {
    this.svApp.memberClearTurn$.next(this.member.id);
  }
  urlify(text: string) {
    var urlRegex = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g;
    return text.replace(urlRegex, function (url) {
      return '<a target="_blank" href="' + url + '">' + url + '</a>';
    })
  }
  deurlify(text: string): string {
    var urlRegex = /<a target="_blank" href="(https?:\/\/[^\s]+)<\/a>/g;
    return text.replace(urlRegex, function (url) {
      var urlR = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g;
      var find = url.match(urlR);
      if (find) {
        return find[0];
      }
      return url;
    })
  }
}


export interface Reply {
  replyId: number;
  replyType: string;
  replyTxt: string;
  replyAt: string;
}
