import { Component, OnInit } from '@angular/core';
import { CurrencyItem, CurrencyService, SelectCurrency } from 'src/app/services/currency.service';

@Component({
  selector: 'app-choose-currency-modal',
  templateUrl: './choose-currency-modal.component.html',
  styleUrls: ['./choose-currency-modal.component.scss']
})
export class ChooseCurrencyModalComponent implements OnInit {
  currencyItems = SelectCurrency;
  currentLang: CurrencyItem;

  constructor(
    public svCurrency: CurrencyService,
  ) {
    this.currentLang = svCurrency.getCurrency();
    this.svCurrency.onCurrencyChange.subscribe((e) => {
      this.currentLang = e;
    });
  }

  ngOnInit() {
  }
  close() {
    document.getElementById('close-currency')?.click();
  }
}
