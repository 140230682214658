<div class="offcanvas offcanvas-end" id="accOffCanvas" style="width: 700px;">
  <div class="offcanvas-header align-items-center">
    <h4 id="offcanvasRightLabel">{{'ANNOUNCEMENT'|translate}}</h4>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <div class="mb-3">
      <label for="" class="form-label">{{'AGENT'|translate}} </label>
      <ng-select [(ngModel)]="agent" [items]="agent$ | async" bindLabel="agentName" [typeahead]="inputAgent$"
        [trackByFn]="agentTrackBy" [typeToSearchText]="'TYPE_TO_SEARCH_USERNAME'|translate" [loading]="agentLoading"
        [placeholder]="'CHOOSE_AGENT'|translate">
      </ng-select>
    </div>
    <form [formGroup]="form" (submit)="onSubmit()">
      <div class="mb-3">
        <label for="" class="form-label">{{'IMAGE'|translate}}</label>
        <div class="text-center mb-3">
          <label for="inp-file" class="d-inline-block pointer border">
            <img *ngIf="!form.value.image" src="/assets/placeholder.jpg" alt="" width="300px" height="300px"
              style="object-fit: contain;">
            <ng-container *ngIf="form.value.image">
              <img *ngIf="form.value.localImage" [src]="form.value.image" alt="" width="300px" height="300px"
                style="object-fit: contain;">
              <img *ngIf="!form.value.localImage" [src]="imgHost + form.value.image" alt="" width="300px" height="300px"
                style="object-fit: contain;">
            </ng-container>
          </label>
        </div>
        <div class="text-center">
          <label for="inp-file" class="btn btn-outline-primary">
            <i class="bi bi-upload"></i> {{'SELECT_IMAGE_FILE'|translate}}
          </label>
          <input type="file" id="inp-file" class="d-none" accept=".jpg,.png,.jpeg" (change)="inputFileChange($event)">
        </div>
      </div>
      <div class="mb-3">
        <label for="" class="form-label">{{'TITLE'|translate}}</label>
        <input type="text" class="form-control" formControlName="title">
      </div>
      <div class="mb-3">
        <label for="" class="form-label">{{'MESSAGE'|translate}}</label>
        <textarea id="" rows="3" class="form-control" formControlName="text"></textarea>
      </div>
      <div class="mb-3">
        <label for="" class="form-label">{{'PRIORITY'|translate}} <small class="text-secondary"> {{'SMALLEST_NUMBER_WILL_BE_MOST_IMPORTANT'|translate}} </small> </label>
        <input type="number" class="form-control mb-1" formControlName="priority" min="0">
      </div>
      <div class="mb-3">
        <label for="" class="form-label">URL</label>
        <input type="text" class="form-control" formControlName="url">
      </div>
      <div class="mb-3">
        <label for="" class="form-label">{{'SHOW'|translate}}</label>
        <div class="form-check form-switch">
          <input id="status" type="checkbox" class="form-check-input" [checked]="form.value.status === 'active'"
            (change)="statusChange($event)">
          <label for="status" class="form-check-label">
            <span *ngIf="form.value.status=== 'active'">{{'SHOW'|translate}}</span>
            <span *ngIf="form.value.status!== 'active'">{{'NOT_SHOWING'|translate}}</span>
            <!-- {{form.value.status === 'active' ? 'แสดง':'ไม่แสดง'}} -->
          </label>
        </div>
      </div>
      <div class="text-end">
        <button type="submit" class="btn btn-primary"> {{'SAVE_DATA'|translate}} </button>
      </div>
    </form>
  </div>
</div>
