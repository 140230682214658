import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { HttpApiService } from '../services/http-api.service';
import { noWhitespaceValidator } from '../utils/functions/no-whitespace';
import { PageModel } from '../utils/interfaces/page-model';
import { Pagination } from '../utils/interfaces/pagination';

@Injectable({
  providedIn: 'root',
})
export class MemberService {
  private memberDetail$ = new BehaviorSubject<Member>(null);
  updateMemberDetail$ = new Subject<any>();

  constructor(
    private http: HttpApiService,
    private ngFb: FormBuilder,
  ) { }

  getMemberDetail() {
    return this.memberDetail$.pipe(skipWhile(val => !val));
  }

  setMemberDetail(data: Member) {
    return this.memberDetail$.next(data);
  }

  /**
   * get Members
   * @param params PageModel, ?ask4betAgentId={ask4betAgentId}&status={status}
   * @returns observable Pagination Members
   */
  getMembers(params: PageModel) {
    return this.http.get<Pagination<Member[]>>(`api/ask4bet/member/pagination`, { params }).pipe(
      map(res => {
        res.items = res.items.map(item => {
          item.wallet_total = item.wallet +
            (item.memberAskmebet?.credit || 0) +
            (item.memberGclub?.credit || 0) +
            (item.memberBethub?.credit || 0);
          return item;
        });
        return res;
      }),
    );
  }

  getMemberById(id: number) {
    return this.http.get<Member>(`api/ask4bet/member?member_id=${id}`);
  }
  getMemberByIdNotUpdate(id: number) {
    return this.http.get<Member>(`api/ask4bet/member/not-update?member_id=${id}`);
  }

  create(value, agentId: number) {
    return this.http.post(
      `api/ask4bet/member?agent_id=${agentId}&ip_address=`,
      value
    );
  }

  edit(value, memberId: number) {
    return this.http.put(
      `api/ask4bet/member/edit?member_id=${memberId}`,
      value
    );
  }

  /**
   * @param status member type
   * @param params with pageModel and member_id
   * @returns observer
   */
  putMemberStatus(status: MemberType, params: PageModel) {
    return this.http.put<Pagination<Member[]>>(`api/ask4bet/member/status/${status}`, {}, { params });
  }

  confirm(memberId: number) {
    return this.http.put(
      `api/ask4bet/member/status/confirmed?member_id=${memberId}`,
      {}
    );
  }

  suspend(memberId: number) {
    return this.http.put(
      `api/ask4bet/member/status/suspend?member_id=${memberId}`,
      {}
    );
  }

  lock(memberId: number) {
    return this.http.put(
      `api/ask4bet/member/status/lock?member_id=${memberId}`,
      {}
    );
  }

  /**
   * @param params member_id=${member_id}
   * @returns
   */
  getWinLose(params: PageModel) {
    return this.http.get<Pagination<MemberWinLose[]>>(`api/ask4bet/winlose/history/login_game`, { params });
  }

  getWithdrawTurnover(member_id: number) {
    return this.http.get<WithdrawTurnover>(`api/ask4bet/withdraw/before_withdraw?member_id=${member_id}`);
  }

  deleteMember(member_id: number) {
    return this.http.delete(`api/ask4bet/member/status/remove?member_id=${member_id}`);
  }

  returnCreditToMember(member_id: number) {
    return this.http.post(`api/ask4bet/withdraw/amb/manual?member_id=${member_id}`, {});
  }

  editWallet(member_id: number, wallet: number) {
    return this.http.put(`api/ask4bet/member/edit/wallet?member_id=${member_id}`, { wallet });
  }

  postResetPassword(model: MemberResetPasswordModel) {
    return this.http.post(`api/ask4bet/member/reset_password`, model);
  }

  get createMemberResetPassword(): FormGroup<MemberResetPasswordModel> {
    return this.ngFb.group({
      member_id: [null],
      new_password: ['', [noWhitespaceValidator]],
      confirm_password: ['', [noWhitespaceValidator]],
    });
  }

  putClearTurnover(member_id: number) {
    return this.http.put(`api/ask4bet/member/clear_turnover?member_id=${member_id}`, {})
  }

  putClearTurnDeposit(member_id: any, deposit_id: any) {
    return this.http.put(`api/ask4bet/member/clear_turnover/per_deposit?member_id=${member_id}&deposit_id=${deposit_id}`, {});
  }

  getStatusGameAMB(username: string): Observable<IRootGetStatusGameAMB> {
    return this.http.get(`api/ask4bet/amb/get/status_amb?username=${username}`);
  }
  ChangeStatusGameAMB(username: string, payload: IModelChangeStatus) {
    return this.http.put(`api/ask4bet/amb/get/chang_amb?username=${username}`, payload);
  }

  putClearTransactionTemp(member_id: number) {
    return this.http.put(`api/transaction/clear/transaction_temp?member_id=${member_id}`, {});
  }

  postUpdateWLMember(member_id: number) {
    return this.http.post(`api/ask4bet/winlose/update/winlose/fe?member_id=${member_id}&noalert`, {});
  }
  postUpdateWLMemberOld(member_id: number) {
    return this.http.post(`api/ask4bet/winlose/update/winlose/fe/old?member_id=${member_id}&noalert`, {});
  }
}

export interface IModelChangeStatus {
  suspend: boolean;
  lock: boolean;
  active: boolean;
}

export interface MemberModel {
  phone: string;
  password?: string;
  first_name: string;
  last_name: string;
  bank_number: string;
  bank: string;
  wallet: number;
  member_recommended_username: string;
  promotion_id: number;
  deposit_statement_id: number;
  datetime: string;
  image_statement: string;
  know_by_id: any;

  agent_id?: number;
}

export interface Member {
  id: number;
  username: string;
  phone: string;
  first_name: string;
  last_name: string;
  fullname: string;
  bank_number: string;
  bank: string;
  bank_code: string;
  bank_name: string;
  bank_image?: any;
  register_date: string;
  register_ip: string;
  register_device: string;
  login_ip: string;
  last_login: string;
  login_device: string;
  wallet: number;
  wallet_outstanding: number;
  wallet_total: number;
  deposit_amount: number;
  deposit_amount_bonus: number;
  deposit_sum: number;
  deposit_sum_bonus: number;
  withdraw_amount: number;
  withdraw_sum: number;
  recommend_total: number;
  recommend_point: number;
  recommend_transfer: number;
  recommend_downline_total: number;
  status: MemberType;
  create_by?: any;
  update_date: string;
  update_by?: any;
  know_by_id: number;
  know_by: string;
  ask4betAgentId: number;
  agentName: string;
  typeAgent: number;
  session_id: string;
  link_name?: any;
  code_aff?: any;
  link_affiliate_id?: any;
  user_aff_username?: any;
  user_affiliate_id?: any;
  current_game: string;
  memberAskmebet: MemberAskmebet;
  memberKlotto: MemberKlotto;
  memberGclub: MemberGclub;
  memberBethub: MemberBethub;
  loyalty: Loyalty;
  line?: any;

  first_deposit?: Firstdeposit;
}

interface Loyalty {
  memberId: number;
  point_alltime: number;
  point_current: number;
  update_by?: any;
  update_dt?: any;
}

interface MemberBethub {
  Id: number;
  credit: number;
  member_id: number;
  password: string;
  username: string;
}

interface MemberGclub {
  Id: number;
  member_id: number;
  gclub_setting_id: number;
  username: string;
  password: string;
  credit: number;
}

interface MemberKlotto {
  member_id: number;
  setting_id: number;
  klotto_member_id: string;
  username: string;
  password: string;
  credit: number;
}

interface MemberAskmebet {
  member_id: number;
  amb_member_id: string;
  setting_id: number;
  username: string;
  password: string;
  credit: number;
}

export interface Firstdeposit {
  Id: number;
  member_id: number;
  promotion_id?: number;
  agent: number;
  account_bank_agent_id?: number;
  credit_before: number;
  amount: number;
  amount_bonus: number;
  amount_total: number;
  credit_after: number;
  credit_agent_before: number;
  credit_agent_after: number;
  stack_other_pro?: boolean;
  reduce_credit_when_withdrawing?: boolean;
  winlose_amount?: any;
  member_id_recommend?: any;
  withdraw_then: boolean;
  note: string;
  datetime: string;
  image_statement: string;
  deposit_statement_id: number;
  create_date: string;
  update_by: number;
  update_date: string;
  is_bonus: boolean;
  is_manual: boolean;
  turn_over: number;
  max_withdraw: number;
  can_withdraw: boolean;
  turn_over_sport: boolean;
  turn_over_casino: boolean;
  turn_over_poker: boolean;
  turn_over_slot: boolean;
  turn_over_keno: boolean;
  turn_over_lotto: boolean;
  turn_over_trading: boolean;
  amount_can_be_withdraw: number;
  deposit_type: string;
  status: string;
  check_by?: any;
  check_date?: any;
  ref_amb: string;
  promotion_new_member: boolean;
  get_to_know_id?: any;
}

export enum MemberTypes {
  confirmed = 'confirmed',
  suspend = 'suspend',
  lock = 'lock',
}

export type MemberType = keyof typeof MemberTypes;

export interface MemberStatusPageModel extends Partial<PageModel> {
  status?: MemberType;
  member_id?: number;
}

export interface MemberWinLose {
  Id: number;
  member_id: number;
  ask4betAgentId: number;
  log_transaction: number;
  username: string;
  ref: string;
  credit_tranfer: number;
  game_list_name: string;
  game_name: string;
  amount: number;
  valid_amount: number;
  wl_amount: number;
  wl_turn_amount: number;
  outstanding: number;
  create_dt: string;
  login_game: string;
  logout_game: string;
  ref_dt: string;
  game_type: string;
  provider: string;
  status: string;
}

export interface MemberResetPasswordModel {
  member_id: number;
  new_password: string;
  confirm_password?: string;
}

export interface WithdrawTurnover {
  promotion_used: TurnoverPromotion[];
  turnover_amount: number;
  played: number;
  should_play: number;
}

export interface TurnoverPromotion {
  deposit_id: number;
  credit_can_not_withdraw: number;
  can_withdraw: boolean;
  promotion_id?: number;
  promotion_name: string;
  game: string[];
  promotion_turnover: number;
  promotion_can_be_withdraw: number;
  winlose_complete: boolean;
  winlose_amount: number;
}

export interface IReturnCreditMember {
  Id: number;
  member_id: number;
  admin_id?: any;
  amount: number;
  credit_from_before: number;
  credit_from_after: number;
  credit_to_before: number;
  credit_to_after: number;
  action: string;
  create_date: Date;
  _ref?: any;
  ip_address: string;
  browser: string;
  from_system: string;
  to_system: string;
  game_list_id?: any;
  game_list_name?: any;
  game_id?: any;
  game_name?: any;
  game_type?: any;
  provider: string;
  wallet_type: string;
  description: string;
  get_winlose: boolean;
  ref_transaction: number;
  status: string;
}

export interface SportsBook {
  isEnable: boolean;
}

export interface Parlay {
  isEnable: boolean;
}

export interface Step2 {
  isEnable: boolean;
}

export interface Step {
  parlay: Parlay;
  step: Step2;
}

export interface Sexy {
  isEnable: boolean;
}

export interface Ag {
  isEnable: boolean;
}

export interface Sa {
  isEnable: boolean;
}

export interface Dg {
  isEnable: boolean;
}

export interface Pt {
  isEnable: boolean;
}

export interface Ab {
  isEnable: boolean;
}

export interface Eb {
  isEnable: boolean;
}

export interface Cr {
  isEnable: boolean;
}

export interface Pm {
  isEnable: boolean;
  limit: number;
}

export interface Bg {
  isEnable: boolean;
  limit: number;
}

export interface Bt {
  isEnable: boolean;
  limit: number;
}

export interface Gd {
  isEnable: boolean;
  limit: number;
}

export interface Gc {
  isEnable: boolean;
  limit: number;
}

export interface Casino {
  sexy: Sexy;
  ag: Ag;
  sa: Sa;
  dg: Dg;
  pt: Pt;
  ab: Ab;
  eb: Eb;
  cr: Cr;
  pm: Pm;
  bg: Bg;
  bt: Bt;
  gd: Gd;
  gc: Gc;
}

export interface Amb {
  isEnable: boolean;
}

export interface Multi {
  amb: Amb;
}

export interface SlotXO {
  isEnable: boolean;
}

export interface Game {
  slotXO: SlotXO;
}

export interface M2 {
  isEnable: boolean;
}

export interface Tg {
  isEnable: boolean;
}

export interface Hg {
  isEnable: boolean;
}

export interface Es {
  isEnable: boolean;
}

export interface Other {
  m2: M2;
  tg: Tg;
  hg: Hg;
  es: Es;
}

export interface Amb2 {
  isEnable: boolean;
}

export interface Pp {
  isEnable: boolean;
  hour: number;
  minute: number;
}

export interface Laos {
  isEnable: boolean;
}

export interface Stock {
  isEnable: boolean;
}

export interface Lotto {
  amb: Amb2;
  pp: Pp;
  laos: Laos;
  stock: Stock;
}

export interface Og {
  isEnable: boolean;
}

export interface Poker {
  og: Og;
}

export interface LimitSetting {
  sportsBook: SportsBook;
  step: Step;
  casino: Casino;
  multi: Multi;
  game: Game;
  other: Other;
  lotto: Lotto;
  poker: Poker;
}

export interface Result {
  limitSetting: LimitSetting;
  suspend: boolean;
  lock: boolean;
  active: boolean;
}

export interface IRootGetStatusGameAMB {
  result: Result;
  code: number;
  message: string;
  url?: any;
  path?: any;
}
