import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';
import { debounceTime, finalize } from 'rxjs/operators';
import { RevertCredit, RevertCreditService } from 'src/app/apis/revert-credit.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { alertSuccess, alertText, alertWarning } from 'src/app/utils/functions/alert';

@Component({
  selector: 'app-revert-credit-round-form',
  templateUrl: './revert-credit-round-form.component.html',
  styleUrls: ['./revert-credit-round-form.component.scss']
})
export class RevertCreditRoundFormComponent extends AppCore implements OnInit, OnChanges, OnDestroy {
  form = this.svRevertCredit.createFormRound;
  saving = false;
  totalDate = 0;

  prev2WeekDateFormat = dayjs().add(-2, 'week').format('YYYY-MM-DD');

  @Input() agentId: number;
  @Input() updateItem: RevertCredit;
  constructor(
    private svRevertCredit: RevertCreditService,
  ) {
    super();
  }

  ngOnInit(): void {
    setTimeout(() => {
      const revertCreditRoundEl = document.getElementById('revertCreditRoundoffcanvas');
      revertCreditRoundEl.addEventListener('show.bs.offcanvas', () => {
        this.form = this.svRevertCredit.createFormRound;
        this.addFormArray();

        const start$ = this.form.get(`start_revert`).valueChanges.pipe(debounceTime(300)).subscribe(val => {
          this.calculateTotalDate();
        });
        const end$ = this.form.get(`end_revert`).valueChanges.pipe(debounceTime(300)).subscribe(val => {
          this.calculateTotalDate();
        });
        this.Subscriptions.add(start$);
        this.Subscriptions.add(end$);

        if (this.updateItem) {
          this.getFormArray(this.form, 'revert_detail').clear();
          this.updateItem.revert_round_details.forEach(d => {
            const createGroupDetail = this.svRevertCredit.createFormRevertDetail;
            createGroupDetail.patchValue(d);
            this.getFormArray(this.form, 'revert_detail').push(createGroupDetail);
          });

          const start_revert = dayjs(this.updateItem.start_revert).format('YYYY-MM-DD');
          const end_revert = dayjs(this.updateItem.end_revert).format('YYYY-MM-DD');
          const winlose_start = dayjs(this.updateItem.winlose_start).format('YYYY-MM-DD');
          const winlose_end = dayjs(this.updateItem.winlose_end).format('YYYY-MM-DD');
          this.form.patchValue({
            ...this.updateItem,
            start_revert,
            end_revert,
            winlose_start,
            winlose_end,
          });
        }
      });
      revertCreditRoundEl.addEventListener('hide.bs.offcanvas', () => {
        this.form = this.svRevertCredit.createFormRound;
      });
    }, 0);
  }

  ngOnChanges() {
  }

  onSubmit() {
    this.form.markAllAsDirty();
    this.form.markAllAsTouched();

    // if (this.form.get('useSameDate').value) {
    //   this.form.patchValue({
    //     winlose_start: this.form.value.start_revert,
    //     winlose_end: this.form.value.end_revert,
    //   });
    // }

    if (!this.form.valid) {
      alertWarning({ message: alertText.formInvalid });
      return;
    }

    const winloseStartDate = dayjs(this.form.value.winlose_start);
    const diffStart = winloseStartDate.diff(dayjs(), 'days');
    if (diffStart < -14) {
      alertWarning({ message: `เลือก ช่วงเวลาในการคำนวน W/L เริ่ม ย้อนหลังได้ไม่เกิน 14 วัน!` });
      return;
    }

    const winloseEndDate = dayjs(this.form.value.winlose_end);
    const diffEnd = winloseEndDate.diff(dayjs(), 'days');
    if (diffEnd < -14) {
      alertWarning({ message: `เลือก ช่วงเวลาในการคำนวน W/L สิ้นสุด ย้อนหลังได้ไม่เกิน 14 วัน!` });
      return;
    }

    const diffStartEnd = winloseEndDate.diff(winloseStartDate, 'days');
    if (diffStartEnd < 0) {
      alertWarning({ message: `ช่วงเวลาในการคำนวน W/L เริ่ม ต้องมากกว่า สิ้นสุด!` });
      return;
    }

    if (this.totalDate <= 0) {
      alertWarning({ message: `วันที่เริ่มต้องก่อนวันที่สิ้นสุด!` });
      return;
    }

    this.saving = true;
    this.svRevertCredit.postRound(this.agentId, this.form.value).pipe(
      finalize(() => this.saving = false)
    ).subscribe(res => {
      alertSuccess({ message: alertText.saveSuccess });
      this.clickBtnClose('closeOffcanvas');
    });
  }

  calculateTotalDate() {
    this.totalDate = 0;
    if (this.form.value.start_revert && this.form.value.end_revert) {
      const startDate = dayjs(this.form.value.start_revert);
      const endDate = dayjs(this.form.value.end_revert);
      this.totalDate = endDate.diff(startDate, 'days');
    }
  }

  removeFormArrayAt(index: number) {
    this.getFormArray(this.form, 'revert_detail').removeAt(index);
  }
  addFormArray() {
    this.getFormArray(this.form, 'revert_detail').push(this.svRevertCredit.createFormRevertDetail);
  }
}
