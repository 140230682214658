import { NgModule } from '@angular/core';
import { RippleModule } from 'primeng/ripple';
import { TooltipModule } from 'primeng/tooltip';
import { CalendarModule } from 'primeng/calendar';
import { SidebarModule } from 'primeng/sidebar';

const Modules = [
  RippleModule,
  TooltipModule,
  CalendarModule,
  SidebarModule,
];

@NgModule({
  declarations: [],
  imports: [
    ...Modules,
  ],
  exports: [
    ...Modules,
  ]
})
export class PrimengModule {
  constructor() { }
}
