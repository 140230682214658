<div class="offcanvas offcanvas-end" tabindex="-1" id="LogAmbMemberSettingOffcanvas" style="width: 90%;">
  <div class="offcanvas-header">
    <h4 class="offcanvas-header">Log AMB Member Setting</h4>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body" *ngIf="logItem">
    <label for="" class="mb-2">{{'CHOOSE_GAMES'|translate}}</label>
    <div class="row gx-2 mb-2">
      <div class="col-auto mb-2" *ngFor="let game of ambGames">
        <button type="button" class="btn"
          [ngClass]="ambActive?.name === game.name ? 'btn-primary' : 'btn-outline-primary'" (click)="changeTab(game)">
          <i [class]="game.icon"></i> {{game.name}}
        </button>
      </div>
    </div>
    <div class="row gx-2 mb-2" *ngIf="ambActive?.name === 'SS_LOTTO'">
      <div class="col-auto mb-2" *ngFor="let lotto of lottoGames">
        <button type="button" class="btn" [ngClass]="lottoActive === lotto ? 'btn-primary' : 'btn-outline-primary'"
          (click)="lottoActive = lotto">
          <i class="fas fa-dice"></i> {{lotto}}
        </button>
      </div>
    </div>

    <div class="setting-panel" *ngIf="ambActive.name === 'SPORT'">
      <app-content class="mb-3">
        <label for="" class="form-label fs-5 fw-bolder text-warning mb-0"> {{'OLD_INFORMATION'|translate}} </label>
        <hr>
        <app-setting-sport *ngIf="oldSetting" [setting]="oldSetting" [readOnly]="true"></app-setting-sport>
      </app-content>
      <app-content class="mb-3">
        <label for="" class="form-label fs-5 fw-bolder text-success mb-0"> {{'UPDATE_INFORMATION'|translate}} </label>
        <hr>
        <app-setting-sport *ngIf="newSetting" [setting]="newSetting" [readOnly]="true"></app-setting-sport>
      </app-content>
    </div>
    <div class="setting-panel" *ngIf="ambActive.name === 'PARLAY'">
      <app-content class="mb-3">
        <label for="" class="form-label fs-5 fw-bolder text-warning mb-0"> {{'OLD_INFORMATION'|translate}} </label>
        <hr>
        <app-setting-sport2 *ngIf="oldSetting" [setting]="oldSetting" gameType="PARLAY" [readOnly]="true">
        </app-setting-sport2>
      </app-content>
      <app-content class="mb-3">
        <label for="" class="form-label fs-5 fw-bolder text-success mb-0"> {{'UPDATE_INFORMATION'|translate}} </label>
        <hr>
        <app-setting-sport2 *ngIf="newSetting" [setting]="newSetting" gameType="PARLAY" [readOnly]="true">
        </app-setting-sport2>
      </app-content>
    </div>
    <div class="setting-panel" *ngIf="ambActive.name === 'STEP'">
      <app-content class="mb-3">
        <label for="" class="form-label fs-5 fw-bolder text-warning mb-0"> {{'OLD_INFORMATION'|translate}} </label>
        <hr>
        <app-setting-sport2 *ngIf="oldSetting" [setting]="oldSetting" gameType="STEP" [readOnly]="true">
        </app-setting-sport2>
      </app-content>
      <app-content class="mb-3">
        <label for="" class="form-label fs-5 fw-bolder text-success mb-0"> {{'UPDATE_INFORMATION'|translate}} </label>
        <hr>
        <app-setting-sport2 *ngIf="newSetting" [setting]="newSetting" gameType="STEP" [readOnly]="true">
        </app-setting-sport2>
      </app-content>
    </div>
    <div class="setting-panel" *ngIf="ambActive.name === 'LIVE_CASINO'">
      <app-content class="mb-3">
        <label for="" class="form-label fs-5 fw-bolder text-warning mb-0"> {{'OLD_INFORMATION'|translate}} </label>
        <hr>
        <app-setting-casino *ngIf="oldSetting" [setting]="oldSetting" [readOnly]="true"></app-setting-casino>
      </app-content>
      <app-content class="mb-3">
        <label for="" class="form-label fs-5 fw-bolder text-success mb-0"> {{'UPDATE_INFORMATION'|translate}} </label>
        <hr>
        <app-setting-casino *ngIf="newSetting" [setting]="newSetting" [readOnly]="true"></app-setting-casino>
      </app-content>
    </div>
    <div class="setting-panel" [class.disabled]="ambActive.name === 'POKER' || ambActive.name === 'E-SPORT'"
      *ngIf="ambActive.name === 'SLOT' || ambActive.name === 'AMB_POKER' || ambActive.name === 'POKER' || ambActive.name === 'KENO' || ambActive.name === 'TRADE' || ambActive.name === 'E-SPORT'">
      <app-content class="mb-3">
        <label for="" class="form-label fs-5 fw-bolder text-warning mb-0"> {{'OLD_INFORMATION'|translate}} </label>
        <hr>
        <app-setting-partner *ngIf="oldSetting" [setting]="oldSetting" [ambGame]="ambActive?.name" [readOnly]="true">
        </app-setting-partner>
      </app-content>
      <app-content class="mb-3">
        <label for="" class="form-label fs-5 fw-bolder text-success mb-0"> {{'UPDATE_INFORMATION'|translate}} </label>
        <hr>
        <app-setting-partner *ngIf="newSetting" [setting]="newSetting" [ambGame]="ambActive?.name" [readOnly]="true">
        </app-setting-partner>
      </app-content>
    </div>
    <div class="setting-panel" *ngIf="ambActive.name === 'M2 Sport'">
      <app-content class="mb-3">
        <label for="" class="form-label fs-5 fw-bolder text-warning mb-0"> {{'OLD_INFORMATION'|translate}} </label>
        <hr>
        <app-setting-m2-sport *ngIf="oldSetting" [setting]="oldSetting" [readOnly]="true"></app-setting-m2-sport>
      </app-content>
      <app-content class="mb-3">
        <label for="" class="form-label fs-5 fw-bolder text-success mb-0"> {{'UPDATE_INFORMATION'|translate}} </label>
        <hr>
        <app-setting-m2-sport *ngIf="newSetting" [setting]="newSetting" [readOnly]="true"></app-setting-m2-sport>
      </app-content>
    </div>
    <div class="setting-panel" *ngIf="ambActive.name === 'SS_LOTTO'">
      <app-content class="mb-3">
        <label for="" class="form-label fs-5 fw-bolder text-warning mb-0"> {{'OLD_INFORMATION'|translate}} </label>
        <hr>
        <app-setting-lotto *ngIf="oldSetting" [setting]="oldSetting" [lottoType]="lottoActive" [readOnly]="true">
        </app-setting-lotto>
      </app-content>
      <app-content class="mb-3">
        <label for="" class="form-label fs-5 fw-bolder text-success mb-0"> {{'UPDATE_INFORMATION'|translate}} </label>
        <hr>
        <app-setting-lotto *ngIf="newSetting" [setting]="newSetting" [lottoType]="lottoActive" [readOnly]="true">
        </app-setting-lotto>
      </app-content>
    </div>

  </div>
</div>
