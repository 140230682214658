<div class="offcanvas offcanvas-end" id="formMemberWithdrawOffcanvas">
  <div class="offcanvas-header">
    <h4 class="mb-0" id="offcanvasRightLabel">{{'CREATE_REQUEST_WITHDARW'|translate}}</h4>
    <button id="memberWithdrawClose" type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
  </div>
  <div class="offcanvas-body">
    <form [formGroup]="form" (submit)="onSubmit()">
      <div class="mb-3">
        <label for="" class="form-label">{{'CHOOSE_MEMBER'|translate}}</label>
        <app-select-member-form formControlName="member_id" [showCredit]="true"></app-select-member-form>
      </div>
      <div class="mb-3">
        <label for="" class="form-label">{{'NOTE'|translate}}</label>
        <textarea rows="3" class="form-control" formControlName="note"></textarea>
      </div>
      <div class="mb-3">
        <label for="" class="form-label">{{'AMOUNT'|translate}}</label>
        <input type="number" class="form-control" formControlName="amount">
      </div>
      <label for="" class="form-label">{{'CHOOSE_TRANSFER_FORMAT'|translate}}</label>
      <div class="row">
        <div class="col-6 mb-3">
          <div class="form-check form-check-inline">
            <input id="transfer-auto" type="radio" class="form-check-input" formControlName="transferType" value="auto">
            <label for="transfer-auto" class="form-check-label">{{'AUTO_TRANSFER_SYSTEM'|translate}}</label>
          </div>
        </div>
        <div class="col-6 mb-3">
          <div class="form-check form-check-inline">
            <input id="transfer-manual" type="radio" class="form-check-input" formControlName="transferType"
              value="manual">
            <label for="transfer-manual" class="form-check-label">{{'MANUAL_TRANSFER'|translate}}</label>
          </div>
        </div>
      </div>

      <div class="" *ngIf="form.get('transferType').value === 'auto'">
        <label for="" class="form-label">{{'CONDITIONS_USING_AUTO_TRANSFER'|translate}}</label>
        <div class="alert alert-danger">
          <ul class="m-0">
            <li>
              {{'PLEASE_CHECK_EVERY_TRANSFER_WHETHER_THE_TRANSFER'|translate}} <strong>{{'SUCCESSFUL_OR_UNSUCCESSFUL'|translate}}</strong>
              {{'SOMETIMES_IN_THE_BANK'|translate}}
              <strong>{{'THERE_MIGHT_BE_ERROR'|translate}}</strong>
            </li>
            <li>{{'IF_THERE_IS_A_DUPLICATE_TRANSFER_WE_WILL_NOW_BE_RESPONSIBLE'|translate}} <strong>{{'SUCH_ERROR'|translate}}</strong></li>
          </ul>
        </div>

        <label for="" class="form-label">{{'PLEASE_SELECT_THE_ACCOUNT_USED_TO_TRANSFER_MONEY'|translate}}</label>
        <app-select-account-bank-form formControlName="account_bank_agent" AccountBankType="withdraw">
        </app-select-account-bank-form>
      </div>

      <div class="text-end">
        <button type="submit" class="btn btn-primary btn-submit" [appLoading]="saving"> {{'SAVE_DATA'|translate}} </button>
      </div>
    </form>
  </div>
</div>
