<div class="offcanvas offcanvas-end" tabindex="-1" id="revertCreditRoundDetailOffcanvas" style="width: 60em;">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title">{{'DETAIL_OF_REVERT_CREDIT'|translate}}</h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <div class="table-responsive">
      <table class="table table-hover table-sm">
        <thead>
          <tr>
            <th>#</th>
            <th>{{'USERNAME'|translate}}</th>
            <th>{{'REVERT_CREDIT'|translate}}</th>
            <th>Valid Amount</th>
            <th>W/L Amount</th>
            <th>{{'STATUS'|translate}}</th>
            <th>{{'REQUEST_DATE'|translate}}</th>
            <th>{{'CONFIRMED_DATE'|translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of revertCreditRequest?.items;index as i; trackBy: trackByFn">
            <td>
              {{i+1}}
            </td>
            <td> {{item.username}} </td>
            <td class="text-end"> {{item.revert_credit | number: '0.2-2'}} </td>
            <td class="text-end"> {{item.valid_amount | number: '0.2-2'}} </td>
            <td class="text-end"> {{item.wl_amount | number: '0.2-2'}} </td>
            <td>
              <span *ngIf="item.status === 'pending'" class="badge bg-warning"> {{item.status}} </span>
              <span *ngIf="item.status === 'complete'" class="badge bg-success"> {{item.status}} </span>
            </td>
            <td>
              <app-show-date-time [date]="item.create_date"></app-show-date-time>
            </td>
            <td>
              <app-show-date-time [date]="item.update_date"></app-show-date-time>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="text-center mb-3">
      <div class="" *ngIf="revertCreditRequest && !revertCreditRequest?.items.length">{{'NO_DATA'|translate}}</div>
      <div class="" *ngIf="loading"> <span class="spinner-border"></span> </div>
    </div>

    <app-pagination *ngIf="revertCreditRequest" [limit]="revertCreditRequest?.limitRow"
      [total]="revertCreditRequest?.resultRow" (valueChange)="pageChange($event)">
    </app-pagination>
  </div>
</div>
