import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Directive({
  selector: '[appViewImg]'
})
export class ViewImgDirective {
  @Input() appViewImg: string;
  @Input() instantClickView = false;

  constructor(
    private el: ElementRef<HTMLImageElement>,
    private renderer: Renderer2,
    private svApp: AppService,
  ) {
    this.el.nativeElement.style.backgroundColor = 'transparent';
    this.renderer.setAttribute(this.el.nativeElement, 'src', `assets/rolling.svg`);
  }

  @HostListener('error') onError() {
    this.instantClickView = false;
    this.el.nativeElement.style.cursor = 'default';
    this.el.nativeElement.style.objectFit = 'cover';
    this.el.nativeElement.style.pointerEvents = 'none';
    this.renderer.setAttribute(this.el.nativeElement, 'src', `assets/placeholder.jpg`);
  }
  @HostListener('load') onLoad() {
    this.instantClickView = true;
    this.el.nativeElement.style.cursor = 'pointer';
    this.el.nativeElement.style.objectFit = 'contain';
  }

  @HostListener('click') onClick() {
    if (!this.instantClickView) { return; }
    this.svApp.viewImage(this.el.nativeElement.src || this.appViewImg);
  }
}
