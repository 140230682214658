import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'fromNow',
  pure: false
})
export class FromNowPipe implements PipeTransform {
  constructor() {
    moment.locale('th');
  }
  transform(value: any): any {
    return moment(value).fromNow();
  }

}
