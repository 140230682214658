import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@ngneat/reactive-forms';
import { Subscription } from 'rxjs';
import { generateArray } from 'src/app/utils/functions/generate-array';
import { AgentForm } from 'src/app/utils/interfaces/create-agent-form.interface';
import { OptionItem } from 'src/app/utils/interfaces/option-item';

@Component({
  selector: 'app-setting-amb-lotto',
  templateUrl: './setting-amb-lotto.component.html',
  styleUrls: ['../../setting-agent.scss'],
})
export class SettingAmbLottoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() lottoActive: string;
  @Input() form: FormGroup<AgentForm>;

  ambLottoItems = [
    {
      value: 'tha', formName: 'thai', playNames: [
        '_6TOP', '_5TOP', '_4TOP', '_4TOD', '_3TOP', '_3TOD', '_3BOT', '_3TOP_OE', '_3TOP_OU',
      ], playNames2: [
        '_2TOP', '_2TOD', '_2BOT', '_2TOP_OE', '_2TOP_OU', '_2BOT_OE', '_2BOT_OU', '_1TOP', '_1BOT'
      ]
    },
    {
      value: 'pp', formName: 'pp', playNames: [
        '_6TOP', '_5TOP', '_4TOP', '_4TOD', '_3TOP', '_3TOD', '_3BOT', '_3TOP_OE', '_3TOP_OU',
      ], playNames2: [
        '_2TOP', '_2TOD', '_2BOT', '_2TOP_OE', '_2TOP_OU', '_2BOT_OE', '_2BOT_OU', '_1TOP', '_1BOT'
      ]
    },
    {
      value: 'lao', formName: 'lao', playNames: [
        '_L_4TOP', '_L_4TOD', '_L_3TOP', '_L_3TOD', '_L_2FB',
      ], playNames2: [
        '_T_4TOP', '_T_4TOD', '_T_3TOP', '_T_3TOD', '_T_2TOP', '_T_2TOD', '_T_2BOT', '_T_1TOP', '_T_1BOT'
      ]
    },
    {
      value: 'sto', formName: 'stock', playNames: []
    },
  ];

  hourItems: OptionItem[] = generateArray(0, 1, 23).map(v => ({ name: v + '', value: v }));
  minItems: OptionItem[] = generateArray(0, 1, 59).map(v => ({ name: v + '', value: v }));

  percentItems: OptionItem[] = generateArray(0, 0.5, 100).map(v => ({ name: v + '%', value: v }));
  formValue: AgentForm;
  Subscription = new Subscription();
  constructor() { }

  ngOnInit(): void {
    if (this.form) {
      const limit_amb_form$ = this.form.get('limit_setting_amb_form').valueChanges.subscribe(res => {
        this.formValue = this.form.getRawValue();
      });
      const limit_amb_lotto_form$ = this.form.get('limit_setting_amb_lotto_form').valueChanges.subscribe(res => {
        this.formValue = this.form.getRawValue();
      });
      this.Subscription.add(limit_amb_form$);
      this.Subscription.add(limit_amb_lotto_form$);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.form) {
    }
  }

  ngOnDestroy(): void {
    this.Subscription.unsubscribe();
  }
}
