import { Injectable } from '@angular/core';
import { HttpApiService } from '../services/http-api.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(
    private http: HttpApiService,
  ) { }

  GetChatRoomMember(memberId: number) {
    return this.http.get<IChatRoom>(`api/chat/admin/get-room/member?member_id=${memberId}`);
  }
  GetChatRoomAgent(agentId: number) {
    return this.http.get<IChatRoomAll[]>(`api/chat/admin/get-room/agent?agent_id=${agentId}`);
  }
  GetChatToday(roomId: number) {
    return this.http.get<IChatMessage[]>(`api/chat/admin/get-chat/room-today?room_id=${roomId}`);
  }
  CreateChatRoom(memberId: number) {
    return this.http.post(`api/chat/admin/create-room?member_id=${memberId}`, {})
  }
  sendMessage(data: ICreateMessage) {
    return this.http.post(`api/chat/admin/send-message`, data)
  }
}

export interface IChatRoom {
  roomId: number
  roomName?: string
  agentId: number
  memberId: number
  last_message?: string
  last_message_type?: string
  count_unread: number
  room_update_dt: string
  room_create_dt: string
  id: number
  username: string
  phone: string
  password: string
  first_name: string
  last_name: string
  bank_number: string
  bank: string
  register_date: string
  register_ip: string
  register_device: string
  login_ip?: string
  last_login?: string
  login_device?: string
  wallet: number
  wallet_outstanding: number
  wallet_seamless: number
  deposit_amount: number
  deposit_amount_bonus: number
  deposit_sum: number
  deposit_sum_bonus: number
  withdraw_amount: number
  withdraw_sum: number
  recommend_total: number
  recommend_point: number
  recommend_transfer: number
  recommend_downline_total: number
  status: string
  create_by?: number
  update_date: string
  update_by?: number
  know_by_id?: number
  limit_setting_amb_update: number
  limit_setting_klotto_update: number
  job_logout_FromGame: boolean
  ask4betAgentId: number
  session_id?: string
  user_affiliate_id: any
  link_affiliate_id: any
  current_game: string
}

export interface IChatRoomAll {
  roomId: number
  agentId: number
  memberId: number
  last_message?: string
  last_message_type: string
  count_unread?: number
  count_unread_member?: number
  count_unread_admin?: number
  room_update_dt: string
  room_create_dt: string
  id: number
  username: string
  phone: string
  password: string
  first_name: string
  last_name: string
  bank_number: string
  bank: string
  register_date: string
  register_ip: string
  register_device: string
  login_ip?: string
  last_login?: string
  login_device?: string
  wallet: number
  wallet_outstanding: number
  wallet_seamless: number
  deposit_amount: number
  deposit_amount_bonus: number
  deposit_sum: number
  deposit_sum_bonus: number
  withdraw_amount: number
  withdraw_sum: number
  recommend_total: number
  recommend_point: number
  recommend_transfer: number
  recommend_downline_total: number
  status: string
  create_by?: number
  update_date: string
  update_by?: number
  know_by_id?: number
  limit_setting_amb_update: number
  limit_setting_klotto_update: number
  job_logout_FromGame: boolean
  ask4betAgentId: number
  session_id?: string
  user_affiliate_id: any
  link_affiliate_id: any
  current_game: string
}

export interface IChatMessage {
  id?: number;
  uuid: string;
  message: string
  type: string
  replyId?: number
  replyMessage?: string
  replyAt: string
  readed?: boolean
  roomId?: number
  memberId?: number
  adminId?: number
  create_dt?: string
  admin_username?: string
  admin_image?: any
  admin_name?: string
  roomName?: string
}
export interface ICreateMessage {
  uuid: string;
  message: string;
  type: string;
  replyId?: number;
  replyType?: string;
  replyMessage?: string;
  replyAt?: string;
  roomId: number;
  roomName?: string;
}