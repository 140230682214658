<div class="offcanvas offcanvas-end" tabindex="-1" id="resultAmbOffcanvas" style="width: 600px;">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title">Result AMB ({{keyName}})</h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <pre *ngIf="jsonItem" [innerHtml]="jsonItem | json"></pre>
    <div *ngIf="stringItem.length" class=""> {{stringItem}} </div>
    <div *ngIf="!jsonItem && !stringItem.length">
      ไม่มีข้อมูล result_amb ({{keyName}})
    </div>
  </div>
</div>
