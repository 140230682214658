<form *ngIf="form" [formGroup]="form">
  <!-- <div class="row">
  <div class="col-7 col-lg-5 mb-3">
    <div class="input-group">
      <span class="input-group-text"> เดิมพันสูงสุดต่อบุคคล </span>
      <input type="number" class="form-control">
      <span class="input-group-text"> บาท </span>
    </div>
  </div>
</div> -->
  <label for="" class="form-label mb-2"> {{'SET_UP_PARTNERSHIP'|translate}} : </label>
  <div class="row" formGroupName="limit_setting_klotto_form">
    <div class="col-12 col-lg-4 mb-3">
      <div class="input-group">
        <div class="input-group-text">
          KLOTTO
          <i class="ms-1 bi bi-exclamation-circle text-warning" *ngIf="formValue.limit_setting_klotto_form.percent_own >
            formValue.limit_setting_klotto_form.percent_max"></i>
        </div>
        <input type="number" class="form-control text-end" formControlName="percent_own" [min]="0"
          [max]="formValue.limit_setting_klotto_form.percent_max" step="0.5">
        <div class="input-group-text">%</div>
      </div>
    </div>
    <div class="col-12 col-lg-4 mb-3">
      <div class=""> {{'BOARD'|translate}} = {{formValue.limit_setting_klotto_form.percent_board}}% </div>
      <div class=""> MAX = {{formValue.limit_setting_klotto_form.percent_max}}% </div>
      <div class=""> {{'BALANCE'|translate}} ({{'YOUR_PARTNER'|translate}}) =
        {{ formValue.limit_setting_klotto_form.percent_max -
        formValue.limit_setting_klotto_form.percent_own }}%
      </div>
    </div>
  </div>

  <!-- <label for="" class="form-label">เลือกประเภทการเล่น</label>
  <div class="mb-2">
    <ng-container *ngFor="let play of KlottoPlays">
      <button type="button" class="btn m-1" (click)="onSelectLotto(play)"
        [class]="selectLotto.key === play.key ? 'btn-primary': 'btn-outline-primary'">
        <img *ngIf="!!flags[play.key]" [src]="flags[play.key]" style="width: 20px; height: 20px; object-fit: contain;">
        {{'LOTTO.'+play.key | translate}}
      </button>
    </ng-container>
  </div> -->

  <!-- <div class="mb-2">
  <label for="" class="form-label mb-2 me-2">สถานะ : </label>
  <div class="form-check form-check-inline">
    <input id="klotto-on" type="radio" class="form-check-input" [value]="true">
    <label for="klotto-on" class="form-check-label text-success"> เปิดการใช้งาน </label>
  </div>
  <div class="form-check form-check-inline">
    <input id="klotto-off" type="radio" class="form-check-input" [value]="false">
    <label for="klotto-off" class="form-check-label text-danger"> ปิดการใช้งาน </label>
  </div>
</div>
 -->

  <!-- <div class="" formGroupName="limit_setting_klotto_form">
    <div class="row">
      <div class="col-12 col-md-6">
        <div *ngFor="let play of KlottoPlays; ">
          <table class="table table-borderless" *ngIf="selectLotto?.name === play.name">
            <tbody>
              <ng-container *ngFor="let pn of play.playNames; let odd = odd">
                <tr *ngIf="!odd">
                  <td class="text-nowrap">{{pn|translate}}</td>
                  <td>
                    <div class="input-group"
                      *ngIf="!formValue.limit_setting_klotto_form[play.name+'_'+pn+'_maxBet_uplineLimit']">
                      <span class="input-group-text">เดิมพันสูงสุด</span>
                      <input type="number" class="form-control text-end" [value]="1" disabled>
                      <span class="input-group-text">ถึง</span>
                      <input type="number" class="form-control text-end" formControlName="{{play.name}}_{{pn}}_maxBet">
                      <span class="input-group-text">บาท</span>
                    </div>
                    <div class="input-group"
                      *ngIf="formValue.limit_setting_klotto_form[play.name+'_'+pn+'_maxBet_uplineLimit']">
                      <span class="input-group-text">เดิมพันสูงสุด</span>
                      <input type="number" class="form-control text-end" [value]="1" disabled>
                      <span class="input-group-text">ถึง</span>
                      <input type="number" class="form-control text-end" formControlName="{{play.name}}_{{pn}}_maxBet"
                        [max]="formValue.limit_setting_klotto_form[play.name+'_'+pn+'_maxBet_uplineLimit']">
                      <span class="input-group-text">บาท</span>
                    </div>
                    <div *ngIf="formValue.limit_setting_klotto_form[play.name+'_'+pn+'_maxBet_uplineLimit']">
                      <div class="small opacity-75 text-end"> Max =
                        {{formValue.limit_setting_klotto_form[play.name+'_'+pn+'_maxBet_uplineLimit']|number:'1.2-2'}}
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div *ngFor="let play of KlottoPlays; ">
          <table class="table table-borderless" *ngIf="selectLotto?.name === play.name">
            <tbody>
              <ng-container *ngFor="let pn of play.playNames; let odd = odd">
                <tr *ngIf="odd">
                  <td class="text-nowrap">{{pn|translate}}</td>
                  <td>
                    <div class="input-group"
                      *ngIf="!formValue.limit_setting_klotto_form[play.name+'_'+pn+'_maxBet_uplineLimit']">
                      <span class="input-group-text">เดิมพันสูงสุด</span>
                      <input type="number" class="form-control text-end" [value]="1" disabled>
                      <span class="input-group-text">ถึง</span>
                      <input type="number" class="form-control text-end" formControlName="{{play.name}}_{{pn}}_maxBet">
                      <span class="input-group-text">บาท</span>
                    </div>
                    <div class="input-group"
                      *ngIf="formValue.limit_setting_klotto_form[play.name+'_'+pn+'_maxBet_uplineLimit']">
                      <span class="input-group-text">เดิมพันสูงสุด</span>
                      <input type="number" class="form-control text-end" [value]="1" disabled>
                      <span class="input-group-text">ถึง</span>
                      <input type="number" class="form-control text-end" formControlName="{{play.name}}_{{pn}}_maxBet"
                        [max]="formValue.limit_setting_klotto_form[play.name+'_'+pn+'_maxBet_uplineLimit']">
                      <span class="input-group-text">บาท</span>
                    </div>
                    <div *ngIf="formValue.limit_setting_klotto_form[play.name+'_'+pn+'_maxBet_uplineLimit']">
                      <div class="small opacity-75 text-end"> Max =
                        {{formValue.limit_setting_klotto_form[play.name+'_'+pn+'_maxBet_uplineLimit']|number:'1.2-2'}}
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div> -->
</form>
