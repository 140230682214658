import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SettingsService, SettingSystem, SystemTypes } from 'src/app/apis/settings.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { alertSuccess, alertText, alertWarning } from 'src/app/utils/functions/alert';
import { focusInvalid } from 'src/app/utils/functions/focus-invalid';

@Component({
  selector: 'app-setting-system-form-offcanvas',
  templateUrl: './setting-system-form-offcanvas.component.html',
  styleUrls: ['./setting-system-form-offcanvas.component.scss']
})
export class SettingSystemFormOffcanvasComponent extends AppCore implements OnInit, OnChanges {
  @Input() updateItem: SettingSystem;

  form = this.svSetting.createFormSystem;
  saving = false;

  SystemTypeItems: SystemTypes[] = [
    SystemTypes.boolean,
    SystemTypes.decimal,
    SystemTypes.int,
    SystemTypes.text,
  ];
  configTypeItems: string[] = [];

  constructor(
    private svSetting: SettingsService,
  ) {
    super();
  }

  ngOnInit(): void {
    setTimeout(() => {
      const ssFormEl = document.getElementById('settingSystemFormOffcanvas');
      ssFormEl.addEventListener('show.bs.offcanvas', () => {
        this.getSettingConfigType();
      });
      ssFormEl.addEventListener('hide.bs.offcanvas', () => {
        this.form = this.svSetting.createFormSystem;
      });
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.updateItem) {
      this.form.patchValue(this.updateItem);
    }
  }

  async onSubmit() {
    this.form.markAllAsDirty();
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      alertWarning({ message: alertText.formInvalid });
      focusInvalid();
      return;
    }

    try {
      this.saving = true;
      if (!this.updateItem) {
        await this.createSettingSystem();
      } else {
        await this.updateSettingSystem();
      }
      alertSuccess({ message: alertText.saveSuccess });
      this.clickBtnClose();
      // } catch (error) {
    } finally {
      this.saving = false;
    }
  }

  createSettingSystem() {
    return this.svSetting.postSystem(this.form.value).toPromise();
  }

  updateSettingSystem() {
    return this.svSetting.putSystem(this.updateItem.id, this.form.value).toPromise();
  }

  override clickBtnClose(): void {
    super.clickBtnClose('btn-close-ss-offcanvas');
  }

  getSettingConfigType() {
    this.svSetting.getConfigType().subscribe(res => {
      this.configTypeItems = res;
    });
  }
}
