import { EventEmitter, OnChanges, Output } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { Offcanvas } from 'bootstrap';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, finalize, map, switchMap, tap } from 'rxjs/operators';
import { Agent, AgentService } from 'src/app/apis/agent.service';
import { Announcement, AnnouncementModel, AnnouncementService } from 'src/app/apis/announcement.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { alertSuccess, alertText, alertWarning } from 'src/app/utils/functions/alert';
import { fileToBase64, validateFiles } from 'src/app/utils/functions/base64';
import { focusInvalid } from 'src/app/utils/functions/focus-invalid';
import { formPage } from 'src/app/utils/functions/form-page';
import { noWhitespaceValidator } from 'src/app/utils/functions/no-whitespace';

@Component({
  selector: 'app-announcement-form-canvas',
  templateUrl: './announcement-form-canvas.component.html',
  styleUrls: ['./announcement-form-canvas.component.scss']
})
export class AnnouncementFormCanvasComponent extends AppCore implements OnInit, OnChanges {
  form = this.createForm();
  saving = false;

  agent: Agent;
  agent$: Observable<Agent[]>;
  inputAgent$ = new Subject<string>();
  agentLoading = false;

  @Input() state: 'create' | 'update';
  @Input() item: Announcement;
  @Input() offcanvas: Offcanvas;
  constructor(
    private ngFb: FormBuilder,
    private svAnnouncement: AnnouncementService,
    private svAgent: AgentService,
  ) {
    super();
  }

  ngOnInit(): void {
    setTimeout(() => {
      const accEl = document.getElementById(`accOffCanvas`);
      accEl.addEventListener('hide.bs.offcanvas', () => {
        this.form = this.createForm();
      });
    }, 0);
  }

  ngOnChanges() {
    this.agent = null;
    this.setAgentInput();

    this.form = this.createForm();
    if (this.item) {
      this.agent = { id: this.item.agent_id, agentName: this.item.agentName } as any;
      this.form.patchValue(this.item);
    }
  }

  createForm(): FormGroup<AnnouncementModel> {
    return this.ngFb.group({
      priority: [0, [Validators.required, Validators.min(0)]],
      title: ['', [noWhitespaceValidator, Validators.maxLength(100)]],
      text: ['', [noWhitespaceValidator, Validators.maxLength(4000)]],
      image: [''],
      url: ['', [Validators.maxLength(250)]],
      status: ['active', [Validators.required]],

      localImage: [false],
    });
  }

  async onSubmit() {
    this.form.markAllAsDirty();
    this.form.markAllAsTouched();

    if (!this.agent) {
      alertWarning({ message: `เลือกเอเย่นต์ก่อน` });
      focusInvalid();
      return;
    }

    if (!this.form.valid) {
      alertWarning({ message: alertText.formInvalid });
      focusInvalid();
      return;
    }

    try {
      this.saving = true;
      if (!this.item) {
        await this.createAnn();
      } else {
        await this.updateAnn();
      }
      alertSuccess({ message: alertText.saveSuccess });
      this.offcanvas.hide();
    } catch (e) {
    }
  }

  createAnn() {
    return this.svAnnouncement.post(this.agent.id, this.form.value).pipe(finalize(() => this.saving = false)).toPromise();
  }

  updateAnn() {
    return this.svAnnouncement.put(this.item.id, this.form.value).pipe(finalize(() => this.saving = false)).toPromise();
  }

  async inputFileChange(event: Event) {
    const inputEl = event.target as HTMLInputElement;
    const files = inputEl.files;
    const validFile = validateFiles(files);
    if (!validFile) { return; }

    const image = await fileToBase64(files.item(0));
    const localImage = true;
    this.form.patchValue({ image, localImage });

    inputEl.value = '';
  }

  statusChange(event: Event) {
    const inputEl = event.target as HTMLInputElement;
    this.form.patchValue({ status: inputEl.checked ? 'active' : 'deactive' });
  }

  agentTrackBy(item: Agent) {
    return item.id;
  }

  setAgentInput() {
    this.svAgent.getAll().subscribe(res => {
      this.agent$ = concat(
        of(res.items || []),
        this.inputAgent$.pipe(
          distinctUntilChanged(),
          tap(() => this.agentLoading = true),
          switchMap(term => {
            const form = formPage({ name: 'agentName', filter: term || '' });
            return this.svAgent.getAll(form.value).pipe(
              map(res => res.items),
              catchError(() => of([])),
              tap(() => this.agentLoading = false),
            );
          }),
        ),
      );
    });
  }
}
