import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignalrBankService {
  private apiHost = environment.bankCenter;

  StateTransfer = new Subject<ITranferStateResult>();

  Connection: SignalR.Hub.Connection;
  connectioLogWithdraw: SignalR.Hub.Proxy;

  constructor() { }
  init() {
    this.Connection = $.hubConnection(this.apiHost + `signalr`, { useDefaultPath: false });
    this.Connection.qs = { app: 'plusgold' };
    this.connectioLogWithdraw = this.Connection.createHubProxy(`BankConnectionHub`);
    this.connectioLogWithdraw.on('updateTransferState', this.OnReceiveLogStateTransfer);

    this.Connection.start()
      .done(() => {
        console.log(`Bank-center Now connected ${this.Connection.id}`);
      })
      .fail(() => {
        console.log('Bank-center Could not connect!!!');
      });
  }
  OnReceiveLogStateTransfer = (message: ITranferStateResult, id: number) => {
    this.StateTransfer.next(message);
  }

}


export interface ITranferStateResult {
  code: number;
  percent: number;
  state: string;
  ref_id: number;
  transfer_id: number;
  created_at: Date;
}
