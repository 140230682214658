import { Injectable } from '@angular/core';
import { HttpApiService } from '../services/http-api.service';

@Injectable({
  providedIn: 'root'
})
export class WithdrawService {

  constructor(
    private http: HttpApiService,
  ) { }

  /**
   * Api Request Confirm
   * @param params ?withdraw_id={withdraw_id}&account_bank_agent={account_bank_agent}
   * @returns Observable
   */
  putRequestConfirm(params: any) {
    return this.http.put(`api/ask4bet/withdraw/request/confirm`, {}, { params });
  }

  /**
   *
   * @param model RequestModel
   * @param params ?member_id={member_id}&agent_id={agent_id}
   * @returns Observable
   */
  postRequest(model: RequestModel, params: any) {
    return this.http.post(`api/ask4bet/withdraw/request/create`, model, { params });
  }
}

export interface RequestConfirmModel {
  withdraw_id: number;
  account_bank_agent: any;
}

export interface RequestModel {
  amount: number;
  note: string;
  is_manual: boolean;
  [key: string]: any;
}
