import { Routes } from '@angular/router';
import { AppPath } from '../utils/constants/paths';

export const publicRoutes: Routes = [
  {
    path: '',
    redirectTo: AppPath.login,
    pathMatch: 'full',
  },
  {
    path: AppPath.login,
    loadChildren: () => import('../pages').then(C => C.LoginModule),
    data: { title: 'LOGIN' },
  },
  {
    path: AppPath.forgetPassword,
    loadChildren: () => import('../pages').then(m => m.ForgetPasswordModule),
    data: { title: 'NOT_FOUND' },
  },
];
