import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from 'src/app/services/currency.service';

@Pipe({
  name: 'exAnyToTHB'
})
export class ExAnyToThbPipe implements PipeTransform {
  constructor(
    private svCurrency: CurrencyService
  ) { }
  transform(value: any, args?: any): any {
    return this.svCurrency.ExchangeANY2THB(value);
  }
}
