import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { finalize } from 'rxjs/operators';
import { RequestModel, WithdrawService } from 'src/app/apis/withdraw.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { alertSuccess, alertText, alertWarning } from 'src/app/utils/functions/alert';
import { focusInvalid } from 'src/app/utils/functions/focus-invalid';

@Component({
  selector: 'app-form-member-withdraw-offcanvas',
  templateUrl: './form-member-withdraw-offcanvas.component.html',
  styleUrls: ['./form-member-withdraw-offcanvas.component.scss']
})
export class FormMemberWithdrawOffcanvasComponent extends AppCore implements OnInit {
  form = this.createForm();
  saving = false;

  constructor(
    private ngFb: FormBuilder,
    private svWithdraw: WithdrawService,
  ) {
    super();
  }

  ngOnInit(): void {
    setTimeout(() => {
      const formMemberWithdrawEl = document.getElementById('formMemberWithdrawOffcanvas');
      formMemberWithdrawEl.addEventListener('hide.bs.offcanvas', () => {
        this.form = this.createForm();
      });
    }, 0);
  }

  createForm(): FormGroup<RequestModel> {
    return this.ngFb.group({
      member_id: [null, [Validators.required]],
      note: ['ลดเครดิตหรือถอน (ออโต้)'],
      amount: [0, [Validators.required, Validators.min(0.01)]],
      is_manual: [true],
      transferType: ['auto'],
      account_bank_agent: [null],
    });
  }

  onSubmit() {
    this.form.markAllAsDirty();
    this.form.markAllAsTouched();

    if (!this.form.value.member_id) {
      alertWarning({ message: `เลือกสมาชิกก่อน!` })
      focusInvalid();
      return;
    }

    if (!this.form.valid) {
      alertWarning({ message: alertText.formInvalid });
      focusInvalid();
      return;
    }

    this.saving = true;
    this.svWithdraw.postRequest(this.form.value, { member_id: this.form.value.member_id, agent_id: this.currentAgentId }).pipe(
      finalize(() => this.saving = false)
    ).subscribe(res => {
      alertSuccess({ message: alertText.saveSuccess });
      this.clickBtnClose('memberWithdrawClose');
    });
  }

}
