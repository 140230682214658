<div class="mb-1"> {{'PAGE'|translate}} {{page}} {{'FROM'|translate}} {{totalPages}} </div>
<nav class="row">
  <div class="col-auto">
    <ul class="pagination pagination-sm">
      <li class="page-item" [class.disabled]="page === 1">
        <a class="page-link link-dark" href="javascript:;" (click)="goFirst()">{{'FIRST_PAGE'|translate}}</a>
      </li>
      <li class="page-item" [class.disabled]="page === 1">
        <a class="page-link link-dark" href="javascript:;" (click)="goPrev()">{{'PREVIOUS_PAGE'|translate}}</a>
      </li>
      <li class="page-item" *ngFor="let numPage of pages" [class.active]="numPage === page">
        <a class="page-link link-dark" href="javascript:;" (click)="onPageChange(numPage)">{{numPage}}</a>
      </li>
      <li class="page-item" [class.disabled]="page === pages[pages.length - 1]">
        <a class="page-link link-dark" href="javascript:;" (click)="goNext()">{{'NEXT_PAGE'|translate}}</a>
      </li>
      <li class="page-item" [class.disabled]="page === pages[pages.length - 1]">
        <a class="page-link link-dark" href="javascript:;" (click)="goLast()">{{'LAST_PAGE'|translate}}</a>
      </li>
    </ul>
  </div>
  <div class="col-auto">
    <select class="form-select form-select-sm" [(ngModel)]="limit" (change)="onChange()">
      <option *ngFor="let num of options" [value]="num">{{num}}</option>
    </select>
  </div>
</nav>
