<div class="modal" id="changeStatusMemberModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"> {{'EDIT_MEMBER_STATUS'|translate}} </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
      </div>
      <div class="modal-body" [perfectScrollbar]="{}">
        <div class="form-check form-check-inline">
          <input id="status-confirmed" type="radio" class="form-check-input" [(ngModel)]="currentStatus"
            value="confirmed">
          <label for="status-confirmed" class="form-check-label">
            <span class="me-1 badge bg-success">confirmed</span>
            <small *ngIf="'confirmed' === member?.status" class="text-secondary">({{'NOW'|translate}})</small>
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input id="status-suspend" type="radio" class="form-check-input" [(ngModel)]="currentStatus" value="suspend">
          <label for="status-suspend" class="form-check-label">
            <span class="me-1 badge bg-warning text-dark">suspend</span>
            <small *ngIf="'suspend' === member?.status" class="text-secondary">({{'NOW'|translate}})</small>
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input id="status-lock" type="radio" class="form-check-input" [(ngModel)]="currentStatus" value="lock">
          <label for="status-lock" class="form-check-label">
            <span class="me-1 badge bg-secondary text-dark">lock</span>
            <small *ngIf="'lock' === member?.status" class="text-secondary">({{'NOW'|translate}})</small>
          </label>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal"> {{'CANCEL'|translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="onSubmit()" [appLoading]="saving">
          {{'SAVE_DATA'|translate}}
        </button>
      </div>
    </div>
  </div>
</div>
