<div class="offcanvas offcanvas-end" tabindex="-1" id="metatagFormOffcanvas" style="width: 40em;">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasBottomLabel">{{updateItem ? 'แก้ไข':'สร้าง'}} Meta tag</h5>
    <button type="button" id="metatag-close" class="btn-close text-reset" data-bs-dismiss="offcanvas"
      aria-label="Close"></button>
  </div>
  <div class="offcanvas-body small">
    <div class="mb-3">
      <label for="" class="form-label">{{'CHOOSE_AGENT'|translate}}</label>
      <app-select-agent-form [(ngModel)]="agentId"></app-select-agent-form>
    </div>
    <form [formGroup]="form" (submit)="onSubmit()">
      <div class="mb-3">
        <label for="" class="form-label">Selete Page {{form.value.tag_page}}</label>
        <div class="d-block">
          <button type="button" class="btn btn-info m-1" (click)="onSeletePage(key)" *ngFor="let key of PageKey"
            [ngClass]="{'active':key == form.value.tag_page}">
            {{key}}
          </button>
        </div>
        <div class="text-danger" *ngIf="!updateItem">
          * {{'IF_PAGE_HAVE'|translate}}<strong>{{'INFORMATION_ALREADY'|translate}}</strong>{{'WILL_BE'|translate}}<strong>{{'OVERWRITE'|translate}}</strong>
        </div>
      </div>
      <div class="" formArrayName="tags">
        <div class="card mb-3" *ngFor="let groupTag of getFormArray(form, 'tags').controls; let i = index"
          [formGroupName]="i">
          <div class="card-body">
            <div class="mb-3">
              <label for="" class="form-label">Key</label>
              <input type="text" class="form-control" formControlName="tag_key">
            </div>
            <div class="">
              <label for="" class="form-label">{{'CONTENT'|translate}}</label>
              <textarea id="" class="form-control" rows="1" formControlName="tag_content"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="text-end mb-3">
        <button type="submit" class="btn btn-primary btn-submit">
          <span *ngIf="updateItem">{{'EDIT'|translate}}</span>
          <span *ngIf="!updateItem">{{'CREATE'|translate}}</span>
          Meta tag
          <!-- {{updateItem ? 'แก้ไข':'สร้าง'}} Meta tag -->
        </button>
      </div>
    </form>
  </div>
</div>
