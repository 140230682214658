import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LoginGameHistory } from 'src/app/apis/transaction.service';

@Component({
  selector: 'app-result-amb-offcanvas',
  templateUrl: './result-amb-offcanvas.component.html',
  styleUrls: ['./result-amb-offcanvas.component.scss']
})
export class ResultAmbOffcanvasComponent implements OnInit, OnChanges {
  @Input() gameHistoryItem: LoginGameHistory;
  @Input() keyName = 'result_amb';

  jsonItem: any;
  stringItem = '';
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    try {
      if (this.gameHistoryItem) {
        this.jsonItem = JSON.parse(this.gameHistoryItem[this.keyName]);
      } else {
        this.jsonItem = null;
        this.stringItem = '';
      }
    } catch (error) {
      this.stringItem = this.gameHistoryItem[this.keyName];
    }
  }
}
