<div class="row mb-2">
  <div class="col-auto mb-2"> {{'STATUS'|translate}} : </div>
  <div class="col-auto mb-2" *ngIf="gameType === 'PARLAY'">
    <div class="form-check form-check-inline">
      <input id="status-check-on-{{inputId}}" type="radio" class="form-check-input" name="parley-{{inputId}}"
        [value]="true" [(ngModel)]="setting.group_init_member.updateLineStatus.parlayEnable" [disabled]="readOnly">
      <label for="status-check-on-{{inputId}}" class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
    </div>
    <div class="form-check form-check-inline">
      <input id="status-check-off-{{inputId}}" type="radio" class="form-check-input" name="parley-{{inputId}}"
        [value]="false" [(ngModel)]="setting.group_init_member.updateLineStatus.parlayEnable" [disabled]="readOnly">
      <label for="status-check-off-{{inputId}}" class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
    </div>
  </div>
  <div class="col-auto mb-2" *ngIf="gameType === 'STEP'">
    <div class="form-check form-check-inline">
      <input id="status-check-on-{{inputId}}" type="radio" class="form-check-input" name="step-{{inputId}}"
        [value]="true" [(ngModel)]="setting.group_init_member.updateLineStatus.stepEnable" [disabled]="readOnly">
      <label for="status-check-on-{{inputId}}" class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
    </div>
    <div class="form-check form-check-inline">
      <input id="status-check-off-{{inputId}}" type="radio" class="form-check-input" name="step-{{inputId}}"
        [value]="false" [(ngModel)]="setting.group_init_member.updateLineStatus.stepEnable" [disabled]="readOnly">
      <label for="status-check-off-{{inputId}}" class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
    </div>
  </div>
</div>

<div class="mb-3">
  <div class="row">
    <div class="col-12 col-md-6 mb-3">
      <div class="mb-3">
        <label for="">{{'SET_LIMIT'|translate}}</label>
        <div class="border-bottom my-2"></div>
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="text-end">{{'MAX_TURN'|translate}} :</td>
              <td *ngIf="gameType === 'PARLAY'">
                <input type="text" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.step.parlay.maxPerBet">
                <div>Max = {{setting.group_init_member.maxSetting.limitSetting.step.parlay.maxPerBet | number}}</div>
              </td>
              <td *ngIf="gameType === 'STEP'">
                <input type="text" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.step.step.maxPerBet">
                <div>Max = {{setting.group_init_member.maxSetting.limitSetting.step.step.maxPerBet | number}}</div>
              </td>
              <td>
              </td>
            </tr>
            <tr>
              <td class="text-end">{{'MIN_TURN'|translate}} :</td>
              <td *ngIf="gameType === 'PARLAY'">
                <input type="text" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.step.parlay.minPerBet">
                <div>Min = {{setting.group_init_member.maxSetting.limitSetting.step.parlay.minPerBet | number}}</div>
              </td>
              <td *ngIf="gameType === 'STEP'">
                <input type="text" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.step.step.minPerBet">
                <div>Min = {{setting.group_init_member.maxSetting.limitSetting.step.step.minPerBet | number}}</div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="text-end">{{'MAX_PLAY_DAY'|translate}} :</td>
              <td *ngIf="gameType === 'PARLAY'">
                <input type="text" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.step.parlay.maxBetPerDay">
                <div>Max = {{setting.group_init_member.maxSetting.limitSetting.step.parlay.maxBetPerDay | number}}</div>
              </td>
              <td *ngIf="gameType === 'STEP'">
                <input type="text" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.step.step.maxBetPerDay">
                <div>Max = {{setting.group_init_member.maxSetting.limitSetting.step.step.maxBetPerDay | number}}</div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="text-end">{{'MAX_PAY_BILL'|translate}} : </td>
              <td *ngIf="gameType === 'PARLAY'">
                <input type="text" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.step.parlay.maxPayPerBill">
                <div>Max = {{setting.group_init_member.maxSetting.limitSetting.step.parlay.maxPayPerBill | number}}
                </div>
              </td>
              <td *ngIf="gameType === 'STEP'">
                <input type="text" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.step.step.maxPayPerBill">
                <div>Max = {{setting.group_init_member.maxSetting.limitSetting.step.step.maxPayPerBill | number}}</div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="text-end">{{'MAX_NUMBER_PAIRS_BILL'|translate}} : </td>
              <td *ngIf="gameType === 'PARLAY'">
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.step.parlay.maxMatchPerBet">
                  <option *ngFor="let m of maxBillOptions" [ngValue]="m.value">{{m.label}}</option>
                </select>
                <div>Max = {{setting.group_init_member.maxSetting.limitSetting.step.parlay.maxMatchPerBet | number}}
                </div>
              </td>
              <td *ngIf="gameType === 'STEP'">
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.step.step.maxMatchPerBet">
                  <option *ngFor="let m of maxBillOptions" [ngValue]="m.value">{{m.label}}</option>
                </select>
                <div>Max = {{setting.group_init_member.maxSetting.limitSetting.step.step.maxMatchPerBet | number}}</div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="text-end">{{'MINIMUM_NUMBER_OF_PAIRS_BILL'|translate}} : </td>
              <td *ngIf="gameType === 'PARLAY'">
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.step.parlay.minMatchPerBet" disabled>
                  <option *ngFor="let m of maxBillOptions" [ngValue]="m.value">{{m.label}}</option>
                </select>
                <div>Min = {{setting.group_init_member.maxSetting.limitSetting.step.parlay.minMatchPerBet | number}}
                </div>
              </td>
              <td *ngIf="gameType === 'STEP'">
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.step.step.minMatchPerBet" disabled>
                  <option *ngFor="let m of maxBillOptions" [ngValue]="m.value">{{m.label}}</option>
                </select>
                <div>Min = {{setting.group_init_member.maxSetting.limitSetting.step.step.minMatchPerBet | number}}
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
