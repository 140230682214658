<div class="row">
  <div class="col-12 col-md-6 col-lg-4">
    <div class="mb-3">
      <label for="" class="form-label">{{'MAX_BET'|translate}}</label>
      <input type="text" class="form-control" [(ngModel)]="klottoSetting.MaxBet" [min]="0">
    </div>
  </div>
</div>

<label for="" class="form-label">{{'CHOOSE_TYPE_OF_PLAY'|translate}}</label>
<div class="mb-2">
  <ng-container *ngFor="let play of klottoSetting.Lottos">
    <button type="button" class="btn m-1" (click)="selectChange(play)"
      [class]="lottoPlayActive === play.LottoTypeKey ? 'btn-primary': 'btn-outline-primary'">
      <img *ngIf="!!flags[play.LottoTypeCode]" [src]="flags[play.LottoTypeCode]"
        style="width: 20px; height: 20px; object-fit: contain;">
      {{'LOTTO.'+play.LottoTypeCode | translate}}
    </button>
  </ng-container>
</div>

<table class="table table-borderless">
  <tbody>
    <tr>
      <td class="text-end" width="200px"><strong>{{'STATUS'|translate}}</strong> :</td>
      <td>
        <div class="form-check form-check-inline">
          <input id="enable" type="radio" class="form-check-input" [value]="true" [(ngModel)]="playSettings.IsEnabled">
          <label for="enable" class="form-check-label text-success"> {{'ACTIVE'|translate}} </label>
        </div>
        <div class="form-check form-check-inline">
          <input id="disable" type="radio" class="form-check-input" [value]="false"
            [(ngModel)]="playSettings.IsEnabled">
          <label for="disable" class="form-check-label text-danger"> {{'DISABLED'|translate}} </label>
        </div>
      </td>
    </tr>
    <tr *ngIf="isAdmin">
      <td class="text-end"><strong>{{'SET_UP_PARTNERSHIP'|translate}}</strong> :</td>
      <td>
        <span class="py-2 px-3 bg-secondary me-2"> {{'ShareParent'}} </span>
        <select class="form-select d-inline-block" [(ngModel)]="playSettings.ShareParent" style="width: 150px;">
          <option *ngFor="let o of takingOption" [ngValue]="o.value"> {{o.label}} </option>
        </select>
      </td>
    </tr>
  </tbody>
</table>
<div class="row">
  <div class="col-12 col-md-6 mb-2" *ngFor="let setting of playSettings.Settings">
    <div class="pb-2 border-bottom">
      <label for="" class="form-label"> {{ 'LOTTO.' + setting.LottoTypePlayName | translate }} </label>
      <div class="input-group">
        <label for="" class="input-group-text">{{'MAX_BET'|translate}}</label>
        <input type="number" class="form-control" [(ngModel)]="setting.MaxBet" [max]="setting.DefaultMaxBet">
      </div>
    </div>
  </div>
</div>
