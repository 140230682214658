import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { HttpApiService } from '../services/http-api.service';
import { formPage } from '../utils/functions/form-page';
import { noWhitespaceValidator } from '../utils/functions/no-whitespace';
import { PageModel } from '../utils/interfaces/page-model';
import { Pagination } from '../utils/interfaces/pagination';

@Injectable({
  providedIn: 'root',
})
export class GetToKnowService {
  constructor(
    private http: HttpApiService,
    private ngFb: FormBuilder,
  ) { }

  /**
   * GET get to know with Array
   * @param params ?agent_id={agent_id}&show_member={show_member}
   * @returns Obs
   */
  get(params: any = {}) {
    return this.http.get<GetToKnow[]>(`api/ask4bet/get_to_know/list`, { params });
  }

  get createFormPage() {
    return formPage({ agent_id: '', show_member: '' });
  }

  /**
   * GET get to know with Pagination
   * @param params PageModel ?agent_id={agent_id}&show_member={show_member}
   * @returns Obs
   */
  getPagination(params: PageModel = {}) {
    return this.http.get<Pagination<GetToKnow[]>>(`api/ask4bet/get_to_know`, { params });
  }

  get createFormToKnowModel(): FormGroup<GetToKnowModel> {
    return this.ngFb.group({
      know_by: ['', [noWhitespaceValidator]],
      show_for_member: [true],
    });
  }

  post(model: GetToKnowModel, agent_id: number) {
    return this.http.post(`api/ask4bet/get_to_know?agent_id=${agent_id || ''}`, model);
  }

  put(model: GetToKnowModel, know_by_id: number) {
    return this.http.put(`api/ask4bet/get_to_know/${know_by_id || ''}`, model);
  }

  delete(know_by_id: number) {
    return this.http.delete(`api/ask4bet/get_to_know?id=${know_by_id}`);
  }
}

export interface GetToKnow {
  id: number;
  know_by: string;
  status: string;
  show_for_member: boolean;
  agent_id?: any;
}

export interface GetToKnowModel {
  know_by: string;
  show_for_member: boolean;
}
