import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { Agent } from './apis/agent.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  version = '1.2.20232909';

  viewImage$ = new ReplaySubject<string>();

  private agentId$ = new BehaviorSubject<number>(0);
  updateAgentId$ = new Subject<number>();
  private currentAgent$ = new BehaviorSubject<Agent>(null);

  memberCreditFormOffcanvas$ = new Subject<MemberCreditFormData>();
  memberCreditClose$ = new Subject();
  memberClearTurn$ = new Subject<number>();
  constructor() { }

  viewImage(src: string) {
    this.viewImage$.next(src);
  }

  closeImage() {
    this.viewImage$.next(null);
  }

  get getAgentId$() {
    return this.agentId$.pipe(debounceTime(300), map(val => val || 0));
  }

  setAgentId$(data: number) {
    this.agentId$.next(data);
  }

  get getCurrentAgent$() {
    return this.currentAgent$.pipe(debounceTime(300), map(val => val || null));
  }

  setCurrentAgent$(data: Agent) {
    this.currentAgent$.next(data);
  }

  exportCSV(data: string, name: string) {
    // var csvString = 'ı,ü,ü,ğ,ş,#Hashtag,ä,ö';
    var universalBOM = "\uFEFF";
    var a = window.document.createElement('a');
    a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + data));
    a.setAttribute('download', `${name}.csv`);
    window.document.body.appendChild(a);
    a.click();
  }

  openMemberCreditFormOffcanvas(data: MemberCreditFormData) {
    this.memberCreditFormOffcanvas$.next(data);
  }
}

export interface MemberCreditFormData {
  is_add: boolean;
  member_id: number;
}
