<form [formGroup]="form" (submit)="onSubmit()">
  <div class="mb-3">
    <label for="" class="form-label">{{'CHOOSE_MEMBER'|translate}}</label>
    <app-select-member-form formControlName="member_id" [showCredit]="true" (change)="selectMemberChange()"
      (changeCreditMember)="onSetCredit($event)">
    </app-select-member-form>
  </div>
  <div class="mb-3" *ngIf="isAdd && Config.AutoSystem">
    <label for="" class="form-label">{{'PROMOTION_IN_PROGRESS'|translate}}</label>
    <div class="bg-secondary p-2">
      <div class="d-flex flex-row" *ngIf="promotion">
        <img class="me-2" [src]="promotion.image | imgHost" alt="" width="60px" height="60px" appViewImg>
        <div class="flex-grow-1 h-100">
          <div class=""> <strong>{{'NAME'|translate}}: </strong> {{promotion?.name}} </div>
          <div class="">
            <strong>{{'BONUS_AMOUNT'|translate}}: </strong>
            {{promotion?.amount}}{{promotion?.type === 'percent' ? '%': ' เครดิต'}}
          </div>
          <div class="text-end mt-auto">
            <a class="link-info m-1" data-bs-toggle="offcanvas" data-bs-target="#promotionOffcanvas"> <i
                class="fas fa-eye"></i> {{'SEE_DETAILS'|translate}}</a>
            <a class="link-danger m-1" (click)="onCancelPromotionRequest()"> <i class="fas fa-times"></i>
              {{'CANCEL'|translate}}</a>
          </div>
        </div>
      </div>
      <div class="" *ngIf="!promotion">
        {{'NO_ITEM'|translate}}
      </div>
    </div>
  </div>
  <div class="mb-3" *ngIf="isAdd && Config.AutoSystem">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="DATETIME" formControlName="showAddTime">
      <label class="form-check-label" for="DATETIME">
        {{'DATETIME'|translate}}
      </label>
    </div>
    <input type="datetime-local" class="form-control" formControlName="datetime" *ngIf="form.value.showAddTime">
  </div>
  <div class="mb-3" *ngIf="Config.AutoSystem">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="NOTE" formControlName="showAddNote">
      <label class="form-check-label" for="NOTE">
        {{'NOTE'|translate}}
      </label>
    </div>
    <textarea rows="3" class="form-control" formControlName="note" *ngIf="form.value.showAddNote"></textarea>
  </div>
  <div class="mb-3" *ngIf="isAdd && Config.AutoSystem">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="PROMOTION" formControlName="showAddPromotion">
      <label class="form-check-label" for="PROMOTION">
        {{'PROMOTION'|translate}}
      </label>
    </div>
    <app-select-promotion-form formControlName="promotion_id"
      *ngIf="form.value.showAddPromotion"></app-select-promotion-form>
  </div>
  <div class="mb-3" *ngIf="isAdd && Config.AutoSystem">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="PICTURE_TRANSFER_SLIP" formControlName="showAddSlip">
      <label class="form-check-label" for="PICTURE_TRANSFER_SLIP">
        {{'PICTURE_TRANSFER_SLIP'|translate}}
      </label>
    </div>
    <app-input-file-form formControlName="image_statement" width="250px" height="250px"
      *ngIf="form.value.showAddSlip"></app-input-file-form>
  </div>


  {{'AMOUNT_CREDIT_TO_TOP_UP'|translate}} <strong style="color: red;">**</strong>
  <div class="mb-3 border p-1 rounded">
    <ng-container *ngIf="svCurrency.getCurrency().value === 'THB'">
      <label for="" class="form-label">{{'AMOUNT'|translate}}</label>
      <input type="number" class="form-control" value="0" formControlName="amount">
    </ng-container>
    <ng-container *ngIf="svCurrency.getCurrency().value !== 'THB'">
      <div class="d-flex justify-content-between">
        <small>{{'AMOUNT'|translate}}</small>
        <small>{{'CONVERT_TO'|translate}}</small>
      </div>
      <div class="input-group mb-2">
        <ng-container *ngIf="isAdd">
          <span class="input-group-text">
            <img [src]="svCurrency.getCurrency().image" width="20px" class="me-1" alt="">
            {{svCurrency.getCurrency().value}}
          </span>
          <input type="tel" class="form-control text-center" placeholder="0.00" formControlName="amountEx"
            (keyup)="onChangeTHB()" (blur)="onChangeTHB()">
          <span class="input-group-text"><i class="fa-solid fa-right-left"></i></span>
          <input type="tel" class="form-control text-center" placeholder="0.00" formControlName="amount"
            (keyup)="onChangeANY()" (blur)="onChangeANY()">
          <span class="input-group-text">
            <img [src]="'https://cdn.karnival.systems/images/flags/th.png'" class="me-1" width="20px" alt=""> THB
          </span>
        </ng-container>
        <ng-container *ngIf="!isAdd">
          <span class="input-group-text">
            <img [src]="'https://cdn.karnival.systems/images/flags/th.png'" class="me-1" width="20px" alt=""> THB
          </span>
          <input type="tel" class="form-control text-center" placeholder="0.00" formControlName="amount"
            (keyup)="onChangeANY()" (blur)="onChangeANY()">
          <span class="input-group-text"><i class="fa-solid fa-right-left"></i></span>
          <input type="tel" class="form-control text-center" placeholder="0.00" formControlName="amountEx"
            (keyup)="onChangeTHB()" (blur)="onChangeTHB()">
          <span class="input-group-text">
            <img [src]="svCurrency.getCurrency().image" width="20px" class="me-1" alt="">
            {{svCurrency.getCurrency().value}}
          </span>
        </ng-container>
      </div>
    </ng-container>
    <small>
      {{'CURRENTCY_EXCHANGE_RATE'|translate}}
      <span class="text-success me-1">
        {{(svCurrency.currencyExchange | async).ANY2THB[svCurrency.getCurrency().value]}}
      </span>
      {{svCurrency.getCurrency().value}} = 1.00000 THB
    </small>
    <div class="d-flex justify-content-between mt-2">
      <span>{{'CREDIT_BEFORE'|translate}}</span>
      <span>{{credit | number : '0.2-2' }} THB</span>
    </div>
    <div class="d-flex justify-content-between">
      <span>{{isAdd ? 'เพิ่มเครดิต' : 'ลดเครดิต'}}</span>
      <span [ngClass]="{'text-success':isAdd,'text-danger':!isAdd}">
        {{isAdd?'+':'-'}}{{form.value.amount | number:'.2-2'}} THB
      </span>
    </div>
    <div class="d-flex justify-content-between" *ngIf="isAdd">
      <span>{{'BONUS'|translate}}</span>
      <span class="text-success">0 THB</span>
    </div>
    <div class="d-flex justify-content-between">
      <span>{{'INCLUDED_AS'|translate}}</span>
      <span class="text-primary">
        <u>{{ (isAdd ? credit + (+form.value.amount) : credit - (+form.value.amount)) | number:'1.2-2'}}</u> THB
      </span>
    </div>
    <div class="d-flex justify-content-between">
      <span>{{'INCLUDED_AS'|translate}} {{svCurrency.getCurrency().value}}</span>
      <span class="opacity-50">
        <u>~{{ (isAdd ? credit + (+form.value.amount) : credit - (+form.value.amount)) | exTHBToAny }}</u> THB
      </span>
    </div>
  </div>
  <hr>
  <div class="text-end">
    <button type="submit" class="btn btn-primary btn-submit" [appLoading]="saving" *ngIf="isAdd">
      {{'CONFIRM'|translate}} {{'DEPOSIT'|translate}}
    </button>
    <button type="submit" class="btn btn-primary btn-submit" [appLoading]="saving" *ngIf="!isAdd">
      {{'CONFIRM'|translate}} {{'WITHDRAWAL'|translate}}
    </button>
  </div>
</form>
