import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Member, MemberService } from 'src/app/apis/member.service';
import { AppCore } from 'src/app/utils/classes/app-core';

@Component({
  selector: 'app-credit-form-offcanvas',
  templateUrl: './credit-form-offcanvas.component.html',
  styleUrls: ['./credit-form-offcanvas.component.scss']
})
export class CreditFormOffcanvasComponent extends AppCore implements OnInit, OnChanges {
  @Input() isAdd: boolean;
  @Input() memberId: number;
  @Input() id = 'creditFormOffcanvas';

  member: Member;
  constructor(
    private svMember: MemberService,
  ) {
    super();
  }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.memberId) {
      this.getMemberById();
    }
  }

  override clickBtnClose(): void {
    super.clickBtnClose('btn-close-credit-form-offcanvas');
  }

  getMemberById() {
    this.svMember.getMemberById(this.memberId).subscribe(res => {
      this.member = res;
    });
  }
}
