import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@ngneat/reactive-forms';
import { Subscription } from 'rxjs';
import { AgentForm } from 'src/app/utils/interfaces/create-agent-form.interface';

@Component({
  selector: 'app-setting-klotto-play',
  templateUrl: './setting-klotto-play.component.html',
  styleUrls: ['../../setting-agent.scss'],
})
export class SettingKlottoPlayComponent implements OnInit, OnChanges, OnDestroy {
  @Input() form: FormGroup<AgentForm>;

  // playCodes = ["THA", "THA-GSB", "THA-BAAC", "VNM", "VNM-VIP", "LAO", "MYS", "VNM-SPL", "YEEKEE", "SGP", "DEU", "KOR", "JPN", "HKG", "CHN", "TWN", "EGY", "UK", "RUS", "IND", "US", "THA-STOCK"];
  // selectPlay = "THA";

  flagcdn = (code) => (`https://flagcdn.com/w20/${code}.png`)
  newVals = (value) => 'https://backoffice-24group.karnival.systems/assets/bank/' + value.toLowerCase() + '.png';
  flags = {
    "THA": this.flagcdn('th'),
    "THA_BANK": this.flagcdn('th'),
    "THA_GSB": this.newVals('gsb'),
    "THA_BAAC": this.newVals('baac'),
    "VNM": this.flagcdn('vn'),
    "VNM_VIP": this.flagcdn('vn'),
    "LAO": this.flagcdn('la'),
    "MYS": this.flagcdn('my'),
    "VNM_SPL": this.flagcdn('la'),
    "YEEKEE": "https://dev-klotto.karnival.cloud/assets/favicon.png",
    "SGP": this.flagcdn('sg'),
    "DEU": this.flagcdn('de'),
    "KOR": this.flagcdn('kr'),
    "JPN": this.flagcdn('jp'),
    "HKG": this.flagcdn('hk'),
    "CHN": this.flagcdn('cn'),
    "TWN": this.flagcdn('tw'),
    "EGY": this.flagcdn('eg'),
    "UK": this.flagcdn('gb'),
    "RUS": this.flagcdn('ru'),
    "IND": this.flagcdn('in'),
    "US": this.flagcdn('us'),
    "THA_STOCK": this.flagcdn('th'),
  };

  KlottoPlays = [
    { name: 'THAI', key: 'THA', playNames: ['2_BOTTOM', '2_TOP', '3_BOTTOM', '3_FRONT', '3_ROLL', '3_TOP'].reverse() },
    { name: 'THAIBANK', key: 'THA_BANK', playNames: ['1_BOTTOM', '1_TOP', '2_BOTTOM', '2_TOP', '3_BOTTOM', '3_FRONT', '3_ROLL', '3_TOP'].reverse() },
    { name: 'LAO', key: 'LAO', playNames: ['1_BOTTOM', '1_TOP', '2_BOTTOM', '2_TOP', '3_ROLL', '3_TOP'].reverse() },
    { name: 'MYS', key: 'MYS', playNames: ['1_BOTTOM', '1_TOP', '2_BOTTOM', '2_TOP', '3_ROLL', '3_TOP'].reverse() },
    { name: 'VNM', key: 'VNM', playNames: ['1_BOTTOM', '1_TOP', '2_BOTTOM', '2_TOP', '3_BOTTOM', '3_ROLL', '3_TOP'].reverse() },
    { name: 'YEEKEE', key: 'YEEKEE', playNames: ['1_BOTTOM', '1_TOP', '2_BOTTOM', '2_TOP', '3_ROLL', '3_TOP'].reverse() },
    { name: 'STOCK', key: 'THA_STOCK', playNames: ['1_BOTTOM', '1_TOP', '2_BOTTOM', '2_TOP', '3_ROLL', '3_TOP'].reverse() },
  ];
  selectLotto = this.KlottoPlays[0];

  formValue: AgentForm;
  Subscription = new Subscription();
  constructor() { }

  ngOnInit(): void {
    if (this.form) {
      const limit_amb_form$ = this.form.get('limit_setting_klotto_form').valueChanges.subscribe(res => {
        this.formValue = this.form.getRawValue();
      });
      this.Subscription.add(limit_amb_form$);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.form) {
      this.formValue = this.form.getRawValue();
    }
  }

  ngOnDestroy(): void {
    this.Subscription.unsubscribe();
  }

  onSelectLotto(play: any) {
    this.selectLotto = play;
  }
}
