import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Modal } from 'bootstrap';
import { Member, MemberService, MemberType } from 'src/app/apis/member.service';
import { alertSuccess, alertWarning } from 'src/app/utils/functions/alert';

@Component({
  selector: 'app-change-status-member-modal',
  templateUrl: './change-status-member-modal.component.html',
  styleUrls: ['./change-status-member-modal.component.scss']
})
export class ChangeStatusMemberModalComponent implements OnInit, OnChanges {
  @Input() member: Member;
  @Input() modal: Modal;

  currentStatus: MemberType;
  saving = false;
  constructor(
    private svMember: MemberService,
  ) { }

  ngOnInit(): void {
    const modalEl = document.getElementById(`changeStatusMemberModal`);
    modalEl.addEventListener('hidden.bs.modal', () => {
      this.currentStatus = null;
    });
  }

  ngOnChanges() {
    if (this.member) {
      this.currentStatus = this.member.status;
    }
  }

  async onSubmit() {
    if (this.currentStatus === this.member.status) {
      alertWarning({ message: `ยังไม่เปลี่ยนสถานะสมาชิก` });
      return;
    }

    this.saving = true;
    try {
      if (this.currentStatus === 'confirmed') {
        await this.svMember.confirm(this.member.id).toPromise();
      } else if (this.currentStatus === 'suspend') {
        await this.svMember.suspend(this.member.id).toPromise();
      } else {
        await this.svMember.lock(this.member.id).toPromise();
      }
      this.saving = false;
      alertSuccess({ message: 'เปลี่ยนสถานะสมาชิกสำเร็จ' });
      this.modal.hide();
    } catch (e) { }
  }

}
