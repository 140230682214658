import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-show-date-time',
  templateUrl: './show-date-time.component.html',
  styles: [':host { display: block}']
})
export class ShowDateTimeComponent implements OnInit, OnChanges {
  @Input() date: string;
  @Input() format = 'dd/MM/yyyy HH:mm:ss';
  @Input() fromNow = true;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

}
