import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import * as dayjs from 'dayjs';
import { HttpApiService } from '../services/http-api.service';
import { noWhitespaceValidator } from '../utils/functions/no-whitespace';
import { PageModel } from '../utils/interfaces/page-model';
import { Pagination } from '../utils/interfaces/pagination';

@Injectable({
  providedIn: 'root'
})
export class RevertCreditService {

  constructor(
    private http: HttpApiService,
    private ngFb: FormBuilder,
  ) { }

  gameTypes = [
    { name: 'คาสิโน', value: 'casino' },
    { name: 'Keno', value: 'keno' },
    { name: 'หวย', value: 'lotto' },
    { name: 'กีฬา', value: 'sport' },
    { name: 'ไพ่', value: 'poker' },
    { name: 'สล็อต', value: 'slot' },
    { name: 'Trading', value: 'trading' },
  ];

  getRound(agent_id: number) {
    return this.http.get<RevertCredit>(`api/ask4bet/revert_credit/round?agent_id=${agent_id}`);
  }

  postRound(agent_id: number, model: RevertCreditRoundModel) {
    return this.http.post<RevertCredit>(`api/ask4bet/revert_credit/create?agent_id=${agent_id}`, model);
  }

  deleteRound(round_id: number, agent_id: number) {
    return this.http.delete(`api/ask4bet/revert_credit/delete/round?round_id=${round_id}&agent_id=${agent_id}`)
  }

  getSetting(agent_id: number) {
    return this.http.get<RevertCreditSettting>(`api/ask4bet/revert_credit/setting?agent_id=${agent_id}`);
  }

  putSetting(agent_id: number, model: RevertCreditSettting) {
    return this.http.put(`api/ask4bet/revert_credit/setting_revert_credit?agent_id=${agent_id}`, model);
  }

  getRequest(revert_round_id: number, agent_id: number, params: PageModel) {
    return this.http.get<Pagination<RevertCreditRequest[]>>(
      `api/ask4bet/revert_credit/revert_request?revert_round_id=${revert_round_id}&agent_id=${agent_id}`,
      { params }
    );
  }

  confirmRequest(revert_request_id: number) {
    return this.http.post(`api/ask4bet/revert_credit/confirmed?revert_request_id=${revert_request_id}`, {});
  }

  rejectRequest(revert_request_id: number) {
    return this.http.delete(`api/ask4bet/revert_credit/reject_request?revert_request_id=${revert_request_id}`);
  }

  getRoundExpire(agent_id: any) {
    return this.http.get(`api/ask4bet/revert_credit/expire?agent_id=${agent_id}`);
  }

  get createFormRound(): FormGroup<RevertCreditRoundModel> {
    return this.ngFb.group({
      start_revert: ['', [noWhitespaceValidator]],
      end_revert: ['', [noWhitespaceValidator]],
      winlose_start: ['', [noWhitespaceValidator]],
      winlose_end: ['', [noWhitespaceValidator]],
      revert_detail: this.ngFb.array([]),
      max_credit_revert: [0, [Validators.min(0), Validators.required]],
      turn_over: [0, [Validators.min(0), Validators.required]],
      turn_over_sport: [true],
      turn_over_casino: [true],
      turn_over_poker: [true],
      turn_over_slot: [true],
      turn_over_keno: [true],
      turn_over_lotto: [true],
      turn_over_trading: [true],
      auto_round: [false],
      image: [''],

      useSameDate: [false],
    });
  }

  get createFormRevertDetail(): FormGroup<RevertCreditDetailModel> {
    return this.ngFb.group({
      min_winlose: [0, [Validators.required]],
      percent_revert: [0, [Validators.required]],
    });
  }

  /**
   * Get Return Credit Round History
   * @param params ?agent_id={agent_id}
   * @returns Obs
   */
  getRoundHistory(params: PageModel) {
    return this.http.get<Pagination<RevertCredit[]>>(`api/ask4bet/revert_credit/history`, { params });
  }

  setRevertCreditItem(item: RevertCredit) {
    const startDt = dayjs(item.start_revert);
    const endDt = dayjs(item.end_revert);
    item.totalDays = endDt.diff(startDt, 'days');

    item.turnOverGames = [];
    for (const type of this.gameTypes) {
      const val = item[`turn_over_${type.value}`];
      if (val) {
        item.turnOverGames.push(type.name);
      }
    }
    return item;
  }
}

export interface RevertCreditRoundModel {
  start_revert: string;
  end_revert: string;
  winlose_start: string;
  winlose_end: string;
  revert_detail: RevertCreditDetailModel[];
  max_credit_revert: number;
  turn_over: number;
  turn_over_sport: boolean;
  turn_over_casino: boolean;
  turn_over_poker: boolean;
  turn_over_slot: boolean;
  turn_over_keno: boolean;
  turn_over_lotto: boolean;
  turn_over_trading: boolean;
  image: string;

  useSameDate?: boolean;
}

export interface RevertCreditDetailModel {
  percent_revert: number;
  min_winlose: number;
}



export interface RevertCredit {
  revert_round_details: RevertCreditRoundDetail[];
  Id: number;
  agent_id: number;
  max_credit_revert: number;
  turn_over: number;
  turn_over_sport: boolean;
  turn_over_casino: boolean;
  turn_over_poker: boolean;
  turn_over_slot: boolean;
  turn_over_keno: boolean;
  turn_over_lotto: boolean;
  turn_over_trading: boolean;
  start_revert: string;
  end_revert: string;
  winlose_start: string;
  winlose_end: string;
  image: string;
  status: string;
  create_date: string;
  update_date: string;
  auto_round: boolean;

  totalDays?: number;
  turnOverGames?: string[];
}

export interface RevertCreditRoundDetail {
  Id: number;
  revert_round_id: number;
  agent_id: number;
  percent_revert: number;
  min_winlose: number;
  status: string;
  create_by: number;
  update_by: number;
  create_date: string;
  update_date: string;
}

export interface RevertCreditRequest {
  Id: number;
  member_id: number;
  username: string;
  agent_id: number;
  agentName: string;
  revert_round_id: number;
  percent_revert: number;
  max_credit_revert: number;
  start_revert: string;
  end_revert: string;
  valid_amount: number;
  wl_amount: number;
  revert_credit: number;
  status: string;
  turn_over_slot: boolean;
  turn_over_poker: boolean;
  turn_over_casino: boolean;
  turn_over_sport: boolean;
  turn_over_keno: boolean;
  turn_over_lotto: boolean;
  turn_over_trading: boolean;
  create_date: string;
  update_date: string;
}

export interface RevertCreditSettting {
  revert_credit_auto: boolean;
  revert_credit_on: boolean;
}
