<form [formGroup]="form">
  <ng-container formGroupName="limit_setting_amb_form">
    <div class="" [class.block]="!form?.getRawValue().limit_setting_amb_form.step_percent_max">
      <div class="mb-2">
        <label for="" class="form-label me-3 mb-2"> {{'STATUS'|translate}} : </label>
        <div class="form-check form-check-inline">
          <input id="step-status-on" type="radio" class="form-check-input" [value]="true" formControlName="step_enable">
          <label for="step-status-off" class="form-check-label text-success">{{'ACTIVE'|translate}}</label>
        </div>
        <div class="form-check form-check-inline">
          <input id="step-status-on" type="radio" class="form-check-input" [value]="false"
            formControlName="step_enable">
          <label for="step-status-off" class="form-check-label text-danger">{{'DISABLED'|translate}}</label>
        </div>
      </div>

      <label for="" class="form-label mb-2"> {{'SET_UP_PARTNERSHIP'|translate}} : </label>
      <div class="row">
        <div class="col-12 col-lg-4 mb-3">
          <div class="input-group">
            <div class="input-group-text">
              STEP
              <i class="ms-1 bi bi-exclamation-circle text-warning" *ngIf="form?.getRawValue().limit_setting_amb_form.step_percent_own >
                form?.getRawValue().limit_setting_amb_form.step_percent_max"></i>
            </div>
            <input type="number" class="form-control text-end" formControlName="step_percent_own" [min]="0"
              [max]="form?.getRawValue().limit_setting_amb_form.step_percent_max" step="0.5">
            <div class="input-group-text">%</div>
          </div>
        </div>
        <div class="col-12 col-lg-4 mb-3">
          <div class=""> {{'BOARD'|translate}} = {{form?.getRawValue().limit_setting_amb_form.step_percent_board}}%
          </div>
          <div class=""> MAX = {{form?.getRawValue().limit_setting_amb_form.step_percent_max}}% </div>
          <div class=""> {{'BALANCE'|translate}} ({{'YOUR_PARTNER'|translate}}) =
            {{ form?.getRawValue().limit_setting_amb_form.step_percent_max -
            form?.getRawValue().limit_setting_amb_form.step_percent_own }}%
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-6 mb-3">
          <label for="" class="form-label">{{'SET_LIMIT'|translate}}</label>
          <hr class="mt-0 mb-2">
          <table class="table table-borderless">
            <tbody>
              <tr>
                <td class="text-end"> {{'MAX_TURN'|translate}} : </td>
                <td class="text-start">
                  <div *ngIf="!formValue.limit_setting_amb_form.step_maxPerBet_uplineLimit">
                    <input type="number" class="form-control form-inline" formControlName="step_maxPerBet">
                  </div>
                  <div *ngIf="formValue.limit_setting_amb_form.step_maxPerBet_uplineLimit">
                    <input type="number" class="form-control form-inline" formControlName="step_maxPerBet"
                      [max]="formValue.limit_setting_amb_form.step_maxPerBet_uplineLimit">
                    <div class="small opacity-75"> Max =
                      {{formValue.limit_setting_amb_form.step_maxPerBet_uplineLimit|number:'1.2-2'}}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="text-end"> {{'MIN_TURN'|translate}} : </td>
                <td class="text-start">
                  <div *ngIf="!formValue.limit_setting_amb_form.step_minPerBet_uplineLimit">
                    <input type="number" class="form-control form-inline" formControlName="step_minPerBet">
                  </div>
                  <div *ngIf="formValue.limit_setting_amb_form.step_minPerBet_uplineLimit">
                    <input type="number" class="form-control form-inline" formControlName="step_minPerBet"
                      [max]="formValue.limit_setting_amb_form.step_minPerBet_uplineLimit">
                    <div class="small opacity-75"> Max =
                      {{formValue.limit_setting_amb_form.step_minPerBet_uplineLimit|number:'1.2-2'}}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="text-end"> {{'MAX_PLAY_DAY'|translate}} : </td>
                <td class="text-start">
                  <div *ngIf="!formValue.limit_setting_amb_form.step_maxBetPerDay_uplineLimit">
                    <input type="number" class="form-control form-inline" formControlName="step_maxBetPerDay">
                  </div>
                  <div *ngIf="formValue.limit_setting_amb_form.step_maxBetPerDay_uplineLimit">
                    <input type="number" class="form-control form-inline" formControlName="step_maxBetPerDay"
                      [max]="formValue.limit_setting_amb_form.step_maxBetPerDay_uplineLimit">
                    <div class="small opacity-75"> Max =
                      {{formValue.limit_setting_amb_form.step_maxBetPerDay_uplineLimit|number:'1.2-2'}}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="text-end"> {{'MAX_PAY_BILL'|translate}} : </td>
                <td class="text-start">
                  <div *ngIf="!formValue.limit_setting_amb_form.step_maxPayPerBill_uplineLimit">
                    <input type="number" class="form-control form-inline" formControlName="step_maxPayPerBill">
                  </div>
                  <div *ngIf="formValue.limit_setting_amb_form.step_maxPayPerBill_uplineLimit">
                    <input type="number" class="form-control form-inline" formControlName="step_maxPayPerBill"
                      [max]="formValue.limit_setting_amb_form.step_maxPayPerBill_uplineLimit">
                    <div class="small opacity-75"> Max =
                      {{formValue.limit_setting_amb_form.step_maxPayPerBill_uplineLimit|number:'1.2-2'}}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="text-end"> {{'MAX_NUMBER_PAIRS_BILL'|translate}} : </td>
                <td class="text-start">
                  <select class="form-select form-inline" formControlName="step_maxMatchPerBet">
                    <option *ngFor="let item of maxMatchPerBetItems" [value]="item.value">{{item.name}}</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td class="text-end"> {{'MINIMUM_NUMBER_OF_PAIRS_BILL'|translate}} : </td>
                <td class="text-start">
                  <select class="form-select form-inline" formControlName="step_minMatchPerBet">
                    <option *ngFor="let item of minMatchPerBetBetItems" [value]="item.value">{{item.name}}</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ng-container>
</form>
