export enum AppPath {
  announce = 'announce',
  login = 'login',
  apiDoc = 'api-doc',
  dashboard = 'dashboard',
  manageAgent = 'manage-agent',
  manageMember = 'manage-member',
  manageSage = 'manage-sage',
  scammer = 'scammer',
  transaction = 'transaction',
  tournament = 'tournament',
  credit = 'credit',
  bank = 'bank',
  games = 'games',
  user = 'user',
  forgetPassword = 'forget-password',
  promotion = 'promotion',
  alertTelegram = 'alert-telegram',
  alertLine = 'alert-line',
  help = 'help',
  line = 'line',
  autoTranfer = 'auto-tranfer',
  notFound = 'not-found',
  marketing = 'marketing',
  broadcast = 'broadcast',
  broadcatSMS = 'broadcat-sms',
  chat = 'chat',
  workDeposit = 'work-deposit',
  workWithdraw = 'work-withdraw',
  settings = 'settings',
  point = 'point',
  memberOutstanding = 'member-outstanding',
  pointStore = 'point-store',
  redeemReward = 'redeem-reward',
  rewardWheel = 'reward-wheel',
  bankSupport = 'bank-support',
  soundSetting = 'sound-setting',
  news = 'news',
  Jobs = 'backgroup-jobs',
  profile = 'profile',
  article = 'article',
  metaTag = 'meta-tag',
  helpUser = 'help-user',
  reports = 'reports',
  prints = 'prints',
  settingAgentFE = 'setting-agent-FE',
  logs = 'logs',
  invoices = 'invoices',
  manageSMS = 'manage-sms',
  supportAdmin = 'support-admin',
  klotto = 'klotto',
}

export enum DashboardPath {
  analyze = 'analyze',
  winlose = 'win-lose',
  loyalty = 'loyalty',
  autoOperation = 'auto-operation',
  downlline = 'downlline',
  summary = 'summary',
}
// 'FOOTBALL', 'CASINO', 'GAME', 'LOTTO',
export enum MemberOutstanding {
  FOOTBALL = 'FOOTBALL',
  CASINO = 'CASINO',
  GAME = 'GAME',
  LOTTO = 'LOTTO',
  KLOTTO = 'KLOTTO',
  OTHER = 'OTHER', //  'MULTI_PLAYER', 'POKER', 'KENO', 'TRADING'
}

// export enum AgentPath {
//   agent = 'agent',
//   masterAgent = 'master-agent',
//   sharedholder = 'sharedholder',
// }

export enum ManageMemberPath {
  all = 'all',
  waiting = 'waiting',
  info = 'info',
  memberWithStatus = 'member-with-status',
  profile = 'profile',
}

export enum ManageSagePath {
  users = 'users',
  links = 'links',
  linksSummary = 'links-summary',
}

export enum TransactionPath {
  member = 'member',
  user = 'user',
}

export enum CreditPath {
  addReduce = 'add-reduce',
  add = 'add',
  reduce = 'reduce',
}

export enum BankPath {
  deposit = 'deposit',
  withdraw = 'withdraw',
  message = 'message',
  statements = 'statements',
}

export enum OperatorPath {
  deposit = 'deposit',
  withdraw = 'withdraw',
}

export enum UserPath {
  //   administrator = 'administrator',
  //   operator = 'operator',
  //   operatorDeposit = 'operator-deposit',
  //   operatorWithdraw = 'operator-withdraw',
  //   organizer = 'organizer',
  //   partner = 'partner',
  //   auditor = 'auditor',
  profile = 'profile',
  detail = 'detail',
  history = 'history',
  login = 'login',
}

export enum CUPath {
  create = 'create',
  update = 'update',
}

export enum PromotionPath {
  depositGeneral = 'deposit-general',
  newMember = 'new-member',
  promotionOrder = 'promotion-order',
  bannerOrder = 'banner-order',
  usageHistory = 'usage-history',
}

export enum MarketingPath {
  commission = 'commission',
  coupon = 'coupon',
  recommend = 'recommend',
  giftVoucher = 'gift-voucher',
  giveBonus = 'give-bonus',
  giveBonusFromDeposit = 'give-bonus-from-deposit',
  randomPrize = 'random-prize',
  returnCredit = 'return-credit',
  rolling = 'rolling',
}

export enum ProfilePath {
  detail = 'detail',
  login = 'login',
  changePassword = 'change-password',
}

export enum GamePath {
  play = 'play',
  agent = 'agent',
  demo = 'demo',
  reorder = 'reorder',
}

export enum ReportsPath {
  WinLoseMemberStable = 'win-lose-member-stable',
  ShareGameStable = 'share-game-stable',
  ShareAgentStable = 'share-agent-stable',
  WinLoseMember = 'win-lose-member',
  ShareGame = 'share-game',
  ShareAgent = 'share-agent',
  ProfitLoss = 'profit-loss',
  ProfitLossUpline = 'profit-loss-upline',
  Promotion = 'promotion',
  PromotionCredit = 'promotion-credit',
  DepositWithdrawMember = 'deposit-withdraw-member',
  Member = 'member',
  WebAccessFrom = 'web-accesss-from',
  ReturnCredit = 'return-credit',
  FinanceAgent = 'finance-agent',
  FinanceBank = 'finance-bank',
  DepositWithdrawAgent = 'deposit-withdraw-agent',
  MemberDeposit = 'member-deposit',
  Commission = 'commission',
  CommissionSummary = 'commission-summary',
  MemberFirstDeposit = 'member-first-deposit',
  WinLoseAgentAndMember = 'winlose-agent-and-member',
  WinLoseAgentAndMemberOld = 'winlose-agent-and-member-old',
  WinLoseAgentAndMemberStable = 'winlose-agent-and-member-stable',
}

export enum LinePath {
  LineOfficial = 'official',
  LineLogin = 'login',
}

export const SettingPaths = {
  All: 'all',
  Member: 'member',
  Deposit: 'deposit',
  Withdraw: 'withdraw',
  Loyalty: 'loyalty',
  Notify: 'notify',
} as const;

export type SettingPathTypes = typeof SettingPaths[keyof typeof SettingPaths];

export enum LogPaths {
  Member = 'member',
  Register = 'register',
  Withdraw = 'withdraw',
  Deposit = 'deposit',
  Login = 'login',
  AmbMemberSetting = 'amb-member-setting',
}

export enum InvoicePaths {
  System = 'system',
  Setting = 'setting',
}

export enum SupportAdminPaths {
  MemberOutstanding = 'member-outstanding',
  OutstandingTransaction = 'outstanding-transaction',
}

export enum SmsPaths {
  Dashboard = 'dashboard',
  History = 'history',
  RequestCredit = 'request-credit',
  Setting = 'setting',
}
