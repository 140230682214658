import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ChatroomService } from 'src/app/services/chatroom.service';
import { AppCore } from 'src/app/utils/classes/app-core';

@Component({
  selector: 'app-chat-panel',
  templateUrl: './chat-panel.component.html',
  styleUrls: ['./chat-panel.component.scss']
})
export class ChatPanelComponent extends AppCore implements OnInit {
  @Input() onOverlay = true;
  openChat = false;
  badge = 0;
  constructor(
    public svChatRoom: ChatroomService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();

    this.svChatRoom.$ResponseBadgeAgent.subscribe((e: number) => {
      if (e) {
        if (e > this.badge) {
          this.svChatRoom.SoundComeMessage();
          this.badge = e;
          this.changeDetectorRef.detectChanges();
        } else {
          this.badge = e;
          this.changeDetectorRef.detectChanges();
        }
      }
    })
  }

  ngOnInit() {
  }
  agentIdChange() {
    if (!this.currentAgentId) {
      return;
    }
    this.svChatRoom.onGetBadgeAgent(this.currentAgentId);
  }
}
