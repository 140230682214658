<form [formGroup]="form" (submit)="onSubmit()">
  <div class="modal" id="giveBonusModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"> {{'GIVE_BONUS_DEPOSIT'|translate}} </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body" [perfectScrollbar]="{}">
          <label for="" class="form-label"> <span class="badge bg-success"> Step 3</span> {{'CHOOSE_PROMOTION'|translate}} </label>
          <app-select-promotion-form formControlName="promotion_id" [showDetail]="false"
            (change)="selectPromotionChange($event)">
          </app-select-promotion-form>

          <div class="mt-3" *ngIf="selectPromotion">
            <div class="bg-secondary p-2">
              <div class=""><strong>{{'USERNAME'|translate}} : </strong> {{member?.username}} </div>
              <div class=""><strong>{{'FULLNAME'|translate}} : </strong> {{member?.first_name + ' ' + member?.last_name}} </div>
              <div class=""><strong>{{'DEPOSIT_AMOUNT'|translate}} : </strong> {{transaction?.amount}} {{'CREDIT'|translate}} </div>
              <div class="">
                <strong>{{'RECIVED_BONUS'|translate}} : </strong> {{selectPromotion?.amount | number: '0.2-2'}}
                <span *ngIf="selectPromotion?.type === 'credit'">{{'CREDIT'|translate}}</span>
                <span *ngIf="selectPromotion?.type === 'percent'">%</span>
              </div>
              <div class="">
                <strong>{{'TOTAL_AMOUNT'|translate}} : </strong>
                <span *ngIf="selectPromotion?.type === 'credit'">
                  {{ transaction?.amount + selectPromotion?.amount | number: '0.2-2'}}
                </span>
                <span *ngIf="selectPromotion?.type === 'percent'">
                  {{ transaction?.amount * ((selectPromotion?.amount / 100) + 1) | number: '0.2-2'}}
                </span>
                {{'CREDIT'|translate}}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal"> {{'CANCEL'|translate}} </button>
          <button type="submit" class="btn btn-primary"> {{'TOP_UP_BONUS'|translate}} </button>
        </div>
      </div>
    </div>
  </div>

</form>
