import { ChartOptions } from 'chart.js';

export const optionLineChart: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    point: {
      pointStyle: 'circle'
    }
  },
  interaction: {
    mode: 'index',
    intersect: false,
  },
  plugins: {
    legend: {
      align: 'end',
      labels: {
        usePointStyle: true
      },
      display: false
    },
    title: {
      display: false
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        drawBorder: false,
        color: 'transparent'
      }
    },
    x: {
      grid: {
        drawBorder: false,
        color: 'rgba(0,0,0,0.1)'
      }
    }
  }
};
