export function focusInvalid() {
  const inValid = document.getElementsByTagName('input');
  for (let i = 0; i < inValid.length; i++) {
    const element = inValid.item(i);
    if (element.classList.contains('ng-invalid')) {
      element.focus();
      break;
    }
  }
}
