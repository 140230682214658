export const Banks = [
  { bank_id: '001', bank_code: null, bank_name: 'ธนาคารแห่งประเทศไทย' },
  { bank_id: '002', bank_code: 'BBL', bank_name: 'ธนาคารกรุงเทพ' },
  { bank_id: '004', bank_code: 'KBANK', bank_name: 'ธนาคารกสิกรไทย' },
  { bank_id: '006', bank_code: 'KTB', bank_name: 'ธนาคารกรุงไทย' },
  { bank_id: '011', bank_code: 'TMB', bank_name: 'ธนาคารทหารไทย' },
  { bank_id: '014', bank_code: 'SCB', bank_name: 'ธนาคารไทยพาณิชย์' },
  { bank_id: '020', bank_code: 'SCBT', bank_name: 'ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)' },
  { bank_id: '022', bank_code: 'CIMB', bank_name: 'ธนาคารซีไอเอ็มบีไทย' },
  { bank_id: '024', bank_code: 'UOB', bank_name: 'ธนาคารยูโอบี' },
  { bank_id: '025', bank_code: 'BAY', bank_name: 'ธนาคารกรุงศรีอยุธยา' },
  { bank_id: '030', bank_code: 'GSB', bank_name: 'ธนาคารออมสิน' },
  { bank_id: '033', bank_code: 'GHB', bank_name: 'ธนาคารอาคารสงเคราะห์' },
  { bank_id: '034', bank_code: 'BAAC', bank_name: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร' },
  { bank_id: '035', bank_code: 'EXIM', bank_name: 'ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย' },
  { bank_id: '065', bank_code: 'TBANK', bank_name: 'ธนาคารธนชาต' },
  { bank_id: '066', bank_code: 'ISBT', bank_name: 'ธนาคารอิสลามแห่งประเทศไทย' },
  { bank_id: '067', bank_code: 'TISCO', bank_name: 'ธนาคารทิสโก้' },
  { bank_id: '069', bank_code: 'KKP', bank_name: 'ธนาคารเกียรตินาคิน' },
  { bank_id: '070', bank_code: 'ICBC', bank_name: 'ธนาคารไอซีบีซี (ไทย)' },
  { bank_id: '071', bank_code: 'TCD', bank_name: 'ธนาคารไทยเครดิตเพื่อรายย่อย' },
  { bank_id: '073', bank_code: 'LHBANK', bank_name: 'ธนาคารแลนด์ แอนด์ เฮาส์' },
  { bank_id: '098', bank_code: 'SME', bank_name: 'ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย' },
];
