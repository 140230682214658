import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@ngneat/reactive-forms';
import { Broadcast, BroadcastModel, BroadcastService } from 'src/app/apis/broadcast.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { alertSuccess, alertText, alertWarning } from 'src/app/utils/functions/alert';
import { focusInvalid } from 'src/app/utils/functions/focus-invalid';

@Component({
  selector: 'app-broadcast-form-offcanvas',
  templateUrl: './broadcast-form-offcanvas.component.html',
  styleUrls: ['./broadcast-form-offcanvas.component.scss']
})
export class BroadcastFormOffcanvasComponent extends AppCore implements OnInit, OnChanges {
  @Input() updateItem: Broadcast;

  form = this.svBroadcast.createForm;
  saving = false;

  constructor(
    private svBroadcast: BroadcastService,
  ) {
    super();
  }

  ngOnInit(): void {
    setTimeout(() => {
      const broadcastFormEl = document.getElementById('broadcastFormOffcanvas');
      broadcastFormEl?.addEventListener('hide.bs.offcanvas', () => {
        this.form = this.svBroadcast.createForm;
        this.saving = false;
      });
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.updateItem) {
    }
  }

  async onSubmit() {
    this.form.markAllAsDirty();
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      alertWarning({ message: alertText.formInvalid });
      focusInvalid();
      return;
    }

    this.saving = true;
    try {
      if (!this.updateItem) {
        await this.postData();
      } else {
        await this.putData();
      }
      alertSuccess({ message: alertText.saveSuccess });
      this.clickBtnClose();
      // } catch (error) {
    } finally {
      this.saving = false;
    }
  }

  postData() {
    return this.svBroadcast.post(this.form.value).toPromise();
  }

  putData() {
    return this.svBroadcast.put(this.updateItem.Id, this.form.value).toPromise();
  }

  override clickBtnClose(): void {
    super.clickBtnClose('btn-close-broadcast-form')
  }
}
