import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpCore } from '../utils/classes/http-core';

@Injectable({
  providedIn: 'root'
})
export class HttpApiService extends HttpCore {
  origin = environment.origin;
  constructor() {
    super();
  }
}
