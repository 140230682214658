import { Injectable } from '@angular/core';
import { HttpApiService } from '../services/http-api.service';
import { PageModel } from '../utils/interfaces/page-model';
import { Pagination } from '../utils/interfaces/pagination';

@Injectable({
  providedIn: 'root'
})
export class LoyaltyService {

  constructor(
    private http: HttpApiService,
  ) { }

  /**
   * Get Transaction Point Pagination
   * @param params ?member_id={member_id}&agent_id={agent_id}
   * @returns Observable
   */
  getTransactionPoint(params: PageModel) {
    return this.http.get<Pagination<TransactionPoint[]>>(`api/ask4bet/loyalty/get/transaction_point`, { params });
  }

  postPointAdd(member_id: number, point: number) {
    return this.http.post(`api/ask4bet/loyalty/point/add?member_id=${member_id}`, { point })
  }
  postPointReduce(member_id: number, point: number) {
    return this.http.post(`api/ask4bet/loyalty/point/reduce?member_id=${member_id}`, { point })
  }
  postPointRefund(tran_id: number) {
    return this.http.post(`api/ask4bet/loyalty/point/refund?tran_id=${tran_id}`, {})
  }

  //#region Wheel Game
  /**
   * get Wheel Game Pagination
   * @param params PageModel ?agent_id={agent_id}
   * @returns Observable
   */
  getWheelGame(params: PageModel) {
    return this.http.get<Pagination<WheelGame[]>>(`api/ask4bet/wheel_game`, { params });
  }
  getWheelGameById(id: number) {
    return this.http.get<WheelGame>(`api/ask4bet/wheel_game/${id}`);
  }
  postWheelGame(agent_id: number, model: WheelGameModel) {
    return this.http.post(`api/ask4bet/wheel_game?agent_id=${agent_id}`, model);
  }
  putWheelGame(id: number, model: WheelGameModel) {
    return this.http.put(`api/ask4bet/wheel_game/${id}`, model);
  }
  deleteWheelGame(id: number) {
    return this.http.delete(`api/ask4bet/wheel_game/${id}`);
  }
  /**
   * Get Wheel Game Log
   * @param params PageModel, id={id}
   * @returns Observable
   */
  getWheelGameLog(params: PageModel) {
    return this.http.get<Pagination<WheelGameLog[]>>(`api/ask4bet/wheel_game/log`, { params });
  }
  //#endregion
}

export interface TransactionPoint {
  id: number;
  memberId: number;
  username: string;
  credit_amount: number;
  credit_per_point: number;
  before_point: number;
  after_point: number;
  point: number;
  note?: any;
  ref_earn_point?: any;
  ref_reduce_point?: any;
  transaction_type: string;
  status: string;
  update_by: number;
  update_by_name: string;
  update_dt: string;
}

export interface WheelGameModel {
  name: string;
  point: number;
  is_enabled: boolean;
  rewards: WheelGameRewardModel[];

  totalAmount?: number;
  totalPercent?: number;
  totalInWheel?: number;
}

export interface WheelGameRewardModel {
  reward_type: string;
  reward_amount: number;
  reward_percent: number;
  reward_in_wheel: any;
}

export interface WheelGame {
  id: number;
  name: string;
  point: number;
  is_enabled: boolean;
  status: string;
  create_dt: string;
  update_dt: string;
  user_id: number;
  rewards: WheelReward[];
  total_amount: number;
  total_percent: number;
  total_in_wheel: number;
}

export interface WheelReward {
  id: number;
  reward_type: string;
  reward_amount?: number | number;
  reward_percent: number;
  reward_in_wheel: number;
}

export interface WheelGameLog {
  id: number;
  wheel_game_id: number;
  wheel_game_name: string;
  wheel_game_point: number;
  member_id: number;
  username: string;
  reward_type: string;
  reward_amount?: number;
  create_dt: string;
}
