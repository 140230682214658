import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder } from '@ngneat/reactive-forms';
import * as dayjs from 'dayjs';
import { finalize } from 'rxjs/operators';
import { GamesService, GameType } from 'src/app/apis/games.service';
import { MemberCreditService, Withdraw, WithdrawWinLose } from 'src/app/apis/member-credit.service';
import { formPage } from 'src/app/utils/functions/form-page';
import { PageEvent } from 'src/app/utils/interfaces/page-event';

@Component({
  selector: 'app-member-win-lose-offcanvas',
  templateUrl: './member-win-lose-offcanvas.component.html',
  styleUrls: ['./member-win-lose-offcanvas.component.scss']
})
export class MemberWinLoseOffcanvasComponent implements OnInit, OnChanges {
  form = this.createForm();
  currentPage = 1;
  winLoseItems: WithdrawWinLose;
  loading = true;
  gameTypeItems: { id: string }[] = [];;
  gameType = GameType;

  @Input() item: Withdraw;
  constructor(
    private svMemberCredit: MemberCreditService,
    private svGames: GamesService,
  ) { }

  ngOnInit(): void {
  }

  async ngOnChanges() {
    if (this.item) {
      const FromDate = dayjs().format('YYYY-MM-DD');
      const ToDate = dayjs().format('YYYY-MM-DD');

      this.form = this.createForm();
      await this.getGameTypes();
      this.form.patchValue({ member_id: this.item.member_id, FromDate, ToDate });
      this.getWithdrawWinLose();
    }
  }

  createForm() {
    return formPage({
      member_id: '',
      game: '',
      FromDate: '',
      ToDate: '',
      casino: false,
      keno: false,
      lotto: false,
      poker: false,
      slot: false,
      sport: false,
      trading: false,
    });
  }

  getWithdrawWinLose() {
    const model = {
      member_id: this.form.value.member_id,
      game: this.form.value.game,
      FromDate: this.form.value.FromDate,
      ToDate: this.form.value.ToDate,
    };
    if (this.form.value.game === 'custom') {
      let newGameType = [];
      if (this.form.value.casino) { newGameType.push('casino'); }
      if (this.form.value.keno) { newGameType.push('keno'); }
      if (this.form.value.lotto) { newGameType.push('lotto'); }
      if (this.form.value.poker) { newGameType.push('poker'); }
      if (this.form.value.slot) { newGameType.push('slot'); }
      if (this.form.value.sport) { newGameType.push('sport'); }
      if (this.form.value.trading) { newGameType.push('trading'); }

      model.game = newGameType.join(',');
    }

    this.loading = true;
    this.svMemberCredit.getWithdrawWinLose(model).pipe(
      finalize(() => this.loading = false)
    ).subscribe(res => {
      this.winLoseItems = res;
    });
  }

  pageChange(event: PageEvent) {
    this.form.patchValue(event);
    this.getWithdrawWinLose();
  }

  getGameTypes() {
    return new Promise(resolve => {
      this.svGames.getTypeGames().subscribe(res => {
        this.gameTypeItems = res;
        resolve(true);
      });
    });
  }

}
