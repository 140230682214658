import { Injectable } from '@angular/core';
import { HttpApiService } from '../services/http-api.service';

@Injectable({
  providedIn: 'root'
})
export class SettingGameMembersService {

  ambGames: AMBGameItem[] = [
    { icon: 'far fa-futbol', name: 'SPORT' },
    { icon: 'far fa-futbol', name: 'PARLAY' },
    { icon: 'far fa-futbol', name: 'STEP' },
    { icon: 'fas fa-puzzle-piece', name: 'LIVE_CASINO' },
    { icon: 'fas fa-gamepad', name: 'SLOT' },
    { icon: 'fas fa-gamepad', name: 'AMB_POKER' },
    { icon: 'fas fa-gamepad', name: 'POKER' },
    { icon: 'fas fa-dice', name: 'SS_LOTTO' },
    { icon: 'far fa-futbol', name: 'M2 Sport' },
    { icon: 'fas fa-gamepad', name: 'KENO' },
    { icon: 'bi bi-graph-up', name: 'TRADE' },
    { icon: 'fas fa-gamepad', name: 'E-SPORT' },
  ];
  lottoGames = ['หวยไทย', 'หวยปิงปอง', 'หวยลาว', 'หวยหุ้น'];

  constructor(
    private http: HttpApiService,
  ) { }

  /**
   * Get Setting Game AMB
   * @param member_id number
   * @returns Observable
   */
  getAMB(member_id: number) {
    return this.http.get<AMBSettingGame>(`api/ask4bet/amb/member_info?member_id=${member_id}`);
  }

  putAMBSetting(member_id: number, model: AMBSettingGame) {
    const settingEnable = {
      ...model.group_init_member.updateLineStatus,
    };
    // sport
    settingEnable.sportBookEnable = model.member_info.limitSetting.sportsBook.isEnable;
    // parlay
    settingEnable.parlayEnable = model.member_info.limitSetting.step.parlay.isEnable;
    // step
    settingEnable.stepEnable = model.member_info.limitSetting.step.step.isEnable;
    // casino
    settingEnable.casinoPtEnable = model.member_info.limitSetting.casino.pt.isEnable;
    settingEnable.casinoSaEnable = model.member_info.limitSetting.casino.sa.isEnable;
    settingEnable.casinoSexyEnable = model.member_info.limitSetting.casino.sexy.isEnable;
    settingEnable.casinoDgEnable = model.member_info.limitSetting.casino.dg.isEnable;
    settingEnable.casinoAgEnable = model.member_info.limitSetting.casino.ag.isEnable;
    settingEnable.casinoBgEnable = model.member_info.limitSetting.casino.bg.isEnable;
    settingEnable.casinoGdEnable = model.member_info.limitSetting.casino.gd.isEnable;
    settingEnable.casinoPmEnable = model.member_info.limitSetting.casino.pm.isEnable;
    settingEnable.casinoBtEnable = model.member_info.limitSetting.casino.bt.isEnable;
    settingEnable.casinoEbEnable = model.member_info.limitSetting.casino.eb.isEnable;
    // slot
    settingEnable.gameSlotGroupAEnable = model.member_info.limitSetting.game.slotGroupA.isEnable;
    settingEnable.gameSlotGroupBEnable = model.member_info.limitSetting.game.slotGroupB.isEnable;
    settingEnable.gameSlotGroupCEnable = model.member_info.limitSetting.game.slotGroupC.isEnable;
    // card
    settingEnable.ambNormalEnable = model.member_info.limitSetting.multi.ambNormal.isEnable;
    settingEnable.ambPremiumEnable = model.member_info.limitSetting.multi.ambPremium.isEnable;
    settingEnable.ambExclusiveEnable = model.member_info.limitSetting.multi.ambExclusive.isEnable;
    // poker
    settingEnable.pokerOgEnable = model.member_info.limitSetting.poker.og.isEnable;
    // keno
    settingEnable.otherTgEnable = model.member_info.limitSetting.other.tg.isEnable;
    // trade
    settingEnable.otherHgEnable = model.member_info.limitSetting.other.hg.isEnable;
    // lotto
    settingEnable.ambLottoEnable = model.member_info.limitSetting.lotto.amb.isEnable;
    settingEnable.ambLottoPPEnable = model.member_info.limitSetting.lotto.pp.isEnable;
    settingEnable.ambLottoLaosEnable = model.member_info.limitSetting.lotto.laos.isEnable;

    return this.http.put(`api/ask4bet/amb/update/member_setting?member_id=${member_id}`, {
      ...model.member_info,
      settingEnable,
      creditModify: false,
      creditLimit: 0,
      initCreditLimit: 0,
    });
  }

  get ambTypeOptions(): AMBOptions[] {
    return [
      { value: 'A', label: 'A (HDP/OU/OE)', desc: 0.3, gMax: 0.9 },
      { value: 'B', label: 'B (HDP/OU/OE)', desc: 0.4, gMax: 1 },
      { value: 'C', label: 'C (HDP/OU/OE)', desc: 0.5, gMax: 1.1 },
      { value: 'D', label: 'D (HDP/OU/OE)', desc: 0.6, gMax: 1.2 },
      { value: 'E', label: 'E (HDP/OU/OE)', desc: 0.7, gMax: 1.25 },
      { value: 'F', label: 'F (HDP/OU/OE)', desc: 1, gMax: 1.6 },
    ];
  }

  genOptions(step = 0.5, maxValue = 0, start = 0, addPercent = true): AMBOptions[] {
    const arrs = Array.from(Array(300).keys());
    const newArrs: AMBOptions[] = [];
    for (let i = 0; i < arrs.length; i++) {
      if (start <= maxValue) {
        newArrs.push({ value: start, label: `${start}${addPercent ? ' %' : ''}` });
        start = +((start + step).toFixed(2));
      } else {
        break;
      }
    }
    return newArrs;
  }

  get shareOptions(): AMBOptions[] {
    return this.genOptions(0.5, 100);
  }

  getSettingKlotto(member_id: number) {
    return this.http.get<KlottoLotto>(`api/klotto/member/${member_id}/lottos`);
  }

  saveSettingKlotto(member_id: number, model: KlottoLotto) {
    return this.http.post(`api/klotto/member/${member_id}/lottos`, model);
  }
}

export interface AMBGameItem {
  icon: string;
  name: AMBGameTypes;
}

export type AMBGameTypes = 'SPORT' | 'PARLAY' | 'STEP' | 'LIVE_CASINO' | 'SLOT' | 'AMB_POKER' | 'POKER' | 'SS_LOTTO' | 'M2 Sport' | 'KENO' | 'TRADE' | 'E-SPORT';

export interface AMBOptions {
  value: string | number;
  label: string;
  desc?: any;

  gMax?: number;
}

export interface AMBSettingGame {
  member_info: Memberinfo;
  group_init_member: Groupinitmember;
  agent_group_profile: Agentgroupprofile;
}

interface Agentgroupprofile {
  _id: string;
  shareSetting: ShareSetting3;
  limitSetting: LimitSetting3;
  commissionSetting: CommissionSetting2;
  parentId: Group;
  currency: string;
  type: string;
  creditLimit: number;
  balance: number;
  totalOutstanding: number;
  totalOutstandingBetCount: number;
}

interface CommissionSetting2 {
  sportsBook: SportsBook7;
  parlay: Parlay2;
  step: Step3;
  casino: Casino3;
  game: Game3;
  multi: Multi3;
  other: Other3;
  poker: Poker3;
}

interface LimitSetting3 {
  sportsBook: SportsBook6;
  step: Step2;
  casino: Casino7;
  game: Game5;
  multi: Multi2;
  lotto: Lotto6;
  other: Other2;
  poker: Poker6;
}

interface Poker6 {
  og: Sexy;
}

interface Lotto6 {
  amb: Amb2;
  pp: Amb2;
  laos: Laos;
  stock: Amb;
  yeekee: Yeekee3;
}

interface Yeekee3 {
  _1BOT: _6TOP;
  _1TOP: _6TOP;
  _2BOT: _6TOP;
  _2TOP: _6TOP;
  _3TOD: _6TOP;
  _3TOP: _6TOP;
}

interface Casino7 {
  sexy: Sexy;
  ag: Sexy;
  ab: Sexy;
  eb: Sexy;
  sa: Sexy;
  dg: Sexy;
  pt: Sexy;
  cr: Sexy;
  pm: Sexy;
  bg: Sexy;
  bt: Sexy;
  gd: Sexy;
  gc: Sexy;
  av: Sexy;
  mc: Sexy;
  we: Sexy;
  wm: Sexy;
  yeekee: Amb;
}

interface ShareSetting3 {
  sportsBook: SportsBook8;
  step: Step5;
  casino: Casino6;
  game: Game6;
  multi: Multi5;
  lotto: Lotto5;
  other: Other6;
  poker: Poker5;
}

interface Poker5 {
  og: Today2;
}

interface Other6 {
  m2: Today2;
  tg: Today2;
  hg: Today2;
  es: Today2;
}

interface Lotto5 {
  amb: Amb3;
  pp: Amb3;
  laos: Amb3;
  stock: Today2;
  yeekee: Amb3;
}

interface Amb3 {
  parent: number;
  own: number;
  remaining: number;
}

interface Multi5 {
  amb: Today2;
  ambExclusive: Today2;
  ambNormal: Today2;
  ambPremium: Today2;
}

interface Game6 {
  slotXO: Today2;
  slotGroupA: Today2;
  slotGroupB: Today2;
  slotGroupC: Today2;
}

interface Casino6 {
  sexy: Today2;
  ag: Today2;
  ab: Today2;
  eb: Today2;
  sa: Today2;
  dg: Today2;
  pt: Today2;
  cr: Today2;
  pm: Today2;
  bg: Today2;
  bt: Today2;
  gd: Today2;
  gc: Gc;
  av: Today2;
  mc: Today2;
  we: Today2;
  wm: Today2;
}

interface Gc {
  parent?: any;
  own?: any;
  remaining?: any;
  min?: any;
}

interface Step5 {
  parlay: Today2;
  step: Today2;
}

interface SportsBook8 {
  today: Today2;
  live: Today2;
}

interface Today2 {
  parent: number;
  own: number;
  remaining: number;
  min: number;
}

interface Groupinitmember {
  prefix: string;
  maxSetting: MaxSetting;
  commission: Commission;
  updateLineStatus: UpdateLineStatus;
  currency: string;
}

interface UpdateLineStatus {
  lock: boolean;
  suspend: boolean;
  sportBookEnable: boolean;
  stepEnable: boolean;
  parlayEnable: boolean;
  casinoSaEnable: boolean;
  casinoMcEnable: boolean;
  casinoAvEnable: boolean;
  casinoWmEnable: boolean;
  casinoWeEnable: boolean;
  casinoAgEnable: boolean;
  casinoSexyEnable: boolean;
  casinoDgEnable: boolean;
  casinoPtEnable: boolean;
  casinoAbEnable: boolean;
  casinoEbEnable: boolean;
  casinoBgEnable: boolean;
  casinoBtEnable: boolean;
  casinoCrEnable: boolean;
  casinoPmEnable: boolean;
  casinoGdEnable: boolean;
  casinoGcEnable: boolean;
  gameSlotEnable: boolean;
  gameSlotGroupAEnable: boolean;
  gameSlotGroupBEnable: boolean;
  gameSlotGroupCEnable: boolean;
  ambNormalEnable: boolean;
  ambPremiumEnable: boolean;
  ambExclusiveEnable: boolean;
  ambLottoEnable: boolean;
  ambLottoPPEnable: boolean;
  ambLottoYeekeeEnable: boolean;
  ambLottoLaosEnable: boolean;
  ambLottoStockEnable: boolean;
  otherM2Enable: boolean;
  otherTgEnable: boolean;
  otherHgEnable: boolean;
  otherEsEnable: boolean;
  pokerOgEnable: boolean;
  downline: string;
}

interface Commission {
  sportsBook: SportsBook7;
  parlay: Parlay2;
  step: Step3;
  casino: Casino3;
  game: Game3;
  multi: Multi3;
  other: Other5;
}

interface Other5 {
  com: number;
  tg: number;
  hg: number;
  es: number;
}

interface SportsBook7 {
  hdpOuOeA: number;
  hdpOuOeB: number;
  hdpOuOeC: number;
  hdpOuOeD: number;
  hdpOuOeE: number;
  hdpOuOeF: number;
  oneTwoDoubleChance: number;
  others: number;
}

interface MaxSetting {
  creditLimit: number;
  maxCreditLimit: number;
  shareSetting: ShareSetting2;
  limitSetting: LimitSetting2;
}

interface LimitSetting2 {
  sportsBook: SportsBook6;
  step: Step2;
  casino: Casino5;
  game: Game5;
  multi: Multi2;
  lotto: Lotto4;
  other: Other2;
  poker: Poker2;
}

interface Lotto4 {
  amb: Amb2;
  pp: Amb2;
  laos: Laos;
  stock: Stock;
  yeekee: Yeekee2;
}

interface Yeekee2 {
  _3TOP: _6TOP;
  _3TOD: _6TOP;
  _3BOT: _3TOP;
  _2TOP: _6TOP;
  _2TOD: _3TOP;
  _2BOT: _6TOP;
  _1TOP: _6TOP;
  _1BOT: _6TOP;
  isEnable: boolean;
}

interface Stock {
  _3TOP: _3TOP;
  _3TOD: _3TOP;
  _2TOP: _3TOP;
  _2BOT: _3TOP;
  _1TOP: _3TOP;
  _1BOT: _3TOP;
  isEnable: boolean;
}

interface _3TOP {
}

interface Game5 {
  slotXO: Amb;
  slotGroupA: Amb;
  slotGroupB: Amb;
  slotGroupC: Amb;
}

interface Casino5 {
  sexy: Sexy;
  ag: Sexy;
  ab: Sexy;
  eb: Sexy;
  sa: Sexy;
  dg: Sexy;
  pt: Sexy;
  cr: Sexy;
  pm: Sexy;
  bg: Sexy;
  bt: Sexy;
  gd: Sexy;
  gc: Sexy;
  mc: Sexy;
  av: Sexy;
  wm: Sexy;
  we: Sexy;
}

interface SportsBook6 {
  hdpOuOe: HdpOuOe;
  oneTwoDoubleChance: OneTwoDoubleChance2;
  others: OneTwoDoubleChance2;
  outright: Outright2;
  isEnable: boolean;
}

interface Outright2 {
  maxPerMatch: number;
}

interface OneTwoDoubleChance2 {
  maxPerBet: number;
}

interface ShareSetting2 {
  sportsBook: SportsBook5;
  step: Step4;
  casino: Casino4;
  game: Game4;
  multi: Multi4;
  lotto: Lotto3;
  other: Other4;
  poker: Poker4;
}

interface Poker4 {
  maxOg: number;
  minOg: number;
}

interface Other4 {
  maxM2: number;
  minM2: number;
  maxTg: number;
  minTg: number;
  maxHg: number;
  minHg: number;
  maxEs: number;
  minEs: number;
}

interface Lotto3 {
  maxAmbLotto: number;
  minAmbLotto: number;
  maxAmbLottoPP: number;
  minAmbLottoPP: number;
  maxAmbLottoLaos: number;
  minAmbLottoLaos: number;
  maxLottoStock: number;
  minLottoStock: number;
  maxAmbLottoYeekee: number;
  minAmbLottoYeekee: number;
}

interface Multi4 {
  maxAmb: number;
  minAmb: number;
  maxAmbNormal: number;
  minAmbNormal: number;
  maxAmbPremium: number;
  minAmbPremium: number;
  maxAmbExclusive: number;
  minAmbExclusive: number;
}

interface Game4 {
  maxSlotXO: number;
  minSlotXO: number;
  maxSlotGroupA: number;
  minSlotGroupA: number;
  maxSlotGroupB: number;
  minSlotGroupB: number;
  maxSlotGroupC: number;
  minSlotGroupC: number;
}

interface Casino4 {
  maxSexy: number;
  minSexy: number;
  maxAG: number;
  minAG: number;
  maxAb: number;
  minAb: number;
  maxEb: number;
  minEb: number;
  maxSa: number;
  minSa: number;
  maxDg: number;
  minDg: number;
  maxPt: number;
  minPt: number;
  maxCr: number;
  minCr: number;
  maxPm: number;
  minPm: number;
  maxBg: number;
  minBg: number;
  maxBt: number;
  minBt: number;
  maxGd: number;
  minGd: number;
  maxGc?: any;
  minGc?: any;
  maxMc: number;
  minMc: number;
  maxAv: number;
  minAv: number;
  maxWm: number;
  minWm: number;
  maxWe: number;
  minWe: number;
}

interface Step4 {
  maxParlay: number;
  minParlay: number;
  maxStep: number;
  minStep: number;
}

interface SportsBook5 {
  maxToday: number;
  minToday: number;
  maxLive: number;
  minLive: number;
}

interface Memberinfo {
  _id: string;
  shareSetting: ShareSetting;
  limitSetting: LimitSetting;
  commissionSetting: CommissionSetting;
  configuration: Configuration;
  group: Group;
  balance: number;
  passwordLock: boolean;
  suspend: boolean;
  lock: boolean;
  active: boolean;
  forceChangePassword: boolean;
  termAndCondition: boolean;
  isAutoChangeOdd: boolean;
  isAutoTopUp: boolean;
  username: string;
  username_lower: string;
  contact: string;
  phoneNo: string;
  currency: string;
  creditLimit: number;
  lastPaymentDate: string;
  password?: any;
  createdDate: string;
  updatedDate: string;
  _v?: any;
  remainingCredit: number;
}

interface Group {
  _id: string;
  type: string;
}

interface Configuration {
  sportsBook: SportsBook4;
}

interface SportsBook4 {
  defaultPrice: DefaultPrice;
  market: string;
  acceptAnyOdd: boolean;
  last: boolean;
  locale: string;
  odd: string;
  views: string;
}

interface DefaultPrice {
  check: boolean;
  price: number;
}

interface CommissionSetting {
  sportsBook: SportsBook3;
  parlay: Parlay2;
  step: Step3;
  casino: Casino3;
  game: Game3;
  multi: Multi3;
  other: Other3;
  poker: Poker3;
}

interface Poker3 {
  og: number;
}

interface Other3 {
  m2: number;
  tg: number;
  hg: number;
  es: number;
}

interface Multi3 {
  amb: number;
  ambExclusive: number;
  ambNormal: number;
  ambPremium: number;
}

interface Game3 {
  slotXO: number;
  slotGroupA: number;
  slotGroupB: number;
  slotGroupC: number;
}

interface Casino3 {
  sexy: number;
  ag: number;
  ab: number;
  eb: number;
  sa: number;
  dg: number;
  pt: number;
  cr: number;
  pm: number;
  bg: number;
  bt: number;
  gd: number;
  gc: number;
  av: number;
  mc: number;
  we: number;
  wm: number;
}

interface Step3 {
  com2: number;
  com3: number;
  com4: number;
  com5: number;
  com6: number;
  com7: number;
  com8: number;
  com9: number;
  com10: number;
  com11: number;
  com12: number;
}

interface Parlay2 {
  com: number;
}

interface SportsBook3 {
  typeHdpOuOe: string;
  hdpOuOe: number;
  oneTwoDoubleChance: number;
  others: number;
}

interface LimitSetting {
  sportsBook: SportsBook2;
  step: Step2;
  casino: Casino2;
  multi: Multi2;
  game: Game2;
  other: Other2;
  lotto: Lotto2;
  poker: Poker2;
}

interface Poker2 {
  og: Amb;
}

interface Lotto2 {
  amb: Amb2;
  pp: Amb2;
  laos: Laos;
  stock: Amb;
  gsb: Amb;
  baac: Amb;
  hanoy: Amb;
  hanoyspecial: Amb;
  malay: Amb;
  yeekee: Yeekee;
}

interface Yeekee {
  _3TOP: _6TOP;
  _3TOD: _6TOP;
  _2TOP: _6TOP;
  _2BOT: _6TOP;
  _1TOP: _6TOP;
  _1BOT: _6TOP;
  isEnable: boolean;
}

interface Laos {
  L_4TOP: _6TOP;
  L_4TOD: _6TOP;
  L_3TOP: _6TOP;
  L_3TOD: _6TOP;
  L_2FB: _6TOP;
  T_4TOP: _6TOP;
  T_4TOD: _6TOP;
  T_3TOP: _6TOP;
  T_3TOD: _6TOP;
  T_2TOP: _6TOP;
  T_2TOD: _6TOP;
  T_2BOT: _6TOP;
  T_1TOP: _6TOP;
  T_1BOT: _6TOP;
  isEnable: boolean;
  hour: number;
  minute: number;
}

interface Amb2 {
  _6TOP: _6TOP;
  _5TOP: _6TOP;
  _4TOP: _6TOP;
  _4TOD: _6TOP;
  _3TOP: _6TOP;
  _3TOD: _6TOP;
  _3BOT: _6TOP;
  _3TOP_OE: _6TOP;
  _3TOP_OU: _6TOP;
  _2TOP: _6TOP;
  _2TOD: _6TOP;
  _2BOT: _6TOP;
  _2TOP_OE: _6TOP;
  _2TOP_OU: _6TOP;
  _2BOT_OE: _6TOP;
  _2BOT_OU: _6TOP;
  _1TOP: _6TOP;
  _1BOT: _6TOP;
  isEnable: boolean;
  hour: number;
  minute: number;
}

interface _6TOP {
  payout: number;
  discount: number;
  max: number;
}

interface Other2 {
  m2: M2;
  tg: Amb;
  hg: Amb;
  es: Amb;
}

interface M2 {
  isEnable: boolean;
  maxPerBetHDP: number;
  minPerBetHDP: number;
  maxBetPerMatchHDP: number;
  maxPerBet: number;
  minPerBet: number;
  maxBetPerDay: number;
  maxPayPerBill: number;
  maxMatchPerBet: number;
  minMatchPerBet: number;
}

interface Game2 {
  slotXO: Amb;
  slotGroupA: Amb;
  slotGroupB: Amb;
  slotGroupC: Amb;
  slotGroupD: Amb;
}

interface Multi2 {
  amb: Amb;
  ambNormal: Amb;
  ambPremium: Amb;
  ambExclusive: Amb;
}

interface Amb {
  isEnable: boolean;
}

interface Casino2 {
  sexy: Sexy;
  ag: Sexy;
  sa: Sexy;
  dg: Sexy;
  pt: Sexy;
  ab: Sexy;
  eb: Sexy;
  cr: Sexy;
  pm: Sexy;
  bg: Sexy;
  bt: Sexy;
  gd: Sexy;
  gc: Sexy;
  mc: Sexy;
  av: Sexy;
  wm: Sexy;
  we: Sexy;
  isWlEnable: boolean;
  winPerDay: number;
}

interface Sexy {
  isEnable: boolean;
  limit: number;
}

interface Step2 {
  parlay: Parlay;
  step: Parlay;
}

interface Parlay {
  isEnable: boolean;
  maxPerBet: number;
  minPerBet: number;
  maxBetPerDay: number;
  maxPayPerBill: number;
  maxMatchPerBet: number;
  minMatchPerBet: number;
}

interface SportsBook2 {
  hdpOuOe: HdpOuOe;
  oneTwoDoubleChance: OneTwoDoubleChance;
  others: OneTwoDoubleChance;
  outright: Outright;
  isEnable: boolean;
}

interface Outright {
  maxPerMatch: number;
  maxPerBet?: any;
}

interface OneTwoDoubleChance {
  maxPerBet: number;
  maxPerMatch?: any;
}

interface HdpOuOe {
  maxPerBet: number;
  maxPerMatch: number;
}

interface ShareSetting {
  sportsBook: SportsBook;
  step: Step;
  casino: Casino;
  game: Game;
  multi: Multi;
  other: Other;
  lotto: Lotto;
  poker: Poker;
}

interface Poker {
  og: Today;
}

interface Lotto {
  amb: Today;
  pp: Today;
  laos: Today;
  stock: Today;
  yeekee: Today;
}

interface Other {
  m2: Today;
  tg: Today;
  hg: Today;
  es: Today;
}

interface Multi {
  amb: Today;
  ambNormal: Today;
  ambPremium: Today;
  ambExclusive: Today;
}

interface Game {
  slotXO: Today;
  slotGroupA: Today;
  slotGroupB: Today;
  slotGroupC: Today;
}

interface Casino {
  sexy: Today;
  ag: Today;
  sa: Today;
  dg: Today;
  pt: Today;
  ab: Today;
  eb: Today;
  cr: Today;
  pm: Today;
  bg: Today;
  bt: Today;
  gd: Today;
  gc: Today;
  mc: Today;
  av: Today;
  wm: Today;
  we: Today;
}

interface Step {
  parlay: Today;
  step: Today;
}

interface SportsBook {
  today: Today;
  live: Today;
}

interface Today {
  parent: number;
}

export interface KlottoLotto {
  MaxBet: number;
  Lottos: KlottoSettingPlay[];
}
export interface KlottoSettingPlay {
  ParentId: string;
  LottoTypeKey: number;
  LottoTypeName: string;
  LottoTypeCode: string;
  ShareParent: number;
  IsEnabled: boolean;
  UpdatedAt: string;
  UpdatedBy: string;
  Settings: KlottoSetting[];
}

interface KlottoSetting {
  LottoTypePlayKey: number;
  LottoTypePlayName: string;
  Payout: number;
  Payout2: number;
  Payout3: number;
  Payout4: number;
  Payout5: number;
  MaxBet: number;
  DefaultMaxBet: number;
}
