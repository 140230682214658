import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { HttpApiService } from '../services/http-api.service';
import { PageModel } from '../utils/interfaces/page-model';
import { Pagination } from '../utils/interfaces/pagination';

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {

  constructor(
    private http: HttpApiService,
    private ngFb: FormBuilder,
  ) { }

  /**
   * It returns a promise that resolves to a Pagination object.
   * @param {PageModel} params - PageModel
   * @returns The question is being returned in the form of a JSON object.
   */
  get(params: PageModel) {
    return this.http.get<Pagination<Broadcast[]>>(`api/ask4bet/broadcast`, { params });
  }

  /**
   * It posts a broadcast to the server.
   * @param {BroadcastModel} model - BroadcastModel
   * @returns The broadcast model is being returned.
   */
  post(model: BroadcastModel) {
    return this.http.post(`api/ask4bet/broadcast`, model);
  }

  /**
   * It updates a broadcast.
   * @param {number} id - number
   * @param {BroadcastModel} model - BroadcastModel
   * @returns The broadcast model.
   */
  put(id: number, model: BroadcastModel) {
    return this.http.put(`api/ask4bet/broadcast?id=${id}`, model);
  }

  /**
   * It deletes a broadcast.
   * @param {number} id - number
   * @returns The question is being returned in the form of a JSON object.
   */
  delete(id: number) {
    return this.http.delete(`api/ask4bet/broadcast?id=${id}`);
  }

  get createForm(): FormGroup<BroadcastModel> {
    return this.ngFb.group({
      agent_id: [null],
      image: [''],
      title: [''],
      message: [''],
    });
  }
}

export interface BroadcastModel {
  title: string;
  message: string;
  image: string;
  agent_id: number;
}

export interface Broadcast {
  Id: number;
  title: string;
  message: string;
  image?: any;
  agent_id: number;
  agentName: string;
  status: string;
  create_by: number;
  create_username: string;
  update_by: number;
  update_username: string;
  create_date: string;
  update_date: string;
  deleting?: boolean;
}
