import { Pipe, PipeTransform } from '@angular/core';
import { ILogWork } from 'src/app/services/signal-r.service';

@Pipe({
  name: 'filterLog',
  pure: false
})
export class FilterLogPipe implements PipeTransform {

  transform(value: ILogWork[], id?: any): any {
    if (id) {
      return value.filter(c => c.account_bank_agent_id === id);
    } else {
      return value;
    }
  }

}
