<div class="row">
  <div class="col-12 col-md-6 mb-3">
    <div class="card" [ngClass]="{'disabled': max === 0}">
      <div class="card-header text-center">
        askmebet poker
        (Min {{min | number: '1.0-0'}}% - Max {{max | number: '1.0-0'}}%)
      </div>
      <div class="card-body">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="text-end">{{'STATUS'|translate}} :</td>
              <td>
                <div class="form-check form-check-inline">
                  <input id="status-check-on-{{inputId}}" type="radio" class="form-check-input" name="pk-{{inputId}}"
                    [value]="true" disabled>
                  <label for="status-check-on-{{inputId}}" class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input id="status-check-off-{{inputId}}" type="radio" class="form-check-input" name="pk-{{inputId}}"
                    [value]="false" disabled checked>
                  <label for="status-check-off-{{inputId}}" class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
