import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-modal-right-when-moblie',
  templateUrl: './modal-right-when-moblie.component.html',
  styleUrls: ['./modal-right-when-moblie.component.scss']
})
export class ModalRightWhenMoblieComponent implements OnInit {
  @ContentChild(TemplateRef) template: TemplateRef<any>;
  isMobile = false;
  constructor() { }

  ngOnInit() {
    this.isMobile = window.document.body.clientWidth >= 1200 ? false : true;
  }

}
