import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AMBOptions, AMBSettingGame, SettingGameMembersService } from 'src/app/apis/setting-game-members.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { randomTxt } from 'src/app/utils/functions/random-txt';

@Component({
  selector: 'app-setting-slot',
  templateUrl: './setting-slot.component.html',
  styleUrls: ['../setting-games.scss'],
})
export class SettingSlotComponent extends AppCore implements OnInit, OnChanges {
  @Input() setting: AMBSettingGame;
  @Input() readOnly = false;

  min = 0;
  max = 0;

  takingOption: AMBOptions[] = [];
  inputId = `slot-${randomTxt(5)}`;
  constructor(
    private svSettingGameMember: SettingGameMembersService,
  ) {
    super();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.setting) {
      this.min = this.setting.group_init_member.maxSetting.shareSetting.game.minSlotXO;
      this.max = this.setting.group_init_member.maxSetting.shareSetting.game.maxSlotXO;

      if (this.setting.member_info.shareSetting.game.slotGroupA.parent < this.min) {
        this.setting.member_info.shareSetting.game.slotGroupA.parent = this.min;
      } else if (this.setting.member_info.shareSetting.game.slotGroupA.parent > this.min) {
        this.setting.member_info.shareSetting.game.slotGroupA.parent = this.max;
      }

      this.takingOption = this.svSettingGameMember.genOptions(0.5, this.max, this.min);
    }
  }
}
