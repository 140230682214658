import iziToast, { IziToastSettings } from 'izitoast';
import Swal, { SweetAlertOptions } from 'sweetalert2';

const defaultSettings: IziToastSettings = {
  position: 'topRight',
  timeout: 5000,
};

export function alertSuccess(settings: IziToastSettings = {}) {
  return iziToast.success({
    ...defaultSettings,
    title: `สำเร็จ`,
    message: ``,
    ...settings,
  });
}
export function alertWarning(settings: IziToastSettings = {}) {
  return iziToast.warning({
    ...defaultSettings,
    title: `แจ้งเเตือน`,
    message: ``,
    ...settings,
  });
}
export function alertInfo(settings: IziToastSettings = {}) {
  return iziToast.info({
    ...defaultSettings,
    title: `ข้อมูล`,
    message: ``,
    ...settings,
  });
}
export function alertDanger(settings: IziToastSettings = {}) {
  return iziToast.error({
    ...defaultSettings,
    title: `ผิดพลาด`,
    message: ``,
    ...settings,
  });
}

const defaultOptions: SweetAlertOptions = {
  icon: 'warning',
  showCancelButton: true,
  showConfirmButton: true,
  cancelButtonText: 'ยกเลิก',
  confirmButtonText: 'ยืนยัน',
  reverseButtons: true,
  focusCancel: true,
  confirmButtonColor: 'var(--bs-primary)',
};
export function alertConfirm(options: SweetAlertOptions = {}) {
  return Swal.fire({
    ...defaultOptions,
    title: `การยืนยัน`,
    html: `คุณต้องการดำเนินการต่อ?`,

    ...options,
  });
}
export function alertConfirmDanger(options: SweetAlertOptions = {}) {
  return Swal.fire({
    ...defaultOptions,
    title: `การยืนยัน`,
    html: `คุณต้องการดำเนินการต่อ?`,
    confirmButtonColor: 'var(--bs-danger)',
    iconColor: 'var(--bs-danger)',
    ...options,
  });
}

export enum alertText {
  formInvalid = 'กรุณากรอกข้อมูลให้ครบ',
  saveSuccess = 'บันทึกข้อมูลสำเร็จ',
  loginSuccess = 'เข้าสู่ระบบสำเร็จ',
  confirmDelete = 'คุณต้องการลบข้อมูล?',
  deleteSuccess = 'ลบข้อมูลสำเร็จ',
}

export function confirmDelete(text: string) {
  return `ต้องการลบ <strong>"${text}"</strong>?`;
}
