<div class="offcanvas offcanvas-end" id="memberTransactionOffcanvas" style="width: 60em;">
  <div class="offcanvas-header">
    <h4 class="mb-0" id="offcanvasRightLabel">{{'HISTORY_OF_CREDIT_INCREASE_DECREASE'|translate}}<span
        *ngIf="member">({{member?.username}})</span> </h4>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
  </div>
  <div class="offcanvas-body">
    <div class="card mb-3 d-inline-block text-nowrap" *ngIf="member && Config.AutoSystem">
      <div class="card-body d-flex align-items-center">
        <img class="me-2" [src]="member.bank_code | bankImg: 'bank_code'" alt="" [appViewImg]="member.bank_name"
          width="30px" height="30px">
        <div class="pe-2">
          <div class="opacity-75">{{member.fullname}}</div>
          <hr class="my-1">
          <div class="">{{member.bank_number}}</div>
        </div>
        <div class="ps-2 border-start text-center">
          <div class=""> {{member.wallet_total | number:'1.2-2'}} </div>
          <div class="small opacity-75"> {{'BALANCE'|translate}} </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th>
              <span class="text-primary">
                {{'DEPOSIT'|translate}}
              </span>/<span class="text-danger">
                {{'WITHDRAWAL'|translate}}
              </span>
            </th>
            <th>{{'CONFIRMED_BY'|translate}}</th>
            <th>{{'CREATE_DATE'|translate}}</th>
            <th>{{'TRANSFER_DATE'|translate}}</th>
            <th>{{'APPROVE_DATETIME'|translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of transactionItems?.items; let i = index">
            <td> {{i+1}} </td>
            <td class="text-end">
              <span *ngIf="item.Transaction === 'Deposit'" class="text-primary">
                {{item.amount | number: '0.2-2'}}
              </span>
              <span *ngIf="item.Transaction !== 'Deposit'" class="text-danger">
                -{{item.amount | number: '0.2-2'}}
              </span>
            </td>
            <td>
              <span *ngIf="!item.update_by_name" class="text-primary opacity-75">
                <i class="fas fa-laptop-code"></i>
                <div class=""> <small>Auto System</small> </div>
              </span>
              <span *ngIf="item.update_by_name">{{item.update_by_name}}</span>
            </td>
            <td>
              <app-show-date-time [date]="item.create_date"></app-show-date-time>
            </td>
            <td>
              <app-show-date-time [date]="item.datetime"></app-show-date-time>
            </td>
            <td>
              <app-show-date-time [date]="item.update_date"></app-show-date-time>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="text-center mb-3">
      <div class="" *ngIf="transactionItems && !transactionItems?.items.length"> {{'NO_DATA'|translate}} </div>
      <div class="" *ngIf="loading"> <span class="spinner-border"></span> </div>
    </div>
    <app-pagination *ngIf="transactionItems" [(page)]="currentPage" [limit]="transactionItems.limitRow"
      [total]="transactionItems.resultRow" (valueChange)="pageChange($event)">
    </app-pagination>
  </div>
</div>
