import { Injectable } from '@angular/core';
import { HttpApiService } from '../services/http-api.service';
import { PageModel } from '../utils/interfaces/page-model';
import { Pagination } from '../utils/interfaces/pagination';

@Injectable({
  providedIn: 'root'
})
export class MemberCreditService {

  constructor(
    private http: HttpApiService,
  ) { }

  postDepositBonusManual(model: DepositBonusModel) {
    return this.http.post(`api/ask4bet/deposit/bonus/manual`, model);
  }

  postDepositBonusForPromotion(model: DepositBonusForPromotionModel) {
    return this.http.post(`api/ask4bet/deposit/use/deposit/for/promotion`, model);
  }

  /**
   * get withdraw pagination
   * @param params ?FromDate={FromDate}&ToDate={ToDate}&status={confirmed,waiting,rejected,failed}&agent_id={agent_id}
   * @returns Observable
   */
  getWithdraw(params: PageModel) {
    return this.http.get<WithdrawPagination<Withdraw[]>>(`api/ask4bet/withdraw/pagination`, { params });
  }

  /**
   *
   * @param params ?FromDate={FromDate}&ToDate={ToDate}&agent_id={agent_id}&status={status}
   * @returns
   */
  getWithdrawOld(params: any) {
    return this.http.get<Pagination<WithdrawOld[]>>(`api/ask4bet/withdraw/pagination/temp/data`, { params });
  }

  postWithdraw(memberId: number, model: CreditModel) {
    return this.http.post(`api/ask4bet/withdraw?member_id=${memberId}`, model);
  }

  putWithdrawCheck(withdraw_id: number) {
    return this.http.put(`api/ask4bet/withdraw/check?withdraw_id=${withdraw_id}`, {});
  }

  putWithdrawRemove(withdraw_id: number) {
    return this.http.put(`api/ask4bet/withdraw/change/status_remove?withdraw_id=${withdraw_id}`, {});
  }

  putWithdrawWaiting(withdraw_id: number) {
    return this.http.put(`api/ask4bet/withdraw/change/status_waiting?withdraw_id=${withdraw_id}`, {});
  }

  putWithdrawConfirmed(withdraw_id: number) {
    return this.http.put(`api/ask4bet/withdraw/change/status_confirmed?withdraw_id=${withdraw_id}`, {});
  }

  putWithdrawRequestConfirm(withdraw_id: number) {
    return this.http.put(`api/ask4bet/withdraw/request/confirm?withdraw_id=${withdraw_id}`, {});
  }

  putWithdrawRequestReject(withdraw_id: number) {
    return this.http.put(`api/ask4bet/withdraw/request/reject?withdraw_id=${withdraw_id}`, {});
  }

  getDeposit(params: PageModel) {
    return this.http.get<DepositPagination<Deposit[]>>(`api/ask4bet/deposit/pagination`, { params });
  }

  /**
   *
   * @param params ?FromDate={FromDate}&ToDate={ToDate}&agent_id={agent_id}
   * @returns
   */
  getDepositOld(params: PageModel) {
    return this.http.get<Pagination<DepositOld[]>>(`api/ask4bet/deposit/pagination/temp/data`, { params });
  }

  postDeposit(memberId: number, model: CreditModel) {
    return this.http.post(`api/ask4bet/deposit?member_id=${memberId}`, model);
  }

  /**
   * get Deposit Waiting Pagination
   * @param params has ?FromDate={FromDate}&ToDate={ToDate}&agent_id={agent_id}
   * @returns Obserable
   */
  getDepositWaiting(params: PageModel) {
    return this.http.get<DepositPagination<Deposit[]>>(`api/ask4bet/deposit/pagination/waiting`, { params });
  }

  deleteDeposit(params: Partial<DeleteDepositModel>) {
    return this.http.delete(`api/ask4bet/deposit`, { params });
  }

  putDepositRequestConfirm(model: DepositRequestConfirmModel) {
    return this.http.put(`api/ask4bet/deposit/request/confirm`, model);
  }

  putDepositRequestReject(model: DepositRequestConfirmModel) {
    return this.http.put(`api/ask4bet/deposit/request/reject`, model);
  }

  /**
   * Get Deposit Statement Pagination
   * @param pararms has ask4betAgentId, accountBankId
   * @returns Observable
   */
  getDepositStatement(params: PageModel) {
    return this.http.get<Pagination<DepositStatement[]>>(`api/ask4bet/deposit_statement/get`, { params });
  }

  /**
   * สร้างรายการ Statement
   * @param model Data
   * @returns Observable
   */
  postDepositStatement(model: DepositStatementCreateModel) {
    return this.http.post(`api/ask4bet/deposit_statement/create`, model);
  }

  deleteDepositStatement(params: { id: any }) {
    return this.http.delete(`api/ask4bet/deposit_statement/remove`, { params })
  }

  postDepositAddCreditAutoAll() {
    return this.http.post(`api/ask4bet/deposit/auto/addcredit/statement`, {});
  }

  /**
   * Get Withdraw Win/Lose Pagination
   * @param params has ?member_id={member_id}&game={game}&FromDate={FromDate}&ToDate={ToDate}
   * @returns Observable
   */
  getWithdrawWinLose(params: PageModel) {
    return this.http.get<WithdrawWinLose>(`api/ask4bet/withdraw/winlose/winlose_deposit`, { params });
  }

  /**
   * เพิ่มเครดิตให้ Member โดยใช้ Statement
   * @param model Data
   * @returns Observable
   */
  postDepositStatementMember(model: DepositStatementMemberModel) {
    return this.http.post(`api/ask4bet/deposit/statement`, model);
  }

  /**
   * ดึงข้อมูลรายการที่ต้องการตรวจสอบ
   * @param params ?FromDate={FromDate}&ToDate={ToDate}&agent_id={agent_id}
   * @returns Observable
   */
  getWithdrawWaiting(params: PageModel) {
    return this.http.get<WithdrawPagination<Withdraw[]>>(`api/ask4bet/withdraw/pagination/waiting`, { params });
  }

  /**
   * Get WinLose
   * @param params PageModel, ?from: '', to: '', member_id: '', game: '',
   * @returns Observable
   */
  getMemberWinLose(params: any) {
    return this.http.get<any>(`api/ask4bet/winlose/sum_winlose`, { params });
  }
}

export interface CreditModel {
  member_id?: number;
  amount: number;
  note: string;
  promotion_id?: number;
  image_statement?: string;
  is_manual: boolean;
  datetime?: string;
  amountEx: number;
  showAddPromotion: boolean;
  showAddSlip: boolean;
  showAddNote: boolean;
  showAddTime: boolean;
}


export interface DepositBonusModel {
  member_id: number;
  note: string;
  bonus_amount: number;
  turn_over: number;
  stack_other_pro: boolean;
  reduce_credit_when_withdrawing: boolean;
  turn_over_sport: boolean;
  turn_over_casino: boolean;
  turn_over_poker: boolean;
  turn_over_slot: boolean;
  turn_over_keno: boolean;
  turn_over_lotto: boolean;
  turn_over_trading: boolean;

  turn_All?: boolean;
}

export interface DepositBonusForPromotionModel {
  deposit_id: number;
  promotion_id: number;
}

export interface WithdrawPagination<T = any[]> extends Partial<Pagination<T>> {
  sum_withdraw: number;
}

export interface Withdraw {
  Id: number;
  member_id: number;
  first_name: string;
  last_name: string;
  fullname: string;
  member_bank: string;
  member_bank_number: string;
  phone: string;
  username: string;
  credit_before: number;
  amount: number;
  amount_bonus: number;
  amount_total: number;
  credit_after: number;
  datetime: string;
  withdraw_type: string;
  status: string;
  note: string;
  run: string;
  withdraw: string;
  bank_code: string;
  bank_id: string;
  bank_name: string;
  update_by?: any;
  update_username?: any;
  update_name?: any;
  update_date: string;
  check_by?: any;
  check_username?: any;
  check_name?: any;
  check_date?: any;
  auto_tranfer_bank_account_id?: any;
  auto_tranfer_date?: any;
  auto_tranfer_start_process_date?: any;
  auto_tranfer_stop_process_date?: any;
  auto_tranfer_admin_id_confirm?: any;
  auto_tranfer_admin_id_cancel?: any;
  auto_tranfer_return_status_code?: any;
  auto_tranfer_status?: any;
  auto_tranfer_ref_code?: any;
  is_bonus: boolean;
  is_manual: boolean;
  create_date: string;
  agent_id: number;
  agentName: string;
}

export interface DepositPagination<T = any[]> extends Partial<Pagination<T>> {
  sum_deposit: number;
}

export interface Deposit {
  Id: number;
  member_id: number;
  first_name: string;
  last_name: string;
  fullname: string;
  member_bank: string;
  member_bank_number: string;
  member_bank_code: string;
  phone: string;
  username: string;
  promotion_id?: any;
  name?: any;
  detail?: any;
  type?: any;
  code_pro?: any;
  max_bonus_amount?: any;
  max_bonus_amount_per_date?: any;
  max_use_per_date?: any;
  receive_now?: any;
  min_deposit_amount?: any;
  max_deposit_amount?: any;
  again?: any;
  image?: any;
  type_pro?: any;
  agent: number;
  agent_name?: any;
  account_bank_agent_id: number;
  account_agent_balance: number;
  account_agent_username: string;
  account_agent_number: string;
  accout_agent_name: string;
  accout_agent_bank: string;
  account_agent_bankcode: string;
  credit_before?: number;
  amount: number;
  amount_bonus: number;
  amount_total: number;
  credit_after?: number;
  stack_other_pro?: any;
  reduce_credit_when_withdrawing?: any;
  member_id_recommend?: any;
  withdraw_then: boolean;
  note: string;
  datetime: string;
  image_statement: string;
  deposit_statement_id?: number;
  statement_account_number?: string;
  statement_amount?: number;
  statement_bank?: string;
  create_date: string;
  update_by: number;
  update_username: string;
  update_name: string;
  update_date: string;
  is_bonus: boolean;
  is_manual: boolean;
  turn_over: number;
  max_withdraw: number;
  can_withdraw: boolean;
  turn_over_FOOTBALL: boolean;
  turn_over_STEP: boolean;
  turn_over_PARLAY: boolean;
  turn_over_GAME: boolean;
  turn_over_CASINO: boolean;
  turn_over_LOTTO: boolean;
  turn_over_M2: boolean;
  turn_over_MULTI_PLAYER: boolean;
  deposit_type: string;
  status: string;
  check_by?: any;
  check_date?: any;
  ref_amb: string;
  promotion_new_member: boolean;
  get_to_know_id?: any;
  agent_id: number;
  agentName: string;
}

export interface DeleteDepositModel {
  member_id?: string;
  deposit_id?: string;
}

export interface DepositStatement {
  id: number;
  info: string;
  amount: number;
  bank: string;
  account_number: string;
  account_bank_agent_id: number;
  account_bank_agent_ask4bet_id: number;
  agentName: string;
  account_bank_agent_number: string;
  account_bank_agent_name: string;
  account_bank_agent_balance?: any;
  bank_id: string;
  bank_code: string;
  bank_name: string;
  statement_datetime: string;
  status: string;
  create_by?: any;
  create_by_name?: any;
  create_date?: any;
  update_by?: any;
  update_by_name?: any;
  update_date?: any;
  member_id?: any;
  member_username?: any;
  member_first_name?: any;
  member_last_name?: any;
  member_agent?: any;
  memer_bank?: any;
  member_bank_code?: any;
  member_bank_number?: any;
  member_bank_name?: any;
  member_phone?: any;
  deposit_id?: any;
  deposit_create_date?: any;
  deposit_update_date?: any;
  deposit_update_by?: any;
  deposit_credit_before?: any;
  deposit_credit_after?: any;
  deposit_amount?: any;
  image_statement?: any;
}

export interface DepositRequestConfirmModel {
  deposit_id: number;
  deposit_statement_id?: number;
  note: string;
}

export interface DepositStatementCreateModel {
  info: string;
  amount: number;
  bank: string;
  account_number: string;
  account_bank_agent_id: number;
  statement_datetime: string;
}


export interface DepositStatementMemberModel {
  deposit_statement_id: number;
  image_statement: string;
  promotion_id: number;
  member_id: number;
  is_manual: boolean;
  note: string;
}

export interface WithdrawWinLose {
  sum_winlose: Sumwinlose;
  deposit: WinLoseDeposit[];
}

export interface WinLoseDeposit {
  amount: number;
  bonus: number;
  total_amount: number;
  update_date: string;
}

export interface Sumwinlose {
  Total_amount: number;
  Total_validAmount: number;
  Total_wlTurnAmount: number;
  Total_outstanding: number;
}

export interface MemberWinLose {
  username: string;
  fullname: string;
  from: string;
  to: string;
  amount: number;
  valid_amount: number;
  wl_amount: number;
}

export interface WithdrawOld {
  Id: number;
  transfer_id?: any;
  member_id: number;
  first_name: string;
  last_name: string;
  fullname: string;
  member_bank: string;
  member_bank_number: string;
  phone: string;
  username: string;
  know_by_id?: number;
  know_by?: string;
  credit_before: number;
  amount: number;
  amount_bonus: number;
  amount_total: number;
  credit_after: number;
  datetime?: any;
  withdraw_type: string;
  status: string;
  note: string;
  run: string;
  withdraw: string;
  bank_code: string;
  bank_id: string;
  bank_name: string;
  update_by?: number;
  update_username?: string;
  update_name?: string;
  update_date: string;
  check_by?: number;
  check_username?: string;
  check_name?: string;
  check_date?: string;
  auto_tranfer_bank_account_id?: number;
  account_agent_number?: string;
  account_agent_bank?: string;
  account_agent_name?: string;
  account_agent_status?: string;
  auto_tranfer_date?: any;
  auto_tranfer_start_process_date?: string;
  auto_tranfer_stop_process_date?: any;
  auto_tranfer_admin_id_confirm?: number;
  auto_tranfer_admin_id_cancel?: any;
  auto_tranfer_return_status_code?: string;
  auto_tranfer_status?: string;
  auto_tranfer_ref_code?: any;
  slip?: any;
  is_bonus: boolean;
  is_manual: boolean;
  create_date: string;
  agent_id: number;
  agentName: string;
}

export interface DepositOld {
  Id: number;
  member_id: number;
  first_name: string;
  last_name: string;
  fullname: string;
  member_bank: string;
  member_bank_number: string;
  member_bank_code: string;
  phone: string;
  username: string;
  know_by_id?: number;
  know_by?: string;
  promotion_id?: number;
  promotion_name?: string;
  promotion_detail?: string;
  promotion_type?: string;
  promotion_code?: string;
  max_bonus_amount?: number;
  max_bonus_amount_per_date?: number;
  max_use_per_date?: number;
  receive_now?: boolean;
  min_deposit_amount?: number;
  max_deposit_amount?: number;
  amount_can_be_withdraw?: number;
  again?: boolean;
  image?: string;
  type_pro?: string;
  agent: number;
  agent_name: string;
  account_bank_agent_id?: any;
  account_agent_username?: any;
  account_agent_number?: any;
  accout_agent_name?: any;
  accout_agent_bank?: any;
  account_agent_balance?: any;
  account_agent_bankcode?: any;
  account_agent_status?: any;
  credit_before: number;
  amount: number;
  amount_bonus?: number;
  amount_total: number;
  credit_after: number;
  stack_other_pro?: boolean;
  reduce_credit_when_withdrawing?: boolean;
  winlose_amount?: number;
  member_id_recommend?: any;
  withdraw_then: boolean;
  note?: string;
  datetime: string;
  image_statement?: string;
  deposit_statement_id?: any;
  statement_account_number?: any;
  statement_amount?: any;
  statement_bank?: any;
  create_date: string;
  update_by?: number;
  update_username?: string;
  update_name?: string;
  update_date: string;
  is_bonus: boolean;
  is_manual: boolean;
  turn_over: number;
  max_withdraw: number;
  can_withdraw: boolean;
  turn_over_sport: boolean;
  turn_over_casino: boolean;
  turn_over_poker: boolean;
  turn_over_slot: boolean;
  turn_over_keno: boolean;
  turn_over_lotto: boolean;
  turn_over_trading: boolean;
  deposit_type: string;
  status: string;
  check_by?: any;
  check_date?: any;
  ref_amb: string;
  promotion_new_member: boolean;
  get_to_know_id?: any;
  agent_id: number;
  agentName: string;
}
