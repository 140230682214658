import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
import { IChatMessage, IChatRoom, IChatRoomAll, ICreateMessage } from '../apis/chat.service';
@Injectable({
  providedIn: 'root'
})
export class ChatroomService {
  public ActiveMemberId = new BehaviorSubject<number>(null);
  public LoadingConnecting = false;

  private apiHost = environment.origin;
  Connection: SignalR.Hub.Connection;
  connectioChatHub: SignalR.Hub.Proxy;

  chatConnected = false;

  $onSendMessage = new Subject<ICreateMessage>();
  $onEditMessage = new Subject<IChatMessage>();
  $onDeleteMessage = new Subject<IDeleteMessage>();
  $onHoldChatRoom = new Subject<IChatRoom>();
  $onGetAdminOnline = new Subject();
  $onReadMessage = new Subject<number>();
  $onGetBadgeAgent = new Subject<number>();

  $onSendMessage$ = new Subscription;
  $onEditMessage$ = new Subscription;
  $onDeleteMessage$ = new Subscription;
  $onHoldChatRoom$ = new Subscription;
  $onGetAdminOnline$ = new Subscription;
  $onReadMessage$ = new Subscription;
  $onGetBadgeAgent$ = new Subscription;

  $ResponseMessageToChatRoom = new Subject<IChatMessage>();
  $ResponseDeleteMessageToChatRoom = new Subject<number>();
  $AdminOnline = new Subject<IAdminOnline[]>();
  $UpdateChatRoom = new Subject<IChatRoomAll>();
  $ResponseBadgeAgent = new Subject<number>();

  audioSubmit = new Audio('assets/sounds/submit-message-2.mp3');
  audioComeMessage = new Audio('assets/sounds/come-message-2.mp3');
  audioComeRoomMessage = new Audio('assets/sounds/come-message.mp3');

  Subscriptions: Subscription = new Subscription;
  constructor(
    private svAuth: AuthenticationService,
  ) {
    this.LoadingConnecting = true;
    this.Connection = $.hubConnection(this.apiHost + `signalr`, { useDefaultPath: false });
    this.Connection.qs = { token: this.svAuth.getToken, 'user-type': 'admin' };
    this.connectioChatHub = this.Connection.createHubProxy(`chatHub`);
  }

  connectChat() {
    return new Promise((resolve, reject) => {

      this.connectioChatHub.on('responseMessageToChatRoom', (val) => this.$ResponseMessageToChatRoom.next(val));
      this.connectioChatHub.on('responseDeleteMessageToChatRoom', (val) => this.$ResponseDeleteMessageToChatRoom.next(val));
      this.connectioChatHub.on('updateChatRoom', (val) => this.$UpdateChatRoom.next(val));
      this.connectioChatHub.on('adminOnline', (val) => this.$AdminOnline.next(val));
      this.connectioChatHub.on('responseBadgeAgent', (val) => this.$ResponseBadgeAgent.next(val));

      this.Connection.start()
        .done(() => {
          this.chatConnected = true;
          console.log(`Chat Now connected ${this.Connection.id}`); // , connection ID= ${connection.id}
          if (this.svAuth.getUser?.Ask4betAgent) {
            this.$onSendMessage$ = this.$onSendMessage.subscribe((payload: ICreateMessage) => {
              this.connectioChatHub.invoke('adminSendMessage', payload, payload.roomName)
                .done(val => {
                  this.SoundOnSubmit();
                });
            });
            this.$onEditMessage$ = this.$onEditMessage.subscribe((payload: IChatMessage) => {
              this.connectioChatHub.invoke('editMessage', payload, payload.id, payload.roomName)
                .done(val => { });
            });
            this.$onDeleteMessage$ = this.$onDeleteMessage.subscribe((payload: IDeleteMessage) => {
              this.connectioChatHub.invoke('deleteMessage', payload.messageId, payload.roomName)
                .done(val => { });
            });
            this.$onHoldChatRoom$ = this.$onHoldChatRoom.subscribe((payload: IChatRoom) => {
              this.connectioChatHub.invoke('holdChatRoom', payload)
                .done(val => {
                });
            });
            this.$onGetAdminOnline$ = this.$onGetAdminOnline.subscribe((agentId: number) => {
              this.connectioChatHub.invoke('getAdminOnline', agentId)
                .done(val => { });
            });
            this.$onReadMessage$ = this.$onReadMessage.subscribe((roomId: number) => {
              this.connectioChatHub.invoke('onReadMessage', roomId, 'admin')
                .done(val => { });
            });
            this.$onGetBadgeAgent$ = this.$onGetBadgeAgent.subscribe((agentId: number) => {
              this.connectioChatHub.invoke('getBadgeAgent', agentId)
                .done(val => { });
            });
          }
          this.LoadingConnecting = false;
          resolve(true);
        })
        .fail(() => {
          this.LoadingConnecting = false;
          resolve(false);
          console.log('Chat Could not connect!!!');
        });
    });
  }
  disConnection() {
    console.log('on Disconnecting...');
    this.connectioChatHub.invoke('onDisconnected', true).done((v) => { });
  }
  reconnecting() {
    this.Connection.disconnected(() => {
      this.LoadingConnecting = true;
      console.log(`Chat Reconnecting...`);
      setTimeout(() => {
        this.$onSendMessage$.unsubscribe();
        this.$onEditMessage$.unsubscribe();
        this.$onDeleteMessage$.unsubscribe();
        this.$onHoldChatRoom$.unsubscribe();
        this.$onGetAdminOnline$.unsubscribe();
        this.$onReadMessage$.unsubscribe();
        this.$onGetBadgeAgent$.unsubscribe();
        console.log('$onSendMessage$ is closed => ', this.$onSendMessage$.closed);
        console.log('$onEditMessage$ is closed => ', this.$onEditMessage$.closed);
        console.log('$onDeleteMessage$ is closed => ', this.$onDeleteMessage$.closed);
        console.log('$onHoldChatRoom$ is closed => ', this.$onHoldChatRoom$.closed);
        console.log('$onGetAdminOnline$ is closed => ', this.$onGetAdminOnline$.closed);
        console.log('$onReadMessage$ is closed => ', this.$onReadMessage$.closed);
        console.log('$onGetBadgeAgent$ is closed => ', this.$onGetBadgeAgent$.closed);
        setTimeout(() => {
          this.connectChat();
          console.log(`Chat Reconnected!!`);
        }, 500);
      }, 5000);
    });
  }
  onGetBadgeAgent(agentId: number) {
    this.$onGetBadgeAgent.next(agentId);
  }
  onReadMessage(roomId: number) {
    this.$onReadMessage.next(roomId);
  }
  onGetAdminOnline(agentId: number) {
    this.$onGetAdminOnline.next(agentId);
  }
  onSendMessage(data: ICreateMessage) {
    this.$onSendMessage.next(data);
  }
  onEditMessage(data: IChatMessage) {
    this.$onEditMessage.next(data);
  }
  onDeleteMessage(data: IDeleteMessage) {
    this.$onDeleteMessage.next(data);
  }
  onHoldChatRoom(data: IChatRoom) {
    this.$onHoldChatRoom.next(data);
  }
  disconnectChat() {
    this.chatConnected = false;
    this.Connection?.stop();
  }
  SoundOnSubmit() {
    this.audioSubmit.play();
  }
  SoundComeMessage() {
    this.audioComeMessage.play();
  }
  SoundComeRoomMessage() {
    this.audioComeRoomMessage.play();
  }
}

export interface IAdminOnline {
  agentId: number;
  connectionId: string;
  device: string;
  fullname: string;
  ip_address: string;
  username: string;
}

export interface IDeleteMessage {
  messageId: number;
  roomName: string;
}