import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-revert-credit-round-history-offcanvas',
  templateUrl: './revert-credit-round-history-offcanvas.component.html',
  styleUrls: ['./revert-credit-round-history-offcanvas.component.scss']
})
export class RevertCreditRoundHistoryOffcanvasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
