import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertOddTypeHTFT'
})
export class ConvertOddTypeHTFTPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case 'AH':
      case 'OU':
      case 'OE':
      case 'X12':
      case 'CS':
      case 'TG':
      case 'DC':
      case 'FTHT':
      case 'TTC':
        return 'Full Time';
      case 'AH1ST':
      case 'OU1ST':
      case 'OE1ST':
      case 'X121ST':
      case 'FHCS':
      case 'TTC1ST':
        return 'First Half';
    }
    return value;
  }

}
