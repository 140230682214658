import { Component, Input, OnInit, SimpleChanges, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { finalize, skipWhile } from 'rxjs/operators';
import { SageLink, UserService } from 'src/app/apis/user.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { alertSuccess, alertWarning } from 'src/app/utils/functions/alert';

@Component({
  selector: 'app-sage-link-form-offcanvas',
  templateUrl: './sage-link-form-offcanvas.component.html',
  styleUrls: ['./sage-link-form-offcanvas.component.scss']
})
export class SageLinkFormOffcanvasComponent extends AppCore implements OnInit, OnDestroy {
  @Input() DataLink: SageLink;

  form: FormGroup = this.createForm();
  saving = false;

  constructor(
    private ngFb: FormBuilder,
    private svUser: UserService,
  ) {
    super();
  }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.form = this.createForm();
    if (this.DataLink) {
      this.form.patchValue({
        link_name: this.DataLink.link_name,
      });
    }
  }

  createForm() {
    return this.ngFb.group({
      link_name: [''],
    });
  }

  onSubmit() {
    if (this.saving) {
      return;
    }

    if (!this.form.value.link_name.trim()) {
      alertWarning({ message: `ต้องกรอกชื่อลิงค์!` });
      return;
    }

    const params = {
      user_id: this.DataLink.user_id,
      id: this.DataLink.Id,
    };
    const model = {
      link_name: this.form.value.link_name.trim(),
    };

    this.saving = true;
    this.svUser.putSageLink(model, params).pipe(
      finalize(() => this.saving = false)
    ).subscribe(res => {
      alertSuccess({ message: `แก้ไขลิงค์สำเร็จ` });
      this.clickBtnClose('btn-close-link-offcanvas');
    });
  }

  selectAllText(event: Event) {
    const inputEl = event.target as HTMLInputElement;
    inputEl.select();
  }

}
