import { AgentTypesType } from '../utils/constants/agent-types';
import {
  AppPath,
  CreditPath,
  DashboardPath, GamePath, ManageMemberPath, MarketingPath, PromotionPath, ReportsPath, SettingPaths, LinePath, LogPaths, InvoicePaths, SupportAdminPaths, MemberOutstanding, SmsPaths, ManageSagePath
} from '../utils/constants/paths';
import { UserRolesType } from '../utils/constants/user-roles';

export const sidebarMenu: SidebarItem[] = [
  /** Header ------------- เเผงควบคุม  */
  {
    type: 'menu', name: 'DASHBOARD', iconClass: 'fas fa-chart-pie', menuList: [
      { name: 'PROFILE', link: ['', AppPath.dashboard, DashboardPath.summary, 'v2'], },
      { name: 'TODAYS_SUMMARY', autosystem: true, link: ['', AppPath.dashboard, DashboardPath.analyze], },
      {
        name: 'PLAY_SUMMARY', autosystem: true, link: ['', AppPath.dashboard, DashboardPath.winlose],
        allowRoles: ['Superadmin', 'Admin'],
      },
      { name: 'SUMMARY_DOWNLINE', autosystem: true, link: ['', AppPath.dashboard, DashboardPath.downlline], },
      {
        name: 'SUMMARY_LOYALTY', autosystem: true, link: ['', AppPath.dashboard, DashboardPath.loyalty],
        allowRoles: ['Superadmin', 'Admin'],
      },
      {
        name: 'SUMMARY_AUTO', autosystem: true, link: ['', AppPath.dashboard, DashboardPath.autoOperation],
        allowRoles: ['Superadmin', 'Admin'],
      },
    ],
  },

  /** Header ------------- การทำงานหลัก  */
  {
    type: 'header', name: 'MAIN_FUNCTION'
  },
  {
    type: 'menu', name: 'AGENT', iconClass: 'fas fa-user-tie',
    menuList: [],
    allowRoles: ['Superadmin', 'Admin', 'Operator'],
    allowAgents: ['Shareholder', 'Senior', 'Master Agent'],
  },
  {
    type: 'menu', name: 'MANAGE_MEMBERS', iconClass: 'fas fa-user-friends', menuList: [
      {
        name: 'ALL_MEMBERS', link: ['', AppPath.manageMember, ManageMemberPath.all],
        spRoles: ['Member Password'],
      },
      {
        name: 'MEMBERS_WAITING_CREATE', autosystem: true, link: ['', AppPath.manageMember, ManageMemberPath.waiting],
        allowRoles: ['Superadmin', 'Admin'],
      },
      { name: 'MEMBERS_WITH_STATUS', link: ['', AppPath.manageMember, ManageMemberPath.memberWithStatus] },
      {
        name: 'SETTING', link: ['', AppPath.settings, SettingPaths.Member],
        allowRoles: ['Superadmin', 'Admin', 'Operator'],
      },
    ],
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Member Management', 'Member Management (View only)'],
    spRoles: ['Member Password'],
  },
  {
    type: 'router', name: 'SAGE_USERS', iconClass: 'fas fa-user-friends',
    link: ['', AppPath.manageSage, ManageSagePath.users],
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Member Management', 'Member Management (View only)'],
    useConfigUserSage: true,
  },
  {
    type: 'router', name: 'SAGE_LINKS', iconClass: 'fas fa-user-friends',
    link: ['', AppPath.manageSage, ManageSagePath.links],
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Member Management', 'Member Management (View only)', 'Zean'],
    useConfigUserSage: true,
  },
  {
    type: 'router', name: 'LIST_CRIMINAL', autosystem: true, link: ['', AppPath.scammer], iconClass: 'fas fa-user-secret',
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Member Management', 'Member Management (View only)'],
  },
  {
    type: 'menu', name: 'DEPOSIT', autosystem: true, badgesName: 'DepositAll', link: ['', AppPath.workDeposit], iconClass: 'fas fa-coins', menuList: [
      {
        name: 'ALL_DEPOSIT', link: ['', AppPath.workDeposit, 'all'],
        allowRoles: ['Superadmin', 'Admin', 'Operator', 'Deposit', 'Deposit Approver']
      },
      {
        name: 'OUTSTANDING_DEPOSIT', badgesName: 'DepositAuto', link: ['', AppPath.workDeposit, 'auto'],
        allowRoles: ['Superadmin', 'Admin', 'Operator', 'Deposit', 'Deposit Approver']
      },
      {
        name: 'REQUEST_WITH_EVIDENCE', badgesName: 'DepositRequest', link: ['', AppPath.workDeposit, 'request'],
        allowRoles: ['Superadmin', 'Admin', 'Operator', 'Deposit', 'Deposit Approver']
      },
      {
        name: 'MANUAL_DEPOSIT', link: ['', AppPath.credit, CreditPath.addReduce, CreditPath.add],
        allowRoles: ['Superadmin', 'Admin', 'Operator', 'Deposit']
      },
      {
        name: 'SETTING', link: ['', AppPath.settings, SettingPaths.Deposit],
        allowRoles: ['Superadmin', 'Admin', 'Operator'],
      },
    ],
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Deposit', 'Deposit Approver'],
  },
  {
    type: 'menu', name: 'WITHDRAWAL', autosystem: true, badgesName: 'WithdrawAll', iconClass: 'fas fa-hand-holding-usd', menuList: [
      { name: 'ALL_WITHDRAWAL', badgesName: 'WithdrawRequest', link: ['', AppPath.workWithdraw, 'all'] },
      { name: 'CHECK_WITHDRAWAL', badgesName: 'WithdrawChecking', link: ['', AppPath.workWithdraw, 'checking'] },
      { name: 'CONFIRM_CANCEL_WITHDRAWAL', link: ['', AppPath.workWithdraw, 'confirmed'] },
      { name: 'MANUAL_WITHDRAWAL', link: ['', AppPath.credit, CreditPath.addReduce, CreditPath.reduce] },
      {
        name: 'SETTING', link: ['', AppPath.settings, SettingPaths.Withdraw],
        allowRoles: ['Superadmin', 'Admin', 'Operator'],
      },
    ],
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Withdraw'],
  },
  {
    type: 'router', name: 'AUTO_TRANSFER_LIST', autosystem: true, realTimeWork: 'TransferOnline', badgesName: 'WithdrawAuto', link: ['', AppPath.autoTranfer, '0'], iconClass: 'fas fa-exchange-alt',
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Withdraw'],
  },
  {
    type: 'router', name: 'BANK', autosystem: true, realTimeWork: 'LogOnline', link: ['', AppPath.bank], iconClass: 'fas fa-landmark',
    allowRoles: ['Superadmin', 'Admin', 'Operator'],
  },
  {
    type: 'menu', name: 'MANAGE_SMS', autosystem: true, iconClass: 'fas fa-sms', menuList: [
      { name: 'SMS_DASHBOARD', link: ['', AppPath.manageSMS, SmsPaths.Dashboard] },
      { name: 'SMS_HISTORY', link: ['', AppPath.manageSMS, SmsPaths.History] },
      { name: 'SMS_REQUEST_CREDIT', link: ['', AppPath.manageSMS, SmsPaths.RequestCredit] },
      { name: 'SETTING', link: ['', AppPath.manageSMS, SmsPaths.Setting], allowRoles: ['Superadmin'] },
    ],
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Member Management', 'Member Management (View only)', 'Report'],
  },
  {
    type: 'router', name: 'Tournament', autosystem: true, link: ['', AppPath.tournament], iconClass: 'fas fa-trophy',
    allowRoles: ['Superadmin', 'Admin'],
  },
  {
    type: 'menu', name: 'CONNECT_LINE', autosystem: true, iconClass: 'fab fa-line',
    menuList: [
      { name: 'LINE Official', link: ['', AppPath.line, LinePath.LineOfficial] },
      { name: 'LINE Login', link: ['', AppPath.line, LinePath.LineLogin] }
    ]
  },
  // {
  //   type: 'router', name: 'Broadcast SMS', link: ['', AppPath.broadcatSMS], iconClass: 'fas fa-sms',
  //   allowRoles: ['Superadmin'],
  // },
  {
    type: 'router', name: 'ANNOUNCEMENT', link: ['', AppPath.news], iconClass: 'far fa-newspaper',
  },
  {
    type: 'router', name: 'ARTICLE', autosystem: true, link: ['', AppPath.article], iconClass: 'fas fa-paint-brush',
  },
  {
    type: 'router', name: 'Meta tag', autosystem: true, link: ['', AppPath.metaTag], iconClass: 'bi bi-tags-fill',
  },
  {
    type: 'router', name: 'SETTING_WEB', autosystem: false, link: ['', AppPath.settingAgentFE], iconClass: 'bi bi-sliders',
  },
  {
    type: 'router', name: 'HELP', autosystem: true, link: ['', AppPath.helpUser], iconClass: 'bi bi-info-circle',
  },
  /**
    * Header ------------- สมาชิกค้างเดิมพัน
    * All
  */
  {
    type: 'header', name: 'MEMBERS_HOLD_BETS', autosystem: false,
    allowRoles: ['Superadmin', 'Admin', 'Operator'],
  },
  {
    type: 'router', name: 'SPORT', autosystem: false, link: ['', AppPath.memberOutstanding, MemberOutstanding.FOOTBALL], iconClass: 'fas fa-futbol',
    allowRoles: ['Superadmin', 'Admin'],
  },
  {
    type: 'router', name: 'LOTTERY_AMB_LOTTO', autosystem: false, link: ['', AppPath.memberOutstanding, MemberOutstanding.LOTTO], iconClass: 'fas fa-spinner',
    allowRoles: ['Superadmin', 'Admin'],
  },
  {
    type: 'router', name: 'LOTTERY_KLOTTO', autosystem: false, link: ['', AppPath.memberOutstanding, MemberOutstanding.KLOTTO], iconClass: 'fab fa-kickstarter-k',
    allowRoles: ['Superadmin', 'Admin'],
    useConfigGameKlotto: true,
  },
  {
    type: 'router', name: 'SS_GAME', autosystem: false, link: ['', AppPath.memberOutstanding, MemberOutstanding.GAME], iconClass: 'bi bi-controller',
    allowRoles: ['Superadmin', 'Admin'],
  },
  {
    type: 'router', name: 'LIVE_CASINO', autosystem: false, link: ['', AppPath.memberOutstanding, MemberOutstanding.CASINO], iconClass: 'bi bi-dice-6',
    allowRoles: ['Superadmin', 'Admin'],
  },
  {
    type: 'router', name: 'OTHER_GAMES', autosystem: false, link: ['', AppPath.memberOutstanding, MemberOutstanding.OTHER], iconClass: 'bi bi-joystick',
    allowRoles: ['Superadmin', 'Admin'],
  },

  /**
   * KLOTTO
   */
  {
    type: 'header', name: 'KLOTTO', autosystem: false,
    allowRoles: ['Superadmin', 'Admin', 'Operator'],
    useConfigGameKlotto: true,
  },
  // {
  //   type: 'router', name: 'SETTING_PLACEOUT_LIMIT_KLOTTO', autosystem: false, link: ['', AppPath.klotto, 'setting-placeout-limit'], iconClass: 'fa-brands fa-kickstarter-k',
  //   allowRoles: ['Superadmin', 'Admin', 'Operator'],
  // },
  // {
  //   type: 'router', name: 'REPORT_PLACEOUT_LIMIT_KLOTTO', autosystem: false, link: ['', AppPath.klotto, 'report-placeout-limit'], iconClass: 'fa-brands fa-kickstarter-k',
  //   allowRoles: ['Superadmin', 'Admin', 'Operator'],
  // },
  // {
  //   type: 'router', name: 'BILL_LIST_KLOTTO', autosystem: false, link: ['', AppPath.klotto, 'bill-list'], iconClass: 'fa-brands fa-kickstarter-k',
  //   allowRoles: ['Superadmin', 'Admin', 'Operator'],
  // },
  {
    type: 'router', name: 'BILL_LIST_KLOTTO', autosystem: false, link: ['', AppPath.klotto, 'klotto-bill-iframe'], iconClass: 'fa-brands fa-kickstarter-k',
    allowRoles: ['Superadmin', 'Admin', 'Operator'],
    useConfigGameKlotto: true,
  },
  {
    type: 'router', name: 'SETTING_GAME_KLOTTO', autosystem: false, link: ['', AppPath.klotto, 'setting-game-klotto-iframe'], iconClass: 'fa-brands fa-kickstarter-k',
    allowRoles: ['Superadmin', 'Admin', 'Operator'],
    useConfigGameKlotto: true,
  },
  {
    type: 'router', name: 'KLOTTO_BILL_OUTSTANDING', autosystem: false, link: ['', AppPath.klotto, 'klotto-bill-outstanding-iframe'], iconClass: 'fa-brands fa-kickstarter-k',
    allowRoles: ['Superadmin', 'Admin', 'Operator'],
    useConfigGameKlotto: true,
  },
  {
    type: 'router', name: 'KLOTTO_RESULT_OUTSTANDING', autosystem: false, link: ['', AppPath.klotto, 'klotto-result-outstanding-iframe'], iconClass: 'fa-brands fa-kickstarter-k',
    allowRoles: ['Superadmin', 'Admin', 'Operator'],
    useConfigGameKlotto: true,
  },
  {
    type: 'router', name: 'KLOTTO_TOP_NUMBER', autosystem: false, link: ['', AppPath.klotto, 'klotto-top-number-iframe'], iconClass: 'fa-brands fa-kickstarter-k',
    allowRoles: ['Superadmin', 'Admin', 'Operator'],
    useConfigGameKlotto: true,
  },

  /**
   * Header ------------- ระบบ LOYALTY
   * Superadmin, Admin
   */
  {
    type: 'header', name: 'LOYALTY', autosystem: true,
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Promotion'],
  },
  {
    type: 'router', name: 'DIAMON', autosystem: true, link: ['', AppPath.point], iconClass: 'far fa-gem',
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Promotion'],
  },
  {
    type: 'router', name: 'MANAGE_POINT_STORE', autosystem: true, link: ['', AppPath.pointStore], iconClass: 'fas fa-store',
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Promotion'],
  },
  {
    type: 'router', name: 'MANAGE_REDEEM_REWARD', autosystem: true, link: ['', AppPath.redeemReward], iconClass: 'fas fa-gift',
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Promotion'],
  },
  {
    type: 'router', name: 'MANAGE_REWARD_WHEEL', autosystem: true, link: ['', AppPath.rewardWheel], iconClass: 'fas fa-dharmachakra',
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Promotion'],
  },

  /**
   * Header ------------- การตลาด
   */
  {
    type: 'header', name: 'MARKETING', autosystem: true,
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Promotion'],
  },
  {
    type: 'menu', name: 'PROMOTION', autosystem: true, iconClass: 'bi bi-tags-fill', menuList: [
      { name: 'DEPOSIT', link: ['', AppPath.promotion, PromotionPath.depositGeneral] },
      { name: 'NEW_MEMBER', link: ['', AppPath.promotion, PromotionPath.newMember] },
      // { name: 'REORDER_PLAY_PAGE', link: ['', AppPath.promotion, PromotionPath.promotionOrder] },
      { name: 'PROMOTION_USAGE_HISTORY', link: ['', AppPath.promotion, PromotionPath.usageHistory] },
      { name: 'BANNER_REORDER_PLAY_PAGE', link: ['', AppPath.promotion, PromotionPath.bannerOrder] },
    ],
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Promotion'],
  },
  {
    type: 'router', name: 'AFFILIATE', autosystem: true, link: ['', AppPath.marketing, MarketingPath.recommend], iconClass: 'bi bi-diagram-3',
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Promotion'],
  },
  {
    type: 'router', name: 'REVERT_CREDIT', autosystem: true, link: ['', AppPath.marketing, MarketingPath.returnCredit], iconClass: 'bi bi-arrow-up-left-square',
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Promotion'],
  },
  // {
  //   type: 'router', name: 'Gift Voucher', link: ['', AppPath.marketing, MarketingPath.giftVoucher], iconClass: 'bi bi-cash-coin',
  //   allowRoles: ['Superadmin', 'Admin', 'Operator', 'Promotion'],
  // },
  {
    type: 'router', name: 'COUPON', autosystem: true, link: ['', AppPath.marketing, MarketingPath.coupon], iconClass: 'bi bi-input-cursor',
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Promotion'],
  },
  {
    type: 'router', name: 'GIVE_CUSTOM_BONUS', autosystem: true, link: ['', AppPath.marketing, MarketingPath.giveBonus], iconClass: 'bi bi-tag',
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Promotion'],
  },
  {
    type: 'router', name: 'GIVE_BONUS_DEPOSIT', autosystem: true, link: ['', AppPath.marketing, MarketingPath.giveBonusFromDeposit], iconClass: 'bi bi-x-diamond-fill',
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Promotion'],
  },
  {
    type: 'router', name: 'SETTING', autosystem: true, link: ['', AppPath.settings, SettingPaths.Loyalty], iconClass: 'fas fa-cog',
    allowRoles: ['Superadmin', 'Admin', 'Operator'],
  },

  /**
   * Header ------------- รายงาน
   */
  {
    type: 'header', name: 'REPORT',
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
  },
  {
    type: 'menu', name: 'REPORT_WL', autosystem: false, iconClass: 'bi bi-file-medical', menuList: [
      {
        name: 'REPORT_WIN_LOSE', autosystem: false, link: ['', AppPath.reports, ReportsPath.WinLoseAgentAndMember],
        // allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
        spRoles: ['Admin Accounting'],
      },
      // {
      //   name: 'WL_MEMBERS', link: ['', AppPath.reports, ReportsPath.WinLoseMember],
      //   allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
      // },
      {
        name: 'SHARE_GAMES', autosystem: false, link: ['', AppPath.reports, ReportsPath.ShareGame],
        // allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
      },
      // {
      //   name: 'SHARE_AGENT', link: ['', AppPath.reports, ReportsPath.ShareAgent],
      //   allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
      // },
      // {
      //   name: 'COMMISSION', autosystem: false, link: ['', AppPath.reports, ReportsPath.Commission],
      //   allowRoles: ['Superadmin'],
      // },
      // {
      //   name: 'DAILY_COMMISSION_SUMMARY', autosystem: false, link: ['', AppPath.reports, ReportsPath.CommissionSummary],
      //   allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
      // },
      {
        name: 'REPORT_WIN_LOSE_STABLE', autosystem: true, link: ['', AppPath.reports, ReportsPath.WinLoseAgentAndMemberStable],
        allowRoles: ['Superadmin'],
      },
      // {
      //   name: 'STABLE_WL_MEMBERS', autosystem: true, link: ['', AppPath.reports, ReportsPath.WinLoseMemberStable],
      //   allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
      // },
      {
        name: 'STABLE_SHARE_GAMES', autosystem: true, link: ['', AppPath.reports, ReportsPath.ShareGameStable],
        allowRoles: ['Superadmin'],
      },
      // {
      //   name: 'STABLE_SHARE_AGENT', autosystem: true, link: ['', AppPath.reports, ReportsPath.ShareAgentStable],
      //   allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
      // },
    ],
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
    spRoles: ['Admin Accounting'],
  },
  {
    type: 'menu', name: 'MEMBER_REPORT', autosystem: true, iconClass: 'bi bi-file-earmark-person', menuList: [
      {
        name: 'NUMBER_MEMBERS', autosystem: true, link: ['', AppPath.reports, ReportsPath.Member],
        allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
      },
      {
        name: 'WEB_ACCESS_FROM', autosystem: true, link: ['', AppPath.reports, ReportsPath.WebAccessFrom],
        allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
      },
      {
        name: 'FIRST_DEPOSIT_MEMBERS', autosystem: true, link: ['', AppPath.reports, ReportsPath.MemberFirstDeposit],
        allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report', 'Zean'],
      },
      {
        name: 'DEPOSIT_WITHDRAW_MEMBERS', autosystem: true, link: ['', AppPath.reports, ReportsPath.DepositWithdrawMember],
        allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
      },
      {
        name: 'MEMBERS_WITH_DEPOSITS', autosystem: true, link: ['', AppPath.reports, ReportsPath.MemberDeposit],
        allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
      },
    ],
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report', 'Zean'],
  },
  {
    type: 'menu', name: 'SAGE_REPORT', autosystem: true, iconClass: 'bi bi-file-earmark-person', menuList: [
      { name: 'REPORT_SAGE_USERS', autosystem: true, link: ['', AppPath.reports, ManageSagePath.users] },
      { name: 'REPORT_SAGE_LINKS', autosystem: true, link: ['', AppPath.reports, ManageSagePath.links] },
      { name: 'REPORT_SAGE_LINKS_SUMMARY', autosystem: true, link: ['', AppPath.reports, ManageSagePath.linksSummary] },
    ],
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report', 'Zean'],
    useConfigUserSage: true,
  },
  {
    type: 'menu', name: 'PROMOTION_REPORT', autosystem: true, iconClass: 'bi bi-file-earmark-bar-graph', menuList: [

      {
        name: 'CREDIT_RETURN', autosystem: true, link: ['', AppPath.reports, ReportsPath.ReturnCredit],
        // allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
      },
      // {
      //   name: 'การเงินตามเอเย่นต์', link: ['', AppPath.reports, ReportsPath.FinanceAgent],
      //   allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
      // },
      // {
      //   name: 'ฝาก-ถอน ตามเอเย่นต์', link: ['', AppPath.reports, ReportsPath.DepositWithdrawAgent],
      //   allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
      // },
      {
        name: 'PROMOTION_SUMMARY', autosystem: true, link: ['', AppPath.reports, ReportsPath.Promotion],
        // allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
      },
      {
        name: 'SUMMARY_CREDIT_PROMOTION', autosystem: true, link: ['', AppPath.reports, ReportsPath.PromotionCredit],
        // allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
      },
    ],
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
  },
  {
    type: 'menu', name: 'FINANCIAL_REPORT', autosystem: true, iconClass: 'fas fa-file-invoice-dollar', menuList: [
      {
        name: 'PROFIT_LOSS', autosystem: true, link: ['', AppPath.reports, ReportsPath.ProfitLoss],
        // allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
      },
      {
        name: 'PROFIT_LOSS_UPLINE', autosystem: true, link: ['', AppPath.reports, ReportsPath.ProfitLossUpline],
        // allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
      },
      {
        name: 'REPORT_DEPOSIT_WITHDRAW', autosystem: true, link: ['', AppPath.transaction],
        // allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
      },
      {
        name: 'REPORT_BANK_FINANCE', autosystem: true, link: ['', AppPath.reports, ReportsPath.FinanceBank],
        // allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
      },
    ],
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
  },
  {
    type: 'menu', name: 'LOG', autosystem: false, iconClass: 'bi bi-file-spreadsheet', menuList: [
      {
        name: 'LOG_MEMBER', autosystem: false, link: ['', AppPath.logs, LogPaths.Member],
        allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
      },
      {
        name: 'LOG_AMB_MEMBER_SETTING', autosystem: false, link: ['', AppPath.logs, LogPaths.AmbMemberSetting],
        allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
      },
      {
        name: 'LOG_WITHDRAW', autosystem: false, link: ['', AppPath.logs, LogPaths.Withdraw],
        allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
      },
      {
        name: 'LOG_DEPOSIT', autosystem: false, link: ['', AppPath.logs, LogPaths.Deposit],
        allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
      },
      {
        name: 'LOG_LOGIN', autosystem: false, link: ['', AppPath.logs, LogPaths.Login],
        allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
      },
      {
        name: 'LOG_REGISTER', autosystem: false, link: ['', AppPath.logs, LogPaths.Register],
        allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
      },
    ],
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Report'],
  },

  /**
   * Header ------------- จัดการระบบ
   * Superadmin, Admin, Operator
   */
  {
    type: 'header', name: 'MANAGE_SYSTEM',
    allowRoles: ['Superadmin', 'Admin', 'Operator'],
  },
  {
    type: 'router', name: 'GAME_ANNOUNCEMENT', autosystem: true, realTimeWork: 'LogOnline', link: ['', AppPath.announce], iconClass: 'bi bi-megaphone',
    spRoles: ['Admin Support'],
  },
  {
    type: 'router', name: 'MEMBER_OUTSTANDING', autosystem: true, link: ['', AppPath.supportAdmin, SupportAdminPaths.MemberOutstanding], iconClass: 'bi bi-wrench-adjustable-circle',
    allowRoles: ['Superadmin', 'Admin'],
    spRoles: ['Admin Support'],
  },
  {
    type: 'menu', name: 'GAMES_MENU',
    iconClass: 'fas fa-gamepad',
    menuList: [
      {
        name: 'GAMES_SYSTEM', link: ['', AppPath.games, GamePath.play],
        allowRoles: ['Superadmin', 'Admin'],
        spRoles: ['Admin Support'],
      },
      {
        name: 'GAMES', link: ['', AppPath.games, GamePath.agent],
        allowRoles: ['Superadmin', 'Admin', 'Operator', 'Game Management'],
        spRoles: ['Admin Support'],
      },
      {
        name: 'TRIAL_GAMES', link: ['', AppPath.games, GamePath.demo],
        allowRoles: ['Superadmin', 'Admin'],
        spRoles: ['Admin Support'],
      },
      {
        name: 'REORDER_GAMES', link: ['', AppPath.games, GamePath.reorder],
        allowRoles: ['Superadmin', 'Admin', 'Operator', 'Game Management'],
        spRoles: ['Admin Support'],
      },
    ],
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Game Management'],
    spRoles: ['Admin Support'],
  },
  {
    type: 'router', name: 'BROADCAST', link: ['', AppPath.broadcast], iconClass: 'fas fa-sms',
    allowRoles: ['Superadmin'],
  },
  {
    type: 'router', name: 'SETTING_SYSTEM', link: ['', AppPath.settings, SettingPaths.All], iconClass: 'fas fa-cogs',
    allowRoles: ['Superadmin', 'Admin'],
  },
  {
    type: 'router', name: 'SUPPORTED_BANKS', link: ['', AppPath.bankSupport], iconClass: 'fas fa-university',
    allowRoles: ['Superadmin', 'Admin'],
  },
  {
    type: 'router', name: 'NOTIFICATION_SOUND', autosystem: true, link: ['', AppPath.soundSetting], iconClass: 'far fa-bell',
    allowRoles: ['Superadmin', 'Admin', 'Operator', 'Deposit', 'Deposit Approver', 'Withdraw'],
  },
  {
    type: 'router', name: 'Telegram alert', autosystem: true, link: ['', AppPath.alertTelegram], iconClass: 'fab fa-telegram',
    allowRoles: ['Superadmin', 'Admin'],
  },
  {
    type: 'router', name: 'LINE alert', autosystem: true, link: ['', AppPath.alertLine], iconClass: 'fab fa-line',
    allowRoles: ['Superadmin', 'Admin'],
  },
  {
    type: 'router', name: 'BACKGROUND_JOBS', link: ['', AppPath.Jobs], iconClass: 'bi bi-terminal',
    allowRoles: ['Superadmin', 'Admin'],
  },
  {
    type: 'menu', name: 'INVOICE_MENU', iconClass: 'bi bi-receipt-cutoff', autosystem: true,
    menuList: [
      {
        name: 'INVOICE_SYSTEM', link: ['', AppPath.invoices, InvoicePaths.System],
        spRoles: ['Admin Accounting'],
      },
      {
        name: 'INVOICE_SETTING', link: ['', AppPath.invoices, InvoicePaths.Setting],
        spRoles: ['Admin Accounting'],
      },
    ],
    allowRoles: ['Superadmin', 'Admin'],
    spRoles: ['Admin Accounting'],
  },
];

export interface SidebarItem {
  type: 'router' | 'header' | 'menu';
  name?: string;
  link?: string[];
  autosystem?: boolean;
  iconClass?: string;
  iconName?: string;
  realTimeWork?: string;
  menuList?: SidebarMenuItem[];
  expanded?: boolean;
  allowRoles?: UserRolesType[];
  allowAgents?: AgentTypesType[];
  badgesName?: string;
  spRoles?: UserRolesType[];
  useConfigGameKlotto?: boolean;
  useConfigUserSage?: boolean;
}

export interface SidebarMenuItem {
  name?: string;
  badgesName?: string;
  autosystem?: boolean;
  link?: string[];
  allowRoles?: UserRolesType[];
  allowAgents?: AgentTypesType[];
  spRoles?: UserRolesType[];
}
