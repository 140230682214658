<div class="offcanvas offcanvas-end" tabindex="-1" id="viewBillKlottoOffcanvas" style="width: 800px;">
  <div class="offcanvas-header">
    <h4 class="offcanvas-title"> {{'VIEW_BILL_DETAILS'|translate}} </h4>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
      id="btn-close-vbk-offcanvas"></button>
  </div>
  <div class="offcanvas-body" *ngIf="klottoBill">
    <div class="table-responsive">
      <table class="table table-bordered table-hover text-nowrap">
        <thead>
          <tr>
            <th rowspan="2">#</th>
            <th rowspan="2">{{'TYPE_PLAY'|translate}}</th>
            <th rowspan="2">{{'BET_LIST'|translate}}</th>
            <th rowspan="2">{{'PAYOUT_RATE'|translate}}</th>
            <th rowspan="2">{{'BET_AMOUNT'|translate}}</th>
            <th colspan="3">{{'AMOUNT_PAID'|translate}}</th>
            <th rowspan="2">{{'STATUS'|translate}}</th>
          </tr>
          <tr>
            <th>{{'AGENT'|translate}}</th>
            <th>{{'master_agent'|translate}}</th>
            <th>{{'senior'|translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let list of klottoBill?.bill_list; let i = index">
            <td> {{i+1}} </td>
            <td>
              <div class="mb-1">
                <img [src]="flags[list.TypeCode]" alt="flag" style="height: 15px; object-fit: contain;">
                {{'LOTTO.'+list.TypeCode|translate}}
              </div>
              <div class="">
                {{'LOTTO.'+list.LottoTypePlayName | translate}}
              </div>
            </td>
            <td> {{list.BetNumber}} </td>
            <td class="text-end"> {{ list.Payout | number:'1.2-2' }} </td>
            <td class="text-end"> {{ list.BetAmount | number:'1.2-2' }} </td>
            <td class="text-end" style="position: relative; padding-right: 1.75rem;">
              <span *ngIf="list.Status === 'won' && list.agentRoleName === 'Agent'">
                {{ list.PayoutAmount | number:'.2' }}
              </span>
              <span *ngIf="!(list.Status === 'won' && list.agentRoleName === 'Agent')">-</span>
            </td>
            <td class="text-end" style="position: relative; padding-right: 1.75rem;">
              <span *ngIf="list.Status === 'won' && list.agentRoleName === 'Master Agent'">
                {{ list.PayoutAmount | number:'.2' }}
              </span>
              <span *ngIf="!(list.Status === 'won' && list.agentRoleName === 'Master Agent')">-</span>
            </td>
            <td class="text-end" style="position: relative; padding-right: 1.75rem;">
              <span *ngIf="list.Status === 'won' && list.agentRoleName === 'Senior Agent'">
                {{ list.PayoutAmount | number:'.2' }}
              </span>
              <span *ngIf="!(list.Status === 'won' && list.agentRoleName === 'Senior Agent')">-</span>
            </td>
            <td>
              <span *ngIf="list.Status === 'won'" class="badge badge-success">
                {{'WIN'|translate}}
              </span>
              <span *ngIf="list.Status === 'los'" class="badge badge-secondary">
                {{'LOSE'|translate}}
              </span>
              <span *ngIf="list.Status === 'act'" class="badge badge-primary">
                {{'WAITING_RESULT'|translate}}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
