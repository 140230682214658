import { Component, OnInit, AfterViewInit, Input, OnChanges } from '@angular/core';
import * as _ from 'lodash';
import { AdvancedPieChart } from '../../chart.interface';

@Component({
  selector: 'app-advanced-pie-chart',
  templateUrl: './advanced-pie-chart.component.html',
  styleUrls: ['./advanced-pie-chart.component.scss']
})
export class AdvancedPieChartComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() MasterData: AdvancedPieChart.Data;
  sum = 0;

  constructor() { }

  ngOnInit() {
  }
  ngOnChanges() {
    if (this.MasterData) {
      this.sum = _.sumBy(this.MasterData.series, c => c.value);
    }
  }
  ngAfterViewInit() {
  }
}
