import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@ngneat/reactive-forms';
import { Subscription } from 'rxjs';
import { generateArray } from 'src/app/utils/functions/generate-array';
import { AgentForm } from 'src/app/utils/interfaces/create-agent-form.interface';
import { OptionItem } from 'src/app/utils/interfaces/option-item';

@Component({
  selector: 'app-setting-amb-m2',
  templateUrl: './setting-amb-m2.component.html',
  styleUrls: ['../../setting-agent.scss'],
})
export class SettingAmbM2Component implements OnInit {
  @Input() form: FormGroup<AgentForm>;
  maxMatchPerBet = 0;
  minMatchPerBetBet = 0;

  maxMatchPerBetItems: OptionItem[] = [];
  minMatchPerBetBetItems: OptionItem[] = [];

  Subscrition = new Subscription();
  formValue: AgentForm;
  constructor() {
  }

  ngOnInit(): void {
    if (this.form) {
      const limit_amb_form$ = this.form.get('limit_setting_amb_form').valueChanges.subscribe(res => {
        this.formValue = this.form.getRawValue();
      });
      this.Subscrition.add(limit_amb_form$);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.form) {
      this.formValue = this.form.getRawValue();

      this.generateOption();

      const maxPerBet$ = this.form.get('limit_setting_amb_form').get('other_m2_maxMatchPerBet').valueChanges.subscribe(() => {
        this.generateOption();
      });
      const minPerBet$ = this.form.get('limit_setting_amb_form').get('other_m2_minMatchPerBet').valueChanges.subscribe(() => {
        this.generateOption();
      });
      this.Subscrition.add(maxPerBet$);
      this.Subscrition.add(minPerBet$);
    }
  }

  ngOnDestroy(): void {
    this.Subscrition.unsubscribe();
  }

  generateOption() {
    this.maxMatchPerBet = this.formValue.limit_setting_amb_form.other_m2_maxMatchPerBet;
    this.minMatchPerBetBet = this.formValue.limit_setting_amb_form.other_m2_minMatchPerBet;
    if (this.formValue.limit_setting_amb_form.other_m2_maxMatchPerBet_uplineLimit) {
      this.maxMatchPerBet = this.formValue.limit_setting_amb_form.other_m2_maxMatchPerBet_uplineLimit;
    }

    this.maxMatchPerBetItems = generateArray(this.minMatchPerBetBet, 1, this.maxMatchPerBet).map(val => ({ name: val + '', value: val }));
    this.minMatchPerBetBetItems = generateArray(0, 1, this.minMatchPerBetBet).map(val => ({ name: val + '', value: val }));
  }
}
