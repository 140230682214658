import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpApiService } from '../services/http-api.service';
import { PageModel } from '../utils/interfaces/page-model';
import { Pagination } from '../utils/interfaces/pagination';
import { Bank } from './interfaces/bank.interface';

@Injectable({
  providedIn: 'root'
})
export class BankService {
  selectBank$ = new BehaviorSubject<AccountBank>(null);

  constructor(
    private http: HttpApiService,
  ) { }

  getBankAll(ask4AgentId: number) {
    return this.http.get<AccountBank[]>(`api/ask4bet/account_agent/bank/all?ask4AgentId=${ask4AgentId || ''}`);
  }

  getBankDeposit(ask4AgentId: number) {
    return this.http.get<AccountBank[]>(`api/ask4bet/account_agent/bank/deposit?ask4AgentId=${ask4AgentId || ''}`);
  }

  getBankWithdraw(ask4AgentId: number) {
    return this.http.get<AccountBank[]>(`api/ask4bet/account_agent/bank/withdraw?ask4AgentId=${ask4AgentId || ''}`);
  }

  updateCreateBank(form: Bank.BankList) {
    return this.http.post<Bank.BankList>(`api/ask4bet/bank`, form);
  }

  getAllBankSupport() {
    return this.http.get<BankProvider[]>(`api/ask4bet/bank`);
  }

  postAccountAgentBank(model: BankModel) {
    return this.http.post(`api/ask4bet/account_agent/create`, model);
  }
  putAccountAgentBank(model: BankModel, editId: number) {
    return this.http.put(`api/ask4bet/account_agent/edit?account_bank_agent_id=${editId}`, model);
  }

  deleteAccountAgentBank(account_bank_agent_id: number) {
    return this.http.delete(`api/ask4bet/account_agent/delete?account_bank_agent_id=${account_bank_agent_id}`);
  }

  /**
   * Get Statement
   * @param params PageModel, ?account_bank_id=${account_bank_id}&action=${deposit,withdraw}
   * @returns Observable
   */
  getAccountBankStatement(params: PageModel) {
    return this.http.get<Pagination<AccountBankStatement[]>>(`api/ask4bet/account_agent/statement`, { params });
  }

  /**
   * Get Statement List
   * @param params ?account_bank_id=${account_bank_id}&action=${deposit,withdraw}
   * @returns Observable
   */
  getAccountBankStatementList(params: any) {
    return this.http.get<AccountBankStatement[]>(`api/ask4bet/account_agent/statement/list`, { params });
  }

  getBankDepositAll() {
    return this.http.get('api/ask4bet/account_agent/bank/all?type=deposit');
  }
  getBankWithdrawAll() {
    return this.http.get('api/ask4bet/account_agent/bank/all?type=withdraw');
  }
}

export interface AccountBank {
  Id: number;
  ask4betAgentId: number;
  agentName: string;
  username: string;
  accout_number: string;
  name: string;
  max_income: number;
  balance?: any;
  bank: string;
  status: string;
  status_task: string;
  bank_code: string;
  bank_name: string;
  update_by: number;
  update_date: string;
  account_type: 'withdraw' | 'deposit' | 'both';
  get_deposit_from: string;
  show_to_member: boolean;
  request_deposit: boolean;
  withdraw_below_amount: number;
  update_by_name: string;
  code_url: string;
  task_running_status: boolean;
  task_running_status_date: string;
  task_log_clear_time: number;
  verified: boolean;
  idcard: string;
  birthday?: any;
  phone: string;
  api_auth?: any;
  pac_code?: any;
  tokenUUID?: any;
  deviceId?: any;
  pin_on_phone?: any;
  access_token: string;
  member_day_register?: number;
  member_deposit_sum?: number;
  member_deposit_amount_sum?: number;
}

export type BankAccountType = keyof typeof BankAccountTypes;

export enum BankAccountTypes {
  deposit = 'ฝาก',
  withdraw = 'ถอน',
  both = 'ฝาก/ถอน',
}

export interface BankProvider {
  bank_id: string;
  bank_code: string;
  bank_name: string;
  bank_image: string;
}

export interface BankModel {
  access_token: string;
  ask4betId: number;
  username: string;
  password: string;
  accout_number: string;
  bank: string;
  name: string;
  max_income: number;
  account_type: 'withdraw' | 'deposit' | 'both';
  show_to_member: boolean;
  request_deposit: boolean;
  get_deposit_from: string;
  withdraw_below_amount: number;
  verified: boolean;
  idcard: string;
  birthday: string;
  phone: string;
  status: string;
  status_task: string;
  enable_bank_task?: boolean;
}

export interface AccountBankStatement {
  id: number;
  info: string;
  amount: number;
  bank: string;
  account_number: string;
  account_bank_agent_id: number;
  account_bank_agent_ask4bet_id: number;
  agentName: string;
  account_bank_agent_number: string;
  account_bank_agent_name: string;
  account_bank_agent_balance?: any;
  bank_id: string;
  bank_code: string;
  bank_name: string;
  statement_datetime: string;
  status: string;
  create_by?: number;
  create_by_name?: string;
  action: string;
  create_date: string;
  update_by?: number;
  update_by_name?: string;
  update_date: string;
  member_id?: number;
  member_username?: string;
  member_first_name?: string;
  member_last_name?: string;
  member_agent?: number;
  memer_bank?: string;
  member_bank_number?: string;
  member_bank_code?: string;
  member_bank_name?: string;
  member_phone?: string;
  deposit_id: number;
  deposit_create_date: string;
  deposit_update_date: string;
  deposit_update_by?: number;
  deposit_credit_before: number;
  deposit_credit_after: number;
  deposit_amount: number;
  image_statement: string;
}


export interface IBankAll {
  Id: number;
  ask4betAgentId: number;
  agentName: string;
  status_task?: string;
  username: string;
  accout_number: string;
  name: string;
  max_income: number;
  balance?: number;
  bank: string;
  status: string;
  bank_code: string;
  bank_name: string;
  update_by: number;
  update_date: string;
  account_type: string;
  get_deposit_from: string;
  show_to_member: boolean;
  request_deposit: boolean;
  withdraw_below_amount: number;
  update_by_name: string;
  code_url: string;
  task_running_status: boolean;
  task_running_status_date: Date;
  task_log_clear_time: number;
  verified: boolean;
  idcard: string;
  birthday?: any;
  phone: string;
  api_auth?: any;
  pac_code?: any;
  tokenUUID?: any;
  deviceId?: any;
  pin_on_phone?: any;
  access_token: string;
}
