import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Member, MemberService, TurnoverPromotion, WithdrawTurnover } from 'src/app/apis/member.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { alertConfirmDanger, alertSuccess } from 'src/app/utils/functions/alert';

@Component({
  selector: 'app-clear-turn-over-member-offcanvas',
  templateUrl: './clear-turn-over-member-offcanvas.component.html',
  styleUrls: ['./clear-turn-over-member-offcanvas.component.scss']
})
export class ClearTurnOverMemberOffcanvasComponent extends AppCore implements OnInit, OnChanges {
  @Input() memberId = 0;
  @Input() id = 'clearTurnoverMemberOffCanvas';

  loading = false;
  saving = false;
  withdrawTurnover: WithdrawTurnover | undefined;
  noWithdrawCredit = 0;

  constructor(
    private svMember: MemberService,
  ) {
    super();
  }

  ngOnInit(): void {
  }
  ngOnChanges() {
    if (!!this.memberId) {
      this.getWthdrawTurnover();
    }
  }

  getWthdrawTurnover() {
    this.loading = true;
    this.withdrawTurnover = undefined;
    this.noWithdrawCredit = 0;
    this.svMember.getWithdrawTurnover(this.memberId).pipe(
      finalize(() => this.loading = false)
    ).subscribe(res => {
      this.withdrawTurnover = res;
      this.noWithdrawCredit = res.promotion_used.reduce((pre, val) => pre + val.credit_can_not_withdraw, 0);
    });
  }

  trackPromotion(index: number, item: TurnoverPromotion) {
    return item.deposit_id;
  }

  clickBtnClose() {
    super.clickBtnClose('clear-turn-btn-close');
  }

  clearPromotion(item: TurnoverPromotion) {
    this.svMember.putClearTurnDeposit(this.memberId, item.deposit_id).subscribe(res => {
      alertSuccess({ message: `ล้างโปรโมชัน สำเร็จแล้ว` });
      this.getWthdrawTurnover();
    });
  }

  async onSubmitClearTurnover() {
    const swal = await alertConfirmDanger({ html: `ยืนยันการล้างค่า TurnOver ทั้งหมด?` });
    if (!swal) { return; }

    this.saving = true;
    this.svMember.putClearTurnover(this.memberId).pipe(
      finalize(() => this.saving = false)
    ).subscribe(res => {
      alertSuccess({ message: `ล้างค่า turnover สำเร็จแล้ว` });
      this.getWthdrawTurnover();
      // this.clickBtnClose();
    });
  }

}
