import { Injectable } from '@angular/core';
import { HttpApiService } from '../services/http-api.service';
import { PageModel } from '../utils/interfaces/page-model';
import { Pagination } from '../utils/interfaces/pagination';

@Injectable({
  providedIn: 'root'
})
export class PromotionService {

  constructor(
    private http: HttpApiService,
  ) { }

  /**
   * get Promotion (Pagination)
   * @param params ?agent_id={agent_id}&promotion_id={promotion_id}&type_pro={normal,new_member}
   * @returns observable
   */
  get(params: PromotionPageModel) {
    return this.http.get<Pagination<Promotion[]>>(`api/ask4bet/promotion/pagination`, { params });
  }

  getCoupon(agent_id: any, params: PageModel = {}) {
    return this.http.get<Pagination<Promotion[]>>(`api/ask4bet/promotion/pagination/coupon?agent_id=${agent_id}`, { params });
  }

  post(agent_id: string | number, model: PromotionModel) {
    return this.http.post(`api/ask4bet/promotion?agent_id=${agent_id}`, model);
  }

  /**
   * update Promotion by promotion_id
   * @param model data for update
   * @param params has query params promotion_id number
   * @returns observable
   */
  put(model: any, params: PromotionPageModel) {
    return this.http.put(`api/ask4bet/promotion`, model, { params });
  }

  /**
   * delete Promotion by promotion_id
   * @param params has query params promotion_id number
   * @returns observable
   */
  delete(params: PromotionPageModel) {
    return this.http.delete(`api/ask4bet/promotion`, { params });
  }

  getHistory(member_id: number) {
    return this.http.get<PromotionHistory[]>(`api/ask4bet/promotion/history?member_id=${member_id}`);
  }

  getRequesting(member_id: number, params: PageModel) {
    return this.http.get<Pagination<PromotionMember[]>>(`api/ask4bet/promotion/promotion_request?member_id=${member_id}`, { params });
  }

  getRejected(member_id: number) {
    return this.http.get<Pagination<PromotionMember[]>>(`api/ask4bet/promotion/promotion_request/rejected?member_id=${member_id}`);
  }

  getRequestingRecommend(member_id: number) {
    return this.http.get<Pagination<PromotionRecommend[]>>(`api/ask4bet/recommended/request/recommend?member_id=${member_id}`);
  }

  putReject(member_id: number) {
    return this.http.put(`api/ask4bet/promotion/reject/promotion?member_id=${member_id}`, {});
  }

  /**
   * Get Promotion Bonus All By Member Id
   * @param params PageModel | member_id
   * @returns Observable
   */
  getBonusByMember(params: PageModel) {
    return this.http.get<Pagination<PromotionBonusMember[]>>(`api/ask4bet/promotion/bonus/all`, { params });
  }

  /**
   * Get Promotion Order
   * @param params PageModel | ?agent_id={}&sort_by={asc,desc}
   * @returns Observable
   */
  getOrder(params: PageModel) {
    return this.http.get<Pagination<Promotion[]>>(`api/ask4bet/promotion/order`, { params });
  }

  putOrder(model: PromotionOrderModel[]) {
    return this.http.put(`api/ask4bet/promotion/order`, model);
  }

  getBannerOrder(agent_id: number) {
    return this.http.get<BannerOrder[]>(`api/ask4bet/promotion/banner?agent_id=${agent_id}`);
  }

  putBannerOrder(agent_id: number, model: BannerOrderModel[]) {
    return this.http.put(`api/ask4bet/promotion/banner?agent_id=${agent_id}`, model);
  }

  /**
   * get Promotion Usage History
   * @param params ?agent_id=
   * @returns Observable
   */
  getUsageHistory(params: any) {
    return this.http.get<Pagination<PromotionUsageHistory[]>>(`api/ask4bet/report/promotion_used`, { params });
  }
}

export interface PromotionPageModel extends Partial<PageModel> {
  agent_id?: number;
  promotion_id?: number;
}

export interface PromotionModel {
  name: string;
  detail: string;
  type: string; // percent, credit
  code_pro: string;
  show_from_member: boolean;
  stack_other_pro: boolean;
  reduce_credit_when_withdrawing: boolean;
  winlose_amount: number;
  amount: number;
  max_bonus_amount: number;
  max_bonus_amount_per_date: number;
  max_use_per_date: number;
  receive_now: boolean;
  min_deposit_amount: number;
  max_deposit_amount: number;
  turn_over: number;
  turn_over_sport: boolean;
  turn_over_casino: boolean;
  turn_over_poker: boolean;
  turn_over_slot: boolean;
  turn_over_keno: boolean;
  turn_over_lotto: boolean;
  turn_over_trading: boolean;
  again: boolean;
  image: string;
  type_pro: string; // normal, new_member
  create_dt: string;
  end_dt: string;
  get_to_know_id: any;
  limit_amount_use_pro: number;
  amount_can_be_withdraw: number;

  turn_All?: boolean;
  imgLocal?: boolean;
  imgTemp?: string;
}

export interface Promotion {
  id: number;
  name: string;
  detail: string;
  type: string;
  code_pro: string;
  show_from_member: boolean;
  stack_other_pro: boolean;
  reduce_credit_when_withdrawing: boolean;
  winlose_amount?: number;
  amount: number;
  max_bonus_amount: number;
  max_bonus_amount_per_date: number;
  max_use_per_date: number;
  receive_now: boolean;
  min_deposit_amount: number;
  max_deposit_amount: number;
  turn_over: number;
  turn_over_sport: boolean;
  turn_over_casino: boolean;
  turn_over_poker: boolean;
  turn_over_slot: boolean;
  turn_over_keno: boolean;
  turn_over_lotto: boolean;
  turn_over_trading: boolean;
  again: boolean;
  image: string;
  type_pro: 'normal' | 'new_member';
  status: string;
  create_dt: string;
  end_dt: string;
  create_by: number;
  agent_id: number;
  username_create: string;
  get_to_know_id?: any;
  know_by?: any;
  order_by?: number;
  update_date: string;
  update_by: number;
  username_update: string;
  limit_amount_use_pro: number;
  amount_can_be_withdraw: number;
}

export interface PromotionMember {
  id: number;
  member_id: number;
  member_username: string;
  member_fullname: string;
  promotion_id: number;
  promotion_name: string;
  promotion_detail: string;
  type: string;
  promotion_image: string;
  promotion_end_date: string;
  type_pro: string;
  member_id_recommend: number;
  promotion_id_recommend: number;
  amount: number;
  amount_bonus: number;
  status: string;
  promotion_end_dt: string;
  is_recommend?: any;
  remark?: any;
  create_dt: string;
  update_dt?: any;
}

export interface PromotionHistory {
  promotion_name: string;
  promotion_detail: string;
  code_pro: string;
  amount: number;
  amount_bonus: number;
  stack_other_pro: boolean;
  max_bonus_amount: number;
  max_bonus_amount_per_date: number;
  max_use_per_date: number;
  min_deposit_amount: number;
  max_deposit_amount: number;
  amount_can_be_withdraw: number;
  winlose_amount?: number;
  pro_type: string;
  turn_over_sport: boolean;
  turn_over_casino: boolean;
  turn_over_poker: boolean;
  turn_over_slot: boolean;
  turn_over_keno: boolean;
  turn_over_lotto: boolean;
  turn_over_trading: boolean;
  type: string;
  recommend_name?: any;
  get_to_know_id?: any;
  create_date: string;
  image: string;
}

export interface PromotionRecommend {
  id: number;
  member_id: number;
  member_username: string;
  fullname: string;
  member_id_recommend: number;
  recommend_username: string;
  recommend_fullname: string;
  promotion_id_recommend: number;
  promotion_name: string;
  promotion_amount: number;
  promotion_type: string;
  promotion_image: string;
  promotion_end_dt: string;
  amount: number;
  status: string;
  is_recommend: boolean;
  remark?: any;
  create_dt: string;
  update_dt: string;
}

export interface PromotionBonusMember {
  Id: number;
  member_id: number;
  first_name: string;
  last_name: string;
  fullname: string;
  member_bank: string;
  member_bank_number: string;
  member_bank_code: string;
  phone: string;
  username: string;
  know_by_id: number;
  know_by: string;
  promotion_id?: any;
  promotion_name?: any;
  promotion_detail?: any;
  promotion_type?: any;
  promotion_code?: any;
  max_bonus_amount?: any;
  max_bonus_amount_per_date?: any;
  max_use_per_date?: any;
  receive_now?: any;
  min_deposit_amount?: any;
  max_deposit_amount?: any;
  amount_can_be_withdraw?: any;
  again?: any;
  image?: any;
  type_pro?: any;
  agent: number;
  agent_name: string;
  account_bank_agent_id?: any;
  account_agent_username?: any;
  account_agent_number?: any;
  accout_agent_name?: any;
  accout_agent_bank?: any;
  account_agent_balance?: any;
  account_agent_bankcode?: any;
  account_agent_status?: any;
  credit_before: number;
  amount: number;
  amount_bonus?: number;
  amount_total: number;
  credit_after: number;
  stack_other_pro?: any;
  reduce_credit_when_withdrawing?: any;
  winlose_amount?: any;
  member_id_recommend?: any;
  withdraw_then: boolean;
  note: string;
  datetime: string;
  image_statement?: string;
  deposit_statement_id?: any;
  statement_account_number?: any;
  statement_amount?: any;
  statement_bank?: any;
  create_date: string;
  update_by?: number;
  update_username?: string;
  update_name?: string;
  update_date: string;
  is_bonus: boolean;
  is_manual: boolean;
  turn_over: number;
  max_withdraw: number;
  can_withdraw: boolean;
  turn_over_sport: boolean;
  turn_over_casino: boolean;
  turn_over_poker: boolean;
  turn_over_slot: boolean;
  turn_over_keno: boolean;
  turn_over_lotto: boolean;
  turn_over_trading: boolean;
  deposit_type: PromotionBonusMemberType;
  status: string;
  check_by?: any;
  check_date?: any;
  ref_amb: string;
  promotion_new_member: boolean;
  get_to_know_id?: any;
  agent_id: number;
  agentName: string;
}

export const PromotionBonusMembers = {
  recommend: 'การแนะนำเพื่อน',
  revert_credit: 'การคืนยอดเสีย',
  loyalty: 'ระบบสะสมเเต้ม',
  bonus: 'โบนัส',
} as const;

export type PromotionBonusMemberType = keyof typeof PromotionBonusMembers;

export interface PromotionOrderModel {
  promotion_id: number;
  order: number;
}

export interface BannerOrderModel {
  ref_id: number;
  banner_type: BannerType;
  banner_priority: number;

  image?: string;
  banner_type_name?: string;
  name?: string;
}

export const bannerType = {
  promotion: 'โปรโมชัน',
  revert_credit: 'คืนยอดเสีย',
  recommend: 'แนะนำเพื่อน'
} as const;

export type BannerType = keyof typeof bannerType;

export interface BannerOrder {
  Id?: number;
  image: string;
  ref_id: number;
  banner_type: BannerType;
  banner_priority: number;
  agent_id?: number;

  banner_type_name?: string;
  name?: string;
}

export interface PromotionUsageHistory {
  id: number;
  username: string;
  phone: string;
  first_name: string;
  last_name: string;
  last_login?: string;
  deposit_amount: number;
  deposit_amount_bonus: number;
  deposit_sum: number;
  deposit_sum_bonus: number;
  withdraw_amount: number;
  withdraw_sum: number;
  recommend_total: number;
  recommend_point: number;
  recommend_transfer: number;
  recommend_downline_total: number;
  create_by?: number;
  update_date: string;
  update_by?: number;
  know_by_id?: number;
  ask4betAgentId: number;
  last_used_proomotion?: LastUsedPromotion[];
}

interface LastUsedPromotion {
  create_date: string;
}
