import { IPAddress } from './../../apis/user.service';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppInjector } from 'src/app/app-injector';
import { LocalName, LocalStorageService } from 'src/app/services/local-storage.service';
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import md5 from 'md5';
import * as moment from 'moment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { alertDanger } from '../functions/alert';

export abstract class HttpCore {
  public origin = '';
  private http = AppInjector.get(HttpClient);
  private svAuth = AppInjector.get(AuthenticationService);
  private svLocalStorage = AppInjector.get(LocalStorageService);
  private deviceService = AppInjector.get(DeviceDetectorService);
  constructor() {
  }

  get<T = any>(url: string, options: HttpOption = {}) {
    options.headers = this.getHeader;
    return this.http.get<T>(`${this.changeOrigin(this.origin)}${url}`, options).pipe(catchError(e => this.handleError(e)));
  }

  post<T = any>(url: string, body: any, options: HttpOption = {}) {
    options.headers = this.getHeader;
    return this.http.post<T>(`${this.changeOrigin(this.origin)}${url}`, body, options).pipe(catchError(e => this.handleError(e)));
  }

  put<T = any>(url: string, body: any, options: HttpOption = {}) {
    options.headers = this.getHeader;
    return this.http.put<T>(`${this.changeOrigin(this.origin)}${url}`, body, options).pipe(catchError(e => this.handleError(e)));
  }

  delete<T = any>(url: string, options: HttpOption = {}) {
    options.headers = this.getHeader;
    return this.http.delete<T>(`${this.changeOrigin(this.origin)}${url}`, options).pipe(catchError(e => this.handleError(e)));
  }

  private get getHeader(): HttpHeaders | {
    [header: string]: string | string[];
  } {
    const ip = this.svLocalStorage.get(LocalName.IpAddress) as IPAddress;
    const header: HttpHeaders | {
      [header: string]: string | string[];
    } = {
      MyIP: ip?.cf_connecting_ip || ip?.forwarded_for || '127.0.0.1',
      Device: this.deviceService.device,
      DeviceType: this.deviceService.deviceType,
      Orientation: this.deviceService.orientation,
      DeviceOS: this.deviceService.os,
      DeviceOSVersion: this.deviceService.os_version,
      browser: this.deviceService.browser,
      browser_version: this.deviceService.browser_version,
      signature: this.signature
    };
    if (this.svAuth.getToken) {
      header.Authorization = `${this.svAuth.getToken}`;
    }
    return header;
  }

  private changeOrigin(origin: string) {
    if (!environment.production && this.svLocalStorage.get(LocalName.ApiHost)) {
      return this.svLocalStorage.get(LocalName.ApiHost);
    } else {
      return origin;
    }
  }

  get signature() {
    const today = moment().format('DD|MM|yyyy').toString();
    const sign = `@:karnival:iteasylife:minnie:${today}:$`;
    return md5(sign);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 401) {
      alertDanger({ message: `เซสชันหมดอายุ กรุณาเข้าสู่ระบบใหม่!` });
      this.svAuth.logout();
    }
    return throwError(error);
  }
}

interface HttpOption {
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  observe?: 'body';
  params?: HttpParams | {
    [param: string]: string | string[];
  };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}
