import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { finalize } from 'rxjs/operators';
import { ReportBill, ReportsService } from 'src/app/apis/reports.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { alertSuccess, alertText, alertWarning } from 'src/app/utils/functions/alert';

@Component({
  selector: 'app-bill-status-form-offcanvas',
  templateUrl: './bill-status-form-offcanvas.component.html',
  styleUrls: ['./bill-status-form-offcanvas.component.scss']
})
export class BillStatusFormOffcanvasComponent extends AppCore implements OnInit {
  @Input() editBill: ReportBill;

  form = this.createForm;
  saving = false;
  constructor(
    private svReport: ReportsService,
    private ngFb: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
  }

  get createForm(): FormGroup<any> {
    return this.ngFb.group({
      payment_date: ['', [Validators.required]],
      image: ['', [Validators.required]],
    });
  }

  onSubmit() {
    this.form.markAllAsDirty();
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      alertWarning({ message: alertText.saveSuccess });
      return;
    }

    this.saving = true;
    this.svReport.putBillPayment(this.editBill.Id, this.form.value).pipe(
      finalize(() => this.saving = false)
    ).subscribe(res => {
      alertSuccess({ message: alertText.saveSuccess });
      this.clickBtnClose('close-bill-status');
    });
  }
}
