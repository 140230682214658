import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalName, LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(
    private ngxTranslate: TranslateService,
    private svLocalStorage: LocalStorageService,
  ) { }

  getLang(): LanguageItem {
    return SelectLanguages.find(f => f.value === (this.ngxTranslate.currentLang as LanguageKey));
  }

  setLang(lang: LanguageKey) {
    this.ngxTranslate.use(lang);
    this.svLocalStorage.set(LocalName.Language, lang);
  }

  openChooseLanguage() {
    const btnEl = document.getElementById('btn-open-choose-lang');
    btnEl?.click();
  }
}

export const AllLanguage = {
  th: 'ภาษาไทย',
  en: 'English',
  kh: 'កម្ពុជា។',
  la: 'ພາສາລາວ',
  mm: 'မြန်မာ',
} as const;

export type LanguageKey = keyof typeof AllLanguage;

export const SelectLanguages: LanguageItem[] = [
  { name: AllLanguage.en, value: 'en', image: 'https://cdn.karnival.systems/images/flags/en.png', },
  { name: AllLanguage.th, value: 'th', image: 'https://cdn.karnival.systems/images/flags/th.png', },
  { name: AllLanguage.kh, value: 'kh', image: 'https://cdn.karnival.systems/images/flags/kh.png', },
  { name: AllLanguage.la, value: 'la', image: 'https://cdn.karnival.systems/images/flags/la.png', },
  { name: AllLanguage.mm, value: 'mm', image: 'https://cdn.karnival.systems/images/flags/mm.png', },
];

export interface LanguageItem {
  name: string;
  value: LanguageKey;
  image: string;
}
