import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'bankImg'
})
export class BankImgPipe implements PipeTransform {

  transform(value: any, arg: '' | 'bank_code' = ''): string {
    if (typeof value !== 'string') { return value || ''; }
    if (value.startsWith(`https://`)) {
      return value;
    } else if (value.startsWith(`data:image`)) {
      return value;
    } else if (value.startsWith(`/assets/`)) {
      return value;
    } else if (arg === 'bank_code') {
      const newVals = 'https://bank-center.karnival.systems/assets/images/bank/' + value.toLowerCase() + '.png';
      return newVals;
    }
    const newVal = environment.image + value.toLowerCase();
    return newVal;
  }

}
