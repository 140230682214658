import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';
import { BankCode, BANK_LOGOS } from 'src/assets/banks-logo/banks';

@Directive({
  selector: '[appBankLogo]'
})
export class BankLogoDirective implements OnChanges {
  /**
   * @params appBankLogo bankCode
   */
  @Input() appBankLogo: string;
  /**
   * @params width with px
   */
  @Input() width = 40;
  /**
   * @params height with px
   */
  @Input() height = 40;
  /**
   * @params padding with px
   */
  @Input() padding = 10;

  constructor(
    private _elRef: ElementRef<HTMLImageElement>,
    private _renderer: Renderer2,
  ) { }

  ngOnChanges() {
    const bankCode: BankCode = (this.appBankLogo?.toLowerCase() as BankCode) || 'baac';
    if (!bankCode) { return; }
    const BANK = BANK_LOGOS[bankCode];
    if (!BANK) { return; }

    const bankLogoEl = document.createElement('span');
    this._renderer.setStyle(bankLogoEl, 'mask', `url('/assets/banks-logo/th/${bankCode}.svg') no-repeat center / contain`);
    this._renderer.setStyle(bankLogoEl, '-webkit-mask', `url('/assets/banks-logo/th/${bankCode}.svg') no-repeat center / contain`);
    this._renderer.setStyle(bankLogoEl, 'width', `${this.width - this.padding}px`);
    this._renderer.setStyle(bankLogoEl, 'height', `${this.height - this.padding}px`);
    this._renderer.setStyle(bankLogoEl, 'display', `inline-block`);
    this._renderer.setStyle(bankLogoEl, 'background-color', `#fff`);
    this._elRef.nativeElement.append(bankLogoEl);

    this._renderer.setStyle(this._elRef.nativeElement, 'background-color', BANK.color);
    this._renderer.setStyle(this._elRef.nativeElement, 'width', `${this.width}px`);
    this._renderer.setStyle(this._elRef.nativeElement, 'height', `${this.height}px`);
    this._renderer.setStyle(this._elRef.nativeElement, 'display', `inline-block`);
    this._renderer.setStyle(this._elRef.nativeElement, 'padding', `${this.padding / 2}px`);
    this._renderer.setStyle(this._elRef.nativeElement, 'border-radius', `4px`);
  }
}
