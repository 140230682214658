import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'imgHost'
})
export class ImgHostPipe implements PipeTransform {

  transform(value: string): any {
    if (!value) {
      return '/assets/placeholder.jpg';
    }
    const noHost = value.startsWith('http') || value.startsWith('data:image') || value.startsWith('assets');
    if (noHost) {
      return value;
    } else {
      return environment.image + value;
    }
  }

}
