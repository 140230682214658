<div class="offcanvas offcanvas-end" id="sageLinkFormOffcanvas">
  <div class="offcanvas-header">
    <h4 class="mb-0" id="offcanvasRightLabel">{{'EDIT_LINK'|translate}}</h4>
    <button id="btn-close-link-offcanvas" type="button" class="btn-close text-reset"
      data-bs-dismiss="offcanvas"></button>
  </div>
  <div class="offcanvas-body">
    <form [formGroup]="form" (submit)="onSubmit()">
      <div class="mb-3">
        <label for="" class="form-label">{{'LINK_NAME'|translate}}</label>
        <input type="text" class="form-control" formControlName="link_name" (focus)="selectAllText($event)">
      </div>
      <div class="mb-3">
        <button type="submit" class="btn btn-primary btn-submit" [disabled]="saving">
          <i *ngIf="saving" class="fas fa-spinner fa-spin"></i>
          {{'EDIT_LINK'|translate}}
        </button>
      </div>
    </form>
  </div>
</div>
