import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from 'src/app/services/currency.service';

@Pipe({
  name: 'exTHBToAny',
  pure: false
})
export class ExThbToAnyPipe implements PipeTransform {
  constructor(
    private svCurrency: CurrencyService,
    private _decimalPipe: DecimalPipe
  ) { }
  transform(value: any): any {
    return `~${this._decimalPipe.transform(this.svCurrency.ExchangeTHB2ANY(value),".2-2")} ${this.svCurrency.getCurrency().value}`;
  }
}
