import { PieChart } from 'src/app/shared/components/charts/chart.interface';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pie-legend-chart',
  templateUrl: './pie-legend-chart.component.html',
  styleUrls: ['./pie-legend-chart.component.scss']
})
export class PieLegendChartComponent implements OnInit {
  @Input() MasterData: PieChart.Series[];
  constructor() { }

  ngOnInit() {
  }
  ngOnChanges() {
  }

}
