import { LocalName } from './services/local-storage.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { IPAddress } from './apis/user.service';
import { UserService } from 'src/app/apis/user.service';
import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { AppCore } from './utils/classes/app-core';
import { animate, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { FADE_ROUTER } from './animations';
import { Chart, registerables } from 'chart.js';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { filter, finalize } from 'rxjs/operators';
import { LanguageService, LanguageKey, AllLanguage } from './services/language.service';
Chart.register(...registerables);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('MyFade', [
      transition(':enter', [
        style({
          opacity: 0
        }),
        animate(500, style({
          opacity: 1
        }))
      ]),
      transition(':leave', [
        animate(500, style({
          opacity: 0
        }))
      ]),
    ]),
    FADE_ROUTER,
  ]
})
export class AppComponent extends AppCore implements OnInit, OnDestroy, AfterViewInit {
  showloadingScreen = true;
  isReady = false;
  loadingTime = 200;
  viewImage: string;
  showChat;
  constructor(
    private router: Router,
    private acRouter: ActivatedRoute,
    private svUser: UserService,
    private svlocal: LocalStorageService,
    private svTitle: Title,
    private svLanguage: LanguageService,
  ) {
    super();

    this.setupLanguage();

    if (!environment.production && !this.svlocal.get(LocalName.ApiHost)) {
      this.svlocal.set(LocalName.ApiHost, '');
    }

    this.svUser.getConnectIpAddress().pipe(
      finalize(() => this.isReady = true)
    ).subscribe((ip: IPAddress) => {
      this.svlocal.set(LocalName.IpAddress, ip);
    });

    const viewImage$ = this.svApp.viewImage$.subscribe(val => {
      this.viewImage = val;
    });
    this.Subscriptions.add(viewImage$);

    this.OnLoadingComponents();
    this.svTitle.setTitle(`${this.Config.YourBand.en} | Auto & Agent Management Site`);
  }

  ngOnInit() {
  }
  ngAfterViewInit() {
    const ele: HTMLLinkElement = document.getElementById('favicon') as HTMLLinkElement;
    ele.href = this.Config.Favicon;
  }

  closeImage() {
    this.svApp.closeImage();
  }

  OnLoadingComponents() {
    this.Subscriptions.add(
      this.router.events.pipe(
        filter(event => event instanceof NavigationStart)
      ).subscribe(() => {
        this.showloadingScreen = true;
      }),
    );
    this.Subscriptions.add(
      this.router.events.pipe(
        filter(event => (event instanceof NavigationCancel || event instanceof NavigationEnd || event instanceof NavigationError))
      ).subscribe(() => {
        this.showloadingScreen = false;
      }),
    );
  }

  setupLanguage() {
    const localLang = this.svlocal.get(LocalName.Language) || '';
    if (localLang && Object.keys(AllLanguage).includes(localLang)) {
      this.svLanguage.setLang(localLang);
    } else {
      this.svLanguage.setLang('th');
    }
  }
}
