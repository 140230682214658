<div class="offcanvas offcanvas-end" tabindex="-1" [id]="id">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" *ngIf="isAdd">เพิ่มเครดิต</h5>
    <h5 class="offcanvas-title" *ngIf="!isAdd">ลดเครดิต</h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
      id="btn-close-credit-form-offcanvas"></button>
  </div>
  <div class="offcanvas-body">
    <app-member-credit-form [isAdd]="isAdd" [memberId]="memberId" (saveSuccess)="clickBtnClose()">
    </app-member-credit-form>
  </div>
</div>
