<div class="offcanvas offcanvas-end" tabindex="-1" id="settingSystemFormOffcanvas">
  <div class="offcanvas-header">
    <h4 class="offcanvas-title" *ngIf="!updateItem"> {{'CREATE'|translate}} {{'SETTING_SYSTEM'|translate}} </h4>
    <h4 class="offcanvas-title" *ngIf="updateItem"> {{'UPDATE'|translate}} {{'SETTING_SYSTEM'|translate}} </h4>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" id="btn-close-ss-offcanvas"></button>
  </div>
  <div class="offcanvas-body">
    <form [formGroup]="form" (submit)="onSubmit()">
      <div class="mb-3">
        <label for="" class="form-label">Config Type</label>
        <!-- <select class="form-select" formControlName="config_type">
          <option *ngFor="let op of SystemTypeItems" [value]="op"> {{op}} </option>
        </select> -->
        <ng-select [items]="configTypeItems" formControlName="config_type" [addTag]="true"></ng-select>
      </div>
      <div class="mb-3">
        <label for="" class="form-label">Url</label>
        <input type="text" class="form-control" formControlName="url">
      </div>
      <div class="mb-3">
        <label for="" class="form-label">Name</label>
        <input type="text" class="form-control" formControlName="name">
      </div>
      <div class="mb-3">
        <label for="" class="form-label">Type</label>
        <ng-select [items]="SystemTypeItems" formControlName="type" [addTag]="true"></ng-select>
        <!-- <select class="form-select" formControlName="type">
          <option *ngFor="let op of configTypeItems" [value]="op"> {{op}} </option>
        </select> -->
      </div>
      <div class="mb-3">
        <label for="" class="form-label">Value</label>
        <input type="text" class="form-control" formControlName="value">
      </div>
      <hr>
      <div class="text-end mb-3">
        <button type="submit" class="btn btn-primary btn-submit"> {{'SAVE_DATA'|translate}} </button>
      </div>
    </form>
  </div>
</div>
