import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SettingDeposit, SettingWithdraw, SettingsService } from '../apis/settings.service';
import { debounceTime } from 'rxjs/operators';
import { LocalName, LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  currencyCurrent = 'USD';
  supports = ['EUR', 'KHR', 'LAK', 'MMK', 'USD'];
  ExchangeRate: any = {};
  currencyTempEx = {
    ANY2THB: {
      THB: 1,
      EUR: 0,
      KHR: 0,
      LAK: 0,
      MMK: 0,
      USD: 0
    },
    THB2ANY: {
      THB: 1,
      EUR: 0,
      KHR: 0,
      LAK: 0,
      MMK: 0,
      USD: 0
    }
  };
  onCurrencyChange = new BehaviorSubject<CurrencyItem>(this.getCurrency());
  currencyExchange = new BehaviorSubject<ICurrency>(this.currencyTempEx);
  constructor(
    private http: HttpClient,
    private svSetting: SettingsService,
    private svLocalStorage: LocalStorageService
  ) {
    this.currencyExchange.pipe(debounceTime(1000)).subscribe((res) => {
      // console.log(res);
      // console.log(this.ExchangeRate);
    });
  }

  ExchangeTHB2ANY(amount: number) {
    // const exchangeCurrencyAmount = this.currencyTempEx.THB2ANY[this.getCurrency().value] || 1;
    const exchangeCurrencyAmount = this.currencyTempEx.ANY2THB[this.getCurrency().value] || 1;
    return +(amount * exchangeCurrencyAmount).toFixed(2);
  }
  ExchangeANY2THB(amount: number) {
    const exchangeCurrencyAmount = this.currencyTempEx.ANY2THB[this.getCurrency().value] || 1;
    return +(amount / exchangeCurrencyAmount).toFixed(2);
  }

  getCurrency(): CurrencyItem {
    this.currencyCurrent = this.svLocalStorage.get(LocalName.currency) || 'USD';
    return SelectCurrency.find(f => f.value === this.currencyCurrent);
  }

  setCurrency(currency: CurrencyKey) {
    this.currencyCurrent = currency;
    this.onCurrencyChange.next(SelectCurrency.find(f => f.value === this.currencyCurrent));
    this.svLocalStorage.set(LocalName.currency, currency);
  }
  openChooseCurrency() {
    const btnEl = document.getElementById('btn-open-choose-currency');
    btnEl?.click();
  }

  setupCurrencyEx(agent_id: number) {
    this.svSetting.getDeposit(agent_id)
      .subscribe((res: SettingDeposit) => {
        this.supports.forEach(c => {
          this.currencyTempEx.ANY2THB[c] = res[`${c}_to_THB`];
          if (!this.currencyTempEx.ANY2THB[c]) {
            //  this.getCurrencyDefualt(c);
          }
        });
        this.currencyExchange.next(this.currencyTempEx);
      });
    this.svSetting.getWithdraw(agent_id)
      .subscribe((res: SettingWithdraw) => {
        this.supports.forEach(c => {
          this.currencyTempEx.THB2ANY[c] = res[`THB_to_${c}`];
          if (!this.currencyTempEx.THB2ANY[c]) {
            // this.getCurrencyDefualt(c);
          }
        });
        this.currencyExchange.next(this.currencyTempEx);
      });
  }
  getCurrencyDefualt(currency: string) {
    this.http.get(`https://coinyep.com/api/v1/?from=${currency}&to=THB&lang=th&format=json`)
      .subscribe((res: ICurrencyEx) => {
        this.ExchangeRate[`THB_to_${currency}`] = res;
        if (!this.currencyTempEx.THB2ANY[currency]) {
          this.currencyTempEx.THB2ANY[currency] = +res.price || 0;
          this.currencyExchange.next(this.currencyTempEx);
        }
      });
    this.http.get(`https://coinyep.com/api/v1/?from=THB&to=${currency}&lang=th&format=json`)
      .subscribe((res: ICurrencyEx) => {
        this.ExchangeRate[`${currency}_to_THB`] = res;
        if (!this.currencyTempEx.ANY2THB[currency]) {
          this.currencyTempEx.ANY2THB[currency] = +res.price || 0;
          this.currencyExchange.next(this.currencyTempEx);
        }
      });
  }
}

export interface ICurrency {
  THB2ANY: {
    USD: number;
    EUR: number;
    LAK: number;
    MMK: number;
    KHR: number;
  },
  ANY2THB: {
    USD: number;
    EUR: number;
    LAK: number;
    MMK: number;
    KHR: number;
  }
}

export interface ICurrencyEx {
  base_symbol: string
  base_name: string
  target_symbol: string
  target_name: string
  price: number
  price_change: number
}

export const AllCurrency = {
  THB: 'THB',
  USD: 'USD',
  KHR: 'KHR',
  LAK: 'LAK',
  MMK: 'MMK',
} as const;

export type CurrencyKey = keyof typeof AllCurrency;

export const SelectCurrency: CurrencyItem[] = [
  { name: AllCurrency.USD, value: 'USD', image: 'https://cdn.karnival.systems/images/flags/en.png', },
  { name: AllCurrency.THB, value: 'THB', image: 'https://cdn.karnival.systems/images/flags/th.png', },
  { name: AllCurrency.KHR, value: 'KHR', image: 'https://cdn.karnival.systems/images/flags/kh.png', },
  { name: AllCurrency.LAK, value: 'LAK', image: 'https://cdn.karnival.systems/images/flags/la.png', },
  { name: AllCurrency.MMK, value: 'MMK', image: 'https://cdn.karnival.systems/images/flags/mm.png', },
];

export interface CurrencyItem {
  name: string;
  value: CurrencyKey;
  image: string;
}
