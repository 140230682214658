import { AfterViewInit, Component, Input, NgZone, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import * as dayjs from 'dayjs';
import { debounceTime, finalize } from 'rxjs/operators';
import { GetToKnow, GetToKnowService } from 'src/app/apis/get-to-know.service';
import { Promotion, PromotionModel, PromotionService } from 'src/app/apis/promotion.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { alertConfirm, alertSuccess, alertText, alertWarning } from 'src/app/utils/functions/alert';
import { fileToBase64, validateFiles } from 'src/app/utils/functions/base64';
import { noWhitespaceValidator } from 'src/app/utils/functions/no-whitespace';

@Component({
  selector: 'app-promotion-form-offcanvas',
  templateUrl: './promotion-form-offcanvas.component.html',
  styleUrls: ['./promotion-form-offcanvas.component.scss']
})
export class PromotionFormOffcanvasComponent extends AppCore implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  form: FormGroup<PromotionModel> = this.createForm();
  loading = false;
  toKnowItems: GetToKnow[] = [];

  @Input() agentId: number;
  @Input() promotion: Promotion;
  @Input() type_pro: 'normal' | 'new_member' = 'normal';
  @Input() isCoupon = false;
  @Input() isView = false;
  namePage: string;
  constructor(
    private svPromotion: PromotionService,
    private svGetToKnow: GetToKnowService,
    private ngFb: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    const promotionOffcanvasEl = document.getElementById(`promotionOffcanvas`) as HTMLDivElement;
    promotionOffcanvasEl.addEventListener('shown.bs.offcanvas', () => {
      this.getToKnow();
      const formReceiveNow$ = this.form.get('receive_now').valueChanges.pipe(debounceTime(100)).subscribe(val => {
        if (val) {
          this.form.patchValue({
            type: 'credit',
            max_bonus_amount: 0,
          });
        }
      });
      const formType$ = this.form.get('type').valueChanges.pipe(debounceTime(100)).subscribe(val => {
        if (val === 'credit') {
          this.form.patchValue({
            max_bonus_amount: 0,
          });
        }
      });
      this.Subscriptions.add(formReceiveNow$);
      this.Subscriptions.add(formType$);

      if (this.isView) {
        this.form.disable();
      }
    });
  }

  ngOnChanges() {
    this.form = this.createForm();
    this.namePage = !this.isCoupon ? 'PROMOTION' : 'COUPON';
    if (this.promotion) {
      const endDate = this.promotion.end_dt ? dayjs(this.promotion.end_dt).format('YYYY-MM-DD') : '';
      this.form.patchValue({
        ...this.promotion,
        end_dt: endDate,
        imgTemp: this.promotion.image,
        get_to_know_id: this.promotion.get_to_know_id || '',
      });
      this.checkGameChange();
    }
  }

  createForm(): FormGroup<PromotionModel> {
    return this.ngFb.group({
      image: [''],
      name: ['', [noWhitespaceValidator, Validators.maxLength(255)]],
      detail: ['', [Validators.maxLength(1024)]],
      end_dt: ['', [Validators.required]],
      type: ['percent', [Validators.maxLength(50)]],
      code_pro: [''],
      show_from_member: [false],
      stack_other_pro: [false],
      reduce_credit_when_withdrawing: [true],
      winlose_amount: [0],
      amount: [0],
      max_bonus_amount: [0],
      max_bonus_amount_per_date: [0],
      max_use_per_date: [0],
      receive_now: [false],
      min_deposit_amount: [0],
      max_deposit_amount: [0],
      turn_over: [0],
      turn_over_sport: [true],
      turn_over_casino: [true],
      turn_over_poker: [true],
      turn_over_slot: [true],
      turn_over_keno: [true],
      turn_over_lotto: [true],
      turn_over_trading: [true],
      again: [false],
      type_pro: [this.type_pro],
      create_dt: [''],
      get_to_know_id: [''],
      limit_amount_use_pro: [0],
      amount_can_be_withdraw: [0],

      turn_All: [true],
      imgLocal: [false],
      imgTemp: [''],
    });
  }

  async inputFileChange(event: Event) {
    const inputEl = event.target as HTMLInputElement;
    const files = inputEl.files;

    const validFile = validateFiles(files);
    if (!validFile) { return; }
    const base64 = await fileToBase64(files[0]);
    this.form.get('image').setValue(base64);
    this.form.get('imgLocal').setValue(true);
  }

  async onSubmit() {
    this.form.markAllAsDirty();
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      alertWarning({ message: alertText.formInvalid });
      return;
    }

    if (this.isCoupon) {
      if (!this.form.get('code_pro').value) {
        this.form.get(`code_pro`).setErrors({ required: true });
        alertWarning({ message: `กรุณากรอกโค้ด` });
        return;
      }
    }

    if (!this.form.get('reduce_credit_when_withdrawing').value) {
      const swal = await alertConfirm({ html: `ถ้าไม่ติ๊ก "<b>ลบโบนัสเมื่อถอน</b>" สมาชิกจะสามารถถอนเครดิตที่เป็นโบนัสได้ <br> ทำรายการต่อ?` });
      if (!swal.isConfirmed) { return; }
    }

    if (this.form.get('receive_now').value && !this.form.get('max_use_per_date').value) {
      const swal = await alertConfirm({ html: `${this.namePage}นี้ จะไม่จำกัดจำนวนครั้งที่ใช้สูงสุดต่อคนใน 1 วัน <br> ทำรายการต่อ?` });
      if (!swal.isConfirmed) {
        setTimeout(() => {
          document.getElementById('max_use_per_date')?.focus();
        }, 300);
        return;
      }
    }

    try {
      this.loading = true;
      if (!this.promotion) {
        await this.createPromotion();
      } else {
        await this.updatePromotion();
      }
      alertSuccess({ message: alertText.saveSuccess });
      this.clickBtnClose('promotion-close');
      this.form = this.createForm();
    } catch (e) { }
  }

  createPromotion() {
    return this.svPromotion.post(this.agentId, this.form.getRawValue()).pipe(finalize(() => this.loading = false)).toPromise();
  }
  updatePromotion() {
    return this.svPromotion.put(this.form.getRawValue(), { promotion_id: this.promotion.id }).pipe(finalize(() => this.loading = false)).toPromise();
  }

  checkAllChange() {
    this.form.patchValue({
      turn_over_sport: this.form.get('turn_All').value,
      turn_over_casino: this.form.get('turn_All').value,
      turn_over_poker: this.form.get('turn_All').value,
      turn_over_slot: this.form.get('turn_All').value,
      turn_over_keno: this.form.get('turn_All').value,
      turn_over_lotto: this.form.get('turn_All').value,
      turn_over_trading: this.form.get('turn_All').value,
    });
  }

  checkGameChange() {
    if (
      this.form.get('turn_over_sport').value === true &&
      this.form.get('turn_over_casino').value === true &&
      this.form.get('turn_over_poker').value === true &&
      this.form.get('turn_over_slot').value === true &&
      this.form.get('turn_over_keno').value === true &&
      this.form.get('turn_over_lotto').value === true &&
      this.form.get('turn_over_trading').value === true
    ) {
      this.form.patchValue({ turn_All: true });
    } else {
      this.form.patchValue({ turn_All: false });
    }
  }

  getToKnow() {
    this.svGetToKnow.get({ agent_id: this.currentAgentId, show_member: '' }).subscribe(res => {
      this.toKnowItems = res;
    });
  }
}
