<ng-select [items]="promotion$ | async" bindValue="id" [placeholder]="'CHOOSE_PROMOTION'|translate"
  [(ngModel)]="promotionId" [loading]="promotionLoading" [trackByFn]="trackByFn" [typeahead]="promotionInput$"
  (change)="selectChange()" [typeToSearchText]="'TYPE_TO_SEARCH_PROMOTION'|translate"
  [notFoundText]="'NOT_FOUND_PROMOTION'|translate" [clearAllText]="'CLEAR'|translate">
  <ng-template ng-label-tmp ng-option-tmp let-item="item">
    <img [src]="imgHost + item.image" alt="" width="25px" height="25px" style="object-fit: contain;">
    {{item.name}}
  </ng-template>
</ng-select>
<div class="mt-3" *ngIf="showDetail">
  <div class="bg-secondary p-2">
    <label for="" class="form-label strong">{{'PROMOTION_DATA'|translate}}</label>
    <div class="">
      <strong>{{'PROMOTION_NAME'|translate}} : </strong>
      <span class=""> {{selectedPromotion?.name || '-'}} </span>
    </div>
    <div class="">
      <strong>{{'BONUS_AMOUNT'|translate}} : </strong>
      <span class="d-inline-flex" *ngIf="selectedPromotion">
        {{(selectedPromotion.amount || 0) | number: '0.2-2'}}
        <span>{{selectedPromotion.type === 'percent'?'%':''}}</span>
      </span>
      <span *ngIf="!selectedPromotion">-</span>
    </div>
  </div>
</div>
