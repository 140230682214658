import { Injectable } from '@angular/core';
import { HttpApiService } from '../services/http-api.service';
import { PageModel } from '../utils/interfaces/page-model';
import { Pagination } from '../utils/interfaces/pagination';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {

  constructor(
    private http: HttpApiService,
  ) { }

  /**
   * Get Announcement Pagination
   * @param params PageModel, ?agent_id={agent_id}
   * @returns Observable pagination
   */
  get(params: AnnouncementPageModel) {
    return this.http.get<Pagination<Announcement[]>>(`api/ask4bet/announcement`, { params });
  }

  getById(id: number) {
    return this.http.get<Announcement>(`api/ask4bet/announcement/${id}`);
  }

  post(agent_id: number, model: AnnouncementModel) {
    return this.http.post(`api/ask4bet/announcement?agent_id=${agent_id}`, model);
  }

  put(id: number, model: AnnouncementModel) {
    return this.http.put(`api/ask4bet/announcement/${id}`, model);
  }

  delete(id: number) {
    return this.http.delete(`api/ask4bet/announcement/${id}`);
  }
}

export interface AnnouncementPageModel extends Partial<PageModel> {
  agent_id?: number;
}

export interface AnnouncementModel {
  priority: number;
  title: string;
  text: string;
  image: string;
  url: string;
  status: string;

  localImage?: boolean;
}

export interface Announcement {
  id: number;
  agent_id: number;
  agentName: string;
  priority: number;
  title: string;
  text: string;
  image: string;
  url: string;
  status: string;
  create_dt: string;
  update_dt: string;
  update_by: number;
  username: string;
}
