import { ITranferStateResult } from './../services/signalr-bank.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpApiService } from '../services/http-api.service';
import { PageModel } from '../utils/interfaces/page-model';
import { Pagination } from '../utils/interfaces/pagination';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  selectBankId$ = new BehaviorSubject<number>(0);

  creditFilters = [{
    name: 'เครดิตปกติ',
    value: 'normal',
  }, {
    name: 'เครดิตเติมมือ',
    value: 'manual',
  }, {
    name: 'เครดิตโบนัส',
    value: 'bonus',
  }, {
    name: 'อัตโนมัติ',
    value: 'auto',
  }];

  withdawAutoOffcanvas$ = new Subject<WithdrawAuto>();
  constructor(
    private http: HttpApiService,
    private ngFb: FormBuilder,
  ) { }

  /**
   * แสดง transaction deposit withdraw
   * @param params PageModel, ?from={from}&to={to}&member_id={member_id}&to_bank={to_bank}&fil={manual,auto,normal,bonus}&type={type}&agent_id={agent_id}
   *
   * api/transaction?from,to,member_id,to_bank,fil,type,agent_id,order_by,descending
   * @returns Observable
   */
  get(params: PageModel = {}) {
    return this.http.get<Pagination<Transaction[]>>(`api/transaction`, { params });
  }

  /**
   * แสดง transaction deposit withdraw
   * @param params PageModel, ?from={from}&to={to}&member_id={member_id}&to_bank={to_bank}&fil={manual,auto,normal,bonus}&type={type}&agent_id={agent_id}
   * @returns Observable
   */
  getCSV(params: PageModel = {}) {
    return this.http.get<Transaction[]>(`api/transaction/csv`, { params });
  }

  /**
   * แสดง transaction ทั้งหมดที่ admin กระทำรายการ
   * @param params PageModel, ?admin_id={admin_id}&member_id={member_id}&type={type}
   * @returns Observable
   */
  getAdmin(params: PageModel) {
    return this.http.get(`api/transaction/admin`, { params })
  }

  getByAgentId(agent_id: number, params: PageModel = {}) {
    return this.http.get<Pagination<Transaction[]>>(`api/transaction/agent?agent_id=${agent_id}`, { params });
  }

  getLoginGameHistory(params: LoginGameHistoryModel) {
    return this.http.get<Pagination<LoginGameHistory[]>>(`api/ask4bet/log_transaction_credit`, { params });
  }

  getLoginGameHistorySummary(params: LoginGameHistoryModel) {
    return this.http.get<LoginGameHistorySummary[]>(`api/ask4bet/log_transaction_credit/summary`, { params });
  }

  getLoginGameHistoryById(id: number) {
    return this.http.get(`api/ask4bet/log_transaction_credit/${id}`);
  }

  /**
   * แสดงรายการที่กำลังรอการเข้าคิวและรายการที่กำลังดพเนินการอยู่ (pending and inprogress)
   * @param accBankId number
   * @returns Obserable
   */
  getWithdrawAutoPending(accBankId: number, params: PageModel = {}) {
    return this.http.get<Pagination<WithdrawAuto[]>>(`api/ask4bet/withdraw/auto/pending?account_id=${accBankId}`, { params });
  }

  /**
   * แสดงรายการโอนที่เกิดข้อผิดพลาด
   * @param accBankId number
   * @returns Obserable
   */
  getWithdrawAutoFailed(accBankId: number, params: PageModel = {}) {
    return this.http.get<Pagination<WithdrawAuto[]>>(`api/ask4bet/withdraw/auto/failed?account_id=${accBankId}`, { params });
  }

  getWithdrawAutoHistory(accBankId: number, params: PageModel = {}) {
    return this.http.get<Pagination<WithdrawAuto[]>>(`api/ask4bet/withdraw/auto/history?account_id=${accBankId}`, { params });
  }

  getWithdrawAutoLog(withdraw_id: number, params: PageModel = {}) {
    return this.http.get<Pagination<WithdrawAutoLog[]>>(`api/ask4bet/withdraw/auto/log?withdraw_id=${withdraw_id}`, { params });
  }

  putWithdrawAutoRetry(withdraw_id: number) {
    return this.http.put(`api/ask4bet/withdraw/auto/retry_auto?withdraw_id=${withdraw_id}`, {});
  }

  putWithdrawAutoCancel(withdraw_id: number) {
    return this.http.put(`api/ask4bet/withdraw/auto/cancel?withdraw_id=${withdraw_id}`, {});
  }
  CheckHaveTransfered(withdraw_id: number) {
    return this.http.get(`api/ask4bet/withdraw/auto/check_duplicate_transfer?withdraw_id=${withdraw_id}`);
  }

  postWinLoseManual(log_transaction_id: number, model: WinLoseManualModel) {
    return this.http.post(`api/ask4bet/winlose/manual?log_transaction_id=${log_transaction_id}`, model);
  }
  get createFormWinLoseManual(): FormGroup<WinLoseManualModel> {
    return this.ngFb.group({
      game: [''],
      amount: [0],
      validAmount: [0],
      wlTurnAmount: [0],
      wlAmount: [0],
      outstanding: [0],
      note: [''],
    })
  }
}

export enum TransactionType {
  deposit = 'deposit',
  withdraw = 'withdraw',
  tournament = 'tournament',
}

export type TransactionTypeKey = keyof typeof TransactionType | '';

export interface PageTransactionModel extends Partial<PageModel> {
  memberId?: number;
  type?: TransactionTypeKey;
}

export interface TransacctionAdminPageModel extends Partial<PageModel> {
  admin_id?: string;
  member_id?: string;
  type?: string;
}

export interface Transaction {
  Id: number;
  member_id: number;
  username: string;
  agent_id: number;
  agent_name: string;
  credit_before: number;
  amount: number;
  amount_bonus: number;
  amount_total: number;
  credit_after: number;
  from_bank?: string;
  from_bank_id?: string;
  from_bank_code?: string;
  from_account_name?: string;
  to_bank?: string;
  to_bank_id?: string;
  to_bank_code?: string;
  to_account_name?: string;
  datetime: string;
  type: string;
  status: string;
  image_statement?: string;
  note: string;
  is_bonus: boolean;
  is_manual: boolean;
  update_by: number;
  update_by_name: string;
  update_date: string;
  create_date: string;
  Transaction: 'Deposit' | 'Withdraw';
}

interface RootObject {
  items: Item[];
  currentPage: number;
  limitRow: number;
  resultRow: number;
}

interface Item {
  Id: number;
  member_id: number;
  username: string;
  agent_id: number;
  agent_name: string;
  credit_before: number;
  amount: number;
  amount_bonus: number;
  amount_total: number;
  credit_after: number;
  from_bank?: string;
  from_bank_id?: string;
  from_bank_code?: string;
  from_account_name?: string;
  to_bank?: string;
  to_bank_id?: string;
  to_bank_code?: string;
  to_account_name?: string;
  datetime: string;
  type: string;
  status: string;
  image_statement?: string;
  note: string;
  is_bonus: boolean;
  is_manual: boolean;
  update_by: number;
  update_by_name: string;
  update_date: string;
  create_date: string;
  Transaction: string;
}

export interface LoginGameHistoryModel extends PageModel {
  member_id?: any;
  from?: string;
  to?: string;
}

export interface LoginGameHistory {
  Id: number;
  member_id: number;
  first_name: string;
  last_name: string;
  username: string;
  amb_username: string;
  admin_id?: any;
  amount: number;
  credit_from_before: number;
  credit_from_after: number;
  credit_to_before?: any;
  credit_to_after?: any;
  action: string;
  create_date: string;
  ref?: string;
  ip_address: string;
  browser: string;
  from_system: string;
  to_system: string;
  game_list_id?: any;
  game_list_name?: any;
  game_list_image?: any;
  game_id?: any;
  game_name?: any;
  game_image?: any;
  game_image_preview?: any;
  game_type?: any;
  provider: string;
  get_winlose: boolean;
  ref_transaction?: any;
  status: string;
  origin_for_bypass: string;
}

export interface LoginGameHistorySummary {
  date: string;
  casino: number;
  slot: number;
  sport: number;
  lotto: number;
  poker: number;
}

export interface WithdrawAuto {
  Id: number;
  member_id: number;
  first_name: string;
  last_name: string;
  fullname: string;
  member_bank: string;
  member_bank_number: string;
  phone: string;
  username: string;
  credit_before: number;
  amount: number;
  amount_bonus: number;
  amount_total: number;
  credit_after: number;
  datetime: string;
  withdraw_type: string;
  status: string;
  note: string;
  run: string;
  slip: string;
  withdraw: string;
  bank_code: string;
  bank_id: string;
  bank_name: string;
  update_by: number;
  update_username: string;
  update_name: string;
  update_date: string;
  check_by: number;
  check_username: string;
  check_name: string;
  check_date: string;
  auto_tranfer_bank_account_id: number;
  account_agent_number: string;
  account_agent_bank: string;
  account_agent_name: string;
  auto_tranfer_date?: any;
  auto_tranfer_start_process_date: string;
  auto_tranfer_stop_process_date?: any;
  auto_tranfer_admin_id_confirm: number;
  auto_tranfer_admin_id_cancel?: any;
  auto_tranfer_return_status_code?: string;
  auto_tranfer_status: string;
  auto_tranfer_ref_code?: any;
  is_bonus: boolean;
  is_manual: boolean;
  create_date: string;
  agent_id: number;
  agentName: string;
  stateTransfer: ITranferStateResult;
}

export interface WithdrawAutoLog {
  Id: number;
  withdraw_id: number;
  admin_id?: number;
  name?: string;
  username?: string;
  status_tranfer: string;
  status_tranfer_to?: string;
  start_process_date: string;
  stop_process_date?: any;
  info: string;
  type_log_error: boolean;
  error_code?: string;
  error_message?: string;
  create_dt: string;
}

export interface WinLoseManualModel {
  game: string;
  amount: number;
  validAmount: number;
  wlTurnAmount: number;
  wlAmount: number;
  outstanding: number;
  note: string;
}
