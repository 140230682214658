<form [formGroup]="form">
  <div class="" [class.block]="!formValue.limit_setting_amb_form.lotto_amb_percent_max">
    <ng-container formGroupName="limit_setting_amb_form">
      <div class="mb-3">
        <label for="" class="form-label mb-2"> {{'SET_UP_PARTNERSHIP'|translate}} : </label>
        <div class="row">
          <div class="col-12 col-lg-4 mb-3">
            <div class="input-group">
              <div class="input-group-text">
                LOTTO
                <i class="ms-1 bi bi-exclamation-circle text-warning" *ngIf="formValue.limit_setting_amb_form.lotto_amb_percent_own >
                  formValue.limit_setting_amb_form.lotto_amb_percent_max"></i>
              </div>
              <input type="number" class="form-control text-end" formControlName="lotto_amb_percent_own" [min]="0"
                [max]="formValue.limit_setting_amb_form.lotto_amb_percent_max" step="0.5">
              <div class="input-group-text">%</div>
            </div>
          </div>
          <div class="col-12 col-lg-4 mb-3">
            <div class=""> {{'BOARD'|translate}} = {{formValue.limit_setting_amb_form.lotto_amb_percent_board}}% </div>
            <div class=""> MAX = {{formValue.limit_setting_amb_form.lotto_amb_percent_max}}% </div>
            <div class=""> {{'BALANCE'|translate}} ({{'YOUR_PARTNER'|translate}}) =
              {{ formValue.limit_setting_amb_form.lotto_amb_percent_max -
              formValue.limit_setting_amb_form.lotto_amb_percent_own }}%
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container formGroupName="limit_setting_amb_lotto_form">
      <section *ngFor="let lotto of ambLottoItems" [hidden]="lotto.value !== lottoActive"
        [ngClass]="{'block': !lotto.playNames?.length}">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="mb-2">
                <label for="" class="form-label me-3 mb-2"> {{'STATUS'|translate}} : </label>
                <div class="form-check form-check-inline">
                  <input id="sport-status-on" type="radio" class="form-check-input" [value]="true"
                    formControlName="{{lotto.formName}}_enable">
                  <label for="sport-status-off" class="form-check-label text-success">{{'ACTIVE'|translate}}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input id="sport-status-on" type="radio" class="form-check-input" [value]="false"
                    formControlName="{{lotto.formName}}_enable">
                  <label for="sport-status-off" class="form-check-label text-danger">{{'DISABLED'|translate}}</label>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 mb-2">
              <label for="" class="form-label me-2 mb-2"> {{'END_OF_BET_TIME'|translate}} : </label>
              <select class="form-select form-inline me-2 mb-2" formControlName="{{lotto.formName}}_hour">
                <option *ngFor="let item of hourItems" [value]="item.value"> {{item.name}} </option>
              </select>
              <span class="d-inline-block me-2 mb-2"> : </span>
              <select class="form-select form-inline me-2 mb-2" formControlName="{{lotto.formName}}_minute">
                <option *ngFor="let item of minItems" [value]="item.value"> {{item.name}} </option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-xl-6">
            <table class="table">
              <thead>
                <tr>
                  <th>{{'LISTS'|translate}}</th>
                  <th width="26%">{{'PAY_RATE'|translate}}</th>
                  <th width="26%">{{'COMMISSION'|translate}}%</th>
                  <th width="26%">{{'MAX_BET'|translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let pn of lotto.playNames">
                  <td class="text-start">{{pn|translate}}</td>
                  <td class="text-start">
                    <div *ngIf="!formValue.limit_setting_amb_lotto_form[lotto.formName+pn+'_payout_uplineLimit']">
                      <input type="text" class="form-control" formControlName="{{lotto.formName}}{{pn}}_payout">
                    </div>
                    <div *ngIf="formValue.limit_setting_amb_lotto_form[lotto.formName+pn+'_payout_uplineLimit']">
                      <input type="text" class="form-control" formControlName="{{lotto.formName}}{{pn}}_payout"
                        [max]="formValue.limit_setting_amb_lotto_form[lotto.formName+pn+'_payout_uplineLimit']">
                      <div class="small opacity-75"> {{'RECEIVED'|translate}} =
                        {{formValue.limit_setting_amb_lotto_form[lotto.formName+pn+'_payout_uplineLimit']}}
                      </div>
                    </div>
                  </td>
                  <td class="text-start">
                    <div *ngIf="!formValue.limit_setting_amb_lotto_form[lotto.formName+pn+'_discount_uplineLimit']">
                      <select class="form-select" formControlName="{{lotto.formName}}{{pn}}_discount">
                        <option *ngFor="let item of percentItems" [value]="item.value">{{item.name}}</option>
                      </select>
                    </div>
                    <div *ngIf="formValue.limit_setting_amb_lotto_form[lotto.formName+pn+'_discount_uplineLimit']">
                      <select class="form-select" formControlName="{{lotto.formName}}{{pn}}_discount">
                        <option *ngFor="let item of percentItems" [value]="item.value"
                          [disabled]="item.value >= formValue.limit_setting_amb_lotto_form[lotto.formName+pn+'_discount_uplineLimit']">
                          {{item.name}}
                        </option>
                      </select>
                      <div class="small opacity-75"> {{'RECEIVED'|translate}} =
                        {{formValue.limit_setting_amb_lotto_form[lotto.formName+pn+'_discount_uplineLimit']}} %
                      </div>
                    </div>
                  </td>
                  <td class="text-start">
                    <div *ngIf="!formValue.limit_setting_amb_lotto_form[lotto.formName+pn+'_max_uplineLimit']">
                      <input type="text" class="form-control" formControlName="{{lotto.formName}}{{pn}}_max">
                    </div>
                    <div *ngIf="formValue.limit_setting_amb_lotto_form[lotto.formName+pn+'_max_uplineLimit']">
                      <input type="text" class="form-control" formControlName="{{lotto.formName}}{{pn}}_max"
                        [max]="formValue.limit_setting_amb_lotto_form[lotto.formName+pn+'_max_uplineLimit']">
                      <div class="small opacity-75"> {{''|translate}}{{'RECEIVED'|translate}} =
                        {{formValue.limit_setting_amb_lotto_form[lotto.formName+pn+'_max_uplineLimit']}}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 col-xl-6">
            <table class="table">
              <thead>
                <tr>
                  <th>{{'LISTS'|translate}}</th>
                  <th width="26%">{{'PAY_RATE'|translate}}</th>
                  <th width="26%">{{'COMMISSION'|translate}}%</th>
                  <th width="26%">{{'MAX_BET'|translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let pn of lotto.playNames2">
                  <td class="text-start">{{pn|translate}}</td>
                  <td class="text-start">
                    <div *ngIf="!formValue.limit_setting_amb_lotto_form[lotto.formName+pn+'_payout_uplineLimit']">
                      <input type="text" class="form-control" formControlName="{{lotto.formName}}{{pn}}_payout">
                    </div>
                    <div *ngIf="formValue.limit_setting_amb_lotto_form[lotto.formName+pn+'_payout_uplineLimit']">
                      <input type="text" class="form-control" formControlName="{{lotto.formName}}{{pn}}_payout"
                        [max]="formValue.limit_setting_amb_lotto_form[lotto.formName+pn+'_payout_uplineLimit']">
                      <div class="small opacity-75"> {{'RECEIVED'|translate}} =
                        {{formValue.limit_setting_amb_lotto_form[lotto.formName+pn+'_payout_uplineLimit']}}
                      </div>
                    </div>
                  </td>
                  <td class="text-start">
                    <div *ngIf="!formValue.limit_setting_amb_lotto_form[lotto.formName+pn+'_discount_uplineLimit']">
                      <select class="form-select" formControlName="{{lotto.formName}}{{pn}}_discount">
                        <option *ngFor="let item of percentItems" [value]="item.value">{{item.name}}</option>
                      </select>
                    </div>
                    <div *ngIf="formValue.limit_setting_amb_lotto_form[lotto.formName+pn+'_discount_uplineLimit']">
                      <select class="form-select" formControlName="{{lotto.formName}}{{pn}}_discount">
                        <option *ngFor="let item of percentItems" [value]="item.value"
                          [disabled]="item.value >= formValue.limit_setting_amb_lotto_form[lotto.formName+pn+'_discount_uplineLimit']">
                          {{item.name}}
                        </option>
                      </select>
                      <div class="small opacity-75"> {{'RECEIVED'|translate}} =
                        {{formValue.limit_setting_amb_lotto_form[lotto.formName+pn+'_discount_uplineLimit']}} %
                      </div>
                    </div>
                  </td>
                  <td class="text-start">
                    <div *ngIf="!formValue.limit_setting_amb_lotto_form[lotto.formName+pn+'_max_uplineLimit']">
                      <input type="text" class="form-control" formControlName="{{lotto.formName}}{{pn}}_max">
                    </div>
                    <div *ngIf="formValue.limit_setting_amb_lotto_form[lotto.formName+pn+'_max_uplineLimit']">
                      <input type="text" class="form-control" formControlName="{{lotto.formName}}{{pn}}_max"
                        [max]="formValue.limit_setting_amb_lotto_form[lotto.formName+pn+'_max_uplineLimit']">
                      <div class="small opacity-75"> {{'RECEIVED'|translate}} =
                        {{formValue.limit_setting_amb_lotto_form[lotto.formName+pn+'_max_uplineLimit']}}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </ng-container>
  </div>
</form>

<!-- Thai -->
<!-- <section [hidden]="lottoActive !== 'tha'">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="mb-2">
          <label for="" class="form-label me-3 mb-2"> สถานะ : </label>
          <div class="form-check form-check-inline">
            <input id="sport-status-on" type="radio" class="form-check-input" [value]="true">
            <label for="sport-status-off" class="form-check-label text-success">เปิดใช้งาน</label>
          </div>
          <div class="form-check form-check-inline">
            <input id="sport-status-on" type="radio" class="form-check-input" [value]="false">
            <label for="sport-status-off" class="form-check-label text-danger">ปิดใช้งาน</label>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-2">
        <label for="" class="form-label me-2 mb-2"> สิ้นสุดเวลาแทง : </label>
        <select class="form-select form-inline me-2 mb-2">
          <option value="" selected>15</option>
        </select>
        <span class="d-inline-block me-2 mb-2"> : </span>
        <select class="form-select form-inline me-2 mb-2">
          <option value="" selected>15</option>
        </select>
      </div>
    </div>
  </div>

  <label for="" class="form-label mb-2"> ตั้งค่าหุ้นส่วน : </label>
  <div class="row">
    <div class="col-12 col-lg-4 mb-3">
      <div class="input-group">
        <div class="input-group-text">หวยไทย</div>
        <input type="text" class="form-control text-end">
        <div class="input-group-text">%</div>
      </div>
    </div>
    <div class="col-12 col-lg-4 mb-3">
      <div class=""> กระดาน = 15% </div>
      <div class=""> MAX = 85% </div>
      <div class=""> คงเหลือ (หุ้นส่วนของคุณ) = 0% </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-xl-6">
      <table class="table">
        <thead>
          <tr>
            <th>รายการ</th>
            <th width="26%">อัตราจ่าย</th>
            <th width="26%">ค่าคอม %</th>
            <th width="26%">แทงสูงสุด</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-start">6 Top</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">5 Top</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">4 Top</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">4 Roll</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">3 Top</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">3 Roll</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">3 Bottom</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">3 Top O/U</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">3 Top O/E</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-12 col-xl-6">
      <table class="table">
        <thead>
          <tr>
            <th>รายการ</th>
            <th width="26%">อัตราจ่าย</th>
            <th width="26%">ค่าคอม %</th>
            <th width="26%">แทงสูงสุด</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-start">2 Top</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">2 Roll</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">2 Bottom</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">2 Top O/U</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">2 Top O/E</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">2 Bottom O/U</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">2 Bottom O/E</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">1 Top</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">1 Bottom</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section> -->

<!-- Pingponn -->
<!-- <section [hidden]="lottoActive !== 'pp'">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="mb-2">
          <label for="" class="form-label me-3 mb-2"> สถานะ : </label>
          <div class="form-check form-check-inline">
            <input id="sport-status-on" type="radio" class="form-check-input" [value]="true">
            <label for="sport-status-off" class="form-check-label text-success">เปิดใช้งาน</label>
          </div>
          <div class="form-check form-check-inline">
            <input id="sport-status-on" type="radio" class="form-check-input" [value]="false">
            <label for="sport-status-off" class="form-check-label text-danger">ปิดใช้งาน</label>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-2">
        <label for="" class="form-label me-2 mb-2"> สิ้นสุดเวลาแทง : </label>
        <select class="form-select form-inline me-2 mb-2">
          <option value="" selected>15</option>
        </select>
        <span class="d-inline-block me-2 mb-2"> : </span>
        <select class="form-select form-inline me-2 mb-2">
          <option value="" selected>15</option>
        </select>
      </div>
    </div>
  </div>

  <label for="" class="form-label mb-2"> ตั้งค่าหุ้นส่วน : </label>
  <div class="row">
    <div class="col-12 col-lg-4 mb-3">
      <div class="input-group">
        <div class="input-group-text">หวยปิงปอง</div>
        <input type="text" class="form-control text-end">
        <div class="input-group-text">%</div>
      </div>
    </div>
    <div class="col-12 col-lg-4 mb-3">
      <div class=""> กระดาน = 15% </div>
      <div class=""> MAX = 85% </div>
      <div class=""> คงเหลือ (หุ้นส่วนของคุณ) = 0% </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-xl-6">
      <table class="table">
        <thead>
          <tr>
            <th>รายการ</th>
            <th width="26%">อัตราจ่าย</th>
            <th width="26%">ค่าคอม %</th>
            <th width="26%">แทงสูงสุด</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-start">6 Top</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">5 Top</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">4 Top</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">4 Roll</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">3 Top</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">3 Roll</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-12 col-xl-6">
      <table class="table">
        <thead>
          <tr>
            <th>รายการ</th>
            <th width="26%">อัตราจ่าย</th>
            <th width="26%">ค่าคอม %</th>
            <th width="26%">แทงสูงสุด</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-start">2 Top</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">2 Roll</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">2 Bottom</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">1 Top</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">1 Bottom</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section> -->

<!-- Lao -->
<!-- <section [hidden]="lottoActive !== 'lao'">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="mb-2">
          <label for="" class="form-label me-3 mb-2"> สถานะ : </label>
          <div class="form-check form-check-inline">
            <input id="sport-status-on" type="radio" class="form-check-input" [value]="true">
            <label for="sport-status-off" class="form-check-label text-success">เปิดใช้งาน</label>
          </div>
          <div class="form-check form-check-inline">
            <input id="sport-status-on" type="radio" class="form-check-input" [value]="false">
            <label for="sport-status-off" class="form-check-label text-danger">ปิดใช้งาน</label>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-2">
        <label for="" class="form-label me-2 mb-2"> สิ้นสุดเวลาแทง : </label>
        <select class="form-select form-inline me-2 mb-2">
          <option value="" selected>15</option>
        </select>
        <span class="d-inline-block me-2 mb-2"> : </span>
        <select class="form-select form-inline me-2 mb-2">
          <option value="" selected>15</option>
        </select>
      </div>
    </div>
  </div>

  <label for="" class="form-label mb-2"> ตั้งค่าหุ้นส่วน : </label>
  <div class="row">
    <div class="col-12 col-lg-4 mb-3">
      <div class="input-group">
        <div class="input-group-text">หวยปิงปอง</div>
        <input type="text" class="form-control text-end">
        <div class="input-group-text">%</div>
      </div>
    </div>
    <div class="col-12 col-lg-4 mb-3">
      <div class=""> กระดาน = 15% </div>
      <div class=""> MAX = 85% </div>
      <div class=""> คงเหลือ (หุ้นส่วนของคุณ) = 0% </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-xl-6">
      <table class="table">
        <thead>
          <tr>
            <th colspan="100%">
              แบบชุด
            </th>
          </tr>
          <tr>
            <th>รายการ</th>
            <th width="26%">อัตราจ่าย</th>
            <th width="26%">ค่าคอม %</th>
            <th width="26%">แทงสูงสุด</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-start">4 Straight</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">4 Roll</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">3 Straight</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">3 Roll</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">2 FB</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-12 col-xl-6">
      <table class="table">
        <thead>
          <tr>
            <th colspan="100%">
              แบบชุด
            </th>
          </tr>
          <tr>
            <th>รายการ</th>
            <th width="26%">อัตราจ่าย</th>
            <th width="26%">ค่าคอม %</th>
            <th width="26%">แทงสูงสุด</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-start">4 Top</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">4 Roll</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">3 Top</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">3 Roll</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">2 Top</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">2 Roll</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">2 Bottom</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">1 Top</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
          <tr>
            <td class="text-start">1 Bottom</td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 50,000 </div>
            </td>
            <td class="text-start">
              <select class="form-select">
                <option value="" selected> 20 % </option>
              </select>
              <div class="small opacity-75"> ได้รับมา = 20 % </div>
            </td>
            <td class="text-start">
              <input type="text" class="form-control">
              <div class="small opacity-75"> ได้รับมา = 1,000 </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section> -->

<!-- Stock -->
<!-- <section [hidden]="lottoActive !== 'sto'" class="block">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="mb-2">
          <label for="" class="form-label me-3 mb-2"> สถานะ : </label>
          <div class="form-check form-check-inline">
            <input id="sport-status-on" type="radio" class="form-check-input" [value]="true">
            <label for="sport-status-off" class="form-check-label text-success">เปิดใช้งาน</label>
          </div>
          <div class="form-check form-check-inline">
            <input id="sport-status-on" type="radio" class="form-check-input" [value]="false">
            <label for="sport-status-off" class="form-check-label text-danger">ปิดใช้งาน</label>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-2">
        <label for="" class="form-label me-2 mb-2"> สิ้นสุดเวลาแทง : </label>
        <select class="form-select form-inline me-2 mb-2">
          <option value="" selected>15</option>
        </select>
        <span class="d-inline-block me-2 mb-2"> : </span>
        <select class="form-select form-inline me-2 mb-2">
          <option value="" selected>15</option>
        </select>
      </div>
    </div>
  </div>

  <label for="" class="form-label mb-2"> ตั้งค่าหุ้นส่วน : </label>
  <div class="row">
    <div class="col-12 col-lg-4 mb-3">
      <div class="input-group">
        <div class="input-group-text">หวยปิงปอง</div>
        <input type="text" class="form-control text-end">
        <div class="input-group-text">%</div>
      </div>
    </div>
    <div class="col-12 col-lg-4 mb-3">
      <div class=""> กระดาน = 15% </div>
      <div class=""> MAX = 85% </div>
      <div class=""> คงเหลือ (หุ้นส่วนของคุณ) = 0% </div>
    </div>
  </div>
</section> -->
