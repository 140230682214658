import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@ngneat/reactive-forms';
import { Subscription } from 'rxjs';
import { generateArray } from 'src/app/utils/functions/generate-array';
import { AgentForm } from 'src/app/utils/interfaces/create-agent-form.interface';
import { OptionItem } from 'src/app/utils/interfaces/option-item';

@Component({
  selector: 'app-setting-amb-step',
  templateUrl: './setting-amb-step.component.html',
  styleUrls: ['../../setting-agent.scss'],
})
export class SettingAmbStepComponent implements OnInit, OnChanges {
  @Input() form: FormGroup<AgentForm>;
  maxMatchPerBet = 0;
  minMatchPerBetBet = 0;

  maxMatchPerBetItems: OptionItem[] = [];
  minMatchPerBetBetItems: OptionItem[] = [];

  Subscription = new Subscription();
  formValue: AgentForm;
  constructor() {
  }

  ngOnInit(): void {
    if (this.form) {
      const limit_amb_form$ = this.form.get('limit_setting_amb_form').valueChanges.subscribe(res => {
        this.formValue = this.form.getRawValue();
      });
      this.Subscription.add(limit_amb_form$);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.form) {
      this.formValue = this.form.getRawValue();
      this.generateOption();

      const stepMaxPerBet$ = this.form.get('limit_setting_amb_form').get('step_maxMatchPerBet').valueChanges.subscribe(() => {
        this.generateOption();
      });
      const stepMinPerBet$ = this.form.get('limit_setting_amb_form').get('step_minMatchPerBet').valueChanges.subscribe(() => {
        this.generateOption();
      });
      this.Subscription.add(stepMaxPerBet$);
      this.Subscription.add(stepMinPerBet$);
    }
  }

  ngOnDestroy(): void {
    this.Subscription.unsubscribe();
  }

  generateOption() {
    this.maxMatchPerBet = this.formValue.limit_setting_amb_form.step_maxMatchPerBet;
    this.minMatchPerBetBet = this.formValue.limit_setting_amb_form.step_minMatchPerBet;
    if (this.formValue.limit_setting_amb_form.step_maxMatchPerBet_uplineLimit) {
      this.maxMatchPerBet = this.formValue.limit_setting_amb_form.step_maxMatchPerBet_uplineLimit;
    }

    this.maxMatchPerBetItems = generateArray(this.minMatchPerBetBet, 1, this.maxMatchPerBet).map(val => ({ name: val + '', value: val }));
    this.minMatchPerBetBetItems = generateArray(0, 1, this.minMatchPerBetBet).map(val => ({ name: val + '', value: val }));
  }

}
