import { AppCore } from 'src/app/utils/classes/app-core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent extends AppCore implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {
  }

}
