import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { debounceTime, finalize } from 'rxjs/operators';
import { Member, MemberService } from 'src/app/apis/member.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { alertSuccess, alertText, alertWarning } from 'src/app/utils/functions/alert';

@Component({
  selector: 'app-reset-password-form-offcanvas',
  templateUrl: './reset-password-form-offcanvas.component.html',
  styleUrls: ['./reset-password-form-offcanvas.component.scss']
})
export class ResetPasswordFormOffcanvasComponent extends AppCore implements OnInit, OnChanges, OnDestroy {
  @Input() member: Member;

  form = this.svMember.createMemberResetPassword;
  saving = false;

  constructor(
    private svMember: MemberService,
  ) {
    super();
  }

  ngOnInit(): void {
    const resetPasswordEl = document.getElementById('resetPassowrdOffcanvas');
    resetPasswordEl.addEventListener('show.bs.offcanvas', () => {
      this.form = this.svMember.createMemberResetPassword;
      if (this.member) {
        this.form.patchValue({
          member_id: this.member.id
        });
      }

      const confPass$ = this.form.get('confirm_password').valueChanges.pipe(
        debounceTime(300)
      ).subscribe(val => {
        const formVal = this.form.value;
        if (formVal.new_password && val && formVal.new_password !== val) {
          this.form.get('confirm_password').setErrors({ notMacth: true });
        } else {
          this.form.get('confirm_password').setErrors(null);
        }
      });
      this.Subscriptions.add(confPass$);
    });
  }
  ngOnChanges(): void {
    if (this.member) {
      this.form.patchValue({
        member_id: this.member.id
      });
    }
  }

  onSubmit() {
    this.form.markAllAsDirty();
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      alertWarning({ message: alertText.formInvalid });
      return;
    }

    if (this.form.value.new_password !== this.form.value.confirm_password) {
      alertWarning({ message: `ยืนยันรหัสผ่านไม่ตรงกัน!` });
      return;
    }

    this.saving = true;
    this.svMember.postResetPassword(this.form.value).pipe(
      finalize(() => this.saving = false)
    ).subscribe(res => {
      alertSuccess({ message: `เปลี่ยนรหัสผ่านใหม่สำเร็จแล้ว` });
      this.clickBtnClose();
    });
  }

  clickBtnClose() {
    super.clickBtnClose('reset-pass-close');
  }
}
