<div class="row mb-2">
  <div class="col-auto mb-2"> {{'STATUS'|translate}} : </div>
  <div class="col-auto mb-2">
    <div class="form-check form-check-inline">
      <input id="status-check-on-{{inputId}}" type="radio" class="form-check-input" name="status-{{inputId}}"
        [value]="true" [(ngModel)]="setting.group_init_member.updateLineStatus.sportBookEnable" [disabled]="readOnly">
      <label for="status-check-on-{{inputId}}" class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
    </div>
    <div class="form-check form-check-inline">
      <input id="status-check-off-{{inputId}}" type="radio" class="form-check-input" name="status-{{inputId}}"
        [value]="false" [(ngModel)]="setting.group_init_member.updateLineStatus.sportBookEnable" [disabled]="readOnly">
      <label for="status-check-off-{{inputId}}" class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
    </div>
  </div>
</div>

<div class="mb-3">
  <div class="row">
    <div class="col-12 col-md-6 mb-3" *ngIf="isAdmin">
      <div class="mb-3">
        <label for="" class="form-label"> Commission Setting </label>
        <hr class="my-2">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="text-end">{{'TYPE'|translate}}Type :</td>
              <td>
                <select class="form-select d-inline-block w-auto" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.commissionSetting.sportsBook.typeHdpOuOe"
                  (change)="selectedTypeComm()">
                  <option *ngFor="let item of typeComOptions" [ngValue]="item.value"> {{item.label}} </option>
                </select>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="text-end">HDP/OU/OE {{typeNo}}:</td>
              <td>
                <select class="form-select d-inline-block w-auto" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.commissionSetting.sportsBook.hdpOuOe">
                  <option *ngFor="let item of hdpOptions" [ngValue]="item.value"> {{item.label}} </option>
                </select>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="text-end">1X2/Double Chance :</td>
              <td>
                <select class="form-select d-inline-block w-auto" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.commissionSetting.sportsBook.oneTwoDoubleChance">
                  <option *ngFor="let item of chanceOptions" [ngValue]="item.value"> {{item.label}} </option>
                </select>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="text-end">Others 1 :</td>
              <td>
                <select class="form-select d-inline-block w-auto" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.commissionSetting.sportsBook.others">
                  <option *ngFor="let item of otherOptions" [ngValue]="item.value"> {{item.label}} </option>
                </select>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
    <div class="col-12 col-md-6 mb-3">
      <div class="mb-3">
        <label for="">{{'SET_LIMIT'|translate}}</label>
        <div class="border-bottom my-2"></div>
        <table class="table table-borderless">
          <thead>
            <tr>
              <th></th>
              <th>{{'MAXIMUM_PER_LIST'|translate}}</th>
              <th>{{'MAXIMUM_PER_GAMES'|translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-end">{{'HANDICAP_OVER_UNDER_EVEN'|translate}}</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.sportsBook.hdpOuOe.maxPerBet">
                <div>Max =
                  {{setting.group_init_member.maxSetting.limitSetting.sportsBook.hdpOuOe.maxPerBet | number}}
                </div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.sportsBook.hdpOuOe.maxPerMatch">
                <div>Max =
                  {{setting.group_init_member.maxSetting.limitSetting.sportsBook.hdpOuOe.maxPerMatch | number}}
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-end">1X2/{{'TWO_CHANCE'|translate}}</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.sportsBook.oneTwoDoubleChance.maxPerBet">
                <div>Max =
                  {{setting.group_init_member.maxSetting.limitSetting.sportsBook.oneTwoDoubleChance.maxPerBet | number}}
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="text-end">{{'Other'|translate}}</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.sportsBook.others.maxPerBet">
                <div>Max =
                  {{setting.group_init_member.maxSetting.limitSetting.sportsBook.others.maxPerBet | number}}
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="text-end">{{'PREDICT_THE_WINNER'|translate}}</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.sportsBook.outright.maxPerMatch">
                <div>Max =
                  {{setting.group_init_member.maxSetting.limitSetting.sportsBook.outright.maxPerMatch | number}}
                </div>
              </td>
              <td>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
