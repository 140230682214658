import { Directive, ElementRef, HostListener, Input, OnChanges, OnInit } from '@angular/core';

@Directive({
  selector: '[appLoading]'
})
export class LoadingDirective implements OnInit, OnChanges {
  @Input() appLoading = false;
  @Input() loadingHtml = `<div class="spinner-border spinner-border-sm"></div>`;

  defaultHtml: string;
  defaultWidth: number;

  constructor(
    private ngElement: ElementRef<HTMLButtonElement>,
  ) { }

  ngOnInit() {
    this.defaultHtml = this.ngElement.nativeElement.innerHTML;
    this.defaultWidth = this.ngElement.nativeElement.offsetWidth;
  }

  ngOnChanges() {
    if (this.defaultHtml) {
      if (this.appLoading) {
        this.ngElement.nativeElement.style.width = `${this.defaultWidth}px`;
        this.ngElement.nativeElement.style.pointerEvents = 'none';
        this.ngElement.nativeElement.setAttribute('disabled', 'true');
        this.ngElement.nativeElement.innerHTML = this.loadingHtml;
      } else {
        this.ngElement.nativeElement.style.width = `auto`;
        this.ngElement.nativeElement.style.pointerEvents = '';
        this.ngElement.nativeElement.removeAttribute('disabled');
        this.ngElement.nativeElement.innerHTML = this.defaultHtml;
      }
    }
  }

  @HostListener('click') onClick() {
    this.defaultHtml = this.ngElement.nativeElement.innerHTML;
    this.defaultWidth = this.ngElement.nativeElement.offsetWidth;
  }
}
