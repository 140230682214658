import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { Modal } from 'bootstrap';
import { DepositBonusForPromotionModel, MemberCreditService } from 'src/app/apis/member-credit.service';
import { Member } from 'src/app/apis/member.service';
import { Promotion } from 'src/app/apis/promotion.service';
import { Transaction } from 'src/app/apis/transaction.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { alertSuccess, alertText, alertWarning } from 'src/app/utils/functions/alert';
import { Pagination } from 'src/app/utils/interfaces/pagination';

@Component({
  selector: 'app-give-bonus-from-deposit-modal',
  templateUrl: './give-bonus-from-deposit-modal.component.html',
  styleUrls: ['./give-bonus-from-deposit-modal.component.scss']
})
export class GiveBonusFromDepositModalComponent extends AppCore implements OnInit, OnChanges {
  promotionItems: Pagination<Promotion[]>;
  selectPromotion: Promotion;

  form = this.createForm();

  @Input() transaction: Transaction;
  @Input() member: Member;
  @Input() modal: Modal;
  constructor(
    private svMemberCredit: MemberCreditService,
    private ngFb: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    const transactionEl = document.getElementById(`giveBonusModal`);
    transactionEl.addEventListener('hidden.bs.modal', () => {
      this.form = this.createForm();
      this.selectPromotion = null;
    });
  }

  ngOnChanges() {
    if (this.transaction) {
      this.form.patchValue({
        deposit_id: this.transaction.Id,
      });
    }
  }

  createForm(): FormGroup<DepositBonusForPromotionModel> {
    return this.ngFb.group({
      deposit_id: [null],
      promotion_id: [null],
    });
  }

  onSubmit() {
    this.form.markAllAsDirty();
    this.form.markAllAsTouched();

    if (!this.form.value.promotion_id) {
      alertWarning({ message: `เลือกโปรโมชันก่อน` });
      return;
    }

    if (!this.form.valid) {
      alertWarning({ message: alertText.formInvalid });
      return;
    }

    this.svMemberCredit.postDepositBonusForPromotion(this.form.value).subscribe(res => {
      alertSuccess({ message: `เพิ่มโบนัสสำเร็จแล้ว` });
      this.modal.hide();
    });
  }

  selectPromotionChange(event: Promotion) {
    this.selectPromotion = event;
  }
}
