<!-- moblie -->
<div class="moblie position-btn-open">
  <button class="btn btn-primary d-moblie rounded-0 rounded-bottom " type="button" data-bs-toggle="offcanvas"
    data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
    <ng-content select="[btn-title]"></ng-content>
  </button>
</div>
<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
  <div class="offcanvas-header">
    <h5 id="offcanvasRightLabel">
      <ng-content select="[title]"></ng-content>
    </h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <ng-container *ngTemplateOutlet="bodyContent"></ng-container>
  </div>
</div>

<!-- desktop -->
<div class="d-desktop">
  <ng-container *ngTemplateOutlet="bodyContent"></ng-container>
</div>

<ng-template #bodyContent>
  <ng-content></ng-content>
</ng-template>
