<div class="offcanvas offcanvas-end" id="revertCreditRoundoffcanvas">
  <div class="offcanvas-header">
    <h5 id="offcanvasRightLabel">
      <span *ngIf="!updateItem">{{'CREATE'|translate}}</span>
      <span *ngIf="updateItem">{{'EDIT'|translate}}</span>
      <!-- {{updateItem ?'แก้ไข': 'สร้าง'}} -->
      {{'TIME_TO_REQUEST_REVERT_CREDIT'|translate}}
    </h5>
    <button id="closeOffcanvas" type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
      aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <form [formGroup]="form" (submit)="onSubmit()">
      <div class="mb-3">
        <label for="" class="form-label">{{'IMAGE'|translate}}</label>
        <app-input-file-form formControlName="image" width="200px" height="200px"></app-input-file-form>
      </div>
      <div class="mb-3">
        <label for="" class="form-label">{{'CHOOSE_START_TIME'|translate}}</label>
        <input type="date" class="form-control" formControlName="start_revert">
      </div>
      <div class="mb-3">
        <label for="" class="form-label">{{'CHOOSE_END_TIME'|translate}}</label>
        <input type="date" class="form-control" formControlName="end_revert">
      </div>
      <div class="mb-3">
        <label for=""> {{'TIME_TO_REQUEST_REVERT_CREDIT'|translate}} {{totalDate || 0}} {{'DAY'|translate}} </label>
      </div>
      <!-- <div class="mb-2">
        <div class="form-check form-check-inline">
          <input id="winlose" type="checkbox" class="form-check-input" formControlName="useSameDate">
          <label for="winlose" class="form-check-label pointer">ใช้ช่วงเวลาในการคำนวน W/L
            เดียวกับช่วงเวลาเริ่ม-สิ้นสุด
          </label>
        </div>
      </div> -->
      <!-- <ng-container *ngIf="!form.get('useSameDate').value"> -->
      <div class="mb-3">
        <label for="" class="form-label">{{'TIME_TO_CALCULATE'|translate}} W/L {{'START'|translate}}</label>
        <input type="date" class="form-control" formControlName="winlose_start" [min]="prev2WeekDateFormat">
      </div>
      <div class="mb-3">
        <label for="" class="form-label">{{'TIME_TO_CALCULATE'|translate}} W/L {{'FINISHED'|translate}}</label>
        <input type="date" class="form-control" formControlName="winlose_end" [min]="prev2WeekDateFormat">
      </div>
      <!-- </ng-container> -->
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>W/L {{'MINIMUM'|translate}}</th>
            <th>{{'RETURN_CREDIT_PERCENT'|translate}}</th>
            <th></th>
          </tr>
        </thead>
        <tbody formArrayName="revert_detail">
          <tr *ngFor="let group of getFormArray(form, 'revert_detail').controls; let i = index" [formGroupName]="i">
            <td>
              <input type="number" class="form-control text-end" formControlName="min_winlose">
            </td>
            <td>
              <div class="input-group">
                <input type="number" class="form-control text-end" formControlName="percent_revert">
                <div class="input-group-text"> % </div>
              </div>
            </td>
            <td>
              <a class="btn link-danger" (click)="removeFormArrayAt(i)">
                <i class="fas fa-times"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="mb-3 text-end">
        <button type="button" class="btn btn-sm btn-success" (click)="addFormArray()">
          <i class="fas fa-plus"></i> {{'ADD_ITEM'|translate}}
        </button>
      </div>
      <div class="mb-3">
        <label for="" class="form-label">{{'MAXIMUM_RECEIVED_REVERT_CREDIT'|translate}}</label>
        <input type="number" class="form-control" formControlName="max_credit_revert">
      </div>
      <div class="mb-3">
        <label for="" class="form-label">{{'TURN_OVER_AMOUNT'|translate}}</label>
        <input type="number" class="form-control" formControlName="turn_over">
      </div>
      <div class="row mb-2">
        <div class="col-4 mb-2">
          <div class="form-check form-check-line">
            <input id="game-sport" type="checkbox" class="form-check-input" formControlName="turn_over_sport">
            <label for="game-sport" class="form-check-label">{{'SPORT'|translate}}</label>
          </div>
        </div>
        <div class="col-4 mb-2">
          <div class="form-check form-check-line">
            <input id="game-casino" type="checkbox" class="form-check-input" formControlName="turn_over_casino">
            <label for="game-casino" class="form-check-label">{{'SS_CASINO'|translate}}</label>
          </div>
        </div>
        <div class="col-4 mb-2">
          <div class="form-check form-check-line">
            <input id="game-poker" type="checkbox" class="form-check-input" formControlName="turn_over_poker">
            <label for="game-poker" class="form-check-label">{{'SS_POKER'|translate}}</label>
          </div>
        </div>
        <div class="col-4 mb-2">
          <div class="form-check form-check-line">
            <input id="game-slot" type="checkbox" class="form-check-input" formControlName="turn_over_slot">
            <label for="game-slot" class="form-check-label">{{'SS_GAME'|translate}}</label>
          </div>
        </div>
        <div class="col-4 mb-2">
          <div class="form-check form-check-line">
            <input id="game-keno" type="checkbox" class="form-check-input" formControlName="turn_over_keno">
            <label for="game-keno" class="form-check-label">Keno</label>
          </div>
        </div>
        <div class="col-4 mb-2">
          <div class="form-check form-check-line">
            <input id="game-lotto" type="checkbox" class="form-check-input" formControlName="turn_over_lotto">
            <label for="game-lotto" class="form-check-label">{{'SS_LOTTO'|translate}}</label>
          </div>
        </div>
        <div class="col-4 mb-2">
          <div class="form-check form-check-line">
            <input id="game-trading" type="checkbox" class="form-check-input" formControlName="turn_over_trading">
            <label for="game-trading" class="form-check-label">Trading</label>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div class="form-check form-switch form-check-line">
          <input id="auto-round" type="checkbox" class="form-check-input" formControlName="auto_round">
          <label for="auto-round" class="form-check-label">{{'ACTIVE_DISABLE_AUTO_CREATE_ROUND'|translate}}</label>
        </div>
      </div>
      <div class="mb-3 text-end">
        <button type="submit" class="btn btn-primary btn-submit" [appLoading]="saving">
          <span *ngIf="updateItem">{{'EDIT_DATA'|translate}}</span>
          <span *ngIf="!updateItem">{{'CREATE_DATA'|translate}}</span>
          <!-- {{updateItem ?'แก้ไข': 'สร้าง'}}ข้อมูล -->
        </button>
      </div>
    </form>
  </div>
</div>
