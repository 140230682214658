import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '@ngneat/reactive-forms';
import { fileToBase64, validateFiles } from 'src/app/utils/functions/base64';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-input-file-form',
  templateUrl: './input-file-form.component.html',
  styleUrls: ['./input-file-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFileFormComponent),
      multi: true
    }
  ]
})
export class InputFileFormComponent implements ControlValueAccessor {
  srcImage = '';
  imgHost = environment.image;
  host: string;
  fileType: 'file' | 'url' = 'file';

  @Input() width = '100px';
  @Input() height = '100px';
  constructor() { }

  writeValue(value: string): void {
    this.srcImage = value || '';

    if (
      this.srcImage.startsWith('data:image/') ||
      this.srcImage.startsWith('http://') ||
      this.srcImage.startsWith('https://')
    ) {
      this.host = '';
    } else {
      this.host = 'host';
    }
  }
  onChange = (value: any) => { };
  onTouched = () => { };
  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  async fileChange(event: Event) {
    const inputEl = event.target as HTMLInputElement;

    const files = inputEl.files;
    const validFiles = validateFiles(inputEl.files);
    if (!validFiles) { return; }

    const base64 = await fileToBase64(files[0]);
    this.srcImage = base64;
    this.host = '';
    this.onChange(this.srcImage);

    this.fileType = 'file';
    inputEl.value = '';
  }
}
