import { alertWarning } from './alert';


export function fileToBase64(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => { resolve(reader.result as string); };
    reader.onerror = (error) => { reject(error); };
  });
}

export function validateFiles(files: FileList, allowTypes = ['jpg', 'jpeg', 'png', 'gif'], maxSizeMB = 2) {
  let valid = true;
  for (let i = 0; i < files.length; i++) {
    const file = files.item(i);
    const fileType = file.type.split('/');
    if (!allowTypes.some(type => type === fileType[1])) {
      alertWarning({ message: `กรุณาเลือกไฟล์ ( ${allowTypes.join(', ')} ) เท่านั้น` });
      valid = false; break;
    }
    const fileSizeMB = file.size / (1024 * 1024);
    if (maxSizeMB < fileSizeMB) {
      alertWarning({ message: `ขนาดไฟล์ต้องไม่เกิน ${maxSizeMB} MB` });
      valid = false; break;
    }
  }
  return valid;
}

export function validateImageType(file: File, types = ['jpg', 'jpeg', 'png']) {
  return types.some(s => s === file.type.split('/')[1]);
}

export async function validateImageDimensions(
  file: File,
  dimensions: { width?: number, height?: number },
  comparison: 'greater' | 'less' | 'equal' | 'greater_or_equal' | 'less_or_equal') {
  if (!dimensions.width && !dimensions.height) {
    throw new Error('width or height must be value.');
  }
  if (!comparison) {
    throw new Error('comparison cannot be null.');
  }

  const { width, height } = await imageDimensions(file);
  return (comparison === 'equal' && (height === dimensions.height || width === dimensions.width))
    || (comparison === 'greater' && (height > dimensions.height || width > dimensions.width))
    || (comparison === 'less' && (height < dimensions.height || width < dimensions.width))
    || (comparison === 'greater_or_equal' && (height >= dimensions.height || width >= dimensions.width))
    || (comparison === 'less_or_equal' && (height <= dimensions.height || width <= dimensions.width))
    || false;
}

export function imageDimensions(file: File): Promise<{ width: number, height: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      const { naturalWidth: width, naturalHeight: height } = img;
      resolve({ width, height });
    };

    img.onerror = () => {
      reject('There was some problem with the image.');
    };

    img.src = URL.createObjectURL(file);
  });
}
