<form *ngIf="form" [formGroup]="form">
  <ng-container formGroupName="limit_setting_amb_form">
    <div class="" [class.block]="!form?.getRawValue().limit_setting_amb_form.other_es_percent_max">
      <div class="mb-2">
        <label for="" class="form-label me-3 mb-2"> {{'STATUS'|translate}} : </label>
        <div class="form-check form-check-inline">
          <input id="es-status-on" type="radio" class="form-check-input" [value]="true"
            formControlName="other_es_enable">
          <label for="es-status-off" class="form-check-label text-success">{{'ACTIVE'|translate}}</label>
        </div>
        <div class="form-check form-check-inline">
          <input id="es-status-on" type="radio" class="form-check-input" [value]="false"
            formControlName="other_es_enable">
          <label for="es-status-off" class="form-check-label text-danger">{{'DISABLED'|translate}}</label>
        </div>
      </div>

      <label for="" class="form-label mb-2"> {{'SET_UP_PARTNERSHIP'|translate}} : </label>
      <div class="row">
        <div class="col-12 col-lg-4 mb-3">
          <div class="input-group">
            <div class="input-group-text">
              ESPORT
              <i class="ms-1 bi bi-exclamation-circle text-warning" *ngIf="form?.getRawValue().limit_setting_amb_form.other_es_percent_own >
                form?.getRawValue().limit_setting_amb_form.other_es_percent_max"></i>
            </div>
            <input type="number" class="form-control text-end" formControlName="other_es_percent_own" [min]="0"
              [max]="form?.getRawValue().limit_setting_amb_form.other_es_percent_max" step="0.5">
            <div class="input-group-text">%</div>
          </div>
        </div>
        <div class="col-12 col-lg-4 mb-3">
          <div class=""> {{'BOARD'|translate}} = {{form?.getRawValue().limit_setting_amb_form.other_es_percent_board}}%
          </div>
          <div class=""> MAX = {{form?.getRawValue().limit_setting_amb_form.other_es_percent_max}}% </div>
          <div class=""> {{'BALANCE'|translate}} ({{'YOUR_PARTNER'|translate}}) =
            {{ form?.getRawValue().limit_setting_amb_form.other_es_percent_max -
            form?.getRawValue().limit_setting_amb_form.other_es_percent_own }}%
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</form>
