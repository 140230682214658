import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { TransactionService } from 'src/app/apis/transaction.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { alertSuccess, alertText, alertWarning } from 'src/app/utils/functions/alert';

@Component({
  selector: 'app-member-win-lose-manual-offcanvas',
  templateUrl: './member-win-lose-manual-offcanvas.component.html',
  styleUrls: ['./member-win-lose-manual-offcanvas.component.scss']
})
export class MemberWinLoseManualOffcanvasComponent extends AppCore implements OnInit, OnChanges {
  form = this.svTransaction.createFormWinLoseManual;
  saving = false;

  @Input() transactionId: number;
  constructor(
    private svTransaction: TransactionService,
  ) {
    super();
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
  }

  onSubmit() {
    this.form.markAllAsDirty();
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      alertWarning({ message: `กรอกข้อมูลไม่ถูกต้อง!` });
      return;
    }

    this.saving = true;
    this.svTransaction.postWinLoseManual(this.transactionId, this.form.value).pipe(
      finalize(() => this.saving = false)
    ).subscribe(_ => {
      alertSuccess({ message: alertText.saveSuccess });
      this.clickBtnClose();
    });
  }

  clickBtnClose() {
    super.clickBtnClose('btn-close-win-lose-manual');
  }
}
