import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Member, MemberService } from 'src/app/apis/member.service';
import { AMBOptions, AMBSettingGame, SettingGameMembersService } from 'src/app/apis/setting-game-members.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { randomTxt } from 'src/app/utils/functions/random-txt';

@Component({
  selector: 'app-setting-sport',
  templateUrl: './setting-sport.component.html',
  styleUrls: ['../setting-games.scss'],
})
export class SettingSportComponent extends AppCore implements OnInit, OnChanges {
  @Input() setting: AMBSettingGame;
  @Input() readOnly = false;

  sportTodayOptions: AMBOptions[] = [];
  sportLiveOptions: AMBOptions[] = [];

  typeComOptions: AMBOptions[] = [];
  typeNo = 0;
  hdpOptions: AMBOptions[] = [];
  chanceOptions: AMBOptions[] = [];
  otherOptions: AMBOptions[] = [];

  inputId = `sport-${randomTxt(5)}`;
  constructor(
    public svSettingGameMember: SettingGameMembersService,
  ) {
    super();

    this.typeComOptions = this.svSettingGameMember.ambTypeOptions;
    this.chanceOptions = this.svSettingGameMember.genOptions(0.05, 0.4, 0);
    this.otherOptions = this.svSettingGameMember.genOptions(0.05, 1, 0);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.setting) {
      const sportsBook = this.setting.group_init_member.maxSetting.shareSetting.sportsBook;
      this.sportTodayOptions = this.svSettingGameMember.genOptions(0.5, sportsBook.maxToday, sportsBook.minToday);
      this.sportLiveOptions = this.svSettingGameMember.genOptions(0.5, sportsBook.maxLive, sportsBook.minLive);
      this.selectedTypeComm();
    }
  }

  selectedTypeComm() {
    const comFin = this.svSettingGameMember.ambTypeOptions.find(c =>
      c.value === this.setting.member_info.commissionSetting.sportsBook.typeHdpOuOe);

    if (!comFin) { return; }
    this.typeNo = comFin?.desc || 0;
    this.hdpOptions = this.svSettingGameMember.genOptions(0.05, comFin.gMax);
  }
}
