<form [formGroup]="form" (submit)="onSubmit()">
  <div class="modal " id="withdrawChangeStatusModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            เปลี่ยนสถานะเป็น{{status === 'waiting' ? 'รอตรวจสอบ': 'ยืนยัน'}}
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body" [perfectScrollbar]="{}">
          <div class="mb-3">
            <label for="" class="form-label text-secondary">ยอดถอน</label>
            <input type="number" class="form-control" formControlName="amount">
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal"> ยกเลิก </button>
          <button type="submit" class="btn btn-primary">
            บันทึก
          </button>
        </div>
      </div>
    </div>
  </div>

</form>
