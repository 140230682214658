import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { finalize } from 'rxjs/operators';
import { ReportCommissionGame, ReportsService } from 'src/app/apis/reports.service';

@Component({
  selector: 'app-commission-games-offcanvas',
  templateUrl: './commission-games-offcanvas.component.html',
})
export class CommissionGamesOffcanvasComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;
  @Input() agent_id: number;

  formGames = this.createForm();
  commissionGamesItems: ReportCommissionGame[];
  loading = false;
  constructor(
    private svReports: ReportsService,
    private ngFb: FormBuilder,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.form && this.agent_id) {
      this.formGames.patchValue({ ...this.form.value, agent_id: this.agent_id });
      this.getReportCommissionGames();
    }
  }

  createForm() {
    return this.ngFb.group({
      from: [''],
      to: [''],
      agent_id: [''],
    });
  }

  getReportCommissionGames() {
    this.loading = true;
    this.svReports.getCommissionGames(this.formGames.value).pipe(
      finalize(() => this.loading = false)
    ).subscribe(res => {
      this.commissionGamesItems = res;
    });
  }

}
