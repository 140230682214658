<div class="offcanvas offcanvas-end" tabindex="-1" id="depositStatementOffcanvas">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title">{{'CREATE'|translate}} Statement</h5>
    <button id="btn-close" type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
  </div>
  <div class="offcanvas-body">
    <form [formGroup]="form" (submit)="onSubmit()">
      <div class="mb-3">
        <label for="" class="form-label"> {{'TRANSFER_DATE'|translate}} </label>
        <input type="datetime-local" class="form-control" formControlName="statement_datetime">
      </div>
      <div class="mb-3">
        <label for="" class="form-label"> {{'CHOOSE_BANK_ACCOUNT'|translate}} </label>
        <!-- *ngIf="showSelect" -->
        <app-select-account-bank-form formControlName="account_bank_agent_id" AccountBankType="deposit"
          (bankChange)="bankDepositChange($event)">
        </app-select-account-bank-form>
      </div>
      <div class="mb-3">
        <label for="" class="form-label"> {{'BALANCE'|translate}} </label>
        <input type="number" class="form-control" formControlName="amount">
      </div>
      <div class="mb-3">
        <label for="" class="form-label"> {{'DETAIL'|translate}} </label>
        <textarea rows="3" class="form-control" formControlName="info"></textarea>
      </div>
      <hr>
      <div class="mb-3 text-end">
        <button type="submit" class="btn btn-primary btn-submit" [appLoading]="saving"> {{'CREATE'|translate}} Statement </button>
      </div>
    </form>

  </div>
</div>
