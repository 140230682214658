import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '@ngneat/reactive-forms';
import { finalize } from 'rxjs/operators';
import { AccountBank, BankService } from 'src/app/apis/bank.service';
import { AppCore } from 'src/app/utils/classes/app-core';

@Component({
  selector: 'app-select-account-bank-form',
  templateUrl: './select-account-bank-form.component.html',
  styles: [`
    :host {
      display: block;
    }
  `],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectAccountBankFormComponent),
      multi: true
    }
  ]
})
export class SelectAccountBankFormComponent extends AppCore implements ControlValueAccessor {
  accountBankItems: AccountBank[] = [];
  loading = false;

  bankId: number;
  @Input() AccountBankType: 'withdraw' | 'deposit' | 'both' = 'both';
  @Output() bankChange = new EventEmitter<AccountBank>();
  constructor(
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    private svBank: BankService,
  ) {
    super();
  }

  async agentIdChange() {
    this.loading = true;
    this.accountBankItems = [];
    if (this.AccountBankType === 'withdraw') {
      const accBankWithdraw = await this.getAccountBankWithdraw();
      this.accountBankItems = Object.assign([], accBankWithdraw);
    } else if (this.AccountBankType === 'deposit') {
      const accBankDeposit = await this.getAccountBankDeposit();
      this.accountBankItems = Object.assign([], accBankDeposit);
    } else {
      const accBankAll = await this.getAccountBankAll();
      this.accountBankItems = Object.assign([], accBankAll);
    }

    // if (this.accountBankItems.length) {
    //   this.bankId = this.accountBankItems[0].Id;
    //   this.onChange(this.bankId);
    // }
  }

  writeValue(value: any): void {
    this.bankId = value;
    this._renderer.setProperty(this._elementRef.nativeElement, 'value', value);
  }
  onChange?: (value: any) => void;
  onTouched?: () => void;
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  trackAccBankId(index: number, item: AccountBank) {
    return item.Id;
  }

  onSelectBank(item: AccountBank) {
    this.onChange(item.Id);
    this.bankChange.emit(item);
  }

  getAccountBankWithdraw() {
    return this.svBank.getBankWithdraw(this.currentAgentId).pipe(
      finalize(() => this.loading = false)
    ).toPromise();
  }
  getAccountBankDeposit() {
    return this.svBank.getBankDeposit(this.currentAgentId).pipe(
      finalize(() => this.loading = false)
    ).toPromise();
  }
  getAccountBankAll() {
    return this.svBank.getBankAll(this.currentAgentId).pipe(
      finalize(() => this.loading = false)
    ).toPromise();
  }
}
