<form [formGroup]="form">
  <ng-container formGroupName="limit_setting_amb_form">
    <div class="contaienr-fluid" [class.block]="!form?.getRawValue().limit_setting_amb_form.casino_percent_max">
      <div class="row">
        <div class="col-12 col mb-3">
          <label for="" class="form-label mb-2"> {{'SET_UP_PARTNERSHIP'|translate}} : </label>
          <div class="row">
            <div class="col-12 col-lg-4 mb-3">
              <div class="input-group">
                <div class="input-group-text">
                  CASINO
                  <i class="ms-1 bi bi-exclamation-circle text-warning" *ngIf="form?.getRawValue().limit_setting_amb_form.casino_percent_own >
                    form?.getRawValue().limit_setting_amb_form.casino_percent_max"></i>
                </div>
                <input type="number" class="form-control text-end" formControlName="casino_percent_own" [min]="0"
                  [max]="form?.getRawValue().limit_setting_amb_form.casino_percent_max" step="0.5">
                <div class="input-group-text">%</div>
              </div>
            </div>
            <div class="col-12 col-lg-4 mb-3">
              <div class=""> {{'BOARD'|translate}} =
                {{form?.getRawValue().limit_setting_amb_form.casino_percent_board}}% </div>
              <div class=""> MAX = {{form?.getRawValue().limit_setting_amb_form.casino_percent_max}}% </div>
              <div class=""> {{'BALANCE'|translate}} ({{'YOUR_PARTNER'|translate}}) =
                {{ form?.getRawValue().limit_setting_amb_form.casino_percent_max -
                form?.getRawValue().limit_setting_amb_form.casino_percent_own }}%
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mb-3" *ngFor="let data of casinoItems; let i = index">
          <div class="card" [class.block]="!data.plays?.length">
            <div class="card-header">
              {{data.name | translate}}
            </div>
            <div class="card-body">
              <div class="table table-borderless mb-0">
                <tbody>
                  <tr>
                    <td class="text-end"> {{'STATUS'|translate}} : </td>
                    <td class="text-start">
                      <div class="form-check form-check-inline">
                        <input id="{{data.name}}-status-on" type="radio" class="form-check-input" [value]="true"
                          formControlName="casino_{{data.name}}_enable">
                        <label for="{{data.name}}-status-off"
                          class="form-check-label text-success">{{'ACTIVE'|translate}}</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input id="{{data.name}}-status-on" type="radio" class="form-check-input" [value]="false"
                          formControlName="casino_{{data.name}}_enable">
                        <label for="{{data.name}}-status-off"
                          class="form-check-label text-danger">{{'DISABLED'|translate}}</label>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td class="text-end">{{'LIMITED_PLAY'|translate}} :</td>
                    <td class="text-start">
                      <div class="form-check" *ngFor="let play of data.plays; let i = index">
                        <input id="{{data.name}}_{{i}}" type="radio" class="form-check-input" [value]="i+1"
                          formControlName="casino_{{data.name}}_limit">
                        <label for="{{data.name}}_{{i}}" class="form-check-label">
                          {{play.name}}
                          <i *ngIf="play.icon" class="fas fa-check text-success"></i>
                          <i *ngIf="!play.icon" class="fas fa-times text-danger"></i>
                        </label>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <div class="text-danger" *ngIf="data.name === 'pt'">**
                  {{'THE_COMMISSION_APPLIES_TO_NORMAL_BACCARAT_TYPE_PLAY_ONLY'|translate}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</form>
