import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertOddType'
})
export class ConvertOddTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case 'AH':
      case 'AH1ST':
        return 'Handicap';
      case 'TTC':
      case 'TTC1ST':
        return 'Total Corner';
      case 'OU':
      case 'OU1ST':
        return 'Over/Under';
      case 'OE':
      case 'OE1ST':
        return 'Odd/Even';
      case 'X12':
      case 'X121ST':
        return '1 X 2';
      case 'CS':
      case 'FHCS':
        return 'Correct Score';
      case 'TG':
        return 'Total Goal';
      case 'DC':
        return 'Double Chance';
      case 'FTHT':
        return 'HT/FT';
      case '1STG':
        return '1st Goal';
      case '2NDG':
        return '2nd Goal';
      case '3RDG':
        return '3rd Goal';
      case '4THG':
        return '4th Goal';
      case '5THG':
        return '5th Goal';
      case '6THG':
        return '6th Goal';
      case '7THG':
        return '7th Goal';
      case '8THG':
        return '8th Goal';
      case '9THG':
        return '9th Goal';
    }
    return value;
  }
}
