import { ConvertPriceTypePipe } from './pipes/convertPriceType.pipe';
import { ConvertOddTypePipe } from './pipes/convertOddType.pipe';
import { ConvertBetFTHTPipe } from './pipes/convertBetFTHT.pipe';
import { ConvertBetCSPipe } from './pipes/convertBetCS.pipe';
import { FilterLogPipe } from './pipes/filter-log.pipe';
import { DoughnutChartComponent } from './components/charts/original-chart/doughnut-chart/doughnut-chart.component';
import { PieLegendChartComponent } from './components/charts/customs-chart/pie-legend-chart/pie-legend-chart.component';
import { PieChartComponent } from './components/charts/original-chart/pie-chart/pie-chart.component';
import { NumberCounterComponent } from './components/number-counter/number-counter.component';
import { AdvancedPieChartComponent } from './components/charts/customs-chart/advanced-pie-chart/advanced-pie-chart.component';
import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AgentContentComponent,
  ContentComponent,
  IframePrintComponent,
  InputImgFileComponent,
  LineChartComponent,
  LoadingScreenComponent,
  MemberCreditFormComponent,
  ModalRightWhenMoblieComponent,
  PaginationComponent,
  ShowAutoSystemComponent,
  ShowDateTimeComponent
} from './components';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoadingDirective, TooltipDirective, ViewImgDirective } from './directives';
import { PerfactScrollBarModule, PrimengModule } from './libs';
import { FromNowPipe } from './pipes/from-now.pipe';
import { ClipboardModule } from 'ngx-clipboard';
import { ImgHostPipe } from './pipes/img-host.pipe';
import {
  AdminSelectAgentFormComponent,
  InputFileFormComponent,
  SelectAccountBankFormComponent,
  SelectAgentFormComponent,
  SelectMemberFormComponent,
  SelectPromotionFormComponent,
} from './forms';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {
  AgentChangePasswordOffcanvasComponent,
  AnnouncementFormCanvasComponent,
  BillFormOffcanvasComponent,
  BillStatusFormOffcanvasComponent,
  BroadcastFormOffcanvasComponent,
  ClearTurnOverMemberOffcanvasComponent,
  CommissionGamesOffcanvasComponent,
  ConfirmWithdrawOffcanvasComponent,
  CreditFormOffcanvasComponent,
  DepositStatementFormOffcanvasComponent,
  DepositStatementMemberFormOffcanvasComponent,
  FormMemberWithdrawOffcanvasComponent,
  LogAmbMemberSettingOffcanvasComponent,
  MemberTransactionOffcanvasComponent,
  MemberWinLoseManualOffcanvasComponent,
  MemberWinLoseOffcanvasComponent,
  MetaTagOffcanvasComponent,
  PromotionFormOffcanvasComponent,
  ResetPasswordFormOffcanvasComponent,
  ResultAmbOffcanvasComponent,
  RevertCreditRoundDetailOffcanvasComponent,
  RevertCreditRoundFormComponent,
  RevertCreditRoundHistoryOffcanvasComponent,
  RewardWheelFormOffcanvasComponent,
  SageLinkFormOffcanvasComponent,
  SageUserFormOffcanvasComponent,
  SettingSystemFormOffcanvasComponent,
  ViewBillKlottoOffcanvasComponent
} from './offcanvas';
import { BankImgPipe } from './pipes/bank-img.pipe';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { BankLogoDirective } from './directives/bank-logo.directive';
import { ConvertOddTypeHTFTPipe } from './pipes/convertOddTypeHTFT.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SafePipePipe } from './pipes/safe-pipe.pipe';
import { NgxLottieViewModule } from 'ngx-lottie-view';

import {
  AlertModalComponent,
  BillModalComponent,
  ChangeStatusMemberModalComponent,
  ChooseLanguageModalComponent,
  GiveBonusFromDepositModalComponent,
  WithdrawChangeStatusModalComponent
} from './modals';
import { ThaiBahtPipeModule } from 'ngx-thaibahtpipe';
import { TranslateModule } from '@ngx-translate/core';
import {
  SettingCardComponent,
  SettingCasinoComponent,
  SettingKlottoComponent,
  SettingLottoComponent,
  SettingM2SportComponent,
  SettingPartnerComponent,
  SettingPokerComponent,
  SettingSlotComponent,
  SettingSport2Component,
  SettingSportComponent
} from './setting-games';
import {
  SettingAmbCasinoComponent,
  SettingAmbEsportComponent,
  SettingAmbKenoComponent,
  SettingAmbLottoComponent,
  SettingAmbM2Component,
  SettingAmbMultiComponent,
  SettingAmbParlayComponent,
  SettingAmbPokerComponent,
  SettingAmbSlotComponent,
  SettingAmbSportComponent,
  SettingAmbStepComponent,
  SettingAmbTradingComponent,
  SettingKlottoPlayComponent,
} from '../pages/manage-agent/components';
import { SageContentComponent } from './components/sage-content/sage-content.component';
import { ChatListComponent } from './components/chat/chat-list/chat-list.component';
import { ChatRoomComponent } from './components/chat/chat-room/chat-room.component';
import { ChatPanelComponent } from './components/chat/chat-panel/chat-panel.component';
import { ChooseCurrencyModalComponent } from './modals/choose-currency-modal/choose-currency-modal.component';
import { ExAnyToThbPipe } from './pipes/ex-any-to-thb.pipe';
import { ExThbToAnyPipe } from './pipes/ex-thb-to-any.pipe';
import { RouterModule } from '@angular/router';

const Modules = [
  FormsModule,
  ReactiveFormsModule,
  NgSelectModule,
  PrimengModule,
  PerfactScrollBarModule,
  ClipboardModule,
  NgxSkeletonLoaderModule,
  NgxCsvParserModule,
  VirtualScrollerModule,
  DragDropModule,
  ThaiBahtPipeModule,
  TranslateModule,
  NgxLottieViewModule
];

const Components = [
  LoadingScreenComponent,
  ContentComponent,
  PaginationComponent,
  InputImgFileComponent,
  AgentContentComponent,
  ModalRightWhenMoblieComponent,
  IframePrintComponent,
  ChooseLanguageModalComponent,
  SageContentComponent,

  SelectAccountBankFormComponent,
  SelectMemberFormComponent,
  SelectAgentFormComponent,
  SelectPromotionFormComponent,
  InputFileFormComponent,
  ShowDateTimeComponent,
  ShowAutoSystemComponent,
  AdminSelectAgentFormComponent,

  GiveBonusFromDepositModalComponent,
  ChangeStatusMemberModalComponent,
  AlertModalComponent,
  WithdrawChangeStatusModalComponent,
  BillModalComponent,
  ChooseCurrencyModalComponent,

  AgentChangePasswordOffcanvasComponent,
  AnnouncementFormCanvasComponent,
  ConfirmWithdrawOffcanvasComponent,
  MemberWinLoseOffcanvasComponent,
  MemberTransactionOffcanvasComponent,
  FormMemberWithdrawOffcanvasComponent,
  PromotionFormOffcanvasComponent,
  RevertCreditRoundFormComponent,
  NumberCounterComponent,
  MetaTagOffcanvasComponent,
  DepositStatementFormOffcanvasComponent,
  ResetPasswordFormOffcanvasComponent,
  DepositStatementMemberFormOffcanvasComponent,
  RewardWheelFormOffcanvasComponent,
  ClearTurnOverMemberOffcanvasComponent,
  MemberWinLoseManualOffcanvasComponent,
  BillFormOffcanvasComponent,
  RevertCreditRoundHistoryOffcanvasComponent,
  RevertCreditRoundDetailOffcanvasComponent,
  CommissionGamesOffcanvasComponent,
  BillStatusFormOffcanvasComponent,
  CreditFormOffcanvasComponent,
  MemberCreditFormComponent,
  LogAmbMemberSettingOffcanvasComponent,
  BroadcastFormOffcanvasComponent,
  SettingSystemFormOffcanvasComponent,
  ResultAmbOffcanvasComponent,
  ViewBillKlottoOffcanvasComponent,
  SageUserFormOffcanvasComponent,
  SageLinkFormOffcanvasComponent,

  DoughnutChartComponent,
  LineChartComponent,
  AdvancedPieChartComponent,
  PieChartComponent,
  PieLegendChartComponent,

  // Setting Games
  SettingPartnerComponent,
  SettingSportComponent,
  SettingCasinoComponent,
  SettingLottoComponent,
  SettingM2SportComponent,
  SettingKlottoComponent,
  SettingSport2Component,
  SettingSlotComponent,
  SettingCardComponent,
  SettingPokerComponent,

  SettingAmbCasinoComponent,
  SettingAmbEsportComponent,
  SettingAmbKenoComponent,
  SettingAmbLottoComponent,
  SettingAmbM2Component,
  SettingAmbMultiComponent,
  SettingAmbParlayComponent,
  SettingAmbPokerComponent,
  SettingAmbSlotComponent,
  SettingAmbSportComponent,
  SettingAmbStepComponent,
  SettingAmbTradingComponent,
  SettingKlottoPlayComponent,

  // Chat
  ChatListComponent,
  ChatRoomComponent,
  ChatPanelComponent
];

const Directives = [
  BankLogoDirective,
  LoadingDirective,
  TooltipDirective,
  ViewImgDirective,
];

const Pipes = [
  FromNowPipe,
  ImgHostPipe,
  BankImgPipe,
  FilterLogPipe,
  SafePipePipe,
  ExAnyToThbPipe,
  ExThbToAnyPipe,
  // --------------
  ConvertBetCSPipe,
  ConvertBetFTHTPipe,
  ConvertOddTypePipe,
  ConvertOddTypeHTFTPipe,
  ConvertPriceTypePipe,
];

@NgModule({
  declarations: [
    ...Components,
    ...Directives,
    ...Pipes,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ...Modules,
  ],
  exports: [
    ...Modules,
    ...Components,
    ...Directives,
    ...Pipes,
  ],
  providers: [
    DecimalPipe,
    BankImgPipe,
  ]
})
export class SharedModule { }
