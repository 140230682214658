import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { LanguageItem, LanguageService, SelectLanguages } from 'src/app/services/language.service';

@Component({
  selector: 'app-choose-language-modal',
  templateUrl: './choose-language-modal.component.html',
  styleUrls: ['./choose-language-modal.component.scss']
})
export class ChooseLanguageModalComponent implements OnInit {
  languageItems = SelectLanguages;
  currentLang: LanguageItem;

  constructor(
    private ngxTranslate: TranslateService,
    public svLanguage: LanguageService,
  ) {
    this.currentLang = svLanguage.getLang();
    this.ngxTranslate.onLangChange.subscribe(() => {
      this.currentLang = svLanguage.getLang();
    });
  }

  ngOnInit(): void {
  }

  close() {
    document.getElementById('close-lang')?.click();
  }

}
