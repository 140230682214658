import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertBetCS'
})
export class ConvertBetCSPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case 'CS00':
        return '0 : 0';
      case 'CS01':
        return '0 : 1';
      case 'CS02':
        return '0 : 2';
      case 'CS03':
        return '0 : 3';
      case 'CS04':
        return '0 : 4';
      case 'CS10':
        return '1 : 0';
      case 'CS11':
        return '1 : 1';
      case 'CS12':
        return '1 : 2';
      case 'CS13':
        return '1 : 3';
      case 'CS14':
        return '1 : 4';
      case 'CS20':
        return '2 : 0';
      case 'CS21':
        return '2 : 1';
      case 'CS22':
        return '2 : 2';
      case 'CS23':
        return '2 : 3';
      case 'CS24':
        return '2 : 4';
      case 'CS30':
        return '3 : 0';
      case 'CS31':
        return '3 : 1';
      case 'CS32':
        return '3 : 2';
      case 'CS33':
        return '3 : 3';
      case 'CS34':
        return '3 : 4';
      case 'CS40':
        return '4 : 0';
      case 'CS41':
        return '4 : 1';
      case 'CS42':
        return '4 : 2';
      case 'CS43':
        return '4 : 3';
      case 'CS44':
        return '4 : 4';
      case 'CSAOS':
        return 'AOS';
    }
  }
}
