import { createLinearGradient } from 'src/app/utils/functions/canvas-linear-gradient';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input, OnChanges } from '@angular/core';
import { Chart } from 'chart.js';
import { PieChart } from '../../chart.interface';


@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('PieChart') canvas: ElementRef<HTMLCanvasElement>;
  @Input() MasterData: PieChart.Series[];
  ctx: CanvasRenderingContext2D;
  myChart: Chart;
  constructor() {
  }

  ngOnInit() {
  }
  ngOnChanges() {
    if (this.MasterData && this.ctx) {
      this.setupChart();
    }
  }
  ngAfterViewInit() {
    this.ctx = this.canvas.nativeElement?.getContext('2d');
    this.setupChart();
  }

  setupChart() {
    if (this.myChart) {
      this.myChart.destroy();
    }
    const config: any = {
      type: 'pie',
      data: this.setupData,
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: 'top',
          },
          title: {
            display: false
          }
        }
      },
    };
    Chart.defaults.font.family = `'Prompt'`;
    this.myChart = new Chart(this.ctx, config);
  }
  get setupData() {
    return {
      labels: this.Labels,
      datasets: [
        {
          borderWidth: 0,
          data: this.data,
          fill: true,
          backgroundColor: this.backgroundColor,
        }
      ]
    };
  }
  get data() {
    const labels = [];
    this.MasterData?.forEach(series => {
      labels.push(series.value);
    });
    return labels;
  }
  get Labels() {
    const labels = [];
    this.MasterData?.forEach(series => {
      labels.push(series.name);
    });
    return labels;
  }
  get backgroundColor() {
    const bg = [];
    this.MasterData?.forEach(series => {
      bg.push(createLinearGradient(series.color, this.ctx));
    });
    return bg;
  }
}
