import { Component, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, Output, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '@ngneat/reactive-forms';
import { concat, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, finalize, map, switchMap, tap } from 'rxjs/operators';
import { Agent, AgentService } from 'src/app/apis/agent.service';
import { formPage } from 'src/app/utils/functions/form-page';

@Component({
  selector: 'app-select-agent-form',
  templateUrl: './select-agent-form.component.html',
  styles: [`
    :host {
      display: block;
    }
  `],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectAgentFormComponent),
      multi: true
    }
  ]
})
export class SelectAgentFormComponent implements ControlValueAccessor, OnDestroy {
  agent$: Observable<Agent[]>;
  agentInput$ = new Subject<string>();
  loading = true;

  agentId: number = null;
  selectAgent: Agent;

  setSelectAllAgent$ = new Subject();

  agentItems: Agent[] = [];
  form = formPage({ name: 'agentName', limit: 2 });
  Subscriptions: Subscription[] = [];
  @Input() showCredit = false;
  @Input() showPoint = false;
  @Input() showLabel = true;
  @Output() change = new EventEmitter();
  @Output() AgentModel = new EventEmitter();
  @Output() agentChanged = new EventEmitter();
  constructor(
    private svAgent: AgentService,
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
  ) {
    this.setSelectAllAgent$.pipe(
      debounceTime(500),
    ).subscribe(() => {
      this.setSelectAllAgent();
    });
  }

  async writeValue(value: any) {
    this.agentId = value;
    this._renderer.setProperty(this._elementRef.nativeElement, 'value', value);
    this.setSelectAllAgent$.next();
  }

  onChange?: (value: any) => void;

  onTouched?: () => void;

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  ngOnDestroy(): void {
    this.Subscriptions.forEach(obs => obs.unsubscribe());
    this.Subscriptions = [];
  }

  getAgent() {
    return this.svAgent.getAll(this.form.value).toPromise();
  }

  async agentChange() {
    this.onChange(this.agentId);
    this.change.emit(this.agentId);
    this.AgentModel.emit(this.agentItems.filter(c => c.id === this.agentId)[0] || null);
    this.selectAgent = this.agentItems.find(item => item.id === this.agentId);
    this.agentChanged.emit(this.selectAgent || null);
  }

  trackByFn(item: Agent) {
    return item.id;
  }

  setAgentItems(items: Agent[]) {
    this.agentItems = items.sort((a, b) => {
      const aName = a.agentName.toUpperCase();
      const bName = b.agentName.toUpperCase();
      if (aName < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }
      return 0;
    });
    return items;
  }

  setSelectAllAgent() {
    this.loading = true;
    this.form.patchValue({ name: 'id', filter: (this.agentId || '') + '' });
    this.svAgent.getAll(this.form.value).pipe(
      map(item => this.setAgentItems(item.items)),
      finalize(() => this.loading = false)
    ).subscribe(res => {
      if (this.agentId) {
        this.selectAgent = this.agentItems.find(item => item.id === this.agentId);
        this.agentChanged.emit(this.selectAgent || null);
      }

      const form = formPage({ ...this.form.value, name: 'agentName' });
      this.agent$ = concat(
        of(res || []), // Default items
        this.agentInput$.pipe(
          debounceTime(300),
          distinctUntilChanged(),
          tap(() => this.loading = true),
          switchMap(term => {
            form.patchValue({ filter: term });
            return this.svAgent.getAll(form.value).pipe(
              map(item => this.setAgentItems(item.items)),
              catchError(() => of([])),
              tap(() => this.loading = false),
            );
          }),
        ),
      );
    });
  }
}
