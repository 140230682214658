<div class="row">
  <div class="col-12 col-md-6 mb-3">
    <div class="card" [ngClass]="{'disabled': max === 0}">
      <div class="card-header text-center">
        <b>Starter</b> <br>
        [ AllWaySpin | Ameba | Booongo | Funky Games | Iconic Gaming | Ka Gaming | Live22 | Manna Play | Relax Gaming |
        Spade Gaming | UPG | Wazdan | Yggdrasil ]
        (Min {{min | number: '1.0-0'}}% - Max {{max | number: '1.0-0'}}%)
      </div>
      <div class="card-body">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="text-end">{{'STATUS'|translate}} :</td>
              <td>
                <div class="form-check form-check-inline">
                  <input id="slot-status-check-on-{{inputId}}" type="radio" class="form-check-input"
                    name="slot-{{inputId}}" [value]="true"
                    [(ngModel)]="setting.group_init_member.updateLineStatus.gameSlotGroupAEnable" [disabled]="readOnly">
                  <label for="slot-status-check-on-{{inputId}}"
                    class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input id="slot-status-check-off-{{inputId}}" type="radio" class="form-check-input"
                    name="slot-{{inputId}}" [value]="false"
                    [(ngModel)]="setting.group_init_member.updateLineStatus.gameSlotGroupAEnable" [disabled]="readOnly">
                  <label for="slot-status-check-off-{{inputId}}"
                    class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 mb-3">
    <div class="card" [ngClass]="{'disabled': max === 0}">
      <div class="card-header text-center">
        <b>Vip</b> <br>
        [ Dragon Gaming | EvoPlay | I8 | Jill | Mega7 | Netent | PG Soft | Pragmatic Play | Redtiger | Simple Play |
        SlotRacha | Slot XO | Spinix | WM Slot ]
        (Min {{min | number: '1.0-0'}}% - Max {{max | number: '1.0-0'}}%)
      </div>
      <div class="card-body">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="text-end">{{'STATUS'|translate}} :</td>
              <td>
                <div class="form-check form-check-inline">
                  <input id="slot-status-check-on-{{inputId}}" type="radio" class="form-check-input"
                    name="slot-{{inputId}}" [value]="true"
                    [(ngModel)]="setting.group_init_member.updateLineStatus.gameSlotGroupBEnable" [disabled]="readOnly">
                  <label for="slot-status-check-on-{{inputId}}"
                    class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input id="slot-status-check-off-{{inputId}}" type="radio" class="form-check-input"
                    name="slot-{{inputId}}" [value]="false"
                    [(ngModel)]="setting.group_init_member.updateLineStatus.gameSlotGroupBEnable" [disabled]="readOnly">
                  <label for="slot-status-check-off-{{inputId}}"
                    class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 mb-3">
    <div class="card" [ngClass]="{'disabled': max === 0}">
      <div class="card-header text-center">
        <b>Exclusive</b> <br>
        [ AMB Slot | Askmebet | Advant Play | Ace333 + MicroGaming | Ninja | 918Kiss ]
        (Min {{min | number: '1.0-0'}}% - Max {{max | number: '1.0-0'}}%)
      </div>
      <div class="card-body">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="text-end">{{'STATUS'|translate}} :</td>
              <td>
                <div class="form-check form-check-inline">
                  <input id="slot-status-check-on-{{inputId}}" type="radio" class="form-check-input"
                    name="slot-{{inputId}}" [value]="true"
                    [(ngModel)]="setting.group_init_member.updateLineStatus.gameSlotGroupCEnable" [disabled]="readOnly">
                  <label for="slot-status-check-on-{{inputId}}"
                    class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input id="slot-status-check-off-{{inputId}}" type="radio" class="form-check-input"
                    name="slot-{{inputId}}" [value]="false"
                    [(ngModel)]="setting.group_init_member.updateLineStatus.gameSlotGroupCEnable" [disabled]="readOnly">
                  <label for="slot-status-check-off-{{inputId}}"
                    class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
