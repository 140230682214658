<div class="offcanvas offcanvas-end" id="memberWinLoseOffcanvas" style="width: 60em;">
  <div class="offcanvas-header">
    <h4 class="mb-0" id="offcanvasRightLabel">Win/Lose</h4>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
  </div>
  <div class="offcanvas-body">
    <div class="mb-3" *ngIf="item">
      <div class=""> {{'USERNAME'|translate}} : {{item.username}} </div>
      <div class=""> {{'FULLNAME'|translate}} : {{item.fullname}} </div>
    </div>
    <form [formGroup]="form" (change)="getWithdrawWinLose()" (submit)="getWithdrawWinLose()">
      <div class="row align-items-end">
        <div class="col-auto mb-3">
          <label for="" class="form-label"> {{'GAME'|translate}} </label>
          <select class="form-select" formControlName="game">
            <option value="">{{'ALL'|translate}}</option>
            <option value="custom">{{'CUSTOM'|translate}}</option>
            <option *ngFor="let gt of gameTypeItems" [value]="gt.id">{{gameType[gt.id]}}</option>
          </select>
        </div>
        <div class="col-auto mb-3">
          <label for="" class="form-label">{{'FROM'|translate}}</label>
          <input type="date" class="form-control" formControlName="FromDate">
        </div>
        <div class="col-auto mb-3">
          <label for="" class="form-label">{{'TO'|translate}}</label>
          <input type="date" class="form-control" formControlName="ToDate">
        </div>
        <div class="col-auto mb-3">
          <button type="submit" class="btn btn-outline-primary"> <i class="bi bi-search"></i> {{'SEARCH'|translate}} </button>
        </div>
        <div class="col-12 mb-3" *ngIf="form.value.game === 'custom'">
          <div class="form-check form-check-inline" *ngFor="let gt of gameTypeItems">
            <input [id]="gt.id" type="checkbox" class="form-check-input" [formControlName]="gt.id">
            <label [attr.for]="gt.id" class="form-check-label">{{gameType[gt.id]}}</label>
          </div>
        </div>
      </div>
    </form>

    <div class="text-center" *ngIf="!winLoseItems"> {{'NO_DATA'|translate}} </div>
    <div class="text-center" *ngIf="loading">
      <span class="spinner-border"></span>
    </div>
    <div class="row" *ngIf="winLoseItems">
      <div class="col-12 mb-3">
        <h5>{{'WIN_LOSE_INFORMATION'|translate}}</h5>
        <hr>
        <div class="">
          <div class="mb-2">{{'AMOUNT'|translate}} : {{winLoseItems.sum_winlose.Total_amount | number: '0.2-2'}}</div>
          <div class="mb-2">{{'OUTSTANDING_AMOUNT'|translate}} : {{winLoseItems.sum_winlose.Total_outstanding | number: '0.2-2'}}</div>
          <div class="mb-2">{{'BET_COUNT'|translate}} : {{winLoseItems.sum_winlose.Total_validAmount | number: '0.2-2'}}</div>
          <div class="mb-2">{{'WIN_LOSE_AMOUNT'|translate}} : {{winLoseItems.sum_winlose.Total_wlTurnAmount | number: '0.2-2'}}</div>
        </div>
      </div>
      <div class="col-12 mb-3">
        <h5>{{'LASTEST_DEPOSIT'|translate}}</h5>
        <hr>
        <div class="">
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>{{'AMOUNT'|translate}}</th>
                  <th>{{'BONUS'|translate}}</th>
                  <th>{{'CONFIRMED_DATE'|translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of winLoseItems.deposit">
                  <td class="text-end"> {{ item.amount | number: '0.2-2' }} </td>
                  <td> {{ item.bonus > 0 ? 'ใช่': 'ไม่ใช่' }} </td>
                  <td>
                    <app-show-date-time [date]="item.update_date"></app-show-date-time>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center mb-3">
            <div class="" *ngIf="!winLoseItems.deposit?.length"> {{'NO_DATA'|translate}} </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
