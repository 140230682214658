import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '@ngneat/reactive-forms';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { Promotion, PromotionService } from 'src/app/apis/promotion.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { formPage } from 'src/app/utils/functions/form-page';

@Component({
  selector: 'app-select-promotion-form',
  templateUrl: './select-promotion-form.component.html',
  styles: [`
    :host {
      display: block;
    }
  `],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectPromotionFormComponent),
      multi: true
    },
  ],
})
export class SelectPromotionFormComponent extends AppCore implements ControlValueAccessor {
  promotion$: Observable<Promotion[]>;
  promotionInput$ = new Subject<string>();
  promotionLoading = false;

  promotionId: number;
  promotionItems: Promotion[];
  selectedPromotion: Promotion;

  form = formPage({ name: 'name', filter: '', limit: 20, agent_id: '', promotion_id: '', type_pro: '' });
  @Input() typePro: 'normal' | 'new_member' | '' = '';
  @Input() showDetail = true;
  @Output() change = new EventEmitter<Promotion>();
  constructor(
    private svPromotion: PromotionService,
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
  ) {
    super();
  }

  writeValue(value: any): void {
    this.promotionId = value;
    this._renderer.setProperty(this._elementRef.nativeElement, 'value', value);
    this.selectedPromotion = this.promotionItems?.find(item => item.id === this.promotionId);
    this.change.emit(this.selectedPromotion);
  }
  onChange?: (value: any) => void;
  onTouched?: () => void;
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  agentIdChange() {
    this.form.patchValue({ agent_id: this.currentAgentId, type_pro: this.typePro });
    this.getPromotion();
  }

  getPromotion() {
    this.svPromotion.get(this.form.value).subscribe(res => {
      this.promotionItems = res.items;

      this.promotion$ = concat(
        of(res.items || []),
        this.promotionInput$.pipe(
          debounceTime(300),
          distinctUntilChanged(),
          tap(() => this.promotionLoading = true),
          switchMap(term => {
            this.form.patchValue({ filter: term || '' });
            return this.svPromotion.get(this.form.value).pipe(
              map(res => {
                this.promotionItems = res.items;
                return res.items;
              }),
              catchError(() => of([])),
              tap(() => this.promotionLoading = false),
            );
          })
        ),
      );
    });
  }

  selectChange() {
    this.selectedPromotion = this.promotionItems?.find(item => item.id === this.promotionId);
    this.onChange(this.promotionId);
    this.change.emit(this.selectedPromotion);
  }

  trackByFn(item: Promotion) {
    return item.id;
  }
}
