import { Component, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { finalize } from 'rxjs/operators';
import { AccountBank, BankService } from 'src/app/apis/bank.service';
import { DepositStatementCreateModel, MemberCreditService } from 'src/app/apis/member-credit.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { alertSuccess, alertText, alertWarning } from 'src/app/utils/functions/alert';
import { focusInvalid } from 'src/app/utils/functions/focus-invalid';
import { noWhitespaceValidator } from 'src/app/utils/functions/no-whitespace';

@Component({
  selector: 'app-deposit-statement-form-offcanvas',
  templateUrl: './deposit-statement-form-offcanvas.component.html',
  styleUrls: ['./deposit-statement-form-offcanvas.component.scss']
})
export class DepositStatementFormOffcanvasComponent extends AppCore implements OnInit {
  form = this.createForm();
  saving = false;

  showSelect = false;
  constructor(
    private svMemberCredit: MemberCreditService,
    private ngFb: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    const statementEl = document.getElementById(`depositStatementOffcanvas`);
    statementEl.addEventListener('show.bs.offcanvas', () => {
      this.form = this.createForm();
      this.showSelect = true;
    });
  }

  createForm(): FormGroup<DepositStatementCreateModel> {
    return this.ngFb.group({
      info: [''],
      amount: [0, [Validators.min(0.01)]],
      bank: ['', [noWhitespaceValidator]],
      account_number: [''],
      account_bank_agent_id: [null],
      statement_datetime: [''],
    });
  }

  bankDepositChange(item: AccountBank) {
    this.form.patchValue({
      bank: item.bank,
      account_number: item.accout_number,
    });
  }

  onSubmit() {
    this.form.markAllAsDirty();
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      alertWarning({ message: alertText.formInvalid });
      focusInvalid();
      return;
    }

    this.saving = true;
    this.svMemberCredit.postDepositStatement(this.form.value).pipe(
      finalize(() => this.saving = false)
    ).subscribe(res => {
      alertSuccess({ message: alertText.saveSuccess });
      this.clickBtnClose('btn-close');
    });
  }
}
