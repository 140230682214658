import { NullTemplateVisitor } from '@angular/compiler';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { DepositStatement, DepositStatementMemberModel, MemberCreditService } from 'src/app/apis/member-credit.service';
import { Member } from 'src/app/apis/member.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { alertSuccess, alertText, alertWarning } from 'src/app/utils/functions/alert';
import { fileToBase64, validateFiles } from 'src/app/utils/functions/base64';
import { Pagination } from 'src/app/utils/interfaces/pagination';

@Component({
  selector: 'app-deposit-statement-member-form-offcanvas',
  templateUrl: './deposit-statement-member-form-offcanvas.component.html',
  styleUrls: ['./deposit-statement-member-form-offcanvas.component.scss']
})
export class DepositStatementMemberFormOffcanvasComponent extends AppCore implements OnInit, OnChanges {
  form = this.createForm();
  memberItems: Pagination<Member[]>;
  memberLoading = true;
  selectMember: Member;

  saving = false;

  @Input() item: DepositStatement;
  constructor(
    private ngFb: FormBuilder,
    private svMemberCredit: MemberCreditService,
  ) {
    super();
  }

  ngOnInit(): void {
    const statementMemberEl = document.getElementById(`depositStatementMemberOffcanvas`);
    statementMemberEl.addEventListener('show.bs.offcanvas', () => {
      this.form = this.createForm();
    });
  }
  ngOnChanges() {
    if (this.item) {
      this.form.patchValue({
        member_id: this.item.member_id || null,
        deposit_statement_id: this.item.id,
      });
    }
  }

  agentIdChange() {
    this.form.patchValue({ promotion_id: null });
  }

  createForm(): FormGroup<DepositStatementMemberModel> {
    return this.ngFb.group({
      deposit_statement_id: [null, [Validators.required]],
      image_statement: [''],
      promotion_id: [null],
      member_id: [null, [Validators.required]],
      is_manual: [false],
      note: [''],
    });
  }

  memberChange() {
    this.selectMember = this.memberItems.items.find(item => item.id === this.form.value.member_id);
  }

  onSubmit() {
    this.form.markAllAsDirty();
    this.form.markAllAsTouched();

    if (!this.form.value.member_id) {
      alertWarning({ message: `เลือกสมาชิกก่อน` });
      return;
    }
    if (!this.form.valid) {
      alertWarning({ message: alertText.formInvalid });
      return;
    }

    this.saving = true;
    this.svMemberCredit.postDepositStatementMember(this.form.value).subscribe(res => {
      alertSuccess({ message: alertText.saveSuccess });
      this.clickBtnClose('btn-close2');
    });
  }

  async inputFileChange(event: Event) {
    const inputEl = event.target as HTMLInputElement;
    const files = inputEl.files;

    const validFile = validateFiles(files);
    if (!validFile) { return; }

    const image_statement = await fileToBase64(files[0]);
    this.form.patchValue({ image_statement });
    inputEl.value = '';
  }
}
