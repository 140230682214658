<div class="py-2 px-3 bg-secondary text-center">
  {{lottoType}}
  (Min {{min | number: '1.0-0'}}% - Max {{max | number: '1.0-0'}}%)
</div>

<div class="position-relative">
  <div class="row mb-2" [ngClass]="{'disabler': lottoType === 'หวยหุ้น'}">
    <div class="col-12 col-md-6 mb-2">
      <div class="mb-3">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="text-end">{{'STATUS'|translate}} : </td>
              <td *ngIf="lottoType === 'หวยไทย'">
                <div class="form-check form-check-inline">
                  <input id="loto-on-{{inputId}}" type="radio" class="form-check-input" name="status-{{inputId}}"
                    [value]="true" [(ngModel)]="setting.group_init_member.updateLineStatus.ambLottoEnable"
                    [disabled]="readOnly">
                  <label for="loto-on-{{inputId}}" class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input id="loto-off-{{inputId}}" type="radio" class="form-check-input" name="status-{{inputId}}"
                    [value]="false" [(ngModel)]="setting.group_init_member.updateLineStatus.ambLottoEnable"
                    [disabled]="readOnly">
                  <label for="loto-off-{{inputId}}" class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
                </div>
              </td>
              <td *ngIf="lottoType === 'หวยปิงปอง'">
                <div class="form-check form-check-inline">
                  <input id="loto-pp-on-{{inputId}}" type="radio" class="form-check-input" name="loto-pp-status"
                    [value]="true" [(ngModel)]="setting.group_init_member.updateLineStatus.ambLottoPPEnable"
                    [disabled]="readOnly">
                  <label for="loto-pp-on-{{inputId}}" class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input id="loto-pp-off-{{inputId}}" type="radio" class="form-check-input" name="loto-pp-status"
                    [value]="false" [(ngModel)]="setting.group_init_member.updateLineStatus.ambLottoPPEnable"
                    [disabled]="readOnly">
                  <label for="loto-pp-off-{{inputId}}" class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
                </div>
              </td>
              <td *ngIf="lottoType === 'หวยลาว'">
                <div class="form-check form-check-inline">
                  <input id="loto-on-{{inputId}}" type="radio" class="form-check-input" name="status-{{inputId}}"
                    [value]="true" [(ngModel)]="setting.group_init_member.updateLineStatus.ambLottoLaosEnable"
                    [disabled]="readOnly">
                  <label for="loto-on-{{inputId}}" class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input id="loto-off-{{inputId}}" type="radio" class="form-check-input" name="status-{{inputId}}"
                    [value]="false" [(ngModel)]="setting.group_init_member.updateLineStatus.ambLottoLaosEnable"
                    [disabled]="readOnly">
                  <label for="loto-off-{{inputId}}" class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
                </div>
              </td>
              <td *ngIf="lottoType === 'หวยหุ้น'">
                <div class="form-check form-check-inline">
                  <input id="loto-on-{{inputId}}" type="radio" class="form-check-input" name="status-{{inputId}}"
                    [value]="true" [(ngModel)]="setting.group_init_member.updateLineStatus.ambLottoStockEnable"
                    [disabled]="readOnly">
                  <label for="loto-on-{{inputId}}" class="form-check-label link-success">{{'ACTIVE'|translate}}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input id="loto-off" type="radio" class="form-check-input" name="status-{{inputId}}" [value]="false"
                    [(ngModel)]="setting.group_init_member.updateLineStatus.ambLottoStockEnable" [disabled]="readOnly">
                  <label for="loto-off" class="form-check-label link-danger">{{'DISABLED'|translate}}</label>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <div class="text-danger" *ngIf="lottoType !== 'หวยหุ้น'">
          * {{'TO_HOLD_THE_LOTTERRY_YOU_MUST_SET_UP_THE_EATING_MENU_AT_THE_STOCK_MENU'|translate}}{{lottoType}}{{'TOO'|translate}}.
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 mb-2">
      <div class="mb-3" *ngIf="lottoType === 'หวยไทย'">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="text-end">{{'END_OF_BET_TIME'|translate}} : </td>
              <td>
                <div class="d-flex">
                  <select class="form-select" style="width: 80px;" [disabled]="readOnly"
                    [(ngModel)]="setting.member_info.limitSetting.lotto.amb.hour">
                    <option *ngFor="let o of hourOptions" [ngValue]="o.value">{{o.label}}</option>
                  </select>
                  <span class="mx-2"> : </span>
                  <select class="form-select" style="width: 80px;" [disabled]="readOnly"
                    [(ngModel)]="setting.member_info.limitSetting.lotto.amb.minute">
                    <option *ngFor="let o of minuteOptions" [ngValue]="o.value">{{o.label}}</option>
                  </select>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mb-3" *ngIf="lottoType === 'หวยปิงปอง'">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="text-end">{{'END_OF_BET_TIME'|translate}} : </td>
              <td>
                <div class="d-flex">
                  <select class="form-select" style="width: 80px;" [disabled]="readOnly"
                    [(ngModel)]="setting.member_info.limitSetting.lotto.pp.hour">
                    <option *ngFor="let o of hourOptions" [ngValue]="o.value">{{o.label}}</option>
                  </select>
                  <span class="mx-2"> : </span>
                  <select class="form-select" style="width: 80px;" [disabled]="readOnly"
                    [(ngModel)]="setting.member_info.limitSetting.lotto.pp.minute">
                    <option *ngFor="let o of minuteOptions" [ngValue]="o.value">{{o.label}}</option>
                  </select>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mb-3" *ngIf="lottoType === 'หวยลาว'">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="text-end">{{'END_OF_BET_TIME'|translate}} : </td>
              <td>
                <div class="d-flex">
                  <select class="form-select" style="width: 80px;" [disabled]="readOnly"
                    [(ngModel)]="setting.member_info.limitSetting.lotto.laos.hour">
                    <option *ngFor="let o of hourOptions" [ngValue]="o.value">{{o.label}}</option>
                  </select>
                  <span class="mx-2"> : </span>
                  <select class="form-select" style="width: 80px;" [disabled]="readOnly"
                    [(ngModel)]="setting.member_info.limitSetting.lotto.laos.minute">
                    <option *ngFor="let o of minuteOptions" [ngValue]="o.value">{{o.label}}</option>
                  </select>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <ng-container *ngIf="lottoType === 'หวยไทย'">
      <div class="col-12 col-xl-6 mb-2">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>{{'LISTS'|translate}}</th>
              <th>{{'PAYOUT_RATE'|translate}}</th>
              <th>{{'COMMISSION'|translate}} %</th>
              <th>{{'MAX_BET'|translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>6 Top</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._6TOP.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._6TOP.payout | number}}</div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._6TOP.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._6TOP.discount | number}}</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._6TOP.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._6TOP.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>5 Top</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._5TOP.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._5TOP.payout | number}}</div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._5TOP.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._5TOP.discount | number}}</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._5TOP.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._5TOP.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>4 Top</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._4TOP.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._4TOP.payout | number}}</div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._4TOP.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._4TOP.discount | number}}</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._4TOP.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._4TOP.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>4 Roll</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._4TOD.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._4TOD.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._4TOD.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._4TOD.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._4TOD.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._4TOD.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>3 Top</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._3TOP.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._3TOP.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._3TOP.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._3TOP.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._3TOP.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._3TOP.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>3 Roll</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._3TOD.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._3TOD.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._3TOD.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._3TOD.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._3TOD.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._3TOD.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>3 Bottom</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._3BOT.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._3BOT.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._3BOT.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._3BOT.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._3BOT.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._3BOT.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>3 Top O/U</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._3TOP_OU.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._3TOP_OU.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._3TOP_OU.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._3TOP_OU.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._3TOP_OU.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._3TOP_OU.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>3 Top O/E</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._3TOP_OE.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._3TOP_OE.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._3TOP_OE.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._3TOP_OE.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._3TOP_OE.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._3TOP_OE.max | number}}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12 col-xl-6 mb-2">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>{{'LISTS'|translate}}</th>
              <th>{{'PAYOUT_RATE'|translate}}</th>
              <th>{{'COMMISSION'|translate}} %</th>
              <th>{{'MAX_BET'|translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>2 Top</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._2TOP.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._2TOP.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._2TOP.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._2TOP.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._2TOP.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._2TOP.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>2 Roll</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._2TOD.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._2TOD.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._2TOD.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._2TOD.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._2TOD.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._2TOD.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>2 Bottom</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._2BOT.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._2BOT.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._2BOT.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._2BOT.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._2BOT.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._2BOT.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>2 Top O/U</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._2TOP_OU.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._2TOP_OU.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._2TOP_OU.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._2TOP_OU.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._2TOP_OU.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._2TOP_OU.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>2 Top O/E</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._2TOP_OE.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._2TOP_OE.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._2TOP_OE.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._2TOP_OE.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._2TOP_OE.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._2TOP_OE.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>2 Bottom O/U</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._2BOT_OU.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._2BOT_OU.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._2BOT_OU.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._2BOT_OU.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._2BOT_OU.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._2BOT_OU.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>2 Bottom O/E</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._2BOT_OE.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._2BOT_OE.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._2BOT_OE.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._2BOT_OE.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._2BOT_OE.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._2BOT_OE.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>1 Top</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._1TOP.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._1TOP.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._1TOP.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._1TOP.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._1TOP.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._1TOP.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>1 Bottom</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._1BOT.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._1BOT.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._1BOT.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._1BOT.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.amb._1BOT.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.amb._1BOT.max | number}}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
    <ng-container *ngIf="lottoType === 'หวยปิงปอง'">
      <div class="col-12 col-xl-6 mb-2">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>{{'LISTS'|translate}}</th>
              <th>{{'PAYOUT_RATE'|translate}}</th>
              <th>{{'COMMISSION'|translate}} %</th>
              <th>{{'MAX_BET'|translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>6 Top</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._6TOP.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._6TOP.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._6TOP.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._6TOP.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._6TOP.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._6TOP.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>5 Top</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._5TOP.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._5TOP.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._5TOP.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._5TOP.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._5TOP.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._5TOP.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>4 Top</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._4TOP.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._4TOP.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._4TOP.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._4TOP.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._4TOP.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._4TOP.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>4 Roll</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._4TOD.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._4TOD.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._4TOD.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._4TOD.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._4TOD.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._4TOD.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>3 Top</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._3TOP.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._3TOP.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._3TOP.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._3TOP.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._3TOP.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._3TOP.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>3 Roll</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._3TOD.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._3TOD.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._3TOD.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._3TOD.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._3TOD.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._3TOD.max | number}}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12 col-xl-6 mb-2">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>{{'LISTS'|translate}}</th>
              <th>{{'PAYOUT_RATE'|translate}}</th>
              <th>{{'COMMISSION'|translate}} %</th>
              <th>{{'MAX_BET'|translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>2 Top</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._2TOP.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._2TOP.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._2TOP.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._2TOP.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._2TOP.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._2TOP.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>2 Roll</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._2TOD.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._2TOD.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._2TOD.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._2TOD.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._2TOD.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._2TOD.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>2 Bottom</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._2BOT.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._2BOT.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._2BOT.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._2BOT.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._2BOT.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._2BOT.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>1 Top</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._1TOP.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._1TOP.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._1TOP.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._1TOP.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._1TOP.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._1TOP.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>1 Bottom</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._1BOT.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._1BOT.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._1BOT.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._1BOT.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.pp._1BOT.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.pp._1BOT.max | number}}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
    <ng-container *ngIf="lottoType === 'หวยลาว'">
      <div class="col-12 col-xl-6 mb-2">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th colspan="100%">{{'SET'|translate}}</th>
            </tr>
            <tr>
              <th>{{'LISTS'|translate}}</th>
              <th>{{'PAYOUT_RATE'|translate}}</th>
              <th>{{'COMMISSION'|translate}} %</th>
              <th>{{'MAX_BET'|translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>4 Straight</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.L_4TOP.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.L_4TOP.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.L_4TOP.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.L_4TOP.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.L_4TOP.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.L_4TOP.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>4 Roll</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.L_4TOD.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.L_4TOD.payout | number}}
                </div>
              </td>
              <td class="text-center" colspan="2">
                {{'USED_IN_CONJUNCTION_WITH_4STRAIGHT'|translate}}
              </td>
            </tr>
            <tr>
              <td>3 Straight</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.L_3TOP.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.L_3TOP.payout | number}}
                </div>
              </td>
              <td class="text-center" colspan="2">
                {{'USED_IN_CONJUNCTION_WITH_4STRAIGHT'|translate}}
              </td>
            </tr>
            <tr>
              <td>3 Roll</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.L_3TOD.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.L_3TOD.payout | number}}
                </div>
              </td>
              <td class="text-center" colspan="2">
                {{'USED_IN_CONJUNCTION_WITH_4STRAIGHT'|translate}}
              </td>
            </tr>
            <tr>
              <td>2 FB</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.L_2FB.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.L_2FB.payout | number}}
                </div>
              </td>
              <td class="text-center" colspan="2">
                {{'USED_IN_CONJUNCTION_WITH_4STRAIGHT'|translate}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12 col-xl-6 mb-2">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th colspan="100%">{{'SET'|translate}}</th>
            </tr>
            <tr>
              <th>{{'LISTS'|translate}}</th>
              <th>{{'PAYOUT_RATE'|translate}}</th>
              <th>{{'COMMISSION'|translate}} %</th>
              <th>{{'MAX_BET'|translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>4 Top</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.T_4TOP.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.T_4TOP.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.T_4TOP.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.T_4TOP.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.T_4TOP.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.T_4TOP.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>4 Roll</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.T_4TOD.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.T_4TOD.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.T_4TOD.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.T_4TOD.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.T_4TOD.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.T_4TOD.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>3 Top</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.T_3TOP.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.T_3TOP.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.T_3TOP.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.T_3TOP.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.T_3TOP.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.T_3TOP.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>3 Roll</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.T_3TOD.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.T_3TOD.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.T_3TOD.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.T_3TOD.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.T_3TOD.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.T_3TOD.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>2 Top</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.T_2TOP.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.T_2TOP.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.T_2TOP.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.T_2TOP.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.T_2TOP.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.T_2TOP.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>2 Roll</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.T_2TOD.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.T_2TOD.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.T_2TOD.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.T_2TOD.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.T_2TOD.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.T_2TOD.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>2 Bottom</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.T_2BOT.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.T_2BOT.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.T_2BOT.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.T_2BOT.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.T_2BOT.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.T_2BOT.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>1 Top</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.T_1TOP.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.T_1TOP.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.T_1TOP.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.T_1TOP.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.T_1TOP.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.T_1TOP.max | number}}</div>
              </td>
            </tr>
            <tr>
              <td>1 Bottom</td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.T_1BOT.payout">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.T_1BOT.payout | number}}
                </div>
              </td>
              <td>
                <select class="form-select" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.T_1BOT.discount">
                  <option *ngFor="let o of maxOptions" [ngValue]="o.value">{{o.label}}</option>
                </select>
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.T_1BOT.discount | number}}%</div>
              </td>
              <td>
                <input type="number" class="form-control" [disabled]="readOnly"
                  [(ngModel)]="setting.member_info.limitSetting.lotto.laos.T_1BOT.max">
                <div class="text-start">{{'RECEIVED'|translate}} =
                  {{setting.group_init_member.maxSetting.limitSetting.lotto.laos.T_1BOT.max | number}}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </div>
</div>
