import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-show-auto-system',
  templateUrl: './show-auto-system.component.html',
  styleUrls: ['./show-auto-system.component.scss']
})
export class ShowAutoSystemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
