import { Injectable } from '@angular/core';
import { HttpApiService } from '../services/http-api.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpApiService,
  ) { }

  authentication() {
    return this.http.get<Login>(`api/ask4bet/authen/user/authentication`);
  }

  login(model: LoginModel) {
    return this.http.post<Login>(`api/ask4bet/authen/user/signin`, model);
  }
}

export interface LoginModel {
  username: string;
  password: string;
  remember: boolean;
  prefix: string;
}

export interface Login {
  Token: string;
}
