import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AMBGameItem, AMBSettingGame, SettingGameMembersService } from 'src/app/apis/setting-game-members.service';
import { LogItem } from 'src/app/pages/logs/pages';
import { AppCore } from 'src/app/utils/classes/app-core';

@Component({
  selector: 'app-log-amb-member-setting-offcanvas',
  templateUrl: './log-amb-member-setting-offcanvas.component.html',
  styleUrls: ['./log-amb-member-setting-offcanvas.component.scss']
})
export class LogAmbMemberSettingOffcanvasComponent extends AppCore implements OnInit, OnChanges {
  @Input() logItem: LogItem;

  ambGames = this.svSettingGame.ambGames;
  lottoGames = this.svSettingGame.lottoGames;

  ambActive = this.ambGames[0];
  lottoActive = this.lottoGames[0];

  oldSetting: AMBSettingGame;
  newSetting: AMBSettingGame;

  constructor(
    private svSettingGame: SettingGameMembersService,
  ) {
    super();
  }

  ngOnInit(): void {
    setTimeout(() => {
      const logAmbSettingEl = document.getElementById('LogAmbMemberSettingOffcanvas');
      logAmbSettingEl.addEventListener('hide.bs.offcanvas', () => {
        this.ambActive = this.ambGames[0];
        this.lottoActive = this.lottoGames[0];
        this.oldSetting = null;
        this.newSetting = null;
      });
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.logItem) {
      this.oldSetting = this.logItem.info_old;
      this.newSetting = this.logItem.info_new;
    }
  }

  changeTab(item: AMBGameItem) {
    this.ambActive = item;
  }

}
