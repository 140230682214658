<div class="d-flex h-100">
  <div class="w-75">
    <div class="show-image-upload" *ngIf="image">
      <div class="show-img">
        Send an image
        <img [src]="image" width="100%" alt="">
        <div class="text-end pt-2">
          <a class="btn btn-light me-2" href="javascript:;" (click)="image = ''">ยกเลิก</a>
          <a class="btn btn-primary" (click)="onSendImage()">
            ส่ง <i class="bi bi-send"></i>
          </a>
        </div>
      </div>
    </div>
    <ng-container *ngIf="member">
      <div class="border-bottom">
        <div class="d-flex justify-content-between">
          <div class="w-75 p-1">
            <img class="rounded-circle me-2" src="assets/mocks/avatar.png" width="30px" alt="">
            <div class="btn-group">
              <button class="btn btn-outline-primary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                {{member?.username}}
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" [routerLink]="getRouteMemberProfile(member.id)">Profile</a>
                </li>
                <li *ngIf="member.status !== 'confirmed'">
                  <a class="dropdown-item" href="javascript:;" (click)="changeStatusMember('confirmed')">
                    <span class="badge badge-success">confirmed</span>
                  </a>
                </li>
                <li *ngIf="member.status !== 'suspend'">
                  <a class="dropdown-item" href="javascript:;" (click)="changeStatusMember('suspend')">
                    <span class="badge badge-warning">suspend</span>
                  </a>
                </li>
                <li *ngIf="member.status !== 'lock'">
                  <a class="dropdown-item" href="javascript:;" (click)="changeStatusMember('lock')">
                    <span class="badge badge-secondary">lock</span>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" [routerLink]="['', AppPath.manageMember, 'edit', member.id]">แก้ไขสมาชิก</a>
                </li>
                <li class="dropdown-divider"></li>
                <li>
                  <a class="dropdown-item" href="javascript:;" (click)="openClearTurnoverMemberOffcanvas()">
                    ล้าง Turnover
                  </a>
                </li>
              </ul>
            </div>
            <button type="button" class="btn btn-primary btn-sm ms-2"
              (click)="openMemberCreditFormOffcanvas(true, member.id)">
              <i class="fa-solid fa-plus"></i> เพิ่มเครดิต
            </button>
            <button type="button" class="btn btn-danger btn-sm ms-2"
              (click)="openMemberCreditFormOffcanvas(false, member.id)">
              <i class="fa-solid fa-minus"></i> ลดเครดิต
            </button>
          </div>
          <div class="w-25 text-end p-2">
            <small> เครดิต <span class="text-primary">{{member?.wallet_total | number:'.2-2'}}</span>
              THB</small>
          </div>
        </div>
      </div>
      <ng-container *ngIf="ChatRoom">
        <div class="chat-body p-2" id="chat-body" (click)="onReadMessage()">
          <ng-container *ngFor="let m of ChatMessage;trackBy:ChatMessageTrackBy;let i = index">
            <div class="chat">
              <div class="row" *ngIf="m.memberId">
                <div class="col-lg-8">
                  <div class="d-flex justify-content-start">
                    <div class="profile">
                      <div class="d-flex align-items-end h-100 pb-4">
                        <div style="width: 40px;">
                          <img [src]="'assets/mocks/profile.png'" width="50px" alt=""
                            *ngIf="!ChatMessage[i+1]?.memberId">
                        </div>
                      </div>
                    </div>
                    <div class="message">
                      <small class="reply-message" *ngIf="m?.replyId">
                        <a class="btn-replay"> <i class="bi bi-reply"></i>
                          {{m?.replyMessage || 'missing message'}}
                        </a>
                      </small>
                      <div class="d-flex justify-content-start">
                        <div class="text-message">
                          <ng-container *ngIf="m.type === 'text'">
                            <span [innerHTML]="m.message"></span>
                          </ng-container>
                          <ng-container *ngIf="m.type === 'image'">
                            <img src="{{m.message | imgHost}}" width="100%">
                          </ng-container>
                        </div>
                        <div class="dropdown">
                          <a class="btn btn-light btn-sm p-0 ms-2 my-2" href="javascript:;" data-bs-toggle="dropdown">
                            <i class="bi bi-three-dots-vertical"></i>
                          </a>
                          <ul class="dropdown-menu">
                            <li>
                              <a class="dropdown-item" (click)="onReplyMessage(m)">
                                <i class="bi bi-reply"></i> ตอบกลับ
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <small class="time">
                        {{m.create_dt || '2023-05-18T20:08:35.4066667' | date:'HH:mm:ss a'}}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row justify-content-end" *ngIf="!m.memberId">
                <div class="col-lg-8 text-end">
                  <div class="d-flex justify-content-end">
                    <div class="message">
                      <small class="reply-message" *ngIf="m?.replyId">
                        <a class="btn-replay"> <i class="bi bi-reply"></i>
                          {{m?.replyMessage || 'missing message'}}
                        </a>
                      </small>
                      <div class="d-flex justify-content-end">
                        <small *ngIf="m.readed" class="opacity-50 p-1">อ่านแล้ว</small>
                        <div class="dropdown dropstart">
                          <a class="btn btn-light btn-sm p-0 me-2 my-2" href="javascript:;" data-bs-toggle="dropdown">
                            <i class="bi bi-three-dots-vertical"></i>
                          </a>
                          <ul class="dropdown-menu">
                            <li *ngIf="m.type === 'text'">
                              <a class="dropdown-item" (click)="onReplyMessage(m)">
                                <i class="bi bi-reply"></i> ตอบกลับ
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" (click)="onEditMessage(m)">
                                <i class="bi bi-pencil-square"></i> แก้ไข
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" (click)="onDeleteMessage(m.id)">
                                <i class="bi bi-trash"></i> ลบข้อความ
                              </a>
                            </li>
                            <li>
                              <hr class="dropdown-divider">
                            </li>
                            <li>
                              <span class="dropdown-item-text">
                                <i class="bi bi-chat-right-dots"></i>
                                {{m.admin_username}}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div class="text-message admin" [ngClass]="{'opacity-50':!m.id}">
                          <ng-container *ngIf="m.type === 'text'">
                            <span [innerHTML]="m.message"></span>
                          </ng-container>
                          <ng-container *ngIf="m.type === 'image'">
                            <img src="{{m.message | imgHost}}" width="100%">
                          </ng-container>
                        </div>
                      </div>
                      <small class="time">
                        {{m.create_dt || '2023-05-18T20:08:35.4066667' | date:'HH:mm:ss a'}}
                      </small>
                    </div>
                    <div class="profile" [ngClass]="{'opacity-50':!m.id}">
                      <div class="d-flex align-items-end h-100 pb-4">
                        <div style="width: 40px;">
                          <img [src]="Config.Favicon" alt="" *ngIf="!ChatMessage[i+1]?.adminId">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

        </div>
        <div class="chat-input border-top" (click)="onReadMessage()">
          <div class="position-relative">
            <div class="reply-box-example" *ngIf="reply">
              <i class="bi bi-reply"></i> {{reply.replyTxt}}
              <a class="reply-close" (click)="onRemoveReply()">
                <i class="bi bi-x-lg"></i>
              </a>
            </div>
            <div class="p-1 top-0">
              <form [formGroup]="form" (submit)="onEnter($event)">
                <div class="form-group position-relative">
                  <textarea class="form-control" formControlName="message" style="padding-right: 65px;"
                    (keypress)="onEnter($event)" (keyup)="onResetForm($event)" rows="2" placeholder="ใส่ข้อความ..."
                    emoji></textarea>
                  <a class="btn-send-message" href="javascript:;" (click)="onSubmit()">
                    <i class="bi bi-send"></i>
                  </a>
                </div>
              </form>
              <div class="p-2">
                <!-- <a class="me-2" href="javascript:;" appTooltip="emoji">
                                    <i class="bi bi-emoji-smile"></i>
                                </a>
                                <a class="me-2" href="javascript:;" appTooltip="แนบไฟล์">
                                    <i class="bi bi-paperclip"></i>
                                </a> -->
                <a class="me-2" href="javascript:;" appTooltip="รูป">
                  <label for="image-file">
                    <i class="bi bi-file-image"></i>
                    <input type="file" id="image-file" accept="image/*" style="display: none;"
                      (change)="inputFileChange($event)">
                  </label>
                </a>
                <a class="me-2" href="javascript:;">
                  <i class="bi bi-chat-square-quote-fill" appTooltip="message shortcut"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!ChatRoom">
        <div class="h-100">
          <div class="text-center fs-1 mt-4">
            <i class="bi bi-chat-dots"></i>
          </div>
          <div class="text-center mb-2 opacity-50">
            สร้างห้องแชทเพื่อเริ่มสนทนากับลูกค้า
          </div>
          <div class="text-center">
            <button class="btn btn-primary text-uppercase" (click)="CreateRoomChat()">
              <i class="bi bi-plus-square-dotted"></i> Create Chat Room
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!member">
      <div class="h-100">
        <div class="text-center fs-1 mt-4">
          <i class="bi bi-chat-dots"></i>
        </div>
        <div class="text-center mb-2 opacity-50">
          กรุณาเลือกแชท หรือ เลือกที่ลูกค้า
        </div>
        <div class="text-center mb-2 opacity-50" *ngIf="memberLoading">
          <div class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div> กำลังโหลดข้อมูลลูกค้า...
        </div>
      </div>
    </ng-container>
  </div>
  <div class="w-25 border-start h-100">
    <div class="list-user-online border-bottom">
      <div class="p-2 position-relative btn-list-chat" *ngFor="let data of adminOnline;let k = index;">
        <table class="w-100">
          <tr>
            <td class="text-center" style="width: 20px;">
              <img class="rounded-circle" style="width: 20px; height: 20px;object-fit: cover;"
                src="assets/mocks/operator.jpg">
            </td>
            <td class="position-relative">
              <p class="m-0">{{data.username}}</p>
              <small class="opacity-50">{{data.fullname}}</small>
              <div class="position-absolute top-0 end-0 me-1">
                <i class="fa-solid fa-circle text-success"></i> online
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="emoji">
      <button *ngFor="let emo of this.emoji.split(' ')" class="btn" style="width: 40px;"
        (click)="onEmoji(emo)">{{emo}}</button>
    </div>
  </div>
</div>
