<div class="p-1 border-bottom">
    <div class="input-group">
        <input type="text" class="form-control">
        <button type="submit" class="btn btn-outline-primary">
            <i class="bi bi-search"></i> ค้นหา
        </button>
    </div>
</div>
<div>
    <ul class="nav">
        <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active':tab === 'chat'}" href="javascript:;"
                (click)="[tab = 'chat',onChangeTab()]">
                <i class="bi bi-chat"></i> แชท
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active':tab === 'member'}" href="javascript:;"
                (click)="[tab = 'member',onChangeTab()]">
                <i class="bi bi-people"></i> ลูกค้าทั้งหมด
            </a>
        </li>
    </ul>
</div>
<div class="list-chat">
    <ng-container *ngIf="tab === 'chat'">
        <ng-container *ngFor="let data of ChatRoom">
            <div class="p-2 border-bottom position-relative btn-list-chat" (click)="onActiveMember(data.id)"
                [ngClass]="{'active':(svChartRoom?.ActiveMemberId | async) === data?.id}">
                <table class="w-100">
                    <tr>
                        <td class="text-center" style="width: 50px;">
                            <img class="rounded-circle" src="assets/mocks/profile.png" width="40px" alt="">
                        </td>
                        <td class="position-relative">
                            <p class="m-0">{{data.username}}</p>
                            <small class="opacity-50" *ngIf="data.last_message_type === 'text'"
                                style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;position: relative;  display: inline-block;width: 160px;">
                                <ng-container *ngIf="data.last_message">
                                    <span [innerHTML]="data.last_message"></span>
                                </ng-container>
                                <ng-container *ngIf="!data.last_message">
                                    {{data.last_message || 'ไม่มีข้อความ'}}
                                </ng-container>
                            </small>
                            <small class="opacity-50" *ngIf="data.last_message_type === 'image'">
                                <img src="{{data.last_message}}" width="10px" height="10px" alt=""> รูปภาพ
                            </small>
                            <div class="position-absolute top-0 end-0">
                                <small class="opacity-50">{{data.room_update_dt | fromNow}}</small>
                            </div>
                        </td>
                    </tr>
                </table>
                <div class="position-absolute top-50 end-0 me-2" *ngIf="data?.count_unread_member">
                    <span class="badge rounded-pill bg-danger">{{data.count_unread_member}}</span>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="tab === 'member'">
        <ng-container *ngFor="let data of memberItems?.items;">
            <div class="p-2 border-bottom position-relative btn-list-chat" (click)="onActiveMember(data.id)"
                [ngClass]="{'active':(svChartRoom?.ActiveMemberId | async) === data?.id}">
                <table class="w-100">
                    <tr>
                        <td class="text-center" style="width: 50px;">
                            <img class="rounded-circle" src="assets/mocks/profile.png" width="40px" alt="">
                        </td>
                        <td class="position-relative">
                            <p class="m-0">{{data.username}}</p>
                            <small class="opacity-50">{{data.fullname}}</small>
                        </td>
                    </tr>
                </table>
            </div>
        </ng-container>
        <div class="text-center p-4" *ngIf="!memberLoading">
            <a href="javascript:;">โหลดเพิ่มเติม</a>
        </div>
        <div class="text-center p-4" *ngIf="memberLoading">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </ng-container>
</div>
<small class="opacity-50 d-block text-center">Chat create by {{Config?.createBy?.name || 'KARNIVAL™'}}</small>