import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { Offcanvas } from 'bootstrap';
import { finalize } from 'rxjs/operators';
import { FADE_IN_OUT } from 'src/app/animations';
import { AccountBank, BankService } from 'src/app/apis/bank.service';
import { RequestConfirmModel, WithdrawService } from 'src/app/apis/withdraw.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { alertSuccess, alertText, alertWarning } from 'src/app/utils/functions/alert';
import { focusInvalid } from 'src/app/utils/functions/focus-invalid';

@Component({
  selector: 'app-confirm-withdraw-offcanvas',
  templateUrl: './confirm-withdraw-offcanvas.component.html',
  styleUrls: ['./confirm-withdraw-offcanvas.component.scss'],
  animations: [FADE_IN_OUT],
})
export class ConfirmWithdrawOffcanvasComponent extends AppCore implements OnInit, OnChanges {
  form = this.createForm();
  bankItems: AccountBank[] = [];
  saving = false;

  transferType = 'auto';

  @Input() withdrawId: number;
  @Input() canvas: Offcanvas;
  constructor(
    private svWithdraw: WithdrawService,
    private ngFb: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    setTimeout(() => {
      const rcOffcanvasEl = document.getElementById(`requestConfirmOffcanvas`);
      rcOffcanvasEl.addEventListener('hide.bs.offcanvas', () => {
        this.form = this.createForm();
      });
    }, 0);
  }

  ngOnChanges() {
    if (this.withdrawId) {
      this.form = this.createForm();
      this.form.patchValue({
        withdraw_id: this.withdrawId,
      });
    }
  }

  createForm(): FormGroup<RequestConfirmModel> {
    return this.ngFb.group({
      withdraw_id: [null, [Validators.required]],
      account_bank_agent: [''],
    });
  }

  onSubmit() {
    this.form.markAllAsDirty();
    this.form.markAllAsTouched();

    if (this.transferType === 'auto' && !this.form.value.account_bank_agent) {
      alertWarning({ message: `กรุณาเลือกบัญชีที่ใช้โอนเงิน!` });
      return;
    }

    if (!this.form.valid) {
      alertWarning({ message: alertText.formInvalid });
      focusInvalid();
      return;
    }

    this.saving = true;
    this.svWithdraw.putRequestConfirm(this.form.value).pipe(
      finalize(() => this.saving = false)
    ).subscribe(res => {
      alertSuccess({ message: alertText.saveSuccess });
      this.canvas.hide();
    });
  }
}
