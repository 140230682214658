<div class="offcanvas offcanvas-end" tabindex="-1" id="depositStatementMemberOffcanvas">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title">{{'ADD_CREDIT_TO_MEMBERS'|translate}}</h5>
    <button id="btn-close2" type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
  </div>
  <div class="offcanvas-body" *ngIf="item">
    <form [formGroup]="form" (submit)="onSubmit()">
      <div class="mb-3">
        <label for="" class="form-label">{{'CHOOSE_MEMBER'|translate}} <span class="text-danger">*</span></label>
        <app-select-member-form formControlName="member_id" [showCredit]="true">
        </app-select-member-form>
      </div>
      <div class="mb-3">
        <label for="" class="form-label">{{'BALANCE'|translate}}</label>
        <div class="form-control text-end" disabled> {{(item.amount || 0) | number: '0.2-2'}} </div>
      </div>
      <div class="mb-3">
        <label for="" class="form-label">{{'PROMOTION'|translate}}</label>
        <app-select-promotion-form formControlName="promotion_id"></app-select-promotion-form>
      </div>
      <div class="mb-3">
        <label for="" class="form-label">{{'NOTE'|translate}}</label>
        <textarea rows="3" class="form-control" formControlName="note"></textarea>
      </div>
      <div class="mb-3">
        <label for="" class="form-label">{{'PROOF_OF_TRANSFER'|translate}}</label>
        <div class="text-center">
          <label for="file-img" class="d-block mb-2 pointer">
            <img *ngIf="!form.value.image_statement" class="shadow-sm" src="/assets/placeholder.jpg" alt=""
              style="width: 300px; height: 300px; object-fit: contain;">
            <img *ngIf="form.value.image_statement" class="shadow-sm" [src]="form.value.image_statement" alt=""
              style="width: 300px; height: 300px; object-fit: contain;">
          </label>
          <label for="file-img" class="btn btn-outline-primary"> <i class="bi bi-upload"></i> {{'CHOOSE_PICTURE'|translate}} </label>
        </div>
        <input type="file" class="d-none" id="file-img" accept=".jpg,.jepg,.png" (change)="inputFileChange($event)">
      </div>

      <hr>
      <div class="mb-3 text-end">
        <button type="submit" class="btn btn-primary btn-submit" [appLoading]="saving">{{'SAVE_DATA'|translate}}</button>
      </div>
    </form>
  </div>
</div>
