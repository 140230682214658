import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AMBGameTypes, AMBOptions, AMBSettingGame, SettingGameMembersService } from 'src/app/apis/setting-game-members.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { randomTxt } from 'src/app/utils/functions/random-txt';

@Component({
  selector: 'app-setting-partner',
  templateUrl: './setting-partner.component.html',
  styleUrls: ['../setting-games.scss'],
})
export class SettingPartnerComponent extends AppCore implements OnInit, OnChanges {
  @Input() setting: AMBSettingGame;
  @Input() ambGame: AMBGameTypes;
  @Input() readOnly = false;

  nameGames = {
    'คีโน่': 'ตั้งค่าส่วนแบ่ง',
    'เทรด': 'ฮอตกราฟ',
    'อีสปอร์ต': 'อีสปอร์ต',
  }
  min = 0;
  max = 0;

  takingOption: AMBOptions[] = [];
  inputId = `partner-${randomTxt(5)}`;
  constructor(
    private svSettingGameMember: SettingGameMembersService
  ) {
    super();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.setting) {
      if (this.ambGame === 'KENO') {
        this.min = this.setting.group_init_member.maxSetting.shareSetting.other.minTg;
        this.max = this.setting.group_init_member.maxSetting.shareSetting.other.maxTg;
      } else if (this.ambGame === 'TRADE') {
        this.min = this.setting.agent_group_profile.shareSetting.other.hg.min;
        this.max = this.setting.agent_group_profile.shareSetting.other.hg.own;
      } else if (this.ambGame === 'E-SPORT') {
        this.min = 0;
        this.max = 0;
      }
      this.takingOption = this.svSettingGameMember.genOptions(0.5, this.max, this.min);
    }
  }

}
