import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@ngneat/reactive-forms';
import * as dayjs from 'dayjs';
import { finalize } from 'rxjs/operators';
import { ReportBill, ReportBillList, ReportBillListModel, ReportsService } from 'src/app/apis/reports.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { alertSuccess, alertText, alertWarning } from 'src/app/utils/functions/alert';
import { focusInvalid } from 'src/app/utils/functions/focus-invalid';

@Component({
  selector: 'app-bill-form-offcanvas',
  templateUrl: './bill-form-offcanvas.component.html',
  styleUrls: ['./bill-form-offcanvas.component.scss']
})
export class BillFormOffcanvasComponent extends AppCore implements OnInit, OnChanges {
  @Input() editBill: ReportBill;
  billItems: ReportBill;

  tabActive = 2;

  formDate = this.svReport.createFromBillDate;
  formNoneStable = this.svReport.createFromBillDate;
  formStepStable = this.svReport.createFromBillDate;
  formStepNonStable = this.svReport.createFromBillDate;
  form = this.svReport.createFormBill;
  saving = false;
  total = 0;

  type = 'winlose';

  constructor(
    private svReport: ReportsService,
  ) {
    super();
    this.getFormArray(this.form, 'lists').push(this.svReport.createFormBillList);
  }

  agentIdChange(): void {
    if (!this.currentAgentId) { return; }
    this.initForms();
  }

  ngOnInit(): void {
    setTimeout(() => {
      const billEl = document.getElementById(`billFormOffcanvas`);
      billEl.addEventListener('hide.bs.offcanvas', () => {
        this.initForms();
      });
    }, 0);
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this.editBill) {
      await this.getBillById();
      this.tabActive = 2;

      const billListForm = this.getFormArray(this.form, 'lists');
      billListForm.clear();

      this.billItems.lists.forEach(() => {
        this.getFormArray(this.form, 'lists').push(this.svReport.createFormBillList);
      });
      const due_date = dayjs(this.billItems.due_date).format('YYYY-MM-DD');
      this.form.patchValue({
        ...this.billItems,
        due_date,
      });
      this.listCalTotal();
    }
  }

  initForms() {
    const from = dayjs().format('YYYY-MM-DDT11:00');
    const to = dayjs().format('YYYY-MM-DDT11:00');
    this.saving = false;
    this.total = 0;
    this.tabActive = 2;

    this.formDate = this.svReport.createFromBillDate;
    this.formNoneStable = this.svReport.createFromBillDate;
    this.formStepStable = this.svReport.createFromBillDate;
    this.formStepNonStable = this.svReport.createFromBillDate;
    this.form = this.svReport.createFormBill;
    this.getFormArray(this.form, 'lists').push(this.svReport.createFormBillList);

    this.formDate.patchValue({ from, to, agent_id: this.currentAgentId });
    this.formNoneStable.patchValue({ from, to, agent_id: this.currentAgentId });
    this.formStepStable.patchValue({ from, to, agent_id: this.currentAgentId });
    this.formStepNonStable.patchValue({ from, to, agent_id: this.currentAgentId });
  }

  async onSubmit() {
    this.form.markAllAsDirty();
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      alertWarning({ message: alertText.formInvalid });
      focusInvalid();
      return;
    }

    if (!this.form.value.lists?.length) {
      alertWarning({ message: `ต้องมีอย่างน้อย 1 รายการ!` });
      return;
    }

    this.saving = true;
    try {
      if (!this.editBill) {
        await this.createBill();
      } else {
        await this.updateBill();
      }
      alertSuccess({ message: alertText.saveSuccess });
      this.clickBtnClose('close-bill-offcanvas');
    } finally {
      this.saving = false;
    }
  }

  addForm() {
    this.getFormArray(this.form, 'lists').push(this.svReport.createFormBillList);
  }
  removeFormAt(index: number) {
    this.getFormArray(this.form, 'lists').removeAt(index);
  }

  createBill() {
    return this.svReport.postBill(this.currentAgentId, this.type, this.form.value).toPromise();
  }
  updateBill() {
    return this.svReport.putBill(this.editBill.Id, this.form.value).toPromise();
  }

  listCalTotal() {
    this.getFormArray(this.form, 'lists').controls.forEach((group: FormGroup<ReportBillListModel>) => {
      let listTotal = 0;
      let listDiscountAmount = 0;
      if (group.value.type_discount === 'percent') {
        listDiscountAmount = ((group.value.discount || 0) / 100) * group.value.price;
      } else {
        listDiscountAmount = (group.value.discount || 0);
      }
      listTotal = group.value.price - listDiscountAmount;
      group.patchValue({ listDiscountAmount, listTotal });
    });

    const billTotal = (this.getFormArray(this.form, 'lists').value as ReportBillListModel[]).reduce((pre, val) => pre + (val.listTotal || 0), 0);
    this.form.get('billTotal').setValue(billTotal);

    this.billCalTotal();
  }

  billCalTotal() {
    let billGrandTotal = 0;
    let billDiscountAmount = 0;
    if (this.form.value.bill_discount_type === 'percent') {
      billDiscountAmount = ((this.form.value.bill_discount || 0) / 100) * this.form.value.billTotal;
    } else {
      billDiscountAmount = (this.form.value.bill_discount || 0);
    }
    billGrandTotal = this.form.value.billTotal - billDiscountAmount;
    this.form.patchValue({ billDiscountAmount, billGrandTotal });
  }

  onSubmitFormDate() {
    this.formDate.markAllAsDirty();
    this.formDate.markAllAsTouched();

    if (!this.formDate.valid) {
      alertWarning({ message: alertText.formInvalid });
      focusInvalid();
      return;
    }

    this.saving = true;
    this.svReport.postBillByDate(this.formDate.value).pipe(
      finalize(() => this.saving = false)
    ).subscribe(res => {
      alertSuccess({ message: alertText.saveSuccess });
      this.clickBtnClose('close-bill-offcanvas');
    });
  }

  getBillById() {
    return new Promise<boolean>((resolve, reject) => {
      this.svReport.getBillById(this.editBill.Id).pipe(finalize(() => resolve(true))).subscribe(res => {
        this.billItems = res;
      });
    });
  }

  onSubmitNonStable() {
    this.formNoneStable.markAllAsDirty();
    this.formNoneStable.markAllAsTouched();

    if (!this.formNoneStable.valid) {
      alertWarning({ message: alertText.formInvalid });
      focusInvalid();
      return;
    }

    this.saving = true;
    this.svReport.createBillNonStable(this.formNoneStable.value).pipe(
      finalize(() => this.saving = false)
    ).subscribe(res => {
      alertSuccess({ message: alertText.saveSuccess });
      this.clickBtnClose('close-bill-offcanvas');
    });
  }

  onSubmitStepStable() {
    this.formStepStable.markAllAsDirty();
    this.formStepStable.markAllAsTouched();

    if (!this.formStepStable.valid) {
      alertWarning({ message: alertText.formInvalid });
      focusInvalid();
      return;
    }

    this.saving = true;
    this.svReport.postBillStepStable(this.formStepStable.value).pipe(
      finalize(() => this.saving = false)
    ).subscribe(res => {
      alertSuccess({ message: alertText.saveSuccess });
      this.clickBtnClose('close-bill-offcanvas');
    });
  }

  onSubmitStepNonStable() {
    this.formStepNonStable.markAllAsDirty();
    this.formStepNonStable.markAllAsTouched();

    if (!this.formStepNonStable.valid) {
      alertWarning({ message: alertText.formInvalid });
      focusInvalid();
      return;
    }

    this.saving = true;
    this.svReport.postBillStepNonStable(this.formStepNonStable.value).pipe(
      finalize(() => this.saving = false)
    ).subscribe(res => {
      alertSuccess({ message: alertText.saveSuccess });
      this.clickBtnClose('close-bill-offcanvas');
    });
  }
}
