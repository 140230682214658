import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertBetFTHT'
})
export class ConvertBetFTHTPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case 'FTHT_HH':
        return 'HH';
      case 'FTHT_HD':
        return 'HD';
      case 'FTHT_HA':
        return 'HA';
      case 'FTHT_DH':
        return 'DH';
      case 'FTHT_DD':
        return 'DD';
      case 'FTHT_DA':
        return 'DA';
      case 'FTHT_AH':
        return 'AH';
      case 'FTHT_AD':
        return 'AD';
      case 'FTHT_AA':
        return 'AA';
    }
    return value;
  }

}
