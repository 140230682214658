import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ReportBill } from 'src/app/apis/reports.service';
import { LocalName, LocalStorageService } from 'src/app/services/local-storage.service';
import { AppCore } from 'src/app/utils/classes/app-core';
import { AppPath } from 'src/app/utils/constants';

@Component({
  selector: 'app-bill-modal',
  templateUrl: './bill-modal.component.html',
  styleUrls: ['./bill-modal.component.scss']
})
export class BillModalComponent extends AppCore implements OnInit, OnChanges {
  @Input() bill: ReportBill;
  url = '';

  constructor(
    private svLocalStorage: LocalStorageService,
  ) {
    super();

    const storageChange = async (e) => {
      if (this.svLocalStorage.get(LocalName.saveBill) === 'success') {
        this.clickBtnClose();
      }
    };
    window.addEventListener('storage', storageChange);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.bill) {
      this.url = `${location.origin}/${AppPath.prints}/bill/${this.bill?.Id}`;
    }
  }

  override clickBtnClose(): void {
    super.clickBtnClose('btn-close-bill-modal');
  }
}
