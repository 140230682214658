import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PrimeNGConfig } from 'primeng/api';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {};
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PerfectScrollbarModule,
  ],
  exports: [
    PerfectScrollbarModule,
  ],
  providers: [
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
  ]
})
export class PerfactScrollBarModule {
  constructor(
    private primengConfig: PrimeNGConfig,
  ) {
    this.primengConfig.ripple = true;
  }
}
