<div class="offcanvas offcanvas-end" tabindex="-1" id="billFormOffcanvas" style="width: 70em;">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasRightLabel" *ngIf="!editBill">{{'CREATE'|translate}} Invoice</h5>
    <h5 class="offcanvas-title" id="offcanvasRightLabel" *ngIf="editBill">{{'EDIT'|translate}} Invoice</h5>
    <button id="close-bill-offcanvas" type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
  </div>
  <div class="offcanvas-body">
    <ul class="nav nav-tabs" *ngIf="!editBill">
      <!-- <li class="nav-item">
        <a class="nav-link" [class.active]="tabActive === 1" (click)="tabActive = 1">Stable</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.active]="tabActive === 2" (click)="tabActive = 2">Non Stable</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.active]="tabActive === 3" (click)="tabActive = 3">Step Stable</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.active]="tabActive === 4" (click)="tabActive = 4">Step Non Stable</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.active]="tabActive === 5" (click)="tabActive = 5">กำหนดเอง</a>
      </li> -->
      <li class="nav-item">
        <a class="nav-link" [class.active]="tabActive === 2" (click)="tabActive = 2">Normal</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.active]="tabActive === 4" (click)="tabActive = 4">Step</a>
      </li>
    </ul>
    <div class="px-2 py-4" *ngIf="tabActive === 1">
      <form [formGroup]="formDate" (submit)="onSubmitFormDate()">
        <div class="mb-3">
          <div class="row">
            <div class="col-12 col-md-6">
              <label for="" class="form-label">{{'FROM_DATE'|translate}}</label>
              <input type="datetime-local" class="form-control" formControlName="from">
            </div>
            <div class="col-12 col-md-6">
              <label for="" class="form-label">{{'TO_DATE'|translate}}</label>
              <input type="datetime-local" class="form-control" formControlName="to">
            </div>
          </div>
        </div>
        <div class="text-end mb-3">
          <button type="submit" class="btn btn-primary btn-submit" [appLoading]="saving"> {{'CREATE'|translate}} Invoice </button>
        </div>
      </form>
    </div>
    <div class="px-2 py-4" *ngIf="tabActive === 2">
      <form [formGroup]="formNoneStable" (submit)="onSubmitNonStable()">
        <div class="mb-3">
          <div class="row">
            <div class="col-12 col-md-6">
              <label for="" class="form-label">{{'FROM_DATE'|translate}}</label>
              <input type="datetime-local" class="form-control" formControlName="from">
            </div>
            <div class="col-12 col-md-6">
              <label for="" class="form-label">{{'TO_DATE'|translate}}</label>
              <input type="datetime-local" class="form-control" formControlName="to">
            </div>
          </div>
        </div>
        <div class="text-end mb-3">
          <button type="submit" class="btn btn-primary btn-submit" [appLoading]="saving"> {{'CREATE'|translate}} Invoice </button>
        </div>
      </form>
    </div>
    <div class="px-2 py-4" *ngIf="tabActive === 3">
      <form [formGroup]="formStepStable" (submit)="onSubmitStepStable()">
        <div class="mb-3">
          <div class="row">
            <div class="col-12 col-md-6">
              <label for="" class="form-label">{{'FROM_DATE'|translate}}</label>
              <input type="datetime-local" class="form-control" formControlName="from">
            </div>
            <div class="col-12 col-md-6">
              <label for="" class="form-label">{{'TO_DATE'|translate}}</label>
              <input type="datetime-local" class="form-control" formControlName="to">
            </div>
          </div>
        </div>
        <div class="text-end mb-3">
          <button type="submit" class="btn btn-primary btn-submit" [appLoading]="saving"> {{'CREATE'|translate}} Invoice </button>
        </div>
      </form>
    </div>
    <div class="px-2 py-4" *ngIf="tabActive === 4">
      <form [formGroup]="formStepNonStable" (submit)="onSubmitStepNonStable()">
        <div class="mb-3">
          <div class="row">
            <div class="col-12 col-md-6">
              <label for="" class="form-label">{{'FROM_DATE'|translate}}</label>
              <input type="datetime-local" class="form-control" formControlName="from">
            </div>
            <div class="col-12 col-md-6">
              <label for="" class="form-label">{{'TO_DATE'|translate}}</label>
              <input type="datetime-local" class="form-control" formControlName="to">
            </div>
          </div>
        </div>
        <div class="text-end mb-3">
          <button type="submit" class="btn btn-primary btn-submit" [appLoading]="saving"> {{'CREATE'|translate}} Invoice </button>
        </div>
      </form>
    </div>
    <div class="px-2 py-4" *ngIf="tabActive === 5">
      <form [formGroup]="form" (submit)="onSubmit()">
        <div class="mb-3">
          <label for="" class="form-label"> {{'TYPE'|translate}} </label>
          <select class="form-select" [(ngModel)]="type" [ngModelOptions]="{standalone:true}">
            <option value="winlose">winlose</option>
            <option value="winlose_stable">winlose_stable</option>
          </select>
        </div>
        <div class="mb-3">
          <label for="" class="form-label"> {{'NAME'|translate}} Invoice </label>
          <input type="text" class="form-control" formControlName="bill_name">
        </div>
        <div class="mb-3">
          <label for="" class="form-label"> {{'DUE_DATE'|translate}} </label>
          <input type="date" class="form-control" formControlName="due_date">
        </div>
        <div class="text-end mb-3">
          <button type="button" class="btn btn-success" (click)="addForm()"> {{'ADD_ITEM'|translate}} </button>
        </div>
        <table class="table table-bordered mb-3">
          <thead>
            <tr>
              <th>{{'ITEM'|translate}}</th>
              <th>WinLose</th>
              <th>Amount</th>
              <th>Valid Amount</th>
              <th>{{'BALANCE'|translate}}</th>
              <th>{{'DISCOUNT'|translate}}</th>
              <th>{{'SUM'|translate}}</th>
              <th></th>
            </tr>
          </thead>
          <tbody formArrayName="lists">
            <tr *ngFor="let item of getFormArray(form, 'lists').controls; let i = index" [formGroupName]="i">
              <td>
                <input type="text" class="form-control m-1" formControlName="list_name" [placeholder]="'ITEM_NAME'|translate">
                <textarea rows="2" class="form-control m-1" formControlName="note" [placeholder]="'NOTE'|translate"></textarea>
              </td>
              <td style="width: 90px;">
                <input type="number" class="form-control text-end" formControlName="amount">
              </td>
              <td style="width: 90px;">
                <input type="number" class="form-control text-end" formControlName="valid_amount">
              </td>
              <td style="width: 90px;">
                <input type="number" class="form-control text-end" formControlName="winlose">
              </td>
              <td style="width: 90px;">
                <input type="number" class="form-control text-end" formControlName="price" (change)="listCalTotal()">
              </td>
              <td style="width: 90px;">
                <div>
                  <div class="text-start">
                    <div class="form-check me-2">
                      <input type="radio" class="form-check-input" formControlName="type_discount" value="amount"
                        id="type_discount_amount_{{i}}" (change)="listCalTotal()">
                      <label for="type_discount_amount_{{i}}" class="form-check-label">{{'AMOUNT'|translate}}</label>
                    </div>
                    <div class="form-check me-2">
                      <input type="radio" class="form-check-input" formControlName="type_discount" value="percent"
                        id="type_discount_percent_{{i}}" (change)="listCalTotal()">
                      <label for="type_discount_percent_{{i}}" class="form-check-label">{{'PERCENT'|translate}}(%)</label>
                    </div>
                  </div>
                </div>
                <input type="number" class="form-control text-end my-2" formControlName="discount"
                  (change)="listCalTotal()">
                <div class="text-end" *ngIf="item.value.type_discount === 'percent'">
                  {{item.value.listDiscountAmount | number:'1.2-2'}}
                </div>
              </td>
              <td style="width: 90px;" class="text-end">
                {{item.value.listTotal | number:'1.2-2'}}
              </td>
              <td>
                <button type="button" class="btn text-danger" (click)="removeFormAt(i)" [appTooltip]="'DELETE'|translate">
                  <i class="fas fa-times"></i>
                </button>
              </td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th class="text-start" colspan="4" rowspan="3" style="vertical-align: top;">
                <strong> {{'NOTE'|translate}} </strong>
                <textarea rows="2" class="form-control m-1" formControlName="note" placeholder=""></textarea>
              </th>
              <th colspan="2" class="text-end">
                รวม
              </th>
              <th class="text-end">
                {{form.get('billTotal').value | number: '1.2-2'}}
              </th>
              <th></th>
            </tr>
            <tr>
              <th colspan="2">
                <div class="text-end">{{'DISCOUNT'|translate}}</div>
                <div class="d-flex justify-content-end">
                  <div class="text-start">
                    <div class="form-check form-check-inline me-2">
                      <input type="radio" class="form-check-input" formControlName="bill_discount_type" value="amount"
                        id="bill_discount_type_amount" (change)="billCalTotal()">
                      <label for="bill_discount_type_amount" class="form-check-label">{{'AMOUNT'|translate}}</label>
                    </div>
                    <div class="form-check form-check-inline me-2">
                      <input type="radio" class="form-check-input" formControlName="bill_discount_type" value="percent"
                        id="bill_discount_type_percent" (change)="billCalTotal()">
                      <label for="bill_discount_type_percent" class="form-check-label">{{'PERCENT'|translate}}(%)</label>
                    </div>
                  </div>
                </div>
              </th>
              <th>
                <input type="number" class="form-control text-end" formControlName="bill_discount" max="100"
                  (change)="billCalTotal()">
                <div class="mt-2 text-end" *ngIf="form.value.bill_discount_type === 'percent'">
                  {{form.value.billDiscountAmount|number:'1.2-2'}}
                </div>
              </th>
              <th></th>
            </tr>
            <tr>
              <th colspan="2" class="text-end">
                {{'SUM'|translate}}
              </th>
              <th class="text-end">
                {{form.get('billGrandTotal').value | number: '1.2-2'}}
              </th>
              <th></th>
            </tr>
          </thead>
        </table>
        <div class="text-end mb-3">
          <button type="submit" class="btn btn-submit btn-primary" *ngIf="!editBill" [appLoading]="saving">
            {{'CREATE'|translate}} Invoice</button>
          <button type="submit" class="btn btn-submit btn-primary" *ngIf="!!editBill" [appLoading]="saving">
            {{'EDIT'|translate}} Invoice</button>
        </div>
      </form>
    </div>
  </div>
</div>
