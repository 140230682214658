import { SignalrBankService } from './services/signalr-bank.service';
import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { PublicComponent, SecureComponent } from './layouts';
import { publicRoutes, secureRoutes } from './routes';
import { SharedModule } from './shared';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { setAppInjector } from './app-injector';
import { AppInterceptorProviders } from './app.interceptor';
import { AuthGuard } from './guards/auth.guard';
import { NoAuthGuard } from './guards/no-auth.guard';
import { AppPath } from './utils/constants/paths';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SettingsService } from './apis/settings.service';
import { ChatroomService } from './services/chatroom.service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    PublicComponent,
    SecureComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot([
      {
        path: '',
        canActivateChild: [NoAuthGuard],
        component: PublicComponent,
        children: publicRoutes,
      },
      {
        path: AppPath.apiDoc,
        loadChildren: () => import('./pages').then(C => C.ApiDocModule),
      },
      {
        path: '',
        canActivateChild: [AuthGuard],
        component: SecureComponent,
        children: secureRoutes,
      },
      {
        path: AppPath.prints,
        component: PublicComponent,
        loadChildren: () => import('./pages').then(C => C.PrintsModule),
      },
      { path: AppPath.notFound, loadChildren: () => import('./pages').then(m => m.NotFoundModule) },
      { path: '**', redirectTo: '', pathMatch: 'full' },
    ]),
    SharedModule,
    BrowserAnimationsModule,
    LoadingBarHttpClientModule,
    LoadingBarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      defaultLanguage: 'th'
    }),
  ],
  providers: [
    AppInterceptorProviders,
    SignalrBankService,
    SettingsService,
    ChatroomService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
    setAppInjector(injector);
  }
}
