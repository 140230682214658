<div class="modal fade" id="billModal">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content" style="height: auto;">
      <div class="modal-header border-0">
        <h5 class="modal-title">Bill</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          id="btn-close-bill-modal"></button>
      </div>
      <div class="modal-body">
        <app-iframe-print *ngIf="bill" [iFrameUrl]="url"></app-iframe-print>
      </div>
      <div class="modal-footer border-0">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{'CLOSE'|translate}}</button>
      </div>
    </div>
  </div>
</div>
