import { animate, state, style, transition, trigger } from '@angular/animations';

/**
 * Alias 'void => *' is ':enter'
 * Alias '* => void' is ':leave'
 */
export const FADE_IN_OUT = trigger(
  'fadeInOut', [
  state('void', style({ opacity: 0 })),
  state('*', style({ opacity: 1 })),
  transition(':enter', [
    animate(500, style({ opacity: 1 }))
  ]),
  transition(':leave', [
    animate(300, style({ opacity: 0, height: 0, overflow: 'hidden' }))
  ]),
]);
