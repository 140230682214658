<div class="" *ngIf="!isRealZean">
  <div class="row">
    <div class="col-md mb-2">
      <label for="" class="form-label"> {{'SELECT_SAGE'|translate}} </label>
      <select class="form-select" [(ngModel)]="sageId" (change)="selectSageChange()">
        <option value="" disabled> {{'SELECT_SAGE'|translate}}... </option>
        <option *ngFor="let item of sageItems" [ngValue]="item.id"> {{item.name}} ({{item.username}}) </option>
      </select>
    </div>
    <div class="col-md"></div>
    <div class="col-md"></div>
  </div>
  <hr>
</div>

<div class="" *ngIf="sageId">
  <ng-content></ng-content>
</div>
