import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { HttpApiService } from '../services/http-api.service';

@Injectable({
  providedIn: 'root'
})
export class MetaTagService {

  constructor(
    private http: HttpApiService,
    private ngFb: FormBuilder,
  ) { }

  get(agent_id: any, tag_page = '') {
    return this.http.get<MetaTag[]>(`api/ask4bet/meta_tag?agent_id=${agent_id || ''}&tag_page=${tag_page}`);
  }

  post(agent_id: any, model: MetaTagModel) {
    return this.http.post(`api/ask4bet/meta_tag?agent_id=${agent_id || ''}`, model);
  }

  get createForm(): FormGroup<MetaTagModel> {
    const form: FormGroup<MetaTagModel> = this.ngFb.group({
      tag_page: ['', [Validators.required, Validators.maxLength(50)]],
      tags: this.ngFb.array([]),
    });
    this.setDefaultFormArray(form);
    return form;
  }

  get createTagForm(): FormGroup<TagModel> {
    return this.ngFb.group({
      tag_key: ['', [Validators.required, Validators.maxLength(50)]],
      tag_content: ['', [Validators.required, Validators.maxLength(500)]],
    });
  }

  setDefaultFormArray(form: FormGroup<MetaTagModel>) {
    const keys = ['title', 'description', 'url', 'image'];
    this.getFormArray(form, 'tags').clear();
    keys.forEach(k => {
      const tagGroup = this.createTagForm;
      tagGroup.get('tag_key').disable();
      tagGroup.get('tag_key').setValue(k);
      this.getFormArray(form, 'tags').push(tagGroup);
    });
  }

  getFormArray<T = any>(group: AbstractControl | FormGroup, name: string) {
    return group.get(name) as FormArray<FormGroup<T>>;
  }
}

export const MetaTagPages = [
  'HOME',
  'PROMOTION',
  'DEMO-GAME',
  'ARTICLE',
  'LOGIN',
  'REGISTER',
];

export interface MetaTagModel {
  tag_page: string;
  tags: TagModel[];
}

export interface TagModel {
  tag_key: string;
  tag_content: string;
}

export interface MetaTag {
  agent_id?: any;
  tag_page: string;
  tags: Tag[];
}

export interface Tag {
  id: number;
  tag_key: string;
  tag_content: string;
  created_at: string;
  updated_at: string;
  updated_by: number;
}
