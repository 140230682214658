<div class="offcanvas offcanvas-end" tabindex="-1" id="commissionGamesOffcanvas" style="width: 60em;">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasRightLabel">Commission Games</h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
  </div>
  <div class="offcanvas-body">
    <div class="table-reponsive">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Game Name</th>
            <th>WinLose</th>
            <th>Agent</th>
            <th>Master Agent</th>
            <th>Senior Agent</th>
            <th>Shareholder</th>
            <th>System</th>
            <th>Provider</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of commissionGamesItems">
            <td> {{item.game_name}} </td>
            <td class="text-end">
              {{item.winlose | number: '1.2-2'}}
            </td>
            <td class="text-end">
              {{item.agent | number: '1.2-2'}}
            </td>
            <td class="text-end">
              {{item.master | number: '1.2-2'}}
            </td>
            <td class="text-end">
              {{item.senior | number: '1.2-2'}}
            </td>
            <td class="text-end">
              {{item.shareholder | number: '1.2-2'}}
              <div class="opacity-75">
                <small>{{item.shareholder_percent | number: '1.2-2'}}%</small>
              </div>
            </td>
            <td class="text-end">
              {{item.system | number: '1.2-2'}}
              <div class="opacity-75">
                <small>{{item.system_percent | number: '1.2-2'}}%</small>
              </div>
            </td>
            <td class="text-end">
              {{item.provider | number: '1.2-2'}}
              <div class="opacity-75">
                <small>{{item.provider_percent | number: '1.2-2'}}%</small>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="text-center">
      <div class="mb-3" *ngIf="commissionGamesItems && !commissionGamesItems?.length">ไม่มีข้อมูล</div>
      <div class="mb-3" *ngIf="loading"> <span class="spinner-border"></span>
      </div>
    </div>
  </div>
</div>
