import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { AppCore } from 'src/app/utils/classes/app-core';

@Component({
  selector: 'app-agent-content',
  templateUrl: './agent-content.component.html',
  styleUrls: ['./agent-content.component.scss']
})
export class AgentContentComponent extends AppCore implements OnInit, OnChanges, OnDestroy {
  agentId: number;
  constructor() {
    super();

    const agentId$ = this.svApp.getAgentId$.pipe(debounceTime(300)).subscribe(res => {
      this.agentId = res;
    });
    const updateAgentId$ = this.svApp.updateAgentId$.subscribe(() => {
      this.agentId = null;
    });
    this.Subscriptions.add(agentId$);
    this.Subscriptions.add(updateAgentId$);
  }

  ngOnInit(): void {
  }
  ngOnChanges() {
  }

}
